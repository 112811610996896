export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    CommentContainer: ['Goal', 'Project'],
    Node: [
      'Answer',
      'Comment',
      'CreatedUser',
      'CustomField',
      'DefaultCustomField',
      'Goal',
      'GoalUpdate',
      'HelpPointer',
      'Kudos',
      'KudosRecipient',
      'Learning',
      'Link',
      'Milestone',
      'Onboarding',
      'Project',
      'ProjectChangelogItem',
      'ProjectDependency',
      'ProjectFusion',
      'ProjectUpdate',
      'Question',
      'Status',
      'Tag',
      'Team',
      'UpdateNote',
      'User',
      'UserInvite',
      'UserTag',
      'Workspace',
      'WorkspaceMember',
    ],
    Update: ['GoalUpdate', 'ProjectUpdate'],
    UpvoteEntities: ['Answer', 'Question'],
  },
};
export default result;
