import { OptionsType } from '@atlaskit/select';
import { ApolloClient } from '@apollo/client';

//#region FilterDoc
export enum SupportedFiltersTypes {
  LABEL = 'label',
  GOAL_SCORE = 'score',
  GOAL_STATUS = 'status_phase',
  PROJECT_STATUS = 'status_phase',
  OWNER = 'owner',
  CONTRIBUTOR = 'contributor',
  WATCHER = 'watcher',
  TEAM = 'team',
  PROJECT = 'project',
  GOAL = 'goal',
  JOB_TITLE = 'job_title',
  MANAGER = 'manager',
  DEPARTMENT = 'department',
  LOCATION = 'location',
  TEAM_MEMBER = 'team_member',
  ENTITY = 'entity',
  TAW = 'taw',
  PILLAR = 'pillar',
  TARGET_DATE = 'target_date',
  IMPACT = 'impact',
  LIKELIHOOD = 'likelihood',
  RISK_STATUS = 'risk_status',
}

export type SupportedFilters = SupportedFiltersTypes;

export enum Operators {
  OR = 'or',
  AND = 'and',
}

export enum ComparatorOperator {
  EQUALS = '=',
  NOT_EQUALS = '!=',
  LIKE = 'LIKE',
  GT = '>',
  GTE = '>=',
  LT = '<',
  LTE = '<=',
}

export type Content = string | number;
export interface Operator {
  operator: Operators;
}
//#endregion

//#region Resolvers
export enum OptionTypes {
  SELECT = 'select',
  DATE_PICKER = 'date_picker',
  STATUS_PICKER = 'status_picker',
}

export interface ResolveLabelsConfig {
  values: Content[];
  client: ApolloClient<object>;
  workspaceId?: string;
}

export interface BaseResolver {
  type: SupportedFilters;
  title: string;
  placeholder?: string;
  icon?: JSX.Element;
  operators: Operators[];
  resolveLabels: (config: ResolveLabelsConfig) => Promise<SelectResolverOptions>;
}

export type SelectResolverOption = {
  /**
   * The string that will show up inside the
   * filter lozenges
   */
  label: string;
  /**
   * This is value that will be sent to Graph
   * for resolution of results and labels.
   */
  value: Content;
  /**
   * An optional icon to provide the select
   * that will prefix the label text
   */
  icon?: JSX.Element;
  /**
   * If a component is provided it will be used
   * to render the option instead of label / icon combination
   */
  component?: JSX.Element;
};
export type SelectResolverOptions = OptionsType<SelectResolverOption>;

export interface ResolveOptionsConfig {
  client: ApolloClient<object>;
  query?: string;
  workspaceId?: string;
  isAssetsEnabled?: boolean;
}
export interface SelectResolver extends BaseResolver {
  optionType: OptionTypes.SELECT;
  resolveOptions: (config: ResolveOptionsConfig) => Promise<SelectResolverOptions>;
}

export interface DatePickerResolver extends Omit<BaseResolver, 'operators' | 'resolveLabels'> {
  optionType: OptionTypes.DATE_PICKER;
}

export interface StatusPickerResolver extends BaseResolver {
  optionType: OptionTypes.STATUS_PICKER;
  resolveOptions: (config: ResolveOptionsConfig) => Promise<SelectResolverOptions>;
}

// Extend this with more resolver types
export type Resolver = SelectResolver | DatePickerResolver | StatusPickerResolver;
export type Resolvers = Resolver[];
//#endregion
