import Loadable from 'react-loadable';
import { RouteContext } from 'react-resource-router';

import { LoadableErrorHandler } from '@shared/LoadableErrorHandler';

export const InitiativeListPage = Loadable<RouteContext, never>({
  loader: () =>
    import(/* webpackChunkName: "InitiativeListPage" */ './InitiativeListPage').then(
      ({ InitiativeListPage }) => InitiativeListPage
    ),
  loading: LoadableErrorHandler,
});
