import { gql } from '@apollo/client';

export const SET_TAGS = gql`
  mutation SetTags($id: ID!, $tagUuids: [String]!) {
    setTags(input: { id: $id, tagUuids: $tagUuids }) {
      tags {
        id
        name
        uuid
      }
    }
  }
`;

export const CREATE_TAG = gql`
  mutation CreateTag($name: String!, $workspaceId: ID!) {
    createTag(input: { name: $name, workspaceId: $workspaceId }) {
      tag {
        id
        uuid
        name
      }
    }
  }
`;
