import { Dispatch, ReactNode, SetStateAction, useCallback, useState } from 'react';

import { Project } from '@queries/SearchProjects';
import { Entity } from 'src/graphql/types';

import { CreationForm } from './CreationForm';
import { ProjectListWithSearch } from './ProjectListWithSearch';
import { CreationDialogContainer } from './styles';

type Props = {
  type: Entity;
  programNameTagName?: string;
  initiativeNameTagName?: string;
  setInitialFocusRef: Dispatch<SetStateAction<HTMLElement | null>>;
  scheduleUpdate: () => void;
  onSubmitSuccess: (tagName: string) => void;
  validateUniqueness?: (tagName: string) => Promise<boolean | undefined>;
  projectsListHeaderText: string;
  onClosePopup: () => void;
  handleBackToMainMenu?: () => void;
  onValidationMessage: (type: Entity, project?: Project) => string | ReactNode;
};

export const CreationDialog = ({
  type,
  programNameTagName,
  initiativeNameTagName,
  setInitialFocusRef,
  scheduleUpdate,
  onSubmitSuccess,
  validateUniqueness,
  projectsListHeaderText,
  handleBackToMainMenu,
  onClosePopup,
  onValidationMessage,
}: Props) => {
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const nameState = useState<string>('');
  const projectsState = useState<Project[]>([]);

  const selectProject = useCallback(
    (project: Project | null) => {
      setSelectedProject(project);
      scheduleUpdate();
    },
    [setSelectedProject, scheduleUpdate]
  );

  const onBackButtonClick = useCallback(() => {
    selectProject(null);
    scheduleUpdate();
  }, [selectProject, scheduleUpdate]);

  return (
    <CreationDialogContainer>
      {!selectedProject ? (
        <ProjectListWithSearch
          type={type}
          nameState={nameState}
          projectsState={projectsState}
          onSelectProject={selectProject}
          setInitialFocusRef={setInitialFocusRef}
          scheduleUpdate={scheduleUpdate}
          projectsListHeaderText={projectsListHeaderText}
          handleBackToMainMenu={handleBackToMainMenu}
        />
      ) : (
        <CreationForm
          type={type}
          project={selectedProject}
          programNameTagName={programNameTagName}
          initiativeNameTagName={initiativeNameTagName}
          onBackButtonClick={onBackButtonClick}
          validateUniqueness={validateUniqueness}
          onValidationMessage={onValidationMessage}
          onSubmitSuccess={onSubmitSuccess}
          onClosePopup={onClosePopup}
        />
      )}
    </CreationDialogContainer>
  );
};
