import styled from 'styled-components';

const Wrapper = styled.div`
  color: red;
  overflow: auto;
`;

type Props = {
  error: Error;
};

export const LoadableErrorHandler = ({ error }: Props) => {
  if (!error) {
    return null;
  }

  return (
    <Wrapper>
      <pre>{error.message}</pre>
      <pre>{error.stack}</pre>
    </Wrapper>
  );
};
