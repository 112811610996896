import Loadable from 'react-loadable';
import { RouteContext } from 'react-resource-router';

import { LoadableErrorHandler } from '@shared/LoadableErrorHandler';

export type ProgramPageProps = RouteContext;

export const MilestonesPage = Loadable<ProgramPageProps, never>({
  loader: () => import('./MilestonesPage').then(({ MilestonesPage }) => MilestonesPage),
  loading: LoadableErrorHandler,
});
