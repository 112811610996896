import { useCallback } from 'react';
import { ContentProps } from '@atlaskit/popup';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import { N300 } from '@atlaskit/theme/colors';
import { Link } from 'react-resource-router';
import Tooltip from '@atlaskit/tooltip';

import { ParentPrograms } from '@shared/Header/Header';
import { Actions, useAnalytics } from '@hooks/useAnalytics';

type PopupContentProps = ContentProps & {
  parentPrograms?: ParentPrograms[];
  source: string;
};

export const ParentProgramsPopup = ({ parentPrograms, source }: PopupContentProps) => {
  const { sendUIEvent } = useAnalytics();
  const handleClick = useCallback(() => {
    sendUIEvent({
      action: Actions.CLICKED,
      actionSubject: 'button',
      actionSubjectId: 'breadcrumb',
      source: source,
    });
  }, [sendUIEvent, source]);

  const cssFn = useCallback((defaultStyles) => ({ ...defaultStyles, padding: '4px 20px', fontSize: '11px' }), []);

  return (
    <MenuGroup maxWidth={200} testId="menu-items">
      <Section>
        {parentPrograms &&
          parentPrograms.map((program) => (
            <Link
              onClick={handleClick}
              key={program.name}
              href={program.link}
              target="_self"
              style={{ textDecorationColor: N300 }}
            >
              <Tooltip content={program.name}>
                <ButtonItem testId={`parent-program-${program.name}`} cssFn={cssFn}>
                  <span style={{ color: N300 }}>{program.name}</span>
                </ButtonItem>
              </Tooltip>
            </Link>
          ))}
      </Section>
    </MenuGroup>
  );
};
