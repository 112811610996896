import { ReactNode, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { ProfilecardTriggerPosition } from '@atlaskit/profilecard';

import { Initiative, Program } from '@program/children/utils/program';
import { ProjectStateValue } from '@generated/graphql';
import { Project, ProjectUpdate, State } from '@queries/GetProjectPageItems';
import { getConfig } from 'src/config';
import { CollapsibleCard } from '@tc/Card/CollapsibleCard';
import { FuzzyDate } from '@tc/FuzzyDate';
import { NoteIcon } from '@tc/Icons/NoteIcon';
import { TargetDateAutoUpdatedCard } from '@tc/TargetDateAutoUpdatedCard';
import { Renderer } from '@tc/Renderer';
import { StatusOrPhaseLozenge } from '@tc/StatusOrPhaseLozenge';
import { UpdateCard, RenderCollapsibleExtrasCallback } from '@tc/UpdateCard';
import { UpdateDiff } from '@tc/UpdateCard/UpdateDiff';
import { StatusSummaryBadges, SummaryItemTypeToCountMap } from '@shared/StatusSummaryBadges';
import { getProjectTawPhase } from '@shared/TawBreakdown/utils';
import { useTawPhaseStore } from '@store/tawPhaseStore';
import { isEmptyDoc } from 'src/utils/adf/adf';
import { DepartmentProject } from '@queries/GetDepartmentProjects';

import { MissedUpdateLabel } from './MissedUpdateLabel';
import { NoteTitle, TawPhase } from './styles';

export interface ProjectUpdateCardProps {
  update: ProjectUpdate;
  project: Project | DepartmentProject;
  renderHeader?: () => ReactNode;
  renderFooterExtras?: RenderCollapsibleExtrasCallback;
  isCollapsible?: boolean;
  isExpanded?: boolean;
  summaryItemTypeToCountMap?: SummaryItemTypeToCountMap;
  profileCardPosition?: ProfilecardTriggerPosition;
  isTransparent?: boolean;
  shouldAddTabs?: boolean;
  isTargetWeekUpdate?: boolean;
  isShowProjectStatus?: boolean;
  isUserAvatarFixedWidth?: boolean;
  onClickCollapseButton?: (id: string) => void;
}

const createTeamCentralUrl = (paths: string[] = []) => `${getConfig().teamCentralUrl}/${paths.join('/')}`;

export const getUpdateShareLink = (projectId: string, updateId: string) =>
  createTeamCentralUrl(['project', projectId, updateId]);

const CardTop = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
`;

const FuzzyDateWrapper = styled.div`
  margin-left: 8px;
`;

export type CardTopExtrasProps = {
  targetDate: string;
  targetDateConfidence: number;
  state: State;
  tawPhase?: string;
  summaryItemTypeToCountMap?: SummaryItemTypeToCountMap;
  isShowProjectStatus?: boolean;
};

export const CardTopExtras = (props: CardTopExtrasProps) => {
  const { isShowProjectStatus = true } = props;

  return (
    <CardTop>
      <TawPhase isEmpty={false}>{props.tawPhase}</TawPhase>
      {isShowProjectStatus ? <StatusOrPhaseLozenge state={props.state} /> : null}
      {props.state.value !== ProjectStateValue.Pending && (
        <FuzzyDateWrapper>
          <FuzzyDate
            value={{
              date: props.targetDate,
              confidence: props.targetDateConfidence,
            }}
            placeholder="N/A"
          />
        </FuzzyDateWrapper>
      )}
      {props.summaryItemTypeToCountMap && (
        <StatusSummaryBadges summaryItemTypeToCountMap={props.summaryItemTypeToCountMap} />
      )}
    </CardTop>
  );
};

export const ProjectUpdateCard = ({ shouldAddTabs = true, ...props }: ProjectUpdateCardProps) => {
  const context = useMemo(
    () => ({
      objectUUID: props.project.uuid,
      workspaceUUID: props.project.workspace.uuid,
    }),
    [props.project.uuid, props.project.workspace.uuid]
  );

  const [{ tawPhases }] = useTawPhaseStore();

  const tawPhase = useMemo(() => getProjectTawPhase(props.project.tags.edges, tawPhases), [
    props.project.tags.edges,
    tawPhases,
  ]);

  const handleOnClickCollapseButton = useCallback(() => {
    props.onClickCollapseButton && props.onClickCollapseButton(props.project.id);
  }, [props]);

  const renderCardTopLeftAfter = useCallback(() => <MissedUpdateLabel {...props} />, [props]);
  const renderCardTopExtras = useCallback(
    () => (
      <CardTopExtras
        targetDate={props.update.newTargetDate}
        targetDateConfidence={props.update.newTargetDateConfidence}
        state={props.update.newState}
        summaryItemTypeToCountMap={props.summaryItemTypeToCountMap}
        tawPhase={tawPhase}
        isShowProjectStatus={props.isShowProjectStatus}
      />
    ),
    [
      props.isShowProjectStatus,
      props.summaryItemTypeToCountMap,
      props.update.newState,
      props.update.newTargetDate,
      props.update.newTargetDateConfidence,
      tawPhase,
    ]
  );

  const hasNonEmptyChangelog = Boolean(props.update.changelog?.length);
  const earliestChangelogEntry = hasNonEmptyChangelog && props.update.changelog?.slice(-1)[0];
  const latestChangelogEntry = hasNonEmptyChangelog && props.update.changelog?.slice(0)[0];
  let targetDateManuallyChanged = false;

  if (latestChangelogEntry) {
    targetDateManuallyChanged = props.update.newTargetDate !== latestChangelogEntry.newValue;
  }

  const renderContentExtras = useCallback(
    ({ scheduleUpdate }) => (
      <>
        {props.update.notes &&
          props.update.notes
            .filter((note) => !note.archived && !isEmptyDoc(note?.summary))
            .map((note) => (
              <CollapsibleCard
                key={`note-${note.uuid}`}
                isExpanded={false}
                isNested={true}
                isInsideCollapsibleContainer={props.isCollapsible}
                scheduleUpdate={scheduleUpdate}
                headerContent={
                  <NoteTitle>
                    <NoteIcon label="" />
                    <h5>{note.title !== null && note.title !== '' ? note.title : 'More detail'}</h5>
                  </NoteTitle>
                }
              >
                <Renderer document={note.summary ?? ''} mode="normal" context={context} />
              </CollapsibleCard>
            ))}
        <UpdateDiff
          newTargetDate={props.update.newTargetDate}
          newTargetDateConfidence={props.update.newTargetDateConfidence}
          newState={props.update.newState}
          oldTargetDate={props.update.oldTargetDate}
          oldTargetDateConfidence={props.update.oldTargetDateConfidence}
          oldState={props.update.oldState}
        />
        {
          // Only render when we have at least one changelog entry and the target date hasn't been manually changed
          earliestChangelogEntry && !targetDateManuallyChanged && (
            <TargetDateAutoUpdatedCard
              oldDate={earliestChangelogEntry.oldValue}
              newDate={props.update.newTargetDate}
              issue={earliestChangelogEntry.projectFusion?.issue}
            />
          )
        }
      </>
    ),
    [
      context,
      earliestChangelogEntry,
      props.isCollapsible,
      props.update.newState,
      props.update.newTargetDate,
      props.update.newTargetDateConfidence,
      props.update.notes,
      props.update.oldState,
      props.update.oldTargetDate,
      props.update.oldTargetDateConfidence,
      targetDateManuallyChanged,
    ]
  );

  return (
    <UpdateCard
      project={props.project as Program | Initiative}
      shouldAddTabs={shouldAddTabs}
      ari={props.update.ari}
      containerAri={props.project.ari}
      id={props.update.id}
      uuid={props.update.uuid}
      isCollapsible={props.isCollapsible ?? false}
      isExpanded={props.isExpanded ?? true}
      isTransparent={props.isTransparent}
      summary={props.update.summary}
      context={context}
      renderHeader={props.renderHeader}
      renderCardTopLeftAfter={renderCardTopLeftAfter}
      isTargetWeekUpdate={props.isTargetWeekUpdate}
      isUserAvatarFixedWidth={props.isUserAvatarFixedWidth}
      renderCardTopExtras={renderCardTopExtras}
      renderContentExtras={renderContentExtras}
      renderFooterExtras={props.renderFooterExtras}
      creator={props.update.creator}
      creationDate={props.update.creationDate}
      editDate={props.update.editDate}
      updateType="project-update"
      updateShareLink={getUpdateShareLink(`${props.project.key}/updates`, props.update.id)}
      profileCardPosition={props.profileCardPosition}
      onClickCollapseButton={handleOnClickCollapseButton}
    />
  );
};
