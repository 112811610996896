import { gql } from '@apollo/client';

import { SearchProjectsQuery } from 'src/graphql/generated/SearchProjects';

export const SEARCH_PROJECTS = gql`
  query SearchProjects($q: String!, $first: Int!, $workspaceId: ID) {
    projectSearch(q: $q, first: $first, workspaceId: $workspaceId) {
      edges {
        node {
          id
          key
          uuid
          name
          state {
            value
            label
          }
          icon {
            id
            color
            shortName
          }
          members {
            edges {
              node {
                id
                pii {
                  name
                  picture
                }
                aaid
              }
            }
          }
          tags {
            edges {
              node {
                name
                id
                uuid
              }
            }
          }
          workspace {
            id
          }
          workspaceId
        }
      }
    }
  }
`;

export type Project = SearchProjectsQuery['projectSearch']['edges'][0]['node'];
