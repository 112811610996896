import { ProfileCardClientData, ProfileClient, StatusType } from '@atlaskit/profilecard';

import { apolloClient } from 'src/clients/apolloClient';
import { GetUserProfileQuery, GetUserProfileQueryVariables } from '@generated/GetUserProfile';
import { GET_USER_PROFILE } from '@queries/GetUserProfile';

export type CardData = ProfileCardClientData & { aaid: string };

type User = GetUserProfileQuery['userByAaid'];

const toProfileData = (data: User): CardData => ({
  aaid: data.aaid,
  isBot: false,
  isCurrentUser: false,
  avatarUrl: data.pii.picture,
  email: data.pii.email,
  fullName: data.pii.name,
  nickname: data.pii.nickname,
  status: data.pii.accountStatus as StatusType,
  location: data.pii.zoneinfo,
});

export class ProfileCardClient extends ProfileClient {
  private profiles: Map<string, CardData> = new Map();

  getProfile(cloudId: string, userId: string) {
    return this.makeRequest(cloudId, userId);
  }

  async makeRequest(_: string, userId: string): Promise<CardData> {
    // Try to get from cache!
    if (!this.profiles.has(userId)) {
      await this.fetchUser(userId);
    }

    return this.profiles.get(userId)!;
  }

  private async fetchUser(aaid: string) {
    try {
      const result = await apolloClient.query<GetUserProfileQuery, GetUserProfileQueryVariables>({
        query: GET_USER_PROFILE,
        variables: { aaid },
      });

      if (result && result.data) {
        const { userByAaid } = result.data;

        this.profiles.set(aaid, toProfileData(userByAaid));
      }
    } catch (err) {
      //
    }
  }
}
