import uniqBy from 'lodash.uniqby';

import { Project, UserEdge, Tag } from '@queries/GetProjectPageItems';
import { Program, getProgramProjectsAndInitiatives, Initiative } from '@program/children/utils/program';

import { isActiveProject } from './sidebar';

export const getMember = ({ node }: UserEdge) => node;

export const isProgram = (project: Program | Initiative) => 'initiatives' in project;

export const getInitiativeContributors = (initiative: Initiative<Project>) =>
  uniqBy(
    [
      ...(!initiative.filtered ? initiative.members.edges.map(getMember) : []),
      ...initiative.projects.filter(isActiveProject).flatMap(({ members }) => members.edges.map(getMember)),
    ],
    'id'
  );

export const getProjects = (project: Program | Initiative) => {
  if (isProgram(project)) {
    return getProgramProjectsAndInitiatives(project as Program);
  }

  return project.projects;
};

export const findUniqueInitiativeTag = (initiativeTags: Tag[]) =>
  initiativeTags.length === 1 ? initiativeTags[0] : undefined;

export const getInitiativeNameTagRegExp = (programNameTagName: string) =>
  new RegExp(`^${programNameTagName}-(-[a-z0-9]+)+$`);
