import { ReactNode, useCallback } from 'react';

type Props = {
  children: ReactNode;
};

export const StopPropagationWrapper = ({ children }: Props) => {
  const handleStopPropagation = useCallback((e) => e.stopPropagation(), []);

  return <div onClick={handleStopPropagation}>{children}</div>;
};
