import { parseISO, isAfter, isBefore, isSameWeek } from 'date-fns';

import { isCreatedOnTargetWeek } from '@shared/utils/weeklySummary';
import { Project as GqlProject, ProjectEdges } from '@queries/GetProjectPageItems';
import { InitiativeProjectListProject } from '@program/children/InitiativeProjectList';
import {
  DEPARTMENT_NAME_TAG_REGEXP,
  DEPARTMENT_NAME_WITH_PILLAR_TAG_REGEXP,
  getDeptName,
} from '@department/children/utils/department';
import { tzDate } from '@tc/util/date';
import { isActiveProject } from '@shared/utils/sidebar';
import { Department } from '@store/departmentStore';
import { Initiative } from '@program/children/utils/program';
import { getDependedOnProjectKeys } from '@shared/utils/project';
import { isInactiveGroupItem } from '@program/children/utils/filter';
import { isActive } from '@tc/Filters/MetaFilter/util';

export type GroupedProjects = {
  department: string;
  projects: InitiativeProjectListProject[];
};

export const prepareProjectNotTargetWeekUpdateData = <T extends GqlProject>(
  project: T,
  startDate: Date,
  endDate: Date
) => {
  if (isCreatedOnTargetWeek(startDate, endDate, new Date())) {
    return project;
  }

  const firstUpdateBeforeTargetWeek = project.updates.edges.find(({ node: { creationDate, newState } }) => {
    const wasCreatedBeforeTargetWeek = isBefore(parseISO(creationDate), startDate);

    return wasCreatedBeforeTargetWeek && !isActive(newState.value);
  });

  if (firstUpdateBeforeTargetWeek) {
    const { newState, newTargetDate, newTargetDateConfidence } = firstUpdateBeforeTargetWeek.node;

    return {
      ...project,
      state: newState,
      targetDate: newTargetDate,
      targetDateConfidence: newTargetDateConfidence,
    };
  }

  return project;
};

export const prepareProjectTargetWeekUpdateData = <T extends GqlProject>(
  project: T,
  startDate: Date,
  endDate: Date
): InitiativeProjectListProject<T> => {
  const wasCreatedAfterTargetWeek = isAfter(parseISO(project.creationDate), endDate);

  if (wasCreatedAfterTargetWeek) {
    return { ...project, wasCreatedAfterTargetWeek };
  }

  const targetWeekUpdate = project.updates.edges.find(({ node: { creationDate } }) =>
    isCreatedOnTargetWeek(startDate, endDate, parseISO(creationDate))
  );

  if (!targetWeekUpdate) {
    return prepareProjectNotTargetWeekUpdateData(project, startDate, endDate);
  }

  const { newState, newTargetDate, newTargetDateConfidence } = targetWeekUpdate.node;

  return {
    ...project,
    state: newState,
    targetDate: newTargetDate,
    targetDateConfidence: newTargetDateConfidence,
  };
};

export const filterNotActiveProjectsForTargetWeek = <T extends GqlProject>(
  projects: T[],
  startDate: Date,
  endDate: Date,
  isGroup?: boolean,
  isDashboard?: boolean
) =>
  projects?.filter((project) => {
    const isThisWeek = isSameWeek(startDate, tzDate());

    if (isThisWeek) {
      return isGroup ? !isInactiveGroupItem(project.state.value) : isActiveProject(project);
    }

    const targetWeekUpdate = project.updates.edges.find(({ node: { creationDate } }) =>
      isCreatedOnTargetWeek(startDate, endDate, parseISO(creationDate))
    );

    const sortedDetail = isGroup
      ? targetWeekUpdate === undefined
        ? !isInactiveGroupItem(project.state.value)
        : !isInactiveGroupItem(targetWeekUpdate?.node.newState.value)
      : isActive(targetWeekUpdate?.node.newState.value || '');

    const sortedInits = isDashboard ? !isInactiveGroupItem(project.state.value) : sortedDetail;

    return sortedInits;
  });

export const getDepartmentTagName = (pillarOrDepartmentTagName: string = '') => {
  const isDepartmentOrPillarTag =
    DEPARTMENT_NAME_TAG_REGEXP.test(pillarOrDepartmentTagName) ||
    DEPARTMENT_NAME_WITH_PILLAR_TAG_REGEXP.test(pillarOrDepartmentTagName);

  if (!pillarOrDepartmentTagName || !isDepartmentOrPillarTag) {
    return;
  }

  const [prefix, departmentPart] = pillarOrDepartmentTagName.split('--');

  return `${prefix}--${departmentPart}`;
};

export const groupProjectsByDepartment = (
  sortedProjects: InitiativeProjectListProject[],
  departments: Department[],
  filteredDepartmentName?: string
) => {
  const departmentLabels = departments.map(({ label }) => label);

  const departmentTagToProjectsMap: { [key: string]: InitiativeProjectListProject[] } = {};
  const projectsWithoutDepartments: InitiativeProjectListProject[] = [];

  sortedProjects.forEach((project) => {
    let hasDept = false;
    const departmentLabelsMap: { [key: string]: boolean } = {};

    project.tags.edges.forEach(({ node: { name } }) => {
      const isValidDepartmentTag = DEPARTMENT_NAME_TAG_REGEXP.test(name) && departmentLabels.includes(name);

      if (isValidDepartmentTag && !departmentLabelsMap[name]) {
        hasDept = true;
        departmentLabelsMap[name] = true;
        departmentTagToProjectsMap[name] = [...(departmentTagToProjectsMap[name] || []), project];
      }

      const isPillarTag = DEPARTMENT_NAME_WITH_PILLAR_TAG_REGEXP.test(name);
      const [prefix, department] = isPillarTag ? name.split('--') : [];
      const deptTagName = `${prefix}--${department}`;
      const isValidPillarTag = departmentLabels.includes(deptTagName);

      if (isValidPillarTag && !departmentLabelsMap[deptTagName]) {
        hasDept = true;
        departmentLabelsMap[deptTagName] = true;
        departmentTagToProjectsMap[deptTagName] = [...(departmentTagToProjectsMap[deptTagName] || []), project];
      }
    });

    if (!hasDept) {
      projectsWithoutDepartments.push(project);
    }
  });

  if (filteredDepartmentName) {
    const filteredDepartmentProjects = departmentTagToProjectsMap[filteredDepartmentName];

    return {
      projectsWithDepartment: filteredDepartmentProjects
        ? [{ department: getDeptName(filteredDepartmentName), projects: filteredDepartmentProjects }]
        : [],
      projectsWithoutDepartments: [],
    };
  }

  const projectsWithDepartment: GroupedProjects[] = Object.keys(departmentTagToProjectsMap).map((key) => ({
    department: getDeptName(key),
    projects: departmentTagToProjectsMap[key],
  }));

  return { projectsWithDepartment, projectsWithoutDepartments };
};

export const findInitiativeByProjectId = (initiatives?: Initiative[], projectId?: string) =>
  initiatives?.find((item) => item.projects.find((proj) => proj.id === projectId));

export const getInitiativesWithProjects = (initiatives: ProjectEdges, initiativesProjects?: ProjectEdges) => {
  if (!initiatives) return [];

  return initiatives.reduce((acc, next) => {
    const projectsKeys = getDependedOnProjectKeys(next.node.dependencies);
    const projects = initiativesProjects
      ? initiativesProjects.filter((project) => projectsKeys.includes(project.node.key)).map((project) => project.node)
      : [];

    acc.push({
      isDuplicated: false,
      link: '',
      ...next.node,
      projects,
      initiativeTag: { name: '', uuid: '', id: '' },
      isVerified: true,
    });

    return acc;
  }, [] as Initiative[]);
};

export const getProjectsPerInitiatives = (initiatives: ProjectEdges): string[][] => {
  if (!initiatives) return [];

  return initiatives.map((init) => getDependedOnProjectKeys(init.node.dependencies));
};
