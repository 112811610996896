import styled from 'styled-components';
import { ReactNode, useCallback, useMemo } from 'react';
import { N10A, N800 } from '@atlaskit/theme/colors';
import Tooltip from '@atlaskit/tooltip';
import Button from '@atlaskit/button';
import { ProfilecardTriggerPosition } from '@atlaskit/profilecard';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import { Tab } from '@atlaskit/tabs';

import { Initiative, Program } from '@program/children/utils/program';
import { getProjectTawPhase } from '@shared/TawBreakdown/utils';
import {
  CardTop as CardTopDefault,
  UpdateTop,
  UpdateWrapper,
  UpdateCreator,
  ExpandButton,
} from '@tc/UpdateCard/styles';
import { Card } from '@tc/Card/styles';
import { CardTopExtras, getUpdateShareLink } from '@tc/UpdateCard/ProjectUpdateCard';
import { RenderCollapsibleExtrasCallback } from '@tc/UpdateCard';
import { CardContentWrapper } from '@tc/Card/CollapsibleCard';
import { Project, ProjectUpdate } from '@queries/GetProjectPageItems';
import { formatFullNiceDate } from '@tc/util/dateFormat';
import { tzUserTimezoneDate } from '@tc/util/date';
import { formatUserRelativeDistance } from '@tc/util/timezoneDatetime';
import { UserAvatar } from '@tc/UserAvatar';
import { MissedUpdateCardTop } from '@tc/UpdateCard/ProjectUpdateCard/styles';
import { useTawPhaseStore } from 'src/store/tawPhaseStore';
import { About } from '@shared/AboutTab/About';
import { SummaryItemTypeToCountMap } from '@shared/StatusSummaryBadges';
import { ContentTabName, ContentTabs, TabData } from '@shared/ContentTabs';
import { DepartmentProject } from '@queries/GetDepartmentProjects';
import { TCLinkWrapper, TCLinkContentWrapper } from '@shared/TCLinkWrapper';
import { useUpdateCard } from '@tc/UpdateCard/useUpdateCard';
import { StopPropagationWrapper } from '@shared/StopPropagationWrapper';

export type ProjectEmptyUpdateCardProps = {
  project: (Project | DepartmentProject) & { link?: string };
  renderHeader?: () => ReactNode;
  renderFooterExtras?: RenderCollapsibleExtrasCallback;
  targetWeekUpdate?: ProjectUpdate;
  profileCardPosition?: ProfilecardTriggerPosition;
  summaryItemTypeToCountMap?: SummaryItemTypeToCountMap;
  isTransparent?: boolean;
  isCollapsible?: boolean;
  isExpanded?: boolean;
  shouldAddTabs?: boolean;
  isTargetWeekUpdate?: boolean;
  isUserAvatarFixedWidth?: boolean;
  isShowProjectStatus?: boolean;
  onClickCollapseButton?: (id: string) => void;
};

const CardTop = styled(CardTopDefault)`
  margin-bottom: 0;
`;

export const NoUpdateText = styled.div<{ isTransparent?: boolean }>`
  opacity: ${({ isTransparent }) => (isTransparent ? 0.4 : 1)};
  margin-top: 16px;
  background-color: ${N10A};
  color: ${N800};
  font-size: 14px;
  line-height: 1.5;
  padding: 8px 15px;
`;

export const calculateZonedCreationDate = (project: Project | DepartmentProject, targetWeekUpdate?: ProjectUpdate) => {
  let zonedCreationDate, editedTooltip, relativeCreationDate, updateShareLink;

  if (targetWeekUpdate) {
    const { creationDate, editDate, id } = targetWeekUpdate;

    zonedCreationDate = tzUserTimezoneDate(creationDate);
    editedTooltip = editDate ? ` (Edited on ${formatFullNiceDate(tzUserTimezoneDate(editDate), true)})` : '';
    relativeCreationDate = formatUserRelativeDistance(creationDate) + (editDate ? ' (edited)' : '');
    updateShareLink = getUpdateShareLink(`${project.key}/updates`, id);
  }

  return {
    zonedCreationDate,
    editedTooltip,
    relativeCreationDate,
    updateShareLink,
  };
};

export const ProjectEmptyUpdateCard = ({
  project,
  renderHeader,
  renderFooterExtras,
  targetWeekUpdate,
  profileCardPosition,
  isTransparent,
  isCollapsible,
  isExpanded,
  shouldAddTabs = true,
  isUserAvatarFixedWidth = false,
  isShowProjectStatus,
  isTargetWeekUpdate,
  summaryItemTypeToCountMap,
  onClickCollapseButton,
}: ProjectEmptyUpdateCardProps) => {
  const {
    contentHeight,
    scheduleUpdate,
    shouldShowPointer,
    handleMouseOver,
    handleMouseLeave,
    handleCardClick,
    expanded,
    toggleCollapsed,
    ref,
    cardTopRef,
  } = useUpdateCard(isExpanded, isCollapsible);
  const [{ tawPhases }] = useTawPhaseStore();

  const {
    owner,
    tags: { edges },
  } = project;

  const { zonedCreationDate, editedTooltip, relativeCreationDate, updateShareLink } = calculateZonedCreationDate(
    project,
    targetWeekUpdate
  );

  const tawPhase = useMemo(() => getProjectTawPhase(edges, tawPhases), [edges, tawPhases]);

  const handleOnToggleCollapsed = useCallback(() => {
    toggleCollapsed();
    onClickCollapseButton && onClickCollapseButton(project.id);
  }, [toggleCollapsed, onClickCollapseButton, project]);

  const handleOnCardClick = useCallback(() => {
    handleCardClick();
    onClickCollapseButton && onClickCollapseButton(project.id);
  }, [handleCardClick, onClickCollapseButton, project]);

  const tabs: TabData[] = [
    {
      id: ContentTabName.ABOUT,
      headingComponent: (
        <Tab testId="project-about-tab" key="project-about-tab">
          About
        </Tab>
      ),
      bodyComponent: <About project={project as Initiative | Program} />,
    },

    {
      id: ContentTabName.UPDATES,
      headingComponent: (
        <Tab testId="project-updates-tab" key="project-updates-tab">
          Updates
        </Tab>
      ),

      bodyComponent: (
        <CardContentWrapper height={contentHeight || 'auto'}>
          <div ref={ref}>
            <NoUpdateText isTransparent={isTransparent}>No update was posted.</NoUpdateText>
            {renderFooterExtras && renderFooterExtras({ scheduleUpdate })}
          </div>
        </CardContentWrapper>
      ),
    },
  ];

  return (
    <UpdateWrapper data-testid="project-empty-update-card">
      <UpdateTop>{renderHeader && renderHeader()}</UpdateTop>
      <Card
        isTargetWeekUpdate={isTargetWeekUpdate}
        shouldShowPointer={shouldShowPointer}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onClick={handleOnCardClick}
      >
        <StopPropagationWrapper>
          <CardTop isTransparent={isTransparent} innerRef={cardTopRef}>
            <UpdateCreator>
              {isCollapsible && (
                <ExpandButton
                  spacing="none"
                  appearance="subtle"
                  iconBefore={expanded ? <ChevronDownIcon label="Collapse" /> : <ChevronRightIcon label="Expand" />}
                  onClick={handleOnToggleCollapsed}
                />
              )}
              <TCLinkWrapper
                isLabel={true}
                content={
                  <TCLinkContentWrapper isUserAvatarFixedWidth={isUserAvatarFixedWidth}>
                    <UserAvatar
                      profileCardPosition={profileCardPosition}
                      size="small"
                      name={owner.pii.name}
                      url={owner.pii.picture}
                      aaid={owner.aaid}
                      isElementNextToIcon={true}
                    />
                  </TCLinkContentWrapper>
                }
              />
              {zonedCreationDate && (
                <Tooltip content={formatFullNiceDate(zonedCreationDate, true) + editedTooltip}>
                  {updateShareLink ? (
                    <Button
                      className="creation-date"
                      appearance="subtle-link"
                      href={updateShareLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      spacing="none"
                    >
                      <TCLinkWrapper
                        isLabel={true}
                        content={<TCLinkContentWrapper>{relativeCreationDate}</TCLinkContentWrapper>}
                      />
                    </Button>
                  ) : (
                    <TCLinkWrapper
                      isLabel={true}
                      content={<TCLinkContentWrapper>{relativeCreationDate}</TCLinkContentWrapper>}
                    />
                  )}
                </Tooltip>
              )}
              <MissedUpdateCardTop showDot={true}>No update was posted</MissedUpdateCardTop>
            </UpdateCreator>
            {targetWeekUpdate ? (
              <CardTopExtras
                isShowProjectStatus={isShowProjectStatus}
                targetDate={targetWeekUpdate.newTargetDate}
                targetDateConfidence={targetWeekUpdate.newTargetDateConfidence}
                summaryItemTypeToCountMap={summaryItemTypeToCountMap}
                state={targetWeekUpdate.newState}
                tawPhase={tawPhase}
              />
            ) : (
              <CardTopExtras
                isShowProjectStatus={isShowProjectStatus}
                targetDate={project.targetDate}
                targetDateConfidence={project.targetDateConfidence}
                summaryItemTypeToCountMap={summaryItemTypeToCountMap}
                state={project.state}
                tawPhase={tawPhase}
              />
            )}
          </CardTop>
          {expanded && shouldAddTabs && <ContentTabs tabType={'update'} tabs={tabs} />}
          {!shouldAddTabs && (
            <CardContentWrapper height={contentHeight}>
              <div ref={ref}>
                <NoUpdateText isTransparent={isTransparent}>No update was posted.</NoUpdateText>
                {renderFooterExtras && renderFooterExtras({ scheduleUpdate })}
              </div>
            </CardContentWrapper>
          )}
        </StopPropagationWrapper>
      </Card>
    </UpdateWrapper>
  );
};
