import { RefObject, useCallback, useEffect, useState, createRef } from 'react';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import { N20A, N30A } from '@atlaskit/theme/colors';
import styled from 'styled-components';

import { Card } from './styles';

const nestedStyle = (props: { isNested?: boolean }) => {
  if (!props.isNested) {
    return;
  }

  return `
    box-shadow: none !important;
    border: 1px solid ${N30A} !important;
  `;
};

export const withTransition = (props: { isTransitionDisabled?: boolean }) => {
  if (props.isTransitionDisabled) {
    return;
  }

  return `
    transition-property: max-height, min-height, height;
    transition: 80ms ease-in-out;
  `;
};

export const Collapsible = styled(Card)<{ isNested?: boolean }>`
  padding: 0 !important;
  margin-bottom: 16px;

  ${nestedStyle}
`;

const CardTop = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 18px;

  > div:first-child {
    flex: 1;
  }

  > div:last-child {
    flex: 0 1 24px;
    height: 24px;
  }
`;

export const CardContentWrapper = styled.div<{ height?: string | number; isTransitionDisabled?: boolean }>`
  min-height: ${(props) =>
    props.height !== undefined || typeof Number(props.height) === 'number'
      ? `${Number(props.height)}px`
      : props.height};
  height: ${(props) => (Number(props.height) === 0 ? '0px' : 'auto')};
  max-height: ${(props) =>
    props.height !== undefined || typeof Number(props.height) === 'number'
      ? `${Number(props.height) + 20}px`
      : props.height};
  overflow: hidden;

  ${withTransition}
`;

const CardContent = styled.div`
  box-sizing: border-box;
  border-top: 2px solid ${N20A};
  padding: 12px 18px;
`;

export type Props = {
  isExpanded?: boolean;
  isNested?: boolean;
  collapsible?: boolean;
  isInsideCollapsibleContainer?: Boolean;
  scheduleUpdate?: () => void;
  children?: JSX.Element;
  headerContent?: JSX.Element;
  headerExtras?: JSX.Element;
};

export const CollapsibleCard = (props: Props) => {
  const { scheduleUpdate, isInsideCollapsibleContainer } = props;

  const [contentHeight, setContentHeight] = useState<number | undefined>(props.isExpanded ? undefined : 0);
  const ref: RefObject<HTMLDivElement> = createRef();

  const toggleCollapsed = useCallback(() => {
    if (contentHeight === 0) {
      const { height } = ref.current!.getBoundingClientRect();

      setContentHeight(height);
    } else {
      setContentHeight(0);
    }

    scheduleUpdate && scheduleUpdate();
  }, [contentHeight, ref, scheduleUpdate]);

  const handleOnClick = useCallback(() => props.collapsible !== false && toggleCollapsed(), [
    props.collapsible,
    toggleCollapsed,
  ]);

  useEffect(() => {
    if (props.isExpanded || contentHeight !== 0) {
      const { height } = ref.current!.getBoundingClientRect();

      setContentHeight(height);
    }
  }, [props.isExpanded, contentHeight, ref]);

  return (
    <Collapsible isNested={props.isNested} data-type="collapsible-card">
      <CardTop onClick={handleOnClick}>
        <div>{props.headerContent}</div>
        <div>
          {props.collapsible !== false && (
            <Button
              spacing="none"
              appearance="subtle"
              iconBefore={contentHeight === 0 ? <ChevronDownIcon label="Expand" /> : <ChevronUpIcon label="Collapse" />}
              onClick={toggleCollapsed}
            />
          )}
          {props.headerExtras}
        </div>
      </CardTop>
      <CardContentWrapper
        height={contentHeight === 0 ? contentHeight : 'auto'}
        isTransitionDisabled={!!isInsideCollapsibleContainer}
      >
        <CardContent innerRef={ref}>{props.children}</CardContent>
      </CardContentWrapper>
    </Collapsible>
  );
};
