import { useEffect } from 'react';
import { FlagsProvider } from '@atlaskit/flag';
import { layers } from '@atlaskit/theme';
import styled, { injectGlobal } from 'styled-components';

import { HelpContextProvider } from '@tc/Navigation/HelpPanel/HelpContext';
import { GlobalLoader } from '@shared/GlobalLoader';
import { GlobalError } from '@shared/GlobalError';
import { NotAtlassianUser } from '@shared/NotAtlassianUser';
import { useImportAssets } from '@store/importAssetsStore';

import { TargetWeekProvider } from './hooks/useTargetWeek';
import { FeatureFlagProvider } from './hooks/useFeatureFlag';
import { useUserStore } from './store/userStore';
import { useInitApp } from './hooks/useInitApp';
import { RouterWrapper } from './RouterWrapper';

injectGlobal`
  #editor-popups > div {
    z-index: ${layers.flag()} !important;
  }
`;

const ContentWrapper = styled.div`
  padding-top: 0;
`;

export const App = () => {
  const [{ user, hasError, isAtlassianUser }] = useUserStore();
  const [, { synchronizeAssets }] = useImportAssets();
  const isInitialized = useInitApp();

  useEffect(() => {
    synchronizeAssets();
  }, [synchronizeAssets]);

  if (!isAtlassianUser) {
    return <NotAtlassianUser email={user.email} />;
  }

  if (hasError) {
    return <GlobalError />;
  }

  if (!isInitialized) {
    return <GlobalLoader />;
  }

  return (
    <FlagsProvider>
      <FeatureFlagProvider>
        <TargetWeekProvider>
          <HelpContextProvider>
            <ContentWrapper>
              <RouterWrapper />
            </ContentWrapper>
          </HelpContextProvider>
        </TargetWeekProvider>
      </FeatureFlagProvider>
    </FlagsProvider>
  );
};
