import { ReactNode, useCallback } from 'react';
import { IntlProvider } from 'react-intl';
import { IntlProvider as IntlNextProvider } from 'react-intl-next';

type Props = {
  children: ReactNode;
};

export const AppIntlProvider = (props: Props) => {
  const locale = 'en';
  const onError = useCallback(() => {}, []);

  return (
    <IntlProvider locale={locale} onError={onError}>
      <IntlNextProvider locale={locale}>
        <>{props.children}</>
      </IntlNextProvider>
    </IntlProvider>
  );
};
