import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right';
import { ReactNode } from 'react';
import styled from 'styled-components';

const FieldChangedWrapper = styled.div<{ isGroup?: boolean }>`
  display: flex;
  justify-content: ${({ isGroup }) => (isGroup ? 'end' : 'start')};
`;

const FromWrapper = styled.div`
  span {
    text-decoration: line-through !important;
  }
`;

interface FieldChangedProps {
  from: ReactNode;
  to: ReactNode;
  disableStrikethrough?: boolean;
  shouldShowPrevValue?: boolean;
  isGroup?: boolean;
}

export const FieldChanged = (props: FieldChangedProps) => {
  const { from, to, disableStrikethrough, shouldShowPrevValue = true } = props;

  return (
    <FieldChangedWrapper isGroup={props.isGroup}>
      {shouldShowPrevValue && (
        <>
          {disableStrikethrough ? from : <FromWrapper>{from}</FromWrapper>}
          <ArrowRightIcon label="Changed to" />
        </>
      )}
      {to}
    </FieldChangedWrapper>
  );
};
