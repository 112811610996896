import { ReactNode } from 'react';
import styled from 'styled-components';
import { N20A, N400, R400, R50, R500, R75 } from '@atlaskit/theme/colors';

export type DatePickerValue = {
  confidence?: number | null;
  date?: string | null;
  phaseName?: string | null;
};

export type DatePickerOptions = {
  confidence?: number | null;
  date: string;
  statusType: string;
};

export type FuzzyDatePickerProps = {
  noDueDateSet?: boolean;
  disabled?: boolean;
  showConfirmButton?: boolean;
  onChange?: (value: Required<DatePickerValue>) => void;
  onToggle?: (isOpen: boolean) => void;
  value: DatePickerValue;
  placeholder?: string;
  renderFooterExtras?: (selectedDate: Required<DatePickerValue>, pendingDate?: Required<DatePickerValue>) => ReactNode;
};

export enum FuzzyScale {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
}

export const fuzzyDateValue: { [key in FuzzyScale]: number } = {
  [FuzzyScale.DAY]: 100,
  [FuzzyScale.MONTH]: 0,
  [FuzzyScale.WEEK]: 50,
  [FuzzyScale.QUARTER]: -50,
};

// We're moving from 3-point confidence (low, medium, high with values 0, 50, 100 respectively)
// to 2-point confidence. Projects that were medium previously will now be considered low confident.
const CONFIDENT_THRESHOLD = 100;

const isNumeric = (value: number): boolean => !isNaN(value);

export const getFuzzyScale = (confidence?: number) => {
  // The number 0 is a false-y value. So we have to be explicit here for null checking
  if (isNumeric(confidence!) && confidence !== undefined && confidence !== null) {
    if (confidence >= CONFIDENT_THRESHOLD) {
      return FuzzyScale.DAY;
    }

    if (confidence === fuzzyDateValue[FuzzyScale.WEEK]) {
      return FuzzyScale.WEEK;
    }

    if (confidence >= fuzzyDateValue[FuzzyScale.MONTH]) {
      return FuzzyScale.MONTH;
    }

    return FuzzyScale.QUARTER;
  } else {
    // If confidence is somehow undefined, we'll show full date just in case
    return FuzzyScale.DAY;
  }
};

export const Wrapper = styled.div<{ dateIsInThePast: boolean }>`
  display: flex;
  align-items: center;

  button {
    padding: 0 !important;
    line-height: 24px !important;
    height: auto !important;
    font-size: 14px !important;
    color: ${(props) => props.dateIsInThePast && `${R400} !important`};
    background-color: ${(props) => props.dateIsInThePast && R50};

    &:hover {
      color: ${(props) => props.dateIsInThePast && `${R500} !important`};
      background-color: ${(props) => props.dateIsInThePast && R75};
    }

    > span:first-child {
      margin: 0;
    }

    > span:nth-child(2) {
      padding: 0 8px;
      margin: 0;
      text-overflow: unset;
    }

    > span + span {
      margin: 0 0 0 -4px !important;
    }
  }

  button[disabled] {
    cursor: default;
    color: ${(props) => (props.dateIsInThePast ? `${R400} !important` : `${N400} !important`)};
    background-color: ${(props) => (props.dateIsInThePast ? R50 : `${N20A} !important`)};
  }
`;

export { FuzzyDate } from './FuzzyDate';
