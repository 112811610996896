import Popup from '@atlaskit/popup';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useRouter } from 'react-resource-router';

import { ProfilePopup } from '@tc/Profile';
import { GetAllWorkspacesQuery } from '@generated/GetAllWorkspaces';
import { GET_ALL_WORKSPACES } from '@queries/GetAllWorkspaces';
import { Actions, Sources, useAnalytics } from '@hooks/useAnalytics';
import { useWorkspaceStore } from '@store/workspaceStore';

import { SwitcherContent } from './SwitcherContent';
import { SwitcherTrigger } from './SwitcherTrigger';

export type WorkspaceDetails = {
  globalId: string;
  cloudId: string;
  name: string;
  organisationId: string;
  timezone?: string;
};

export const ProfileAndWorkspaceSwitcher = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { loading, data } = useQuery<GetAllWorkspacesQuery>(GET_ALL_WORKSPACES);

  const [{ workspace }, { setCurrentWorkspace }] = useWorkspaceStore();
  const { sendUIEvent } = useAnalytics();

  const [, routerActions] = useRouter();

  const allWorkspaceOptions = useMemo(
    () =>
      data?.allWorkspaces?.edges?.map((ws) => ({
        name: ws.node.name,
        globalId: ws.node.id,
        organisationId: ws.node.organisationId,
        cloudId: ws.node.cloudId,
      })),
    [data?.allWorkspaces?.edges]
  );

  const count = useMemo(() => data && data.allWorkspaces.count, [data]);

  const shouldShowWorkspaceSwitcher = !!count && count > 1;

  const handlePopup = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleItemClick = useCallback(
    (selectedWorkspace: WorkspaceDetails) => {
      setIsOpen(false);

      setCurrentWorkspace(selectedWorkspace);

      sendUIEvent({
        action: Actions.CLICKED,
        actionSubject: 'button',
        actionSubjectId: 'workspaceItem',
        source: Sources.NAVBAR,
        attributes: {
          name: selectedWorkspace.name,
        },
      });

      routerActions.push('/');
    },
    [setCurrentWorkspace, sendUIEvent, routerActions]
  );

  const renderContent = useCallback(
    () =>
      allWorkspaceOptions ? <SwitcherContent options={allWorkspaceOptions} handleClick={handleItemClick} /> : null,
    [allWorkspaceOptions, handleItemClick]
  );

  const renderTrigger = useCallback(
    ({ ...triggerProps }) => (
      <SwitcherTrigger
        triggerProps={{ ...triggerProps }}
        handleClick={handlePopup}
        isLoading={loading}
        currentWorkspace={workspace}
      />
    ),
    [workspace, loading, handlePopup]
  );

  return (
    <>
      <ProfilePopup />
      {shouldShowWorkspaceSwitcher && (
        <Popup
          placement={'bottom-end'}
          content={renderContent}
          isOpen={isOpen}
          onClose={handlePopup}
          trigger={renderTrigger}
          testId="workspace-switcher-menu"
        />
      )}
    </>
  );
};
