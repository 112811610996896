import { useState, useEffect, useCallback } from 'react';
import { Emoji, OptionalEmojiDescription } from '@atlaskit/emoji';

import { getEmojiProvider } from '@tc/providers';
import { useWorkspaceStore } from '@store/workspaceStore';

import { Container, sizeMap } from './styles';
import { getColor, getEmoji, toEmojiId, validateColor } from './utils';
import { Props } from '.';

export const ProjectIcon = (props: Props) => {
  const [emoji, setEmoji] = useState<OptionalEmojiDescription>(undefined);
  const [{ workspace }] = useWorkspaceStore();
  const { cloudId } = workspace;

  useEffect(() => {
    (async (): Promise<void> => {
      const provider = await getEmojiProvider(cloudId);

      const resolvedEmoji = await provider.findByEmojiId(
        toEmojiId(props.id!, props.shortName || getEmoji(props.projectUuid))
      );

      setEmoji(resolvedEmoji);
    })();
  }, [cloudId, props.id, props.projectUuid, props.shortName]);

  const ResolvedEmojiComponent = useCallback(
    () => (emoji && <Emoji emoji={emoji} fitToHeight={sizeMap[props.size || 'large'].emojiSize} />) || null,
    [emoji, props.size]
  );

  return (
    <Container
      onClick={props.onClick}
      size={props.size || 'large'}
      color={validateColor(props.color || getColor(props.projectUuid))}
    >
      <ResolvedEmojiComponent />
    </Container>
  );
};
