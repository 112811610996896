import Loadable from 'react-loadable';
import { RouteContext } from 'react-resource-router';

import { LoadableErrorHandler } from '@shared/LoadableErrorHandler';

export const InitiativePage = Loadable<RouteContext, never>({
  loader: () =>
    import(/* webpackChunkName: "InitiativePage" */ './InitiativePage').then(({ InitiativePage }) => InitiativePage),
  loading: LoadableErrorHandler,
});
