import Loadable from 'react-loadable';

import { Icon as GqlIcon } from 'src/graphql/generated/graphql';
import { LoadableErrorHandler } from '@shared/LoadableErrorHandler';

export type Size = 'xxlarge' | 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall';
export type Icon = GqlIcon;

export type Props = {
  projectUuid?: string;
  color?: string | null;
  id?: string | null;
  shortName?: string | null;
  size?: Size | null;
  onClick?: () => void;
};

export const ProjectIcon = Loadable<Props, never>({
  loader: () => import(/* webpackChunkName: "project-icon" */ './ProjectIcon').then(({ ProjectIcon }) => ProjectIcon),
  loading: LoadableErrorHandler,
});
