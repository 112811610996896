import { Entity, Tags } from 'src/graphql/types';
import { Tag } from '@queries/GetProjectPageItems';
import { Project } from '@queries/SearchProjects';
import { Initiative } from '@program/children/utils/program';
import { DepartmentProject } from '@queries/GetDepartmentProjects';

export type EntityTags = {
  programTags: Tag[];
  initiativeTags: Tag[];
  sizeTags: Tag[];
};

const projectTypeToFullEntityName: { [key: string]: string } = {
  Proj: 'Project',
  Init: 'Initiative',
  Mile: 'Milestone',
  Pgm: 'Program',
  '-': 'Project',
};

export type EntityResult = { type?: Entity } & EntityTags;

export const EXCEPT_NUMBERS_AND_LETTERS = /[^a-zA-Z0-9]/g;
export const PROGRAM_NAME_TAG_REGEXP = /^pgm-(-[a-z0-9]+)+$/;

export const INDEPENDENT_INITIATIVE_NAME_TAG_REGEXP = /^init-(-[a-z0-9]+)+$/;

export const getEntityTypeAndTags = ({ tags }: { tags: Project['tags'] }): EntityResult => {
  const sizeTags: Tag[] = [];
  const programTags: Tag[] = [];
  const initiativeTags: Tag[] = [];

  const sizeTagNames = Object.values(Tags);

  tags.edges.forEach(({ node }) => {
    const { name } = node;

    // check if tag is size tag
    if (sizeTagNames.includes(name as Tags)) {
      return sizeTags.push(node);
    }

    // check if tag is program tag (pgm--<program>)
    if (PROGRAM_NAME_TAG_REGEXP.test(name)) {
      return programTags.push(node);
    }

    //check if tag is simple initiative tag (init--<initiative>)
    if (INDEPENDENT_INITIATIVE_NAME_TAG_REGEXP.test(name)) {
      return initiativeTags.push(node);
    }
  });

  const [sizeTag] = sizeTags.length === 1 ? sizeTags : [];
  const sizeTagName = sizeTag?.name;

  let type;

  if (sizeTagName === Tags.program && programTags.length === 1) {
    type = Entity.PROGRAM;
  }

  if (sizeTagName === Tags.initiative && initiativeTags.length === 1) {
    type = Entity.INITIATIVE;
  }

  if (
    sizeTagName !== Tags.program &&
    sizeTagName !== Tags.initiative &&
    (initiativeTags.length || programTags.length)
  ) {
    type = Entity.PROJECT;
  }

  return { type, programTags, initiativeTags, sizeTags };
};

export const getEntityType = (project: Initiative | Project | DepartmentProject, isProjectIsInitiative: boolean) => {
  const entityType = ((project as unknown) as { type: string }).type;

  if (entityType) {
    return projectTypeToFullEntityName[entityType];
  }

  return isProjectIsInitiative ? ((project as Initiative).isVerified ? 'Milestone' : 'Initiative') : 'Project';
};

export const removeSpecialCharacters = (name: string) => name?.replace(EXCEPT_NUMBERS_AND_LETTERS, '').toLowerCase();
