import { gql } from '@apollo/client';

export const GET_USER_WORKSPACE_BY_CONTEXT = gql`
  query UserWorkspaceByContext($workspaceContext: WorkspaceContextInput) {
    userWorkspaceByContext(workspaceContext: $workspaceContext) {
      workspace {
        id
        name
        organisationId
        cloudId
        timezone
      }
    }
  }
`;
