import styled from 'styled-components';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import EditorDateIcon from '@atlaskit/icon/glyph/editor/date';
import { TriggerProps } from '@atlaskit/popup';
import Tooltip from '@atlaskit/tooltip';
import { toDate } from 'date-fns-tz';
import { endOfWeek, parseISO } from 'date-fns';

import { TargetDateUpdateData } from '@tc/StatusHistory/modals/AllUpdatesModal/TargetDateUpdates/ChildTargetDateUpdate';
import { isDateInThePast } from '@tc/util/date';
import {
  formatDateRange,
  formatFullNiceDate,
  formatNiceDate,
  formatNiceMonthDate,
  formatNiceQuarterDate,
  formatTooltipNiceMonthDate,
  formatTooltipNiceQuarterDate,
} from '@tc/util/dateFormat';
import { isActive } from '@tc/Filters/MetaFilter/util';
import { ellipsis } from '@tc/UserAvatar/styles';

import { FuzzyDatePickerProps, FuzzyScale, getFuzzyScale, Wrapper } from './';

const NO_DUE_DATE_SET_MSG = 'No due date set';

export const renderDateString = (
  dateString?: string,
  confidence?: number,
  placeholder?: string,
  tooltipMode?: boolean
) => {
  if (dateString) {
    const date = toDate(dateString);
    const fuzzyDateType = getFuzzyScale(confidence);

    switch (fuzzyDateType) {
      case FuzzyScale.DAY:
        return tooltipMode ? formatFullNiceDate(date, true) : formatNiceDate(date);
      case FuzzyScale.QUARTER:
        return tooltipMode ? formatTooltipNiceQuarterDate(date) : formatNiceQuarterDate(date);
      case FuzzyScale.WEEK:
        return formatDateRange(date, endOfWeek(parseISO(dateString), { weekStartsOn: 1 }));
      default:
      case FuzzyScale.MONTH:
        return tooltipMode ? formatTooltipNiceMonthDate(date) : formatNiceMonthDate(date);
    }
  } else {
    return placeholder || NO_DUE_DATE_SET_MSG;
  }
};

type FuzzyDateProps = Pick<FuzzyDatePickerProps, 'value' | 'placeholder' | 'noDueDateSet'> & {
  shouldCheckIfTargetDateInThePast?: boolean;
  triggerProps?: TriggerProps;
  onClick?: () => void;
  targetDateUpdateData?: TargetDateUpdateData;
  isDashboard?: boolean;
  id?: string;
};

export const FuzzyDate = (props: FuzzyDateProps) => {
  const {
    noDueDateSet,
    onClick,
    targetDateUpdateData,
    triggerProps = {},
    placeholder,
    shouldCheckIfTargetDateInThePast = true,
    isDashboard,
    id,
  } = props;
  const { date, confidence, phaseName } = props.value;

  const StyledButton = styled(Button)`
    background-color: ${targetDateUpdateData?.statusColor} !important;
    pointer-events: none;

    :hover {
      cursor: auto;
    }
  `;

  const DateWrapper = styled.div`
    ${ellipsis};
    color: ${targetDateUpdateData?.statusColorFont};
  `;

  const dateIcon = (
    <EditorDateIcon testId="editor-date-icon" primaryColor={targetDateUpdateData?.statusColorFont} label="" />
  );
  const downIcon = <ChevronDownIcon testId="chevron-down-icon" label="Chevron Icon" />;

  const shouldCheckIfDateInThePast = shouldCheckIfTargetDateInThePast && !noDueDateSet && isActive(phaseName!);

  const isDueDate = noDueDateSet ? NO_DUE_DATE_SET_MSG : renderDateString(date!, confidence!, placeholder, true);

  const content = targetDateUpdateData?.status || isDueDate;

  return (
    <Wrapper
      dateIsInThePast={isDateInThePast(shouldCheckIfDateInThePast ? date : null)}
      data-testid={isDashboard ? `fuzzy-date-picker-button-${id}` : 'fuzzy-date-picker-button'}
    >
      <Tooltip content={content}>
        <div>
          <StyledButton
            {...triggerProps}
            isDisabled={targetDateUpdateData ? false : !onClick}
            onClick={onClick}
            iconBefore={dateIcon}
            iconAfter={onClick && downIcon}
          >
            <DateWrapper>
              {noDueDateSet ? NO_DUE_DATE_SET_MSG : renderDateString(date!, confidence!, placeholder)}
            </DateWrapper>
          </StyledButton>
        </div>
      </Tooltip>
    </Wrapper>
  );
};
