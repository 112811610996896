import { assetsClient } from 'src/clients/assetsClient';

export const fetchVerifyProgram = async (projectKey: string) => {
  try {
    const response = await assetsClient.fetchVerifyProgram(projectKey);
    const isVerified: boolean = await response.json();

    return { isVerified, isLoading: false };
  } catch (err) {
    return { isLoading: false };
  }
};
