import { gql } from '@apollo/client';

export const CREATE_PROJECT_LINK = gql`
  mutation createProjectLink($url: String!, $projectId: ID!, $name: String, $iconUrl: String) {
    createProjectLink(input: { url: $url, projectId: $projectId, name: $name, iconUrl: $iconUrl }) {
      project {
        id
      }
    }
  }
`;
