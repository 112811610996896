import { Y400 } from '@atlaskit/theme/colors';
import EditorWarningIcon from '@atlaskit/icon/glyph/editor/warning';

import { EllipsisTooltip } from '@tc/EllipsisTooltip';

type Props = {
  tooltipContent: string;
  label?: string;
};

export const WarningIcon = (props: Props) => (
  <EllipsisTooltip content={props.tooltipContent} position="bottom">
    <EditorWarningIcon label={props.label || 'duplication-warning'} primaryColor={Y400} />
  </EllipsisTooltip>
);
