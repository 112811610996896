import { N30A } from '@atlaskit/theme/colors';
import styled from 'styled-components';

export const Divider = styled.hr`
  border: none;
  background-color: ${N30A};
  margin: 1.714em 0;
  height: 2px;
  border-radius: 1px;
`;
