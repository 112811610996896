import { getConfig } from 'src/config';

type SubmitFeedbackProps = {
  requestTypeId: string;
  fields: Array<{ id: string; value: string | Array<{ id: string }> }>;
};

class JSDClient {
  submitFeedback({ requestTypeId, fields }: SubmitFeedbackProps) {
    const { feedbackUrl, jiraServiceDeskEmbeddableKey } = getConfig();

    return fetch(`${feedbackUrl}/${jiraServiceDeskEmbeddableKey}/request?requestTypeId=${requestTypeId}`, {
      body: JSON.stringify({ fields }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    });
  }
}

export const jiraServiceDeskClient = new JSDClient();
