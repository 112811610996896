import { Field } from '@atlaskit/form';
import { SimpleTag } from '@atlaskit/tag';
import TagGroup from '@atlaskit/tag-group';
import Tooltip from '@atlaskit/tooltip';
import styled from 'styled-components';

import { getTagColor } from '@shared/Labels';

const FieldWrapper = styled.div`
  margin-top: 15px;
`;

const TagWrapper = styled.span`
  > span {
    margin-left: 0;
    margin-bottom: 0;
  }
`;

type LabelsFieldProps = {
  defaultValue: string;
  sizeTagName: string;
  shouldShowSizeTag: boolean;
  programName?: string;
};

type LabelProps = {
  value: string;
};

const Label = ({ value }: LabelProps) => {
  const text = `# ${value}`;

  return (
    <Tooltip content={text} position="bottom">
      <TagWrapper>
        <SimpleTag text={text} color={getTagColor(value)} />
      </TagWrapper>
    </Tooltip>
  );
};

export const LabelsField = ({ defaultValue, sizeTagName, shouldShowSizeTag, programName }: LabelsFieldProps) => (
  <FieldWrapper>
    <Field name="label" label="Labels we’re gonna add" defaultValue={defaultValue}>
      {({ fieldProps: { value } }) => (
        <TagGroup>
          {defaultValue && <Label value={value} />}
          {programName && <Label value={programName} />}
          {shouldShowSizeTag && <Label value={sizeTagName} />}
        </TagGroup>
      )}
    </Field>
  </FieldWrapper>
);
