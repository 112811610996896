import { Wrapper, defaultProps, Props } from '@tc/Logo/Wrapper';

const svg = `
<canvas height="32" width="32" aria-hidden="true"></canvas>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="32" height="32" rx="3" fill="#ABF5D1"/>
  <path d="M17.0078 22V10.7266H14.9922V22H17.0078Z" fill="#006644"/>
</svg>

`;

export const CreateInitiativeIcon = (props: Props) => <Wrapper {...defaultProps} {...props} svg={svg} />;
