import { createContext, useCallback, useState } from 'react';

const noop = () => {
  /* noop */
};

export interface HelpInterface {
  isOpen: boolean;
  setIsOpen(value: boolean): void;
  isOpenFeedbackPopup: boolean;
  setIsOpenFeedbackPopup(value: boolean): void;
}

export const HelpContext = createContext<HelpInterface>({
  isOpen: false,
  setIsOpen: noop,
  isOpenFeedbackPopup: false,
  setIsOpenFeedbackPopup: noop,
});

export const HelpContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleSetIsOpen = useCallback((value: boolean) => {
    setIsOpen(value);
  }, []);

  const [isOpenFeedbackPopup, setIsOpenFeedbackPopup] = useState(false);
  const handleSetIsOpenFeedbackPopup = useCallback((value: boolean) => {
    setIsOpenFeedbackPopup(value);
  }, []);

  return (
    <HelpContext.Provider
      value={{
        isOpen,
        setIsOpen: handleSetIsOpen,
        isOpenFeedbackPopup,
        setIsOpenFeedbackPopup: handleSetIsOpenFeedbackPopup,
      }}
    >
      {children}
    </HelpContext.Provider>
  );
};
