import { formatDistance } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { parseIsoLocalDateTime } from './dateFormat';
import { timezoneHolder } from './timezoneHolder';

const parseToZonedDateTime = (dateTime: string, timezone: string) => {
  const date = Date.parse(dateTime + 'Z');

  if (isNaN(date)) {
    return parseIsoLocalDateTime(dateTime, new Date(NaN));
  }

  return utcToZonedTime(date, timezone);
};

/**
 * Generally speaking, there are 2 ways to render dates when it comes to timezone
 *
 * 1. Use workspace timezone, eg, with calculating monday-sunday week-window boundary
 * 2. Use user timezone, eg, when formatting relative UTC date (aka "Updated X minutes ago")
 */
export const utcToUserTimezone = (dt: string) => parseToZonedDateTime(dt, timezoneHolder.getUserTimezone());
export const utcToWorkspaceTimezone = (dt: string) => parseToZonedDateTime(dt, timezoneHolder.getWorkspaceTimezone());

/**
 * Use user timezone for these
 */
export const formatUserRelativeDistance = (dateString?: string) =>
  dateString
    ? formatDistance(utcToUserTimezone(dateString), utcToZonedTime(Date.now(), timezoneHolder.getUserTimezone()), {
        addSuffix: true,
      })
    : '-';
