import { ReactNode, useMemo } from 'react';
import { N800 } from '@atlaskit/theme/colors';
import { Link } from 'react-resource-router';
import Tooltip from '@atlaskit/tooltip';
import styled from 'styled-components';
import { ProfilecardTriggerPosition } from '@atlaskit/profilecard';

import { getEntityTypeAndTags } from '@shared/utils/entity';
import { Project } from '@queries/GetProjectPageItems';
import { DueDate } from '@program/children/DueDate';
import { Initiative } from '@program/children/utils/program';
import { UserAvatar } from '@tc/UserAvatar';
import { StatusOrPhaseLozenge } from '@tc/StatusOrPhaseLozenge';
import { ProjectIcon } from '@tc/ProjectIcon';
import { DepartmentProject } from '@queries/GetDepartmentProjects';
import { DepartmentProject as ExtendedDepartmentProject } from '@department/children/utils/department';
import { TCLinkContentWrapper, TCLinkWrapper } from '@shared/TCLinkWrapper';
import { useTawPhaseStore } from 'src/store/tawPhaseStore';
import { ProjectType } from '@department/children/utils/departmentTable';
import { Entity } from 'src/graphql/types';
import { createLink } from '@shared/utils/project';
import { getProjectTawPhase } from '@shared/TawBreakdown/utils';
import { Tag } from '@shared/TawBreakdown/TawTag';
import { ellipsis } from '@tc/UserAvatar/styles';
import { ProjectName } from '@shared/CardHeader';
import { useWarningIcon } from '@hooks/useWarningIcon';
import { AriaLabelWarningIcon, Entities, TooltipContentWarningIcon } from 'src/constants';
import { VerifiedIcon } from '@shared/VerifiedIcon';
import { ENABLE_ASSETS } from 'src/featureFlagsKeys';
import { useFeatureFlag } from '@hooks/useFeatureFlag';

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Name = styled.span`
  width: 100%;
  min-width: 50px;
  font-size: 14px;
  line-height: 26px;
  padding: 0 12px;
  ${ellipsis}

  > a {
    color: ${N800};
  }

  @media (max-width: 512px) {
    min-width: 10px;
  }
`;

export const StatusWithAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;

  @media (max-width: 512px) {
    flex-shrink: 1;
  }
`;

export const TargetDate = styled.div`
  margin-right: 8px;
`;

export const StatusWrapper = styled.div`
  margin-right: 8px;
`;

export const AvatarWrapper = styled.div`
  div,
  span {
    margin: 0 !important;
  }
`;

const TawPhase = styled(Tag)`
  margin: 0 8px 0 0;
  padding: 2px 8px;
  cursor: default;
`;

type Props = {
  project: (Project | Initiative | DepartmentProject) & { isVerified?: boolean };
  className?: string;
  hideDueDate?: boolean;
  shouldCheckIfTargetDateInThePast?: boolean;
  renderExtras?: () => ReactNode;
  testId?: string;
  shouldOpenInNewWindow?: boolean;
  showTawPhase?: boolean;
  hideStatusWithAvatar?: boolean;
  profileCardPosition?: ProfilecardTriggerPosition;
  link?: { href: string; target: '_self' | '_blank' };
  isEmbeds?: boolean;
  shouldShowFavourite?: boolean;
  projectType?: typeof Entities[keyof typeof Entities];
};

export const ProjectRow = ({
  project,
  className,
  renderExtras,
  hideDueDate = false,
  shouldCheckIfTargetDateInThePast = true,
  testId,
  shouldOpenInNewWindow,
  showTawPhase = false,
  hideStatusWithAvatar = false,
  profileCardPosition,
  link,
  isEmbeds,
}: Props) => {
  const extendedProject = project as ExtendedDepartmentProject;
  const { type: deptProjectType, owner } = extendedProject;
  const { type } = getEntityTypeAndTags(project);

  const isProject = type === Entity.PROJECT || deptProjectType === ProjectType.PROJECT;
  const isInvalid = deptProjectType === ProjectType.INVALID;

  const [{ tawPhases }] = useTawPhaseStore();

  const showAssets = useFeatureFlag(ENABLE_ASSETS, false);

  const tawPhase = useMemo(() => (showTawPhase ? getProjectTawPhase(project.tags.edges, tawPhases) : null), [
    project.tags.edges,
    showTawPhase,
    tawPhases,
  ]);

  const extendedInitiative = project as (Project | Initiative | DepartmentProject) & { isDuplicated: boolean };
  const isDuplicatedInitiative = extendedInitiative.isDuplicated;
  const renderWarningIcon = useWarningIcon(
    isDuplicatedInitiative,
    TooltipContentWarningIcon.DUPLICATED_INITIATIVE,
    AriaLabelWarningIcon.DUPLICATED_INITIATIVE
  );

  const { href, target } = useMemo(() => createLink(project, shouldOpenInNewWindow), [project, shouldOpenInNewWindow]);
  const calculatedTarget = link ? link.target : isEmbeds ? '_blank' : target;

  const verifiedIconContentByProjectType = extendedProject?.type
    ? extendedProject?.type === ProjectType.PROGRAM
      ? 'Atlassian Program'
      : extendedProject?.type === ProjectType.MILESTONE
      ? 'Atlassian Milestone'
      : 'Atlassian Program'
    : null;

  return (
    <Row className={className} data-testid={testId}>
      <ProjectIcon projectUuid={project.uuid} {...project.icon} size="small" />
      <Name>
        <Link href={link ? link.href : href} target={calculatedTarget}>
          <StyledDiv>
            <ProjectName>
              <Tooltip content={project.name} position="bottom-start">
                <TCLinkWrapper
                  isLabel={isProject || isInvalid}
                  isIconNeeded={isProject || isInvalid}
                  content={<TCLinkContentWrapper haveLineHeightNormal={true}>{project.name}</TCLinkContentWrapper>}
                />
              </Tooltip>
            </ProjectName>
            <VerifiedIcon
              content={
                verifiedIconContentByProjectType ??
                (type === Entity.PROGRAM
                  ? 'Atlassian Program'
                  : project.isVerified
                  ? 'Atlassian Milestone'
                  : 'Atlassian Initiative')
              }
              isVerified={!!showAssets && project && project.isVerified}
            />
            {renderWarningIcon()}
          </StyledDiv>
        </Link>
      </Name>
      {!hideStatusWithAvatar && (
        <StatusWithAvatarWrapper>
          {showTawPhase && <TawPhase isEmpty={false}>{tawPhase}</TawPhase>}
          {!hideDueDate && (
            <TargetDate>
              <DueDate project={project} shouldCheckIfTargetDateInThePast={shouldCheckIfTargetDateInThePast} />
            </TargetDate>
          )}
          <StatusWrapper>
            <StatusOrPhaseLozenge state={project.state} />
          </StatusWrapper>
          <AvatarWrapper>
            <UserAvatar
              name={owner.pii.name}
              url={owner.pii.picture}
              size="small"
              showName={false}
              hideBorder={true}
              aaid={owner.aaid}
              profileCardPosition={profileCardPosition}
              isEmbeddedPage={isEmbeds}
            />
          </AvatarWrapper>
        </StatusWithAvatarWrapper>
      )}
      {renderExtras && renderExtras()}
    </Row>
  );
};
