import { PropsWithChildren } from 'react';
import { Routes, Redirect, RouteContext } from 'react-resource-router';

import { ProgramPageEmbed } from 'src/embeds/program';
import { InitiativePageEmbed } from 'src/embeds/initiative';
import { DepartmentPageEmbed } from 'src/embeds/department';
import { MilestonePageEmbed } from 'src/embeds/milestone';
import { MilestonesPage } from 'src/pages/milestones';
import { verificationProgramResource } from 'src/resources/program/verificationProgramResource';

import { ProgramPage } from './pages/program';
import { ProgramListPage } from './pages/programList';
import { InitiativePage } from './pages/initiative';
import { DepartmentPage } from './pages/department';
import { InitiativeListPage } from './pages/initiativeList';
import { DepartmentListPage } from './pages/departmentList';
import { SettingsPage } from './pages/settingsPage';
import { ReportPage } from './pages/report';
import { Dashboard, InitiativeDashboardPage } from './pages/dashboard';
import { UnderConstructionStaticPage } from './pages/underConstruction/index';

export enum routesMap {
  PROGRAM = 'program',
  DASHBOARD = 'dashboard',
  INITIATIVE_DASHBOARD = 'initiative-dashboard',
  PROGRAM_REPORT = 'program-report',
  PROGRAMS = 'programs',
  MILESTONE = 'milestone',
  MILESTONE_DASHBOARD = 'milestone-dashboard',
  INITIATIVES = 'initiatives',
  MILESTONES = 'milestones',
  INDEPENDENT_INITIATIVE = 'independent-initiative',
  INITIATIVE_REPORT = 'initiative-report',
  MILESTONE_REPORT = 'milestone-report',
  DEPARTMENT = 'department',
  DEPARTMENTS = 'departments',
  EMBED_PROGRAM = 'embed-program',
  EMBED_DEPARTMENT = 'embed-department',
  EMBED_INDEPENDENT_INITIATIVE = 'embed-independent-initiative',
  SETTINGS = 'settings',
  PROGRAM_ASSETS = 'assets',
  UNDER_CONSTRUCTION_STATIC = 'under_construction',
  EMBED_MILESTONE = 'embed_milestone',
}

export const routesPathMap = {
  [routesMap.PROGRAMS]: '/',
  [routesMap.DASHBOARD]: '/program/:name/dashboard',
  [routesMap.INITIATIVE_DASHBOARD]: '/initiative/:initiative/dashboard',
  [routesMap.PROGRAM]: '/program/:name/:tabName?',
  [routesMap.PROGRAM_REPORT]: '/program/:name/manage/:reportType',
  [routesMap.MILESTONE]: '/milestone/:initiative/:tabName?',
  [routesMap.MILESTONE_DASHBOARD]: '/milestone/:initiative/dashboard',
  [routesMap.INITIATIVES]: '/initiatives',
  [routesMap.MILESTONES]: '/milestones',
  [routesMap.INDEPENDENT_INITIATIVE]: '/initiative/:initiative/:tabName?',
  [routesMap.INITIATIVE_REPORT]: '/initiative/:name/manage/:reportType',
  [routesMap.MILESTONE_REPORT]: '/milestone/:name/manage/:reportType',
  [routesMap.DEPARTMENT]: '/department/:department',
  [routesMap.DEPARTMENTS]: '/departments',
  [`${routesMap.SETTINGS}-redirect`]: '/settings',
  [routesMap.SETTINGS]: '/settings/:pageName',
  [routesMap.EMBED_PROGRAM]: '/embeds/program/:name/:tabName?',
  [routesMap.EMBED_DEPARTMENT]: '/embeds/department/:name',
  [routesMap.EMBED_INDEPENDENT_INITIATIVE]: '/embeds/initiative/:initiative/:tabName?',
  [routesMap.EMBED_MILESTONE]: '/embeds/milestone/:key/:tabName?',
  [routesMap.PROGRAM_ASSETS]: '/program/:key/assets/:callType',
  [routesMap.UNDER_CONSTRUCTION_STATIC]: '/under_construction',
};

type RouterMapProps = {
  isAssetsEnable?: boolean;
};

export const getRoutes = ({ isAssetsEnable }: RouterMapProps = {}): Routes => [
  {
    name: routesMap.PROGRAMS,
    exact: true,
    path: routesPathMap[routesMap.PROGRAMS],
    component: ProgramListPage,
  },
  {
    name: routesMap.DASHBOARD,
    exact: true,
    path: routesPathMap[routesMap.DASHBOARD],
    component: (props: PropsWithChildren<RouteContext>) => <Dashboard {...props} isAssetsEnable={isAssetsEnable} />,
    resources: [verificationProgramResource],
  },
  {
    name: routesMap.INITIATIVE_DASHBOARD,
    exact: true,
    path: routesPathMap[routesMap.INITIATIVE_DASHBOARD],
    component: (props: PropsWithChildren<RouteContext>) => <InitiativeDashboardPage {...props} />,
  },
  {
    name: routesMap.MILESTONE_DASHBOARD,
    exact: true,
    path: routesPathMap[routesMap.MILESTONE_DASHBOARD],
    component: (props: PropsWithChildren<RouteContext>) => <InitiativeDashboardPage {...props} />,
  },
  {
    name: routesMap.PROGRAM,
    exact: true,
    path: routesPathMap[routesMap.PROGRAM],
    component: (props: PropsWithChildren<RouteContext>) => <ProgramPage {...props} isAssetsEnable={isAssetsEnable} />,
    resources: [verificationProgramResource],
  },
  {
    name: routesMap.PROGRAM_REPORT,
    exact: true,
    path: routesPathMap[routesMap.PROGRAM_REPORT],
    component: (props: PropsWithChildren<RouteContext>) => <ReportPage {...props} isAssetsEnable={isAssetsEnable} />,
    resources: [verificationProgramResource],
  },
  {
    name: routesMap.MILESTONES,
    exact: true,
    path: routesPathMap[routesMap.MILESTONES],
    component: (props: PropsWithChildren<RouteContext>) => <MilestonesPage {...props} />,
  },
  {
    name: routesMap.INITIATIVES,
    exact: true,
    path: routesPathMap[routesMap.INITIATIVES],
    component: (props: PropsWithChildren<RouteContext>) => <InitiativeListPage {...props} />,
  },
  {
    name: routesMap.MILESTONE,
    exact: true,
    path: routesPathMap[routesMap.MILESTONE],
    component: (props: PropsWithChildren<RouteContext>) => <InitiativePage {...props} />,
  },
  {
    name: routesMap.INDEPENDENT_INITIATIVE,
    exact: true,
    path: routesPathMap[routesMap.INDEPENDENT_INITIATIVE],
    component: (props: PropsWithChildren<RouteContext>) => <InitiativePage {...props} />,
  },
  {
    name: routesMap.INITIATIVE_REPORT,
    exact: true,
    path: routesPathMap[routesMap.INITIATIVE_REPORT],
    component: (props: PropsWithChildren<RouteContext>) => <ReportPage {...props} isAssetsEnable={isAssetsEnable} />,
    resources: [verificationProgramResource],
  },
  {
    name: routesMap.MILESTONE_REPORT,
    exact: true,
    path: routesPathMap[routesMap.MILESTONE_REPORT],
    component: (props: PropsWithChildren<RouteContext>) => <ReportPage {...props} isAssetsEnable={isAssetsEnable} />,
    resources: [verificationProgramResource],
  },
  {
    name: routesMap.DEPARTMENT,
    exact: true,
    path: routesPathMap[routesMap.DEPARTMENT],
    component: (props) => <DepartmentPage isAssetsEnable={isAssetsEnable} {...props} />,
  },
  {
    name: routesMap.DEPARTMENTS,
    exact: true,
    path: routesPathMap[routesMap.DEPARTMENTS],
    component: (props) => <DepartmentListPage {...props} isAssetsEnable={isAssetsEnable} />,
  },
  {
    name: `${routesMap.SETTINGS}-redirect`,
    exact: true,
    path: routesPathMap[`${routesMap.SETTINGS}-redirect`],
    component: () => <Redirect to="/settings/departments" />,
  },
  {
    name: routesMap.SETTINGS,
    exact: false,
    path: routesPathMap[routesMap.SETTINGS],
    component: SettingsPage,
  },
  {
    name: routesMap.EMBED_PROGRAM,
    exact: true,
    path: routesPathMap[routesMap.EMBED_PROGRAM],
    component: (props) => <ProgramPageEmbed {...props} />,
  },
  {
    name: routesMap.EMBED_DEPARTMENT,
    exact: true,
    path: routesPathMap[routesMap.EMBED_DEPARTMENT],
    component: (props) => <DepartmentPageEmbed isAssetsEnable={isAssetsEnable} {...props} />,
  },
  {
    name: routesMap.EMBED_INDEPENDENT_INITIATIVE,
    exact: true,
    path: routesPathMap[routesMap.EMBED_INDEPENDENT_INITIATIVE],
    component: InitiativePageEmbed,
  },
  {
    name: routesMap.EMBED_MILESTONE,
    exact: true,
    path: routesPathMap[routesMap.EMBED_MILESTONE],
    component: MilestonePageEmbed,
  },
  {
    name: routesMap.UNDER_CONSTRUCTION_STATIC,
    exact: true,
    path: routesPathMap[routesMap.UNDER_CONSTRUCTION_STATIC],
    component: UnderConstructionStaticPage,
  },
  {
    name: 'redirect',
    path: '*',
    component: () => <Redirect to="/" />,
  },
];
