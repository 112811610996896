import { Project } from 'src/graphql/queries/GetProjectPageItems';
import { FuzzyDate } from '@tc/FuzzyDate';
import { isDueDateSet } from '@tc/util/truthUtil';
import { DepartmentProject } from 'src/graphql/queries/GetDepartmentProjects';

type Props = {
  project: Project | DepartmentProject;
  shouldCheckIfTargetDateInThePast?: boolean;
};

export const DueDate = ({ project, shouldCheckIfTargetDateInThePast = true }: Props) => {
  const { targetDate, targetDateConfidence, creationDate, newState, state } = project;

  return (
    <FuzzyDate
      value={{ date: targetDate, confidence: targetDateConfidence, phaseName: newState?.value }}
      noDueDateSet={!isDueDateSet({ targetDate, creationDate, state: state })}
      shouldCheckIfTargetDateInThePast={shouldCheckIfTargetDateInThePast}
    />
  );
};
