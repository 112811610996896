export type AdfNode = {
  type: string;
  text?: string;
  content?: AdfNode[];
  attrs?: {
    [key: string]: string;
  };
};

export type AdfDocument = {
  version: 1;
  content: AdfNode[];
};

export const isEmptyDoc = (doc?: string | null | AdfDocument): doc is null | undefined => {
  if (!doc) {
    return true;
  }

  const adfDoc: AdfDocument = typeof doc === 'string' ? JSON.parse(doc) : doc;
  const firstNode = adfDoc?.content?.length > 0 && adfDoc.content[0];

  // Considered 'empty' if no node
  if (!firstNode) {
    return true;
  }

  // If our doc has more than 1 node, not empty
  if (adfDoc?.content?.length > 1) {
    return false;
  }

  // Considered 'empty' if node has empty content.
  // But doc is 'not empty' if 'content' field doesn't exist because it
  // could be other node type.
  return firstNode.content ? firstNode.content.length === 0 : false;
};
