import { N200, N300, N30A } from '@atlaskit/theme/colors';
import Button from '@atlaskit/button';
import styled, { css } from 'styled-components';

import { AvatarName, AvatarWrapper, ellipsis } from '@tc/UserAvatar/styles';
import { Card } from '@tc/Card/styles';

export const ChangeCard = styled(Card)`
  box-shadow: none;
  border: 1px solid ${N30A};
  padding: 8px 12px !important;

  > div {
    padding: 8px;
    display: flex;
    align-items: center;

    > h5 {
      flex-basis: 20%;
      color: ${N300};
      font-size: 11px;
      line-height: 16px;
      font-weight: 600;
      text-transform: none;
    }
  }
`;

export const UpdateWrapper = styled.div<{ isSelected?: boolean }>`
  position: relative;

  ${({ isSelected }) =>
    isSelected &&
    css`
      border: 1px solid #1d7afc;
      border-radius: 3px;
    `}

  &[animate='pulse'] {
    &:after {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      content: '';
      background: transparent;
      border-radius: 3px;
      box-shadow: 0 0 0 0 grey;
      animation: pulse 1.5s 3;
      animation-delay: 1s;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.99, 0.96);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
    }

    70% {
      transform: scale(1.01, 1.04);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.99, 0.96);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;

export const UpdateCard = styled(Card)<{
  shouldShowPointer: boolean;
  isPaddingDecreased?: boolean;
  isDashboardCard?: boolean;
  isTargetWeekUpdate?: boolean;
}>`
  padding: ${({ isPaddingDecreased, isDashboardCard, isTargetWeekUpdate }) =>
    isPaddingDecreased ? '20px 20px 10px' : isDashboardCard || !isTargetWeekUpdate ? '24px 24px 24px 35px' : '24px'};
  cursor: ${({ shouldShowPointer }) => (shouldShowPointer ? 'pointer' : 'default')};
`;

export const UpdateTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > span {
    color: ${N200};
  }
`;

export const ExpandButton = styled(Button)<{ isDashboardPage?: boolean }>`
  margin-right: ${({ isDashboardPage }) => (isDashboardPage ? ' 5px' : '3px')};
  margin-left: ${({ isDashboardPage }) => (isDashboardPage ? '-27px' : '-18px')};
`;

export const RelativeCreationDateWrapper = styled.div`
  margin-left: 8px;
`;

export const CardTop = styled.div<{ isTransparent?: boolean; isDashboard?: boolean; isEmbed?: boolean }>`
  opacity: ${({ isTransparent }) => (isTransparent ? 0.4 : 1)};
  align-items: center;
  position: relative;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-wrap: wrap;
  cursor: ${({ isEmbed }) => (isEmbed ? 'pointer' : 'default')};

  > button + div {
    ${ellipsis}
  }

  > div {
    display: flex;
    align-items: center;

    > * + * {
      margin-left: ${({ isDashboard }) => (isDashboard ? 0 : '8px')};
      ${ellipsis}
    }
  }
`;

export const UpdateCreator = styled.div`
  max-width: 100%;

  ${AvatarWrapper} > div:first-child {
    display: flex;
  }

  ${AvatarName} {
    margin: 0;
    font-weight: 600;
  }

  > .creation-date {
    margin-left: 8px;
    color: ${N200};
    font-weight: normal;
  }
`;

export const Summary = styled.div`
  font-size: 16px;
  margin: 12px 0 12px 0;
`;

export const UpdateReactions = styled.div`
  display: flex;
  align-items: center;

  button {
    min-width: 24px;
    min-height: 24px;
    line-height: 20px;
  }

  .miniMode > button {
    margin: 1px 0;
  }
`;

export const CardFooter = styled.div<{ isScrollable?: boolean }>`
  margin-top: ${({ isScrollable }) => (isScrollable ? '14px' : '24px')};

  display: flex;
  align-items: center;
  justify-content: space-between;

  > * + *:before {
    margin-left: 12px;
    margin-right: 12px;
    content: '\\2022';
  }
`;

export const UpdateCardContentWrapper = styled.div<{ isTransparent?: boolean }>`
  opacity: ${({ isTransparent }) => (isTransparent ? 0.4 : 1)};
`;

export const NoWrap = styled.div`
  display: 'flex';
  flex-wrap: 'nowrap';
`;
