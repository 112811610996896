import Loadable from 'react-loadable';
import { RouteContext } from 'react-resource-router';

import { LoadableErrorHandler } from '@shared/LoadableErrorHandler';

export type ProgramPageProps = RouteContext & {
  isAssetsEnable?: boolean;
};
export const ProgramPage = Loadable<ProgramPageProps, never>({
  loader: () => import(/* webpackChunkName: "ProgramPage" */ './ProgramPage').then(({ ProgramPage }) => ProgramPage),
  loading: LoadableErrorHandler,
});
