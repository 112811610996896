import {
  DEPARTMENT_NAME_TAG_REGEXP,
  DEPARTMENT_NAME_WITH_PILLAR_TAG_REGEXP,
  getDepartmentPillarRegExp,
} from '@department/children/utils/department';
import { Project as GqlProject } from '@queries/GetProjectPageItems';
import { GoalEdge } from '@queries/SearchProjectByKey';
import { Status } from 'src/graphql/types';

import { Initiative, Program } from './program';

enum FilterKeys {
  DEPARTMENT_OR_PILLAR = 'departmentOrPillar',
}

export type Filters = {
  [FilterKeys.DEPARTMENT_OR_PILLAR]?: string;
};

export const isInactiveGroupItem = (status?: string) => status === Status.CANCELLED || status === Status.DONE;

export const filterByDepartmentOrPillar = <T extends Pick<GqlProject, 'tags'>>(entity: T, value: string) => {
  const isValidPillarTag = DEPARTMENT_NAME_WITH_PILLAR_TAG_REGEXP.test(value);

  if (isValidPillarTag) {
    return entity.tags.edges.some(({ node: { name } }) => name === value);
  }

  const isValidDepartmentTag = DEPARTMENT_NAME_TAG_REGEXP.test(value);
  const departmentPillarRegExp = getDepartmentPillarRegExp(value);

  return (
    !isValidDepartmentTag ||
    entity.tags.edges.some(({ node: { name } }) => name === value || departmentPillarRegExp.test(name))
  );
};

const filterKeyToFilterMap: { [key in FilterKeys]: Function } = {
  [FilterKeys.DEPARTMENT_OR_PILLAR]: filterByDepartmentOrPillar,
};

export const filterEntity = <T extends GqlProject>(filters: Filters, entity: T) =>
  Object.entries(filters).every(([key, value]) => !value || filterKeyToFilterMap[key as FilterKeys](entity, value));

export const filterProgram = (program: Program, filters: Filters) => {
  const filteredInitiatives = (program.initiatives || []).reduce<Initiative[]>((acc, initiative) => {
    const filteredProjects = initiative.projects?.filter(filterEntity.bind(null, filters));

    const isInitiativeFiltered = !filterEntity(filters, initiative);

    if (!isInitiativeFiltered || filteredProjects.length) {
      acc.push({ ...initiative, filtered: isInitiativeFiltered, projects: filteredProjects });
    }

    return acc;
  }, []);

  const filteredProjects = (program.projects || []).filter(filterEntity.bind(null, filters));

  return { ...program, initiatives: filteredInitiatives, projects: filteredProjects };
};

export const filterGoalsByDepartment = (goalEdges: GoalEdge[], department: string) =>
  goalEdges.reduce<Array<GoalEdge & { node: GoalEdge['node'] & { filtered?: boolean } }>>((acc, { node }) => {
    const isFiltered = !filterByDepartmentOrPillar(node, department);

    const filteredSubGoals = node.subGoals.edges.reduce<
      Array<
        GoalEdge['node']['subGoals']['edges'][0] & {
          node: GoalEdge['node']['subGoals']['edges'][0]['node'] & { filtered?: boolean };
        }
      >
    >((acc, { node }) => {
      const isFiltered = !filterByDepartmentOrPillar(node, department);
      const filteredSubGoals = node.subGoals.edges.filter(({ node }) => filterByDepartmentOrPillar(node, department));

      if (!isFiltered || filteredSubGoals.length) {
        acc.push({ node: { ...node, filtered: isFiltered, subGoals: { ...node.subGoals, edges: filteredSubGoals } } });
      }

      return acc;
    }, []);

    if (!isFiltered || filteredSubGoals.length) {
      acc.push({ node: { ...node, filtered: isFiltered, subGoals: { edges: filteredSubGoals } } });
    }

    return acc;
  }, []);
