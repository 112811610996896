import { createResource, RouterContext } from 'react-resource-router';

import { fetchVerifyProgram } from '@program/children/services/fetchVerifyProgram';
import { checkIfAtlasKey } from 'src/utils/router/parseRouter';

const PROGRAM_DETAILS = 'programDetails';

export const verificationProgramResource = createResource({
  type: PROGRAM_DETAILS,
  getKey: (context: RouterContext) => context.match.params?.name as string,
  getData: async (
    context: RouterContext,
    { isAssetsEnable }
  ): Promise<{ isVerified?: boolean; isLoading: boolean }> => {
    const key = context.match.params?.name as string;

    const isAtlasKey = checkIfAtlasKey(key);

    if (!isAtlasKey || !isAssetsEnable) {
      return { isVerified: false, isLoading: false };
    }

    return await fetchVerifyProgram(key);
  },
});
