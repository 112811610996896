import { useCallback } from 'react';

import { WarningWrapper } from '@program/children/InitiativeCardHeader';
import { WarningIcon } from '@shared/WarningIcon';
import { AriaLabelWarningIcon, TooltipContentWarningIcon } from 'src/constants';

export const useWarningIcon = (
  shouldRender = false,
  tooltipContent = TooltipContentWarningIcon.PURE_WARNING,
  label = AriaLabelWarningIcon.PURE_WARNING
) =>
  useCallback(
    () =>
      shouldRender ? (
        <WarningWrapper data-testid="warning-icon">
          <WarningIcon tooltipContent={tooltipContent} label={label} />
        </WarningWrapper>
      ) : null,
    [shouldRender, tooltipContent, label]
  );
