/**
 * Simple cache with TTL
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class Cache<T = any> {
  private cache: Map<string, { exp: number; value: T }> = new Map();

  get(key: string): T | undefined {
    if (!this.has(key)) {
      return;
    }

    return this.cache.get(key)!.value;
  }

  has(key: string): boolean {
    const item = this.cache.get(key);

    if (!item) {
      return false;
    }

    if (item.exp < Date.now()) {
      this.cache.delete(key);

      return false;
    }

    return true;
  }

  set(key: string, value: T, ttl: number) {
    return this.cache.set(key, { value, exp: Date.now() + ttl });
  }
}
