import Spinner from '@atlaskit/spinner';
import styled from 'styled-components';

const SpinnerWrapper = styled.div<{ size: 'small' | 'medium'; isListPage: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ size, isListPage }) => (size === 'medium' ? (isListPage ? '-24px 32px' : '32px') : '8px')};
  margin-bottom: ${({ size, isListPage }) => (isListPage ? (size === 'medium' ? '24px' : '0px') : 'auto')};
`;

type Props = {
  size?: 'small' | 'medium';
  isListPage?: boolean;
};

export const LoadingState = ({ size = 'medium', isListPage = false }: Props) => (
  <SpinnerWrapper size={size} isListPage={isListPage}>
    <Spinner size={size} />
  </SpinnerWrapper>
);
