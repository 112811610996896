import { MediaProvider } from '@atlaskit/editor-common/provider-factory';
import { MediaClientConfig } from '@atlaskit/media-core';

import { getConfig } from 'src/config';
import { Cache } from '@tc/util/cache';

import { Context } from './';

const MEDIA_TOKEN_TIMEOUT = 59 * 60 * 1000;
const MEDIA_UPLOAD_TOKEN_TIMEOUT = 59 * 60 * 1000;

const cacheKeyForContext = (ctx: Context) => `${ctx.workspaceUUID}__${ctx.objectUUID}`;

const access = {
  'urn:filestore:chunk:*': ['create', 'read'],
  'urn:filestore:upload:*': ['create', 'read', 'update'],
  'urn:filestore:file': ['create'],
  'urn:filestore:file:*': ['read', 'delete'],
  'urn:filestore:collection:*': ['create', 'insert', 'read'],
};

const clients = new Cache();

export const getMediaTokens = async () => {
  const { mediaTokensUrl } = getConfig();
  const body = JSON.stringify({
    access,
  });

  const response = await fetch(mediaTokensUrl, {
    method: 'POST',
    body,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    credentials: 'include',
  });

  return response.json();
};

const fetchAllTokens = async () => {
  const { mediaBaseUrl } = getConfig();
  const clientTokens = await getMediaTokens();

  Object.entries<string>(clientTokens).forEach(([key, value]) => {
    try {
      clients.set(
        key,
        {
          ...parseMediaToken(value),
          baseUrl: mediaBaseUrl,
        },
        MEDIA_TOKEN_TIMEOUT
      );
    } catch (e) {
      /* Don't care */
    }
  });
};

const getToken = async (workspaceUUID: string) => {
  if (!clients.has(workspaceUUID)) {
    await fetchAllTokens();
  }

  return clients.get(workspaceUUID);
};

const getGeneralMediaClientConfig = (context: Context): MediaClientConfig => ({
  authProvider: async () => getToken(context.workspaceUUID),
});

// ------------ New way -------------

export const parseMediaToken = (value?: string) => {
  if (value) {
    try {
      return JSON.parse(value);
    } catch (e) {
      /* Don't care */
    }
  }

  return {};
};

const rendererClients = new Cache();
const getRendererToken = async (ctx: Context) => {
  const cacheKey = cacheKeyForContext(ctx);

  if (!rendererClients.has(cacheKey)) {
    const { mediaRendererTokenUrl, mediaBaseUrl } = getConfig();
    const response = await fetch(mediaRendererTokenUrl, {
      method: 'POST',
      body: JSON.stringify({
        objectUUID: ctx.objectUUID,
        workspaceUUID: ctx.workspaceUUID,
      }),
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      credentials: 'include',
    });

    const jsonResp = await response.json();

    rendererClients.set(
      cacheKey,
      {
        ...parseMediaToken(jsonResp.token),
        baseUrl: mediaBaseUrl,
      },
      MEDIA_TOKEN_TIMEOUT
    );
  }

  return rendererClients.get(cacheKey);
};

const getRendererMediaClientConfig = (context: Context): MediaClientConfig => ({
  authProvider: async () => getRendererToken(context),
});

const uploadClients = new Cache();
const getUploadToken = async (ctx: Context) => {
  const cacheKey = cacheKeyForContext(ctx);

  if (!uploadClients.has(cacheKey)) {
    const { mediaUploadTokenUrl, mediaBaseUrl } = getConfig();
    const response = await fetch(mediaUploadTokenUrl, {
      method: 'POST',
      body: JSON.stringify({
        objectUUID: ctx.objectUUID,
        workspaceUUID: ctx.workspaceUUID,
      }),
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      credentials: 'include',
    });

    const jsonResp = await response.json();

    uploadClients.set(
      cacheKey,
      {
        ...parseMediaToken(jsonResp.token),
        baseUrl: mediaBaseUrl,
      },
      MEDIA_UPLOAD_TOKEN_TIMEOUT
    );
  }

  return uploadClients.get(cacheKey);
};

const getUploadMediaClientConfig = (context: Context): MediaClientConfig => ({
  authProvider: async () => getUploadToken(context),
});

// --------- Create provider ----------

const mediaProviderMap: {
  [k: string]: MediaProvider & {
    featureFlags: Record<string, boolean>; // not sure why this isn't in the type.
  };
} = {};

export const getMediaProviderForContext = (ctx: Context) => {
  const useTightClaims = true;
  const cacheKey = cacheKeyForContext(ctx);

  if (!mediaProviderMap[cacheKey]) {
    mediaProviderMap[cacheKey] = {
      featureFlags: {},
      uploadParams: { collection: ctx.objectUUID },
      viewMediaClientConfig: useTightClaims ? getRendererMediaClientConfig(ctx) : getGeneralMediaClientConfig(ctx),
      uploadMediaClientConfig: useTightClaims ? getUploadMediaClientConfig(ctx) : getGeneralMediaClientConfig(ctx),
    };
  }

  return mediaProviderMap[cacheKey];
};
