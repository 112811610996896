import Loadable from 'react-loadable';
import { RouteContext } from 'react-resource-router';

import { LoadableErrorHandler } from '@shared/LoadableErrorHandler';

type DepartmentEmbedPageProps = RouteContext & {
  isAssetsEnable?: boolean;
};

export const DepartmentPageEmbed = Loadable<DepartmentEmbedPageProps, never>({
  loader: () =>
    import(/* webpackChunkName: "DepartmentPageEmbed" */ './DepartmentPageEmbed').then(
      ({ DepartmentPageEmbed }) => DepartmentPageEmbed
    ),
  loading: LoadableErrorHandler,
});
