import { N200, N500 } from '@atlaskit/theme/colors';
import styled from 'styled-components';

import { Tag } from '@shared/TawBreakdown/TawTag';
import { ellipsis } from '@tc/UserAvatar/styles';

export const NoteTitle = styled.div<{ isDashboardMoreDetail?: boolean }>`
  display: flex;
  align-items: center;
  margin-left: -4px;
  cursor: ${(p) => (p.isDashboardMoreDetail ? 'pointer' : 'default')};

  h5 {
    font-size: 14px;
    font-weight: normal;
    margin: 0 0 0 4px;
    padding: 0;
  }

  &::before {
    display: none;
  }
`;

export const MissedUpdateCardTop = styled.span<{
  showDot?: boolean;
  isAlignedLeft?: boolean;
  isDarkerColor?: boolean;
  isEditList?: boolean;
}>`
  margin-left: ${({ isAlignedLeft, isEditList }) => (isEditList ? '40px' : isAlignedLeft ? 0 : '8px')};
  color: ${({ isDarkerColor }) => (isDarkerColor ? N500 : N200)};
  font-weight: 500;
  ${ellipsis}
  margin-right: 8px;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${(props) =>
    props.showDot
      ? `
        &:before {
          margin-right: 8px;
          content: '\\2022';
        }
      `
      : ''}
`;

export const TawPhase = styled(Tag)`
  white-space: nowrap;
  margin: 0;
  padding: 2px 8px;
  cursor: default;
`;
