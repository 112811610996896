import { TawPhase } from 'src/store/tawPhaseStore';

type UpdateTawPhasesProps = {
  allTawPhases: TawPhase[];
  isIncreasingOperation: boolean;
  newPriority: number;
  previousPriority: number;
};

export const getTawPhasesToUpdate = ({
  allTawPhases,
  isIncreasingOperation,
  newPriority,
  previousPriority,
}: UpdateTawPhasesProps) =>
  isIncreasingOperation
    ? allTawPhases.filter(({ priority }) => priority >= newPriority && priority < previousPriority)
    : allTawPhases.filter(({ priority }) => priority > previousPriority && priority <= newPriority);

export const getTawPhasesWithNewPriorities = ({
  allTawPhases,
  isIncreasingOperation,
  newPriority,
  previousPriority,
}: UpdateTawPhasesProps) => {
  const tawPhasesToUpdate = getTawPhasesToUpdate({
    allTawPhases,
    isIncreasingOperation,
    newPriority,
    previousPriority,
  });

  return tawPhasesToUpdate.map(({ priority, ...rest }) => {
    const newPriority = isIncreasingOperation ? ++priority : --priority;

    return {
      ...rest,
      priority: newPriority,
    };
  });
};
