import Loadable from 'react-loadable';
import { RouteContext } from 'react-resource-router';

import { LoadableErrorHandler } from '@shared/LoadableErrorHandler';

export type ReportPageProps = RouteContext & {
  isAssetsEnable?: boolean;
};

export const ReportPage = Loadable<ReportPageProps, never>({
  loader: () => import(/* webpackChunkName: "ReportPage" */ './ReportPage').then(({ ReportPage }) => ReportPage),
  loading: LoadableErrorHandler,
});
