import { addWeeks } from 'date-fns';

import { endOfWeek, startOfWeek, tzDate } from '@tc/util/date';
import { parseIsoLocalDateTime } from '@tc/util/dateFormat';

export interface ExpectedQueryParams {
  start?: string;
  end?: string;
}

export const getDefaultStartDate = () => startOfWeek(addWeeks(tzDate(), -1));
export const getDefaultEndDate = () => endOfWeek(addWeeks(tzDate(), -1));

export const getStartDate = ({ start }: ExpectedQueryParams): Date =>
  parseIsoLocalDateTime(start, getDefaultStartDate());
export const getEndDate = ({ end }: ExpectedQueryParams): Date => parseIsoLocalDateTime(end, getDefaultEndDate());
