import { gridSize } from '@atlaskit/theme/constants';
import { h500 } from '@atlaskit/theme/typography';
import { N200 } from '@atlaskit/theme/colors';
import styled from 'styled-components';

import { Context } from '@tc/providers';
import { Renderer } from 'src/components/teamcentral/Renderer';

export const DescriptionTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: ${gridSize()}px;
  align-items: center;
  margin: 0 0 ${gridSize() * 2}px;
  min-height: ${gridSize() * 3}px;
`;

export const DescriptionTitle = styled.h5`
  ${h500}
`;

export const Placeholder = styled.div`
  color: ${N200};
`;

export type DescriptionWithTitleProps = {
  title: string;
  testId: string;
  placeholder: string;
  value: string;
  context?: Context;
};

export const DescriptionWithTitle = (props: DescriptionWithTitleProps) => {
  const { title, testId, value, placeholder } = props;

  const descriptionPlaceholder = <Placeholder>{placeholder}</Placeholder>;
  const renderDescription = <Renderer document={value} mode={'normal'} context={props.context} />;

  const getDescription = () => (value ? renderDescription : descriptionPlaceholder);

  return (
    <div data-testid={testId}>
      <DescriptionTitleWrapper>
        <DescriptionTitle>{title}</DescriptionTitle>
      </DescriptionTitleWrapper>
      {getDescription()}
    </div>
  );
};
