import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Checkbox } from '@atlaskit/checkbox';

import { fuzzyDateValue, FuzzyScale } from '@tc/FuzzyDate';
import { Statuses } from '@shared/StatusOptions/StatusOptions';

type Props = {
  label: string;
  value: string;
  onSelectStatus: (status: string) => void;
  confidence: number;
  statuses: string[];
};
export const StatusCheckbox = ({ label, value, confidence, onSelectStatus, statuses }: Props) => {
  const [isChecked, setIsChecked] = useState(value === Statuses.ENDING);

  const onChange = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      const status = event.currentTarget.value;

      setIsChecked((prevState) => !prevState);
      onSelectStatus(status);
    },
    [onSelectStatus]
  );

  const isDisabled = value === Statuses.ACTIVE_DURING && confidence === fuzzyDateValue[FuzzyScale.DAY];

  useEffect(() => {
    if (isDisabled) {
      setIsChecked(false);
    }
  }, [isDisabled]);

  useEffect(() => {
    if (statuses.length === 0 && value === Statuses.ENDING) {
      setIsChecked(true);
    }
  }, [statuses, value]);

  return (
    <Checkbox
      key={label}
      value={value}
      label={label}
      onChange={onChange}
      isDisabled={isDisabled}
      isChecked={isChecked}
    />
  );
};
