import { ReactNode } from 'react';
import { Field, ErrorMessage } from '@atlaskit/form';
import { SimpleTag } from '@atlaskit/tag';
import TagGroup from '@atlaskit/tag-group';
import { N200, N800 } from '@atlaskit/theme/colors';
import styled from 'styled-components';

import { ProjectIcon } from '@tc/ProjectIcon/ProjectIcon';
import { Project } from '@queries/SearchProjects';
import { Tag } from '@queries/GetProjectPageItems';
import { getTagColor } from '@shared/Labels';
import { TCLinkContentWrapper, TCLinkWrapper } from '@shared/TCLinkWrapper';
import { ValidationLoader } from '@shared/loaders/ValidationLoader';
import { ellipsis } from '@tc/UserAvatar/styles';

import { SelectedProject } from './styles';

const UnsuitableLabelsTitle = styled.div`
  margin: 24px 0 4px 0;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: ${N200};
`;

const TagGroupWrapper = styled.div`
  span {
    margin-left: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ProjectLink = styled.a`
  margin-left: 12px;
  ${ellipsis};
  font-size: 14px;
  line-height: 20px;

  &,
  &:hover {
    color: ${N800};
  }
`;

type Props = {
  project: Project;
  link: string;
  unsuitableTags: Tag[];
  error?: string | ReactNode;
  isLoading: boolean;
  isValid: boolean;
  validationErrorMessage: string | ReactNode;
};

export const SelectedProjectField = ({
  project,
  error,
  link,
  unsuitableTags,
  isLoading,
  isValid,
  validationErrorMessage,
}: Props) => (
  <Field name="project" defaultValue="" label="Global State for">
    {({ fieldProps }) => (
      <>
        <SelectedProject data-name={fieldProps.id} data-value={fieldProps.value}>
          <ProjectIcon {...project.icon} size={'small'} projectUuid={project.uuid} />
          <TCLinkWrapper
            isLabel={true}
            content={
              <TCLinkContentWrapper>
                <ProjectLink href={link} target="_blank" rel="noopener noreferrer">
                  {project.name}
                </ProjectLink>
              </TCLinkContentWrapper>
            }
          />
        </SelectedProject>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {isLoading && !error ? (
          <ValidationLoader />
        ) : (
          !isValid && !error && <ErrorMessage>{validationErrorMessage}</ErrorMessage>
        )}
        {!!unsuitableTags?.length && (
          <>
            <UnsuitableLabelsTitle>Unsuitable labels</UnsuitableLabelsTitle>
            <TagGroupWrapper>
              <TagGroup>
                {unsuitableTags.map(({ name }) => (
                  <SimpleTag key={name} text={name} color={getTagColor(name)} />
                ))}
              </TagGroup>
            </TagGroupWrapper>
          </>
        )}
      </>
    )}
  </Field>
);
