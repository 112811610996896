import Loadable from 'react-loadable';
import { RouteContext } from 'react-resource-router';

import { LoadableErrorHandler } from '@shared/LoadableErrorHandler';

export const MilestonePageEmbed = Loadable<RouteContext, never>({
  loader: () =>
    import(/* webpackChunkName: "InitiativePageEmbed" */ './MilestoneEmbed').then(
      ({ MilestonePageEmbed }) => MilestonePageEmbed
    ),
  loading: LoadableErrorHandler,
});
