import { useEffect } from 'react';

import { useUserStore } from 'src/store/userStore';
import { useWorkspaceStore } from 'src/store/workspaceStore';

export const useInitApp = (): boolean => {
  const [{ user }, { authCheck }] = useUserStore();
  const [{ workspace }, { fetchCurrentWorkspace }] = useWorkspaceStore();

  useEffect(() => {
    authCheck();
  }, [authCheck]);

  useEffect(() => {
    if (!user) {
      return;
    }

    fetchCurrentWorkspace(user);
  }, [user, fetchCurrentWorkspace]);

  return Boolean(user && workspace);
};
