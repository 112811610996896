import { createHook, createStore, Action } from 'react-sweet-state';

import { teamCentralClient } from 'src/clients/teamCentralClient';
import { getLogInUrl } from '@shared/utils/auth';
import { timezoneHolder } from '@tc/util/timezoneHolder';

export type User = {
  account_id: string;
  account_status: string;
  account_type: string;
  email: string;
  email_verified: boolean;
  extended_profile: {
    department: string;
  };
  locale: string;
  zoneinfo?: string;
  name: string;
  nickname: string;
  picture: string;
};

type RequestError = {
  code: number;
  message: string;
};

type UserStore = {
  user: User;
  isAtlassianUser: boolean;
  hasError: boolean;
  dashboardView: boolean;
  requestError: RequestError | null;
};

export const isTestUser = (email: string) => email.includes('usersinbuckets.com');
export const isAtlassianUser = (email: string) => email.includes('@atlassian.com');

export const hasAccessToApplication = (email: string) => isAtlassianUser(email) || isTestUser(email);

export const actions = {
  authCheck: (): Action<UserStore> => async ({ setState, getState }) => {
    const res = await teamCentralClient.authCheck();
    const user: User = await res.json();

    if (res.status === 401) {
      window.location.href = getLogInUrl();

      return;
    }

    if (res.status === 200) {
      timezoneHolder.setUserTimezone(user.zoneinfo);
      setState({ ...getState(), user, isAtlassianUser: !!user && hasAccessToApplication(user.email) });

      return;
    }

    setState({ ...getState(), hasError: true });
  },
  setRequestError: (requestError: RequestError | null): Action<UserStore> => async ({ setState, getState }) => {
    setState({ ...getState(), requestError });
  },
};

export const userStore = createStore({
  name: 'user-store',
  initialState: {
    hasError: false,
    isAtlassianUser: true,
    requestError: null,
  } as UserStore,
  actions,
});

export const useUserStore = createHook(userStore);
