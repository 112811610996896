import { sortBy, uniqBy, findIndex } from 'lodash';
import { ApolloCache } from '@apollo/client';

import { INDEPENDENT_INITIATIVE_TAG_PREFIX, PROGRAM_TAG_PREFIX } from 'src/constants';
import { ListProgramsQuery } from '@generated/ListPrograms';
import { Entity } from 'src/graphql/types';
import { Project } from '@queries/ListPrograms';
import { Tag } from '@queries/GetProjectPageItems';
import { getEntityTypeAndTags } from '@shared/utils/entity';
import { ListInitiativesQuery } from '@generated/ListInitiatives';
import { AddWatcherMutation, RemoveWatcherMutation } from '@generated/Watchers';
import { Operators, ComparatorOperator } from '@tc/Filters/MetaFilter/types';
import { AccountStatus, ProjectPhase } from '@generated/graphql';

export type Program = Project & {
  link: string;
  programTag: Tag;
  isVerified?: boolean;
};

export type Initiative = Project & {
  link: string;
  initiativeTag: Tag;
  isVerified?: boolean;
};

export type UpdateQueryProps = {
  cache: ApolloCache<AddWatcherMutation | RemoveWatcherMutation>;
  value: boolean;
  id: string;
};

export enum FilterKeys {
  NAME = 'name',
  LABEL = 'label',
  STATUS_PHASE = 'status_phase',
  CONTRIBUTOR = 'contributor',
}

export type Filters = {
  [FilterKeys.NAME]: string;
  [FilterKeys.LABEL]: string[];
  [FilterKeys.STATUS_PHASE]: string[];
  [FilterKeys.CONTRIBUTOR]: string[];
};

export const getTagNameToProgramsMap = (queryResult?: ListProgramsQuery) =>
  queryResult?.projectTql.edges.reduce<{ [key: string]: Omit<Program, 'link' | 'isDuplicated'>[] }>((acc, { node }) => {
    const { type, programTags } = getEntityTypeAndTags(node);
    const [programTag] = type === Entity.PROGRAM ? programTags : [];

    if (programTag?.name) {
      acc[programTag.name] = [...(acc[programTag.name] || []), { ...node, programTag }];
    }

    return acc;
  }, {});

export const getInitiativeTag = (initiativeTags: Tag[]) =>
  initiativeTags.reduce(
    (acc, tag) =>
      tag.name.includes(INDEPENDENT_INITIATIVE_TAG_PREFIX) && !acc.name.includes(INDEPENDENT_INITIATIVE_TAG_PREFIX)
        ? tag
        : acc,
    initiativeTags[0]
  );

export const getTagNameToInitiativesMap = (queryResult?: ListInitiativesQuery) =>
  queryResult?.projectTql.edges.reduce<{ [key: string]: Initiative[] }>((acc, { node }) => {
    const { type, initiativeTags } = getEntityTypeAndTags(node);

    if (type !== Entity.INITIATIVE) {
      return acc;
    }

    const initiativeTag = getInitiativeTag(initiativeTags);

    if (initiativeTag?.name) {
      acc[initiativeTag.name] = [...(acc[initiativeTag.name] || []), { ...node, initiativeTag }] as Initiative[];
    }

    return acc;
  }, {});

export const getPrograms = (queryResult?: ListProgramsQuery): Program[] | undefined => {
  const tagNameToProgramsMap = getTagNameToProgramsMap(queryResult);

  if (!tagNameToProgramsMap) {
    return;
  }

  return Object.values(tagNameToProgramsMap).map((programs) => {
    const link = `/program/${programs[0].programTag.name.replace(PROGRAM_TAG_PREFIX, '')}`;

    return {
      ...sortBy(programs, ['creationDate'])[0],
      link: link.toString(),
    };
  });
};

export const createInitiativeLink = (initiativeTag: Tag) => {
  const [, programOrInitiativeName, initiativeName] = initiativeTag.name.split('--');
  // TODO remove program/... route from condition when migrated to new labelling

  return initiativeName
    ? `/program/${programOrInitiativeName}/${initiativeName}`
    : `/initiative/${programOrInitiativeName}`;
};

export const getInitiatives = (queryResult?: ListInitiativesQuery): Initiative[] | undefined => {
  const tagNameToInitiativesMap = getTagNameToInitiativesMap(queryResult);

  if (!tagNameToInitiativesMap) {
    return;
  }

  return Object.values(tagNameToInitiativesMap).map((initiatives) => {
    const link = createInitiativeLink(initiatives[0].initiativeTag);

    return {
      ...sortBy(initiatives, ['creationDate'])[0],
      link: link.toString(),
    };
  });
};

export const getMilestonesList = (milestonesKeys: string[], queryResult?: ListProgramsQuery): Initiative[] => {
  if (milestonesKeys.length === 0) {
    return [];
  }
  const milestones = queryResult?.projectTql.edges
    .filter(({ node }) => milestonesKeys.includes(node.key))
    .map(({ node }) => node) as Initiative[];

  if (!milestones) {
    return [];
  }

  const initiatives = getInitiatives(queryResult);

  return sortBy(uniqBy([...(initiatives || []), ...milestones], 'key'), (item) =>
    findIndex(queryResult?.projectTql.edges, ({ node }) => node.key === item.key)
  );
};

export const getTqlQuery = (defaultTqlQuery: string, filters: Filters) => {
  const tqlQuery = [defaultTqlQuery];
  const orOperator = ` ${Operators.OR} `;

  Object.entries(filters).forEach(([filter, value]) => {
    if (Array.isArray(value) && value.length) {
      const tqlQueryChunk = `(${value
        .map((uuid) => `${filter} ${ComparatorOperator.EQUALS} ${uuid}`)
        .join(orOperator)})`;

      tqlQuery.push(tqlQueryChunk);
    }

    if (filter === FilterKeys.NAME && value && typeof value === 'string') {
      tqlQuery.push(`(name ${ComparatorOperator.LIKE} '${value.trim()}')`);
    }

    if (
      filter === FilterKeys.STATUS_PHASE &&
      (value.includes(ProjectPhase.Cancelled) || value === ProjectPhase.Cancelled) &&
      !value.includes(ProjectPhase.Paused)
    ) {
      tqlQuery.push(`(${FilterKeys.STATUS_PHASE} ${ComparatorOperator.NOT_EQUALS} ${ProjectPhase.Paused})`);
    }
  });

  return tqlQuery.join(` ${Operators.AND} `);
};

export const isActiveUser = ({
  node: {
    pii: { accountStatus },
  },
}: {
  node: { pii: { accountStatus: AccountStatus } };
}) => accountStatus === AccountStatus.Active;
