import { gql } from '@apollo/client';

export const GET_USER_PROFILE = gql`
  query GetUserProfile($aaid: String!) {
    userByAaid(aaid: $aaid) {
      id
      aaid
      pii {
        nickname
        picture
        name
        accountStatus
        email
        zoneinfo
      }
    }
  }
`;
