import { useCallback, useEffect, useRef } from 'react';

import { Status } from 'src/graphql/types';

import { Resolver, SelectResolverOptions } from './types';

export type CheckedStatuses = {
  [value: string]: boolean;
};

/**
 * This hook exists purely to open the select menu from PopupSelect on render.
 *
 * TODO: Need to find a better way to work this behaviour into the flow without having
 * to preload all options
 */
export const usePopupSelectHackOpen = (selectedResolver?: Resolver) => {
  const ref = useRef<HTMLSpanElement>(null);
  const setRef = useCallback((node) => {
    // @ts-ignore
    ref.current = node;
  }, []);

  useEffect(() => {
    if (ref.current) {
      ref.current.click();
    }
  }, [selectedResolver]);

  return setRef;
};

export const isActive = (status: string) =>
  status === Status.ON_TRACK || status === Status.AT_RISK || status === Status.OFF_TRACK;
export const isInactive = (status: string) =>
  status === Status.PAUSED || status === Status.PENDING || status === Status.DONE || status === Status.CANCELLED;

export const getInitialCheckedStatuses = (
  options: SelectResolverOptions,
  statuses: string[],
  isActiveStatuses: boolean
) => {
  if (isActiveStatuses) {
    const statusToSelectionMap: CheckedStatuses = options.reduce((acc, option) => {
      if (isActive(option.value.toString())) {
        return { ...acc, [option.value]: statuses.includes(option.value.toString()) };
      }

      return acc;
    }, {});

    const isActiveOptionSelected = Object.values(statusToSelectionMap).every((isSelected) => isSelected);

    if (isActiveOptionSelected) {
      statusToSelectionMap.active = true;
    } else {
      statusToSelectionMap.active = false;
    }

    return statusToSelectionMap;
  }

  const statusToSelectionMap: CheckedStatuses = options.reduce((acc, option) => {
    if (isInactive(option.value.toString())) {
      return { ...acc, [option.value]: statuses.includes(option.value.toString()) };
    }

    return acc;
  }, {});

  const isInactiveOptionSelected = Object.values(statusToSelectionMap).every((isSelected) => isSelected);

  if (isInactiveOptionSelected) {
    statusToSelectionMap.inactive = true;
  } else {
    statusToSelectionMap.inactive = false;
  }

  return statusToSelectionMap;
};

export const getCheckedChildrenCount = (checkedStatuses: CheckedStatuses, parent: string) => {
  const childStatuses = Object.keys(checkedStatuses).filter((i) => i !== parent);

  return childStatuses.reduce((count, i) => (checkedStatuses[i] ? count + 1 : count), 0);
};
