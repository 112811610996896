import { useCallback } from 'react';
import {
  SendScreenEventInput,
  TrackEventPayload,
  UIEventPayload,
} from '@atlassiansox/analytics-web-client/dist/types/types';
import { userType } from '@atlassiansox/analytics-web-client';

import { analyticsClient } from 'src/clients/analyticsClient';
import { useUserStore } from 'src/store/userStore';

export enum Actions {
  CLICKED = 'clicked',
  CREATED = 'created',
  ERROR = 'error',
}

export enum Sources {
  NOT_ATLASSIAN_USER_PAGE = 'notAtlassianUserPage',
  PROGRAM_LIST_PAGE = 'programListPage',
  INITIATIVE_LIST_PAGE = 'initiativeListPage',
  DEPARTMENT_LIST_PAGE = 'departmentListPage',
  PROGRAM_PAGE = 'programPage',
  INITIATIVE_PAGE = 'initiativePage',
  MILESTONE_PAGE = 'milestonePage',
  DEPARTMENT_PAGE = 'departmentPage',
  PROGRAM_PAGE_REPORT = 'programPageReportScreen',
  INITIATIVE_PAGE_REPORT = 'initiativePageReportScreen',
  PROGRAM_PAGE_DASHBOARD = 'programPageDashboardScreen',
  INITIATIVE_PAGE_DASHBOARD = 'initiativePageDashboardScreen',
  TAW_BREAKDOWN_MODAL = 'tawBreakdownModal',
  UNDER_CONSTRUCTION_PAGE = 'underConstructionPage',
  NAVBAR = 'navbar',
}

export const useAnalytics = () => {
  const [{ user }] = useUserStore();

  const sendScreenEvent = useCallback(
    (options: SendScreenEventInput) => {
      if (!user) {
        return;
      }

      analyticsClient.setUserInfo(userType.ATLASSIAN_ACCOUNT, user.account_id);

      return analyticsClient.sendScreenEvent(options);
    },
    [user]
  );

  const sendUIEvent = useCallback(
    (options: UIEventPayload) => {
      if (!user) {
        return;
      }

      analyticsClient.setUserInfo(userType.ATLASSIAN_ACCOUNT, user.account_id);

      return analyticsClient.sendUIEvent(options);
    },
    [user]
  );

  const sendTrackEvent = useCallback(
    (options: TrackEventPayload) => {
      if (!user) {
        return;
      }

      analyticsClient.setUserInfo(userType.ATLASSIAN_ACCOUNT, user.account_id);

      return analyticsClient.sendTrackEvent(options);
    },
    [user]
  );

  return {
    sendScreenEvent,
    sendUIEvent,
    sendTrackEvent,
  };
};
