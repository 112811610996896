import { N300, N800 } from '@atlaskit/theme/colors';
import styled, { css } from 'styled-components';

import { ellipsis } from '@tc/UserAvatar/styles';

export const Wrapper = styled.div`
  margin-bottom: 40px;
`;

export const CardHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  min-width: 0;
`;

export const CardHeaderInfoContainer = styled.div<{ isStatusChanged?: boolean }>`
  max-width: 100%;
  display: flex;
  align-items: center;
  width: ${({ isStatusChanged }) => (isStatusChanged ? '85%' : '70%')};

  @media (max-width: 760px) {
    width: 65%;
  }
`;

export const TypeAndNameWrapper = styled.div`
  display: flex;
  height: 42px;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 3px;
  flex: 1;
  min-width: 0;
`;

export const LinkWrapper = styled.div`
  ${ellipsis}
  line-height: 16px;
  padding: 5px;

  a {
    color: ${N800};
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
  }
`;

export const CustomLink = styled.div`
  display: inline;

  a {
    ${ellipsis}
  }
`;

export const Type = styled.sub`
  transform: translate(0, 5px);
  padding-left: 5px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: ${N300};
`;

export const IconContainer = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.color &&
    css`
      background: ${(props) => props.color};
    `}
  width: 32px;
  height: 32px;
  border-radius: 3px;
`;

export const CardHeaderActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;
`;

export const ButtonWrapper = styled.div`
  margin: 5px 9px 0 0;
`;

export const ProjectUpdateCardWrapper = styled.div`
  margin-top: 12px;
`;
