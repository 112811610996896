import { RefObject, useCallback, useState } from 'react';
import Popup, { TriggerProps } from '@atlaskit/popup';
import { N20, N300 } from '@atlaskit/theme/colors';
import styled from 'styled-components';
import { PrimaryDropdownButton } from '@atlaskit/atlassian-navigation';
import Badge from '@atlaskit/badge';

import { AssetsParentProgram, ParentPrograms } from '@shared/Header/Header';

import { ParentProgramsPopup } from './ParentProgramsPopup';

export const DropdownWrapper = styled.div`
  min-width: 55px;
`;

export const NewDropdown = styled(PrimaryDropdownButton)`
  padding: 0 5px 0 3px !important;
  color: ${N300} !important;
  align-self: flex-start;

  > span:first-of-type {
    margin-right: 5px !important;
  }

  &:hover,
  &:focus,
  &[aria-expanded='true'] {
    background-color: ${N20} !important;
    color: ${N300} !important;
  }
`;

interface Props {
  parentPrograms?: (ParentPrograms | AssetsParentProgram)[];
  source: string;
}

export const IntiativeBreadcrumbs = ({ parentPrograms, source }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const renderPopupContent = useCallback(
    (props) => <ParentProgramsPopup parentPrograms={parentPrograms} source={source} {...props} />,
    [parentPrograms, source]
  );

  const onClick = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const onClose = useCallback(() => setIsOpen(false), []);

  const triggerFunction = useCallback(
    ({ ref, ...rest }: TriggerProps) => (
      <DropdownWrapper innerRef={ref as RefObject<HTMLDivElement>}>
        <NewDropdown {...rest} aria-label="Toggle new menu" testId="expand-parent-programs-button" onClick={onClick}>
          <Badge testId="parent-program-counter">{parentPrograms?.length}</Badge>
        </NewDropdown>
      </DropdownWrapper>
    ),
    [onClick, parentPrograms?.length]
  );

  return (
    <Popup
      placement="bottom-start"
      content={renderPopupContent}
      isOpen={isOpen}
      onClose={onClose}
      trigger={triggerFunction}
      testId="create-menu"
    />
  );
};
