import { Maybe, Exact, Scalars, ProjectDependencyRelationship } from '@generated/graphql';

export enum Tags {
  program = 'size-program',
  initiative = 'size-initiative',
  epic = 'size-epic',
  project = 'size-project',
}

export enum TawTags {
  Wonder = 'taw-wonder',
  Explore = 'taw-explore',
  Make = 'taw-make',
  Impact = 'taw-impact',
  'No Phase' = 'taw-no-phase',
}

export enum Entity {
  PROGRAM = 'program',
  MILESTONE = 'milestone',
  INITIATIVE = 'initiative',
  PROJECT = 'project',
}

export enum Status {
  ON_TRACK = 'on_track',
  AT_RISK = 'at_risk',
  OFF_TRACK = 'off_track',
  DONE = 'done',
  PENDING = 'pending',
  PAUSED = 'paused',
  NO_UPDATE = 'missed',
  CANCELLED = 'cancelled',
}

export enum StatusCategory {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export type GqlStatusType = 'off_track' | 'at_risk' | 'on_track';

export type GqlPhaseType = 'pending' | 'in_progress' | 'paused' | 'done' | 'cancelled';

export interface GqlUser {
  aaid: string;
  id: string;
  pii: {
    name: string;
    nickname: string;
    picture: string;
    email?: string;
  };
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export type BaseProject = {
  __typename?: 'Project';
  id: string;
  key: string;
  name: string;
  uuid: any;
  creationDate: any;
  tags: Maybe<{
    __typename?: 'TagConnection';
    edges: Maybe<
      Array<
        Maybe<{
          __typename?: 'TagEdge';
          node: Maybe<{
            __typename?: 'Tag';
            name: Maybe<string>;
            id: string;
            uuid: Maybe<any>;
          }>;
        }>
      >
    >;
  }>;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export type DependantProjects = {
  dependencies: Maybe<{
    __typename?: 'ProjectDependencyConnection';
    edges: Maybe<
      Array<
        Maybe<{
          __typename?: 'ProjectDependencyEdge';
          node: Maybe<{
            __typename?: 'ProjectDependency';
            linkType: Maybe<ProjectDependencyRelationship>;
            outgoingProject: Maybe<{ __typename?: 'Project'; key: string }>;
          }>;
        }>
      >
    >;
  }>;
};

export type BaseProjectQueryWithDependencies<T = BaseProject> = {
  __typename?: 'Query';
  projectTql: Maybe<{
    __typename?: 'ProjectConnection';
    edges: Maybe<
      Array<
        Maybe<{
          __typename?: 'ProjectEdge';
          node: Maybe<T & DependantProjects>;
        }>
      >
    >;
  }>;
};

export type BaseProjectQuery<T = BaseProject> = {
  __typename?: 'Query';
  projectTql: Maybe<{
    __typename?: 'ProjectConnection';
    edges: Maybe<
      Array<
        Maybe<{
          __typename?: 'ProjectEdge';
          node: Maybe<T & DependantProjects>;
        }>
      >
    >;
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean; endCursor: Maybe<string> };
  }>;
};

export type BaseProjectQueryVariables = Exact<{
  q: Scalars['String'];
  workspaceId?: Maybe<Scalars['ID']>;
}>;

export type ProjectQueryVariables = Exact<{
  q: Scalars['String'];
  workspaceId?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  firstUpdate?: Maybe<Scalars['Int']>;
  createdAtOrAfter?: Maybe<Scalars['DateTime']>;
  createdAtOrBefore?: Maybe<Scalars['DateTime']>;
  endCursor?: string;
}>;

export type BaseProjectCountQuery = {
  __typename?: 'Query';
  projectTql: Maybe<{
    __typename?: 'ProjectConnection';
    edges: Maybe<
      Array<
        Maybe<{
          __typename?: 'ProjectEdge';
          node: Maybe<{
            key: Scalars['String'];
            updates: Maybe<{
              count: Scalars['Int'];
            }>;
          }>;
        }>
      >
    >;
  }>;
};
