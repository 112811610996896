import Loadable from 'react-loadable';
import { RouteContext } from 'react-resource-router';

import { LoadableErrorHandler } from '@shared/LoadableErrorHandler';

export const ProgramListPage = Loadable<RouteContext, never>({
  loader: () =>
    import(/* webpackChunkName: "ProgramListPage" */ './ProgramListPage').then(
      ({ ProgramListPage }) => ProgramListPage
    ),
  loading: LoadableErrorHandler,
});
