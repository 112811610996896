import { gridSize } from '@atlaskit/theme/constants';
import styled, { css } from 'styled-components';

export const gridSizeTimes = (factor: number) => gridSize() * factor;

export const responsiveGridSizeTimes = (n: number) => 80 * n;

export const responsiveMobileWidthThreshold = 560;

/**
 * Using ADG3 comfortable spacing -> 40px gutters
 * 20px half gutters used as padding around columns -> adjacent columns are 40px apart.
 */
export const halfGutterSize: 20 = 20;

/* Minimum width columns are allowed to be */
export const minColumnWidth: number = responsiveGridSizeTimes(3);

/**
 * Minimum page width for non-responsive pages: 768px
 * From Atlassian Cloud minimum screen size:
 * https://confluence.atlassian.com/cloud/supported-browsers-744721663.html
 */
export const responsiveContentWidthThreshold = 768;

/**
 * Threshold width where layout switches between narrow and standard views.
 *
 *   768 (Min page layout content width) +
 *    64 (width of global nav) +
 * +  34 (Total unavoidable ancentral padding-left/margin-left)
 * = Responsive threshold width
 */
export const responsiveThreshold = responsiveContentWidthThreshold + 64;

/**
 * Threshold width where layout switches between standard view that's good for larger laptop screen (aka 15")
 * to a "less spacious" layout that's better for a smaller laptop screen (aka 13")
 *
 * This is currently set to 1280px width
 */
export const smallLaptopScreenResponsiveThreshold = responsiveContentWidthThreshold + 512;

/**
 * Contains styles common to all columns
 */
export const baseColumnStyles = css`
  padding: 0 ${halfGutterSize}px ${halfGutterSize}px;
  min-width: ${minColumnWidth}px;

  /**
   * Responsive behaviour:
   * - Fit available space
   * - Remove padding to fully utilise narrow screen real estate
   */
  @media (max-width: ${responsiveThreshold}px) {
    flex: 1 1;
    padding: 0;
  }
`;

/**
 * Contains and arranges a set of columns.
 */
export const Section = styled.div<{ marginTop?: number }>`
  /* arrange contents in a row */
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: ${(props) => props.marginTop !== undefined && props.marginTop}px;
`;

/**
 * Same as Section but stacks its contained columns vertically when resized below
 * a threshold width.
 */
export const ResponsiveSection = styled(Section)`
  @media (max-width: ${responsiveThreshold}px) {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 0;
  }
`;

/**
 * A standard column.
 * 8 grid units wide by default but shrinks to fit available space.
 */
export const MainColumn = styled.main<{ marginTop?: number }>`
  ${baseColumnStyles};
  flex: 0 1 ${responsiveGridSizeTimes(8)}px;
  margin-top: ${(props) => (props.marginTop !== undefined ? props.marginTop : gridSizeTimes(4))}px;
  margin-bottom: ${gridSizeTimes(4)}px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: ${responsiveThreshold}px) {
    &:last-of-type {
      margin-bottom: ${gridSizeTimes(4)}px;
    }
  }
`;

/**
 * A narrow column specifically for secondary navigations that shrink down to a single icon on narrow screens.
 */
export const NavColumn = styled.div`
  ${baseColumnStyles};
  position: sticky;
  top: 56px;
  height: calc(100vh - 56px);
  padding: 0;
  box-shadow: 2px 0px 0px rgba(9, 30, 66, 0.04);
  min-width: ${responsiveGridSizeTimes(2.5)}px;
  flex: 0 0 ${responsiveGridSizeTimes(2.5)}px;

  &:last-of-type {
    margin-bottom: 0;
  }

  // AtlasKit side nav element
  > nav {
    min-width: ${responsiveGridSizeTimes(2.5)}px;
    background-color: transparent;

    button {
      background-color: transparent;
    }
  }
`;

/**
 * A narrow column that does not grow or shrink in width except on narrow
 * screens where it fills the available width.
 */
export const AsideColumn = styled.aside`
  ${baseColumnStyles};
  flex: 0 0 ${responsiveGridSizeTimes(4)}px;
  margin-bottom: ${gridSizeTimes(4)}px;
  z-index: 1 !important;
  min-width: 400px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

/**
 * A column that uses the full available width.
 */
export const FullWidthColumn = styled.main`
  ${baseColumnStyles};
  flex: 1 1 ${responsiveGridSizeTimes(12)}px;
  max-width: 1000px;

  /* Children should be 32px apart */
  > *:not(:last-child) {
    margin-bottom: ${gridSizeTimes(4)}px;
  }
`;

export const ResponsivePage = styled.div`
  padding: 0 20px 20px;
`;

export const ResponsivePageWithSidebar = styled.div`
  background-color: #fff;

  ${ResponsiveSection} {
    justify-content: space-between;
  }

  ${MainColumn} {
    flex: 0 1 ${responsiveGridSizeTimes(11)}px;
    margin-left: auto;
    margin-right: auto;
    min-height: calc(100vh - 56px);
    padding: 0 48px;
  }

  ${NavColumn} {
    box-sizing: border-box;
  }

  ${AsideColumn} {
    background-color: #fafbfc;
    min-height: calc(100vh - 56px);
    flex: 0 0 440px;
    padding: 0 48px 48px;
    box-sizing: border-box;
  }

  @media (max-width: ${smallLaptopScreenResponsiveThreshold}px) {
    ${MainColumn} {
      padding: 0 28px 28px;
    }

    ${AsideColumn} {
      padding: 0 28px 28px;
      flex: 0 0 ${responsiveGridSizeTimes(4)}px;
    }
  }

  @media (max-width: ${responsiveThreshold}px) {
    ${MainColumn} {
      padding: 0 20px 20px;

      margin-left: 0;
      margin-right: 0;
      min-height: 0;
      flex: auto;
    }

    ${NavColumn} {
      min-height: unset;
      height: -webkit-fit-content;
      position: relative;
      top: 0;
    }

    ${AsideColumn} {
      padding: 0 20px 20px;
      min-height: 0;
      flex: auto;
    }
  }
`;

export const ResponsivePageWithoutSidebar = styled.div`
  background-color: #fff;

  ${MainColumn} {
    flex: 0 1 ${responsiveGridSizeTimes(15)}px;
    margin-left: auto;
    margin-right: auto;
    min-height: calc(100vh - 56px);
    padding: 0 48px;
  }

  @media (max-width: ${smallLaptopScreenResponsiveThreshold}px) {
    ${MainColumn} {
      padding: 0 28px 28px;
    }
  }

  @media (max-width: ${responsiveThreshold}px) {
    ${MainColumn} {
      padding: 0 20px 20px;

      margin-left: 0;
      margin-right: 0;
      min-height: 0;
      flex: auto;
    }
  }
`;
