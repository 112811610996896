import { useCallback } from 'react';
import { HeadingItem, MenuGroup, Section, StatelessCSSFn } from '@atlaskit/menu';

import { SwitchWorkspaceItem } from '@tc/Navigation/ProfileAndWorkspaceSwitcher/SwitchWorkspaceItem';

import { WorkspaceDetails } from '.';

export const SwitcherContent = ({
  options,
  handleClick,
}: {
  options: WorkspaceDetails[];
  handleClick: (selectedWorkspace: WorkspaceDetails) => void;
}) => {
  const headingCssFn: StatelessCSSFn = useCallback(() => ({ textTransform: 'none' }), []);

  return (
    <MenuGroup minWidth={192} maxWidth={480}>
      <Section>
        <HeadingItem cssFn={headingCssFn}>Switch workspace</HeadingItem>

        {options &&
          options.map((ws) => <SwitchWorkspaceItem onItemClick={handleClick} workspace={ws} key={ws.globalId} />)}
      </Section>
    </MenuGroup>
  );
};
