import styled, { keyframes } from 'styled-components';

const loadingFlash = keyframes`
  0% {
      background-position: -10px;
  }
  100% {
      background-position: calc(100% + 250px);
  }
`;

const Skeleton = styled.div`
  background-color: rgb(240, 240, 240);
  border-radius: 5px;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: linear-gradient(
      90deg,
      rgb(240, 240, 240) 0px,
      #f9f9f9 calc(50% - 25px),
      #f9f9f9 calc(50% + 25px),
      rgb(240, 240, 240) 100%
    );
    background-size: 35%;
    background-position: 0%;
    background-repeat: no-repeat;
    animation: ${loadingFlash} 2.5s infinite linear;
  }
`;

export const SkeletonLoader = ({ className }: { className?: string }) => <Skeleton className={className} />;
