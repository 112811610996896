import {
  addWeeks,
  differenceInCalendarWeeks as differenceInCalendarWeeksReal,
  endOfWeek as endOfWeekReal,
  format as formatReal,
  getWeek as getWeekReal,
  isPast,
  isSameWeek as isSameWeekReal,
  isThisWeek as isThisWeekReal,
  parse as parseReal,
  setDay as setDayReal,
  startOfWeek as startOfWeekReal,
  isBefore,
} from 'date-fns';
import { toDate } from 'date-fns-tz';

import { utcToUserTimezone, utcToWorkspaceTimezone } from './timezoneDatetime';
import { timezoneHolder } from './timezoneHolder';

type DEFAULT_DATE_FNS_TYPE = {
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
};

const DEFAULT_DATE_FNS_OPTIONS: DEFAULT_DATE_FNS_TYPE = {
  weekStartsOn: 1, // Start on monday. Week is monday -> sunday.
};

const mixinDefaultOptions = <p extends Function>(fn: p) =>
  function (this: unknown, ...theArgs: unknown[]) {
    const lastIndex = fn.length - 1;

    if (fn.length === theArgs.length) {
      theArgs[lastIndex] = {
        ...DEFAULT_DATE_FNS_OPTIONS,
        ...(theArgs[lastIndex] as object),
      };
    } else {
      theArgs[lastIndex] = DEFAULT_DATE_FNS_OPTIONS;
    }

    return fn.apply(this, theArgs);
  };

export const differenceInCalendarWeeks = mixinDefaultOptions(differenceInCalendarWeeksReal);
export const endOfWeek = mixinDefaultOptions(endOfWeekReal);
export const format = mixinDefaultOptions(formatReal);
export const getWeek = mixinDefaultOptions(getWeekReal);
export const isSameWeek = mixinDefaultOptions(isSameWeekReal);
export const isThisWeek = mixinDefaultOptions(isThisWeekReal);
export const parse = mixinDefaultOptions(parseReal);
export const setDay = mixinDefaultOptions(setDayReal);
export const startOfWeek = mixinDefaultOptions(startOfWeekReal);

export const isLastWeek = (date: Date) => isSameWeek(date, addWeeks(tzDate(), -1));
export const isDateBeforeNextTwoWeeks = (date: string) => isBefore(tzDate(date), addWeeks(endOfWeek(tzDate()), 2));

export const tzDate = (dateString?: string | null) =>
  dateString
    ? utcToWorkspaceTimezone(dateString)
    : // Create new time-zoned date object
      toDate(Date.now(), {
        ...DEFAULT_DATE_FNS_OPTIONS,
        timeZone: timezoneHolder.getWorkspaceTimezone(),
      });

export const tzUserTimezoneDate = (dateString?: string) =>
  dateString
    ? utcToUserTimezone(dateString) // Create new time-zoned date object
    : toDate(Date.now(), {
        ...DEFAULT_DATE_FNS_OPTIONS,
        timeZone: timezoneHolder.getUserTimezone(),
      });

export const isDateInThePast = (dateString?: string | null) =>
  dateString ? isPast(new Date(`${dateString.split('T')[0]}T23:59:59`)) : false;

export const localDateToISODate = (date: Date) => new Date(date.toISOString().slice(0, -1));
