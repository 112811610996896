import Loadable from 'react-loadable';
import { RouteContext } from 'react-resource-router';

import { LoadableErrorHandler } from '@shared/LoadableErrorHandler';

type DepartmentListPageProps = RouteContext & {
  isAssetsEnable?: boolean;
};

export const DepartmentListPage = Loadable<DepartmentListPageProps, never>({
  loader: () =>
    import(/* webpackChunkName: "DepartmentListPage" */ './DepartmentListPage').then(
      ({ DepartmentListPage }) => DepartmentListPage
    ),
  loading: LoadableErrorHandler,
});
