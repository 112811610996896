import TagGroup from '@atlaskit/tag-group';
import styled from 'styled-components';

import { SkeletonLoader } from '@shared/SkeletonLoader';

export const SimpleTagSkeleton = styled(SkeletonLoader)`
  height: 16px;
  width: 100px;
  border-radius: 3px;
  margin-right: 10px;
`;

export const LabelsLoader = () => (
  <TagGroup>
    <SimpleTagSkeleton />
    <SimpleTagSkeleton />
  </TagGroup>
);
