import { isSameMonth, isSameQuarter, isValid } from 'date-fns';
import startCase from 'lodash.startcase';

import { ProjectStateValue } from '@generated/graphql';
import { DepartmentProject as DepartmentProjectType } from '@department/children/utils/department';
import { Entity as EntityType, Status, TawTags } from 'src/graphql/types';
import { isSameWeek, tzDate } from '@tc/util/date';
import { fuzzyDateValue, FuzzyScale, getFuzzyScale } from '@tc/FuzzyDate';
import { getHighestPriorityTawPhaseTag, getTawTags } from '@shared/TawBreakdown/utils';
import { TawPhase } from '@store/tawPhaseStore';
import { parse } from '@store/utils/parsers';
import { NO_TAW_PHASE } from 'src/constants';
import { INDEPENDENT_INITIATIVE_NAME_TAG_REGEXP } from '@shared/utils/entity';
import { STATUS_ORDER, Statuses } from '@shared/StatusOptions/StatusOptions';
import { DepartmentProjectEdges } from '@queries/GetDepartmentProjects';
import { isActive } from '@tc/Filters/MetaFilter/util';

import { ProjectType } from './departmentTable';
import { getDepartmentPillarRegExp } from './department';

export type DepartmentProject = DepartmentProjectType & { pillars: string[] };

enum InvalidEntity {
  INVALID = '-',
}

export const Entity = { ...EntityType, ...InvalidEntity };

export enum FilterKeys {
  Q = 'q',
  ENTITY = 'entity',
  PROJECT_STATUS = 'status_phase',
  TAW = 'taw',
  PILLAR = 'pillar',
  TARGET_DATE = 'target_date',
  LABEL = 'label',
}

type FilterKeysWithoutEntity = Exclude<FilterKeys, FilterKeys.ENTITY>;

type FilterNameToFunctionMap = {
  [key in FilterKeysWithoutEntity]: Function;
};

export type Filters = {
  [FilterKeys.Q]?: string;
  [FilterKeys.ENTITY]?: string;
  [FilterKeys.PROJECT_STATUS]?: string;
  [FilterKeys.TAW]?: string;
  [FilterKeys.PILLAR]?: string;
  [FilterKeys.TARGET_DATE]?: string;
  [FilterKeys.LABEL]?: string;
};

export type Projects = {
  programs: DepartmentProject[];
  initiatives: DepartmentProject[];
  milestones?: DepartmentProject[];
  projects: DepartmentProject[];
  invalidEntities: DepartmentProject[];
};

type InvalidEntities = {
  invalidProjects: DepartmentProject[];
  restInvalidEntities: DepartmentProject[];
};

export type PillarOption = {
  label: string;
  value: string;
};

export const filterByName = (project: DepartmentProject, filterValue: string) =>
  project.name.toLowerCase().includes(filterValue.toLowerCase());

export const filterByValidStatus = (data: DepartmentProjectEdges) => {
  const statuses = ['at_risk', 'off_track', 'on_track'];

  return data
    .map((project): string | null => {
      const isValid =
        project.node.updates.edges.length &&
        project.node.updates.edges.some((item: DepartmentProject['updates']['edges'][0]) =>
          statuses.includes(item.node.newState.value)
        );

      return isValid ? project.node.id : null;
    })
    .filter(Boolean) as string[];
};

export const filterByStatusOrPhase = (project: DepartmentProject, filterValue: string) => {
  const parsedStatusPhase = decodeURIComponent(filterValue).split(',');

  const { state, latestUpdateDate } = project;

  const isInProgress = isActive(state.value);
  const isDonePhase = state.value === ProjectStateValue.Done;
  const isCancelledStatus = state.value === ProjectStateValue.Cancelled;

  return parsedStatusPhase.some((value) => {
    if (isInProgress) {
      return value === state.value || (value === Status.NO_UPDATE && !isSameWeek(new Date(latestUpdateDate), tzDate()));
    } else if (isDonePhase && !isCancelledStatus) {
      return value === state.value && !isActive(state.value);
    } else if (isCancelledStatus) {
      return value === state.value;
    } else {
      return value === state.value;
    }
  });
};

export const filterByTawPhase = (project: DepartmentProject, value: string, tawPhases: TawPhase[] | null) => {
  if (!tawPhases?.length) {
    return false;
  }

  const parsedTawPhase = decodeURIComponent(value).split(',');
  const hasFilterByNoPhase = parsedTawPhase.includes(TawTags[NO_TAW_PHASE]);
  const selectedPhases = parsedTawPhase.filter((phase) => phase !== TawTags[NO_TAW_PHASE]);

  const tawTags = getTawTags(project.tags.edges, tawPhases);
  const highestPriorityTawPhaseTag = getHighestPriorityTawPhaseTag(tawTags, tawPhases);

  return (hasFilterByNoPhase && !tawTags.length) || selectedPhases.includes(parse(highestPriorityTawPhaseTag || ''));
};

const filterByPillar = (project: DepartmentProject, value: string) => {
  const pillars = decodeURIComponent(value).split(',');

  return project.tags.edges.some(({ node: { name } }) => pillars.includes(name));
};

const filterByLabel = (project: DepartmentProject, value: string) => {
  const label = decodeURIComponent(value).split(',');

  return project.tags.edges.some(({ node: { uuid } }) => label.includes(uuid));
};

export const filterByDate = (project: DepartmentProject, value: string, departmentsIds: string[]) => {
  const parsedDate = decodeURIComponent(value).split(',');

  const fuzzyDateType = getFuzzyScale(Number(parsedDate[1]));

  if (parsedDate[2] && Object.keys(STATUS_ORDER).includes(parsedDate[2])) {
    return filterByDateAndStatus(project, parsedDate, fuzzyDateType, departmentsIds);
  }

  switch (fuzzyDateType) {
    case FuzzyScale.DAY:
      return project.targetDate === parsedDate[0] && project.targetDateConfidence === Number(parsedDate[1]);
    case FuzzyScale.MONTH:
      return isSameMonth(tzDate(project.targetDate), tzDate(parsedDate[0]));
    case FuzzyScale.QUARTER:
      return isSameQuarter(tzDate(project.targetDate), tzDate(parsedDate[0]));
  }
};

export const filterByDateAndStatus = (
  project: DepartmentProject,
  values: string[],
  dateType: string,
  departmentsIds: string[]
) => {
  const [time, dateConfidence, ...statuses] = values;

  const filters: { [key: string]: () => boolean | undefined } = {
    [FuzzyScale.DAY]: () => {
      if (statuses.includes(Statuses.STARTING) && project.startDate === time) {
        return true;
      }

      if (
        statuses.includes(Statuses.ENDING) &&
        project.targetDate === time &&
        project.targetDateConfidence === Number(dateConfidence)
      ) {
        return true;
      }
    },
    [FuzzyScale.MONTH]: () => {
      if (
        statuses.includes(Statuses.STARTING) &&
        project.startDate &&
        isSameMonth(tzDate(project.startDate), tzDate(time))
      ) {
        return true;
      }

      if (statuses.includes(Statuses.ENDING) && isSameMonth(tzDate(project.targetDate), tzDate(time))) {
        return true;
      }

      if (statuses.includes(Statuses.ACTIVE_DURING) && departmentsIds.includes(project.id)) {
        return true;
      }
    },
    [FuzzyScale.WEEK]: () => {
      if (
        statuses.includes(Statuses.STARTING) &&
        project.startDate &&
        isSameWeek(tzDate(project.startDate), tzDate(time))
      ) {
        return true;
      }

      if (statuses.includes(Statuses.ENDING) && isSameWeek(tzDate(project.targetDate), tzDate(time))) {
        return true;
      }

      if (statuses.includes(Statuses.ACTIVE_DURING) && departmentsIds.includes(project.id)) {
        return true;
      }
    },
    [FuzzyScale.QUARTER]: () => {
      if (
        statuses.includes(Statuses.STARTING) &&
        project.startDate &&
        isSameQuarter(tzDate(project.startDate), tzDate(time))
      ) {
        return true;
      }

      if (statuses.includes(Statuses.ENDING) && isSameQuarter(tzDate(project.targetDate), tzDate(time))) {
        return true;
      }

      if (statuses.includes(Statuses.ACTIVE_DURING) && departmentsIds.includes(project.id)) {
        return true;
      }
    },
  };

  return filters[dateType]();
};

const filterNameToFunctionMap: FilterNameToFunctionMap = {
  [FilterKeys.Q]: filterByName,
  [FilterKeys.PROJECT_STATUS]: filterByStatusOrPhase,
  [FilterKeys.TAW]: filterByTawPhase,
  [FilterKeys.PILLAR]: filterByPillar,
  [FilterKeys.TARGET_DATE]: filterByDate,
  [FilterKeys.LABEL]: filterByLabel,
};

const filterProject = (
  project: DepartmentProject,
  filters: Filters,
  tawPhase: TawPhase[] | null,
  departmentsIds?: string[]
) =>
  Object.entries(filters).every(
    ([name, value]) =>
      !value ||
      (name === FilterKeys.TARGET_DATE
        ? filterNameToFunctionMap[FilterKeys.TARGET_DATE](project, value, departmentsIds)
        : filterNameToFunctionMap[name as FilterKeysWithoutEntity](project, value, tawPhase))
  );

const initiativeTag = (initiative: DepartmentProject) =>
  initiative.tags.edges.find((tag) => INDEPENDENT_INITIATIVE_NAME_TAG_REGEXP.test(tag.node.name))?.node.name;

const sortFromOldToNewByCreationDate = (filteredInitiatives: DepartmentProject[]) =>
  filteredInitiatives.sort(
    (init, nextInit) => new Date(init.creationDate).getTime() - new Date(nextInit.creationDate).getTime()
  );

export const removeDuplicatedInitiatives = (filteredInitiatives: DepartmentProject[]) =>
  sortFromOldToNewByCreationDate(filteredInitiatives).filter(
    (init, index, arr) => arr.findIndex((initiative) => initiativeTag(initiative) === initiativeTag(init)) === index
  );

export const filterProjects = (
  filters: Filters,
  allProjects: Projects,
  tawPhase: TawPhase[] | null,
  departmentsIds?: string[]
) => {
  const { programs, initiatives, projects, milestones = [], invalidEntities } = allProjects;

  const { [FilterKeys.ENTITY]: entityFilter, ...usedFilters } = filters;

  const parsedEntities = entityFilter ? decodeURIComponent(entityFilter).split(',') : [];

  const shouldIncludePrograms = parsedEntities.includes(Entity.PROGRAM) || !parsedEntities.length;
  const shouldIncludeInitiatives = parsedEntities.includes(Entity.INITIATIVE) || !parsedEntities.length;
  const shouldIncludeMilestones = parsedEntities.includes(Entity.MILESTONE) || !parsedEntities.length;

  const shouldIncludeProjects = parsedEntities.includes(Entity.PROJECT) || !parsedEntities.length;
  const shouldIncludeInvalidEntities = parsedEntities.includes(Entity.INVALID) || !parsedEntities.length;

  const shouldIncludeAllInvalidEntities = shouldIncludeInvalidEntities && shouldIncludeProjects;

  const { invalidProjects, restInvalidEntities } = splitInvalidEntities(invalidEntities);

  const invalidEntitiesResult = shouldIncludeAllInvalidEntities
    ? invalidEntities
    : shouldIncludeInvalidEntities
    ? restInvalidEntities
    : invalidProjects;

  return {
    filteredPrograms: shouldIncludePrograms
      ? programs.filter((program) => filterProject(program, usedFilters, tawPhase, departmentsIds))
      : [],
    filteredInitiatives: shouldIncludeInitiatives
      ? removeDuplicatedInitiatives(
          initiatives.filter((initiative) => filterProject(initiative, usedFilters, tawPhase, departmentsIds))
        )
      : [],
    filteredMilestones: shouldIncludeMilestones
      ? milestones.filter((milestone) => filterProject(milestone, usedFilters, tawPhase, departmentsIds))
      : [],
    filteredProjects: shouldIncludeProjects
      ? projects.filter((project) => filterProject(project, usedFilters, tawPhase, departmentsIds))
      : [],
    filteredInvalidEntities:
      shouldIncludeInvalidEntities || shouldIncludeProjects
        ? invalidEntitiesResult.filter((invalidEntity) =>
            filterProject(invalidEntity, usedFilters, tawPhase, departmentsIds)
          )
        : [],
  };
};

export const isValidDate = (dateStr: string) => {
  const date = new Date(dateStr);

  return isValid(date) && date.toISOString().includes(dateStr);
};

export const isValidConfidence = (confidence: number) => {
  if (
    confidence === fuzzyDateValue[FuzzyScale.DAY] ||
    confidence === fuzzyDateValue[FuzzyScale.MONTH] ||
    confidence === fuzzyDateValue[FuzzyScale.QUARTER]
  ) {
    return true;
  }
};

export const getPillarName = (pillarTag: string) => startCase(pillarTag.split('--').pop());

export const getPillarOptions = (entities: DepartmentProject[], departmentTag: string): PillarOption[] => {
  const pillarTagRegExp = getDepartmentPillarRegExp(departmentTag);

  const pillarTagToOptionMap = entities.reduce<{ [key: string]: PillarOption }>((acc, { tags }) => {
    tags.edges.forEach(({ node: { name } }) => {
      const isPillarTag = pillarTagRegExp.test(name);

      if (isPillarTag && !acc[name]) {
        acc[name] = { label: getPillarName(name), value: name };
      }
    });

    return acc;
  }, {});

  return Object.values(pillarTagToOptionMap);
};

export const splitInvalidEntities = (invalidEntities: DepartmentProject[]) =>
  invalidEntities.reduce<InvalidEntities>(
    (acc, project) => {
      if (project.type === ProjectType.INVALID) {
        acc.restInvalidEntities.push(project);

        return acc;
      }
      acc.invalidProjects.push(project);

      return acc;
    },
    {
      invalidProjects: [],
      restInvalidEntities: [],
    }
  );

export const filterUndefinedFilters = (filtersWithUndefinedValues: Omit<Filters, 'q'>) => {
  const filteredFiltersArray = Object.entries(filtersWithUndefinedValues).filter(([, value]) => !!value);

  return Object.fromEntries(filteredFiltersArray);
};

export const hasFiltered = (filters: Filters) =>
  filters[FilterKeys.Q] ||
  filters[FilterKeys.ENTITY] ||
  filters[FilterKeys.PROJECT_STATUS] ||
  filters[FilterKeys.TAW] ||
  filters[FilterKeys.LABEL] ||
  filters[FilterKeys.TARGET_DATE];
