import { FieldChanged } from '@tc/ProjectUpdate/FieldChanged';
import { StatusOrPhaseLozenge } from '@tc/StatusOrPhaseLozenge';

import { Props } from './WeeklySummaryModalRow';

export const Status = ({ isNoChangeSection, isNoUpdateWidget, project }: Props) => {
  const { targetWeekUpdate, weekBeforeTargetWeekUpdate } = project;

  if (isNoUpdateWidget && targetWeekUpdate) {
    const { newState } = targetWeekUpdate;

    return <StatusOrPhaseLozenge state={newState} />;
  }

  if (isNoUpdateWidget && weekBeforeTargetWeekUpdate) {
    const { newState } = weekBeforeTargetWeekUpdate;

    return <StatusOrPhaseLozenge state={newState} />;
  }

  if (!isNoChangeSection && !isNoUpdateWidget && weekBeforeTargetWeekUpdate && targetWeekUpdate) {
    const { newState: newPhaseTwoWeeksAgo } = weekBeforeTargetWeekUpdate;
    const { newState: newPhaseWeekAgo } = targetWeekUpdate;

    return (
      <div data-testid="weekly-summary-status">
        <FieldChanged
          from={<StatusOrPhaseLozenge state={newPhaseTwoWeeksAgo} />}
          to={<StatusOrPhaseLozenge state={newPhaseWeekAgo} />}
        />
      </div>
    );
  }

  if (!isNoChangeSection && !isNoUpdateWidget && !weekBeforeTargetWeekUpdate && targetWeekUpdate) {
    const { oldState, newState } = targetWeekUpdate;

    return (
      <div data-testid="weekly-summary-status">
        <FieldChanged
          shouldShowPrevValue={!!weekBeforeTargetWeekUpdate}
          from={<StatusOrPhaseLozenge state={oldState} />}
          to={<StatusOrPhaseLozenge state={newState} />}
        />
      </div>
    );
  }

  return null;
};
