import { FuzzyDate, getFuzzyScale } from '@tc/FuzzyDate';
import { FieldChanged } from '@tc/ProjectUpdate/FieldChanged';
import { StatusOrPhaseLozenge } from '@tc/StatusOrPhaseLozenge';
import { State } from '@queries/GetProjectPageItems';
import { isActive } from '@tc/Filters/MetaFilter/util';

import { ChangeCard } from './styles';

export const isValid = (value: unknown | number | undefined | null) => value !== undefined && value !== null;

export type UpdateDiffProps = {
  // To show target date diff, populate these
  newTargetDate: string;
  newTargetDateConfidence: number;
  oldTargetDate?: string;
  oldTargetDateConfidence?: number;

  // To show phase diff:
  newState: State;
  oldState: State;
};

export const UpdateDiff = (props: UpdateDiffProps) => {
  const { newTargetDate, newTargetDateConfidence, newState, oldTargetDate, oldTargetDateConfidence, oldState } = props;

  // Backend has been updated to treat "targetDate" and "targetDateConfidence" separately
  // These values are only populated when the value has actually changed
  // In this case, oldTargetDateConfidence would be null, unless user has also changed the confidence
  // This wasn't the case previously.
  const updatedOrPrevOldConfidence = isValid(oldTargetDateConfidence)
    ? oldTargetDateConfidence
    : newTargetDateConfidence;

  const statusOrPhaseChanged = isValid(oldState) && oldState?.value !== newState.value;

  const dateChanged = oldTargetDate !== null && oldTargetDate !== newTargetDate;
  const confidenceChanged =
    updatedOrPrevOldConfidence !== null &&
    getFuzzyScale(updatedOrPrevOldConfidence) !== getFuzzyScale(newTargetDateConfidence);
  const isInProgress = isActive(newState.value);
  const dateOrConfidenceChanged = isInProgress && (dateChanged || confidenceChanged);
  const hasAnyChanges = statusOrPhaseChanged || dateOrConfidenceChanged;

  return hasAnyChanges ? (
    <ChangeCard data-testid="change-card">
      {isInProgress && dateOrConfidenceChanged && (
        <div data-testid="date-changed">
          <h5>Date changed</h5>
          <FieldChanged
            from={
              <FuzzyDate
                value={{
                  date: oldTargetDate || newTargetDate,
                  confidence: updatedOrPrevOldConfidence,
                }}
              />
            }
            to={
              <FuzzyDate
                value={{
                  date: newTargetDate,
                  confidence: newTargetDateConfidence,
                }}
              />
            }
          />
        </div>
      )}
      {statusOrPhaseChanged && (
        <div data-testid="status-changed">
          <h5>Status changed</h5>
          <FieldChanged
            from={<StatusOrPhaseLozenge state={oldState || newState} />}
            to={<StatusOrPhaseLozenge state={newState} />}
          />
        </div>
      )}
    </ChangeCard>
  ) : null;
};
