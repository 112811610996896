import { useCallback, Dispatch, SetStateAction } from 'react';
import { useRouterActions } from 'react-resource-router';

import { CreationDialog } from '@shared/CreationFlow/CreationDialog';
import { Entity } from 'src/graphql/types';
import { PROGRAM_TAG_PREFIX } from 'src/constants';
import { getProgramPageProjectTqlQueries } from '@program/children/utils/program';
import { validateUniqueness } from '@shared/CreationFlow/utils/validation';
import { useWorkspaceStore } from 'src/store/workspaceStore';

type Props = {
  setInitialFocusRef: Dispatch<SetStateAction<HTMLElement | null>>;
  scheduleUpdate: () => void;
  handleBackToMainMenu: () => void;
  onClosePopup: () => void;
};

export const handleOnValidationMessage = (type: Entity) => {
  if (type === Entity.PROJECT) {
    return 'This ticket is defined as a project, so it can’t be added as a program';
  }

  if (type === Entity.INITIATIVE) {
    return 'This ticket is defined as an initiative, so it can’t be added as a program';
  }

  return 'This program already exists in Global State';
};

export const ProgramCreation = ({ setInitialFocusRef, scheduleUpdate, handleBackToMainMenu, onClosePopup }: Props) => {
  const [{ workspace }] = useWorkspaceStore();
  const { id: workspaceId } = workspace;
  const { push } = useRouterActions();

  const handleSubmitSuccess = useCallback(
    (tagName: string) => {
      push(`/program/${tagName.replace(PROGRAM_TAG_PREFIX, '')}`);
    },
    [push]
  );

  const handleValidateUniqueness = useCallback(
    (tagName: string): Promise<boolean | undefined> => {
      const { programQ } = getProgramPageProjectTqlQueries(tagName);

      return validateUniqueness({ query: programQ, workspaceId });
    },
    [workspaceId]
  );

  return (
    <CreationDialog
      type={Entity.PROGRAM}
      setInitialFocusRef={setInitialFocusRef}
      scheduleUpdate={scheduleUpdate}
      onSubmitSuccess={handleSubmitSuccess}
      validateUniqueness={handleValidateUniqueness}
      projectsListHeaderText="New Global State for program"
      handleBackToMainMenu={handleBackToMainMenu}
      onValidationMessage={handleOnValidationMessage}
      onClosePopup={onClosePopup}
    />
  );
};
