import { gql } from '@apollo/client';

export const UPDATE_USER_WORKSPACE = gql`
  mutation updateUserWorkspacePreference($workspaceId: ID!) {
    updateUserWorkspacePreference(input: { workspaceId: $workspaceId }) {
      result {
        id
        name
      }
    }
  }
`;
