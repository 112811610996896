import { CreateFlagArgs, DismissFn } from '@atlaskit/flag';
import { createHook, createStore, Action } from 'react-sweet-state';
import { R400, G300 } from '@atlaskit/theme/colors';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';

import { assetsClient } from 'src/clients/assetsClient';
import { formatToDateWithTime } from '@tc/util/dateFormat';

export enum ImportStatuses {
  DONE = 'DONE',
  IN_PROGRESS = 'IN_PROGRESS',
}

type ImportAssetsStore = {
  isLoading: boolean;
  lastAssetsDBImportDate: string;
  syncInProgress: boolean;
  importStatus: ImportStatuses | null;
};

type ImportSyncResponse = {
  lastAssetsDBImportDate: string;
  status: string | null;
};

type ImportAssetsArgs = {
  showFlag: (args: CreateFlagArgs) => DismissFn;
};

const initialState = {
  isLoading: false,
  lastAssetsDBImportDate: '',
  importStatus: null,
  syncInProgress: true,
} as ImportAssetsStore;

export const actions = {
  checkImportStatus: ({ showFlag }: ImportAssetsArgs): Action<ImportAssetsStore> => async ({ setState }) => {
    try {
      setState({ isLoading: true });
      const response = await assetsClient.checkImportStatus();

      if (response.ok) {
        const { lastAssetsDBImportDate, status } = await response.json();

        setState({
          lastAssetsDBImportDate: formatToDateWithTime(new Date(lastAssetsDBImportDate)),
          importStatus: status,
          syncInProgress: status === ImportStatuses.IN_PROGRESS,
          isLoading: status === ImportStatuses.IN_PROGRESS,
        });
      }
    } catch (error) {
      showFlag({
        title: 'Error',
        description: `Something went wrong with assets import.`,
        icon: <ErrorIcon label="Error during importing assets" primaryColor={R400} size="medium" />,
        isAutoDismiss: true,
      });

      setState({ importStatus: null, syncInProgress: false, isLoading: false });
    }
  },
  triggerImportAssets: ({ showFlag }: ImportAssetsArgs): Action<ImportAssetsStore> => async ({ setState }) => {
    try {
      setState({ isLoading: true });
      const response = await assetsClient.triggerAssetsImport();

      if (response.ok) {
        const { timestamp, status } = await response.json();

        setState({
          lastAssetsDBImportDate: timestamp ? formatToDateWithTime(new Date(timestamp)) : 'None',
          importStatus: status,
          syncInProgress: status === ImportStatuses.IN_PROGRESS,
          isLoading: status === ImportStatuses.IN_PROGRESS,
        });
        showFlag({
          title: 'Importing started',
          description: 'Assets importing are started ',
          icon: <CheckCircleIcon primaryColor={G300} label="Successful assets importing" />,
          isAutoDismiss: true,
          appearance: 'info',
        });
      }
    } catch (error) {
      showFlag({
        title: 'Error',
        description: `Something went wrong with assets import.`,
        icon: <ErrorIcon label="Error during importing assets" primaryColor={R400} size="medium" />,
        isAutoDismiss: true,
      });

      throw error;
    } finally {
      setState({ isLoading: false });
    }
  },
  synchronizeAssets: (): Action<ImportAssetsStore> => async ({ setState }) => {
    try {
      setState({ syncInProgress: true });
      const response = await assetsClient.syncAssets();

      if (response.ok) {
        const { lastAssetsDBImportDate }: ImportSyncResponse = await response.json();

        setState({ lastAssetsDBImportDate, syncInProgress: false });

        return;
      } else {
        setState({ syncInProgress: false });
        throw await response.json();
      }
    } catch (e) {
      throw e;
    }
  },
};

const importAssetsStore = createStore({
  name: 'import-assets-store',
  initialState,
  actions,
});

export const useImportAssets = createHook(importAssetsStore);
