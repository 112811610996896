import { endOfQuarter, isSameMonth, isSameYear, isThisYear, parseISO, startOfQuarter } from 'date-fns';

import { THIS_WEEK } from 'src/constants';

import { differenceInCalendarWeeks, format, isSameWeek, parse, tzDate } from './date';

const SAME_MONTH_FORMAT = 'd';
const SAME_YEAR_FORMAT = 'd MMM';
const DIFF_YEAR_FORMAT = 'd MMM yyyy';

export const MONTH_ONLY_FORMAT = 'MMMM';
export const MONTH_NUMBER = 'M';
export const JUST_YEAR = 'y';
export const MONTH_DAY_SHORT_FORMAT = 'MMM dd';
const MONTH_ONLY_FORMAT_SHORT = 'MMM';
const MONTH_ONLY_FORMAT_DIFF_YEAR = 'MMM yyyy';

export const MONTH_WITH_YEAR = 'MMMM yyyy';
const FULL_NICE_FORMAT = 'dd-MMM-yyyy';

export const ISO_LOCAL_DATE = 'yyyy-MM-dd';
const ISO_LOCAL_TIME = 'HH:mm:ss';

export const NICE_DATE_TIME = 'd MMMM yyyy h:mm a';

export const ISO_LOCAL_DATE_TIME = `${ISO_LOCAL_DATE}'T'${ISO_LOCAL_TIME}`;
export const DATA_AND_TIME = `${ISO_LOCAL_DATE}' Time:'${ISO_LOCAL_TIME}`;

export const formatDateRange = (from: Date, to: Date, locale?: string) => {
  const options = locale ? { locale: { code: locale } } : undefined;

  if (isSameYear(from, to)) {
    const fromDisplay = format(from, isSameMonth(from, to) ? SAME_MONTH_FORMAT : SAME_YEAR_FORMAT, options);
    const toDisplay = formatNiceDate(to, locale);

    return `${fromDisplay}-${toDisplay}`;
  } else {
    const fromDisplay = format(from, DIFF_YEAR_FORMAT, options);
    const toDisplay = format(to, DIFF_YEAR_FORMAT, options);

    return `${fromDisplay}-${toDisplay}`;
  }
};

export const formatFullNiceDate = (date: Date, noDash?: boolean) =>
  format(date, noDash ? DIFF_YEAR_FORMAT : FULL_NICE_FORMAT);

export const formatNiceMonthDate = (date: Date, locale?: string) =>
  formatWithLocale(date, isThisYear(date) ? MONTH_ONLY_FORMAT : MONTH_ONLY_FORMAT_DIFF_YEAR, locale);

export const formatTooltipNiceMonthDate = (date: Date) => formatWithLocale(date, MONTH_WITH_YEAR);

export const formatNiceDateTime = (date: Date) => formatWithLocale(date, NICE_DATE_TIME);

export const formatNiceQuarterDate = (date: Date, locale?: string) =>
  `${formatWithLocale(startOfQuarter(date), MONTH_ONLY_FORMAT_SHORT)}-${formatWithLocale(
    endOfQuarter(date),
    isThisYear(date) ? MONTH_ONLY_FORMAT_SHORT : MONTH_ONLY_FORMAT_DIFF_YEAR,
    locale
  )}`;

export const formatTooltipNiceQuarterDate = (date: Date) =>
  `${formatWithLocale(startOfQuarter(date), MONTH_ONLY_FORMAT)}-${formatWithLocale(
    endOfQuarter(date),
    MONTH_WITH_YEAR
  )}`;

export const formatNiceDate = (date: Date, locale?: string) =>
  formatWithLocale(date, isThisYear(date) ? SAME_YEAR_FORMAT : DIFF_YEAR_FORMAT, locale);

const formatWithLocale = (date: Date, dateFormat: string, locale?: string) =>
  format(date, dateFormat, locale ? { locale: { code: locale } } : undefined);

export const formatIsoLocalDate = (date: Date) => format(date, ISO_LOCAL_DATE);

export const formatIsoLocalDateTime = (date: Date) => format(date, ISO_LOCAL_DATE_TIME);

export const formatToDateWithTime = (date: Date) => format(date, DATA_AND_TIME);

/**
 * Format display text for a given interval using a week-window granularity against an "anchor" date
 * @param start of interval
 * @param end of interval
 * @param anchorDate used as relative date, at which calculation will be performed against. If in doubt, leave empty
 */
export const formatWeekRange = (start: Date, end: Date, anchorDate: Date = tzDate()) => {
  if (isSameWeek(anchorDate, start)) {
    return THIS_WEEK;
  } else if (isLastWeekOf(anchorDate, end)) {
    return 'Last week';
  } else {
    return formatDateRange(start, end);
  }
};

/**
 * Is the older date last week of the newer date?
 */
function isLastWeekOf(newer: Date, older: Date) {
  return differenceInCalendarWeeks(newer, older) === 1;
}

export const parseIsoLocalDateTime = (dateString: string | undefined, defaultValue: Date) => {
  if (dateString) {
    let date = parse(dateString, ISO_LOCAL_DATE_TIME, defaultValue);

    if (!date || isNaN(date.getTime())) {
      date = parseISO(dateString);
    }

    if (!date || isNaN(date.getTime())) {
      date = defaultValue;
    }

    return date;
  } else {
    return defaultValue;
  }
};
