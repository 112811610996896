import sortBy from 'lodash.sortby';

import { Project } from '@queries/GetProjectPageItems';
import { TawTags } from 'src/graphql/types';
import { filterNotActiveProjects } from '@shared/utils/sidebar';
import { DepartmentProject } from '@queries/GetDepartmentProjects';
import { TawPhase as TawPhases } from 'src/store/tawPhaseStore';
import { parse, parseBack } from 'src/store/utils/parsers';
import { NO_TAW_PHASE } from 'src/constants';

export type ProjectKeyToTawTagMap = {
  [key: string]: string;
};

export type ProjectKeyToProjectMap = {
  [key: string]: Project | DepartmentProject;
};

export type TawTagNameToProjectsMap<T> = {
  [key: string]: T[];
};

export enum TawPhase {
  WONDER = 'Wonder',
  EXPLORE = 'Explore',
  MAKE = 'Make',
  IMPACT = 'Impact',
  NO_PHASE = 'No Phase',
}

export const statusToScoresMap: { [status: string]: number } = {
  off_track: 1,
  at_risk: 2,
  on_track: 3,
  pending: 4,
  paused: 5,
  done: 6,
  cancelled: 7,
};

const getProjectScore = ({ state }: Pick<Project, 'state'>): number => statusToScoresMap[state.value];

export const getProjectKeyToProjectMap = <T extends Project | DepartmentProject>(projects: T[]) =>
  projects.reduce<ProjectKeyToProjectMap>((acc, project) => ({ ...acc, [project.key]: project }), {});

export const getTawTagNameToProjectsMap = <T extends Pick<Project, 'tags'>>(projects: T[], tawPhases: TawPhases[]) =>
  projects.reduce<TawTagNameToProjectsMap<T>>((acc, project) => {
    const tawTagName = project.tags.edges.reduce((acc, { node: { name } }) => {
      const isTawTag = tawPhases.some(({ tawPhase }) => parse(tawPhase) === name);
      const prevTawPhasePriority = tawPhases.find(({ tawPhase }) => parse(tawPhase) === acc)?.priority;
      const currentTawPhasePriority = tawPhases.find(({ tawPhase }) => parse(tawPhase) === name)?.priority;

      const isCurrentPriorityHigher =
        prevTawPhasePriority && currentTawPhasePriority && prevTawPhasePriority < currentTawPhasePriority;

      if ((!acc && isTawTag) || (acc && isTawTag && isCurrentPriorityHigher)) {
        return name;
      }

      return acc;
    }, '');
    const name = (tawTagName || TawTags[NO_TAW_PHASE]) as TawTags;

    acc[name] = [...(acc[name] || []), project];

    return acc;
  }, {});

export const sortProjects = (projects: Project[] | DepartmentProject[] = []) =>
  sortBy(projects, [getProjectScore, 'latestUpdateDate']);

export const getActiveProjectsCount = <T extends Pick<Project, 'state'> | Pick<DepartmentProject, 'state'>>(
  projects?: T[]
) => filterNotActiveProjects(projects || []).length;

export const getHighestPriorityTawPhaseTag = (tawTags: string[], tawPhases?: TawPhases[] | null) => {
  if (!tawTags.length) {
    return;
  }

  const tawPhasesWithPriorities = tawPhases?.filter(({ tawPhase }) => tawTags.includes(parse(tawPhase)));

  return sortBy(tawPhasesWithPriorities, ['priority']).reverse()[0].tawPhase;
};

export const getTawTags = (tags: Project['tags']['edges'], tawPhases?: TawPhases[] | null) => {
  if (!tawPhases?.length) {
    return [];
  }
  const tawPhaseNames = tawPhases.map(({ tawPhase }) => parse(tawPhase));

  return tags.filter(({ node: { name } }) => tawPhaseNames.includes(name)).map(({ node: { name } }) => name);
};

export const getProjectTawPhase = (tags: Project['tags']['edges'], tawPhases?: TawPhases[] | null) => {
  const tawTags = getTawTags(tags, tawPhases);

  if (!tawTags.length) {
    return NO_TAW_PHASE;
  }

  return parseBack(getHighestPriorityTawPhaseTag(tawTags, tawPhases) || '');
};
