import { getConfig } from 'src/config';
import { Department } from '@store/departmentStore';

export type CreateDeptData = Omit<Department, 'cloudId'>;

type UpdateDeptData = Pick<Department, 'departmentName'>;

const URLS = {
  departments: `${getConfig().gsApiBaseUrl}/departments`,
  tawPhases: `${getConfig().gsApiBaseUrl}/taw-phases`,
  favourites: `${getConfig().gsApiBaseUrl}/favourites`,
};

class GSClient {
  fetchDepartments(cloudId: string) {
    return fetch(`${URLS.departments}/${cloudId}`);
  }

  createDepartment(cloudId: string, data: CreateDeptData) {
    return fetch(`${URLS.departments}/${cloudId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }

  updateDepartment(cloudId: string, data: UpdateDeptData, label: string) {
    return fetch(`${URLS.departments}/${cloudId}/${label}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }

  deleteDepartment(cloudId: string, label: string) {
    return fetch(`${URLS.departments}/${cloudId}/${label}`, {
      method: 'DELETE',
    });
  }

  fetchTawPhases(cloudId: string) {
    return fetch(`${URLS.tawPhases}/${cloudId}`);
  }

  createTawPhases(cloudId: string, tawPhase: string) {
    return fetch(`${URLS.tawPhases}/${cloudId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ tawPhase }),
    });
  }

  updateTawPhases(cloudId: string, tawPhase: string, priority: number) {
    return fetch(`${URLS.tawPhases}/${cloudId}/${tawPhase}/change-priority`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ priority }),
    });
  }

  deleteTawPhase(cloudId: string, label: string) {
    return fetch(`${URLS.tawPhases}/${cloudId}/${label}`, {
      method: 'DELETE',
    });
  }

  getFavourites(accountId: string) {
    return fetch(`${URLS.favourites}/${accountId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
  }

  addToFavourites(atlasKey: string, accountId: string) {
    return fetch(`${URLS.favourites}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ atlasKey, accountId }),
    });
  }

  removeFromFavourites(atlasKey: string, accountId: string) {
    return fetch(`${URLS.favourites}/${accountId}/${atlasKey}`, {
      method: 'DELETE',
    });
  }

  delete(url: string, options?: RequestInit) {
    return fetch(url, { ...(options ? options : {}), method: 'DELETE' });
  }

  post(url: string, body: Object, options?: RequestInit) {
    return fetch(`${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      ...(options ? options : {}),
    });
  }

  get(url: string, options?: RequestInit) {
    return fetch(url, { ...(options ? options : {}), method: 'GET' });
  }

  put(url: string, body: Object, options?: RequestInit) {
    return this.post(url, body, { ...(options ? options : {}), method: 'PUT' });
  }

  update(url: string, body: Object, options?: RequestInit) {
    return this.post(url, body, { ...(options ? options : {}), method: 'PATCH' });
  }
}

export const gsClient = new GSClient();
