import styled, { keyframes } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

const LoadingAnimation = keyframes`
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
`;

const Logo = styled.img`
  width: 80px;
  animation: ${LoadingAnimation} 2s infinite;
`;

export const GlobalLoader = () => (
  <Container>
    <Logo src="/logo.png" alt="logo" />
  </Container>
);
