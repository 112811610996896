import styled from 'styled-components';
import Tooltip from '@atlaskit/tooltip';

const VerifiedIconWrapper = styled.div`
  div {
    display: flex;
  }
`;

type Props = {
  isVerified: boolean | undefined;
  content: string;
};

export const VerifiedIcon = ({ isVerified, content }: Props) => (
  <>
    {isVerified && (
      <VerifiedIconWrapper>
        <Tooltip delay={500} content={content} position="bottom">
          <img src="/verified-icon.svg" alt={content} />
        </Tooltip>
      </VerifiedIconWrapper>
    )}
  </>
);
