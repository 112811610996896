import { EmojiId } from '@atlaskit/emoji/dist/types';
import { B100, G200, P100, P500, R200, T100, Y200, Y500, R75, G75 } from '@atlaskit/theme/colors';

const colors: string[] = [B100, T100, G200, Y200, P100, P500, R200, Y500, R75, G75];

export const DEFAULT_EMOJI: EmojiId = {
  id: '1f349',
  shortName: ':watermelon:',
};

export const DEFAULT_COLOR = T100;

export const getColor = (projectId?: string) => {
  if (!projectId) {
    return DEFAULT_COLOR;
  }

  return colors[Math.abs(getHash(projectId)) % colors.length];
};

const emojis: string[] = [
  ':apple:',
  ':pear:',
  ':tangerine:',
  ':lemon:',
  ':banana:',
  ':watermelon:',
  ':grapes:',
  ':strawberry:',
  ':melon:',
  ':cherries:',
  ':peach:',
  ':mango:',
  ':pineapple:',
  ':coconut:',
  ':kiwi:',
];

export const getEmoji = (projectId?: string) => {
  if (!projectId) {
    return DEFAULT_EMOJI.shortName;
  }

  return emojis[Math.abs(getHash(projectId)) % emojis.length];
};

const getHash = (str: string) => {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    // tslint:disable:no-bitwise
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash = hash & hash;
    // tslint:enable:no-bitwise
  }

  return hash;
};

export const toEmojiId = (id?: string, shortName?: string): Required<Omit<EmojiId, 'fallback'>> => {
  if (!shortName) {
    return DEFAULT_EMOJI as Required<EmojiId>;
  }

  return validateEmoji({ id, shortName }) as Required<EmojiId>;
};

export const validateColor = (color: string) => (colors.indexOf(color.toUpperCase()) !== -1 ? color : DEFAULT_COLOR);

export const validateEmoji = (emoji: Omit<EmojiId, 'fallback'>) => {
  const { id, shortName } = emoji;

  const shortNameValid = shortName ? /^:[a-zA-Z0-9_-]*:$/.test(shortName) : true;
  const idValid = id ? /^[a-zA-Z0-9_-]*$/.test(id) : true;

  if (!shortNameValid || !idValid) {
    return DEFAULT_EMOJI;
  }

  return emoji;
};
