import { ThemeAppearance } from '@atlaskit/lozenge';
import {
  B400,
  B50,
  B75,
  G300,
  G400,
  G50,
  G75,
  R300,
  R400,
  R50,
  R75,
  Y300,
  Y400,
  Y50,
  Y75,
  N800,
  N0,
  N40,
  N100,
} from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { Status } from '@tc/StatusLozenge';
import { GoalStateValue } from '@generated/graphql';
import { GqlStatusType } from 'src/graphql/types';

export type ScoreStatus = GqlStatusType | 'pending';

export type StatusColour = {
  light: string;
  dark: string;
  background: string;
  text: string;
  icon: string;
  iconForeground?: string;
  appearance: ThemeAppearance;
};

type ScoreStatusMap = { [scoreStatus in ScoreStatus]: StatusColour };

const scoreMap: ScoreStatusMap = {
  pending: {
    light: token('color.background.neutral', B50),
    dark: token('color.background.neutral.hovered', B75),
    background: token('color.background.neutral.bold', B400),
    text: token('color.text.inverse', N800),
    icon: token('color.background.accent.gray.subtler', N40),
    iconForeground: token('color.icon.accent.gray', N100),
    appearance: 'inprogress',
  },
  off_track: {
    light: token('color.background.danger', R50),
    dark: token('color.background.danger.hovered', R75),
    background: token('color.background.danger.bold', R400),
    text: token('color.text.inverse', N0),
    icon: token('color.background.accent.red.subtler', R300),
    appearance: 'removed',
  },
  at_risk: {
    light: token('color.background.warning', Y50),
    dark: token('color.background.warning.hovered', Y75),
    background: token('color.background.accent.orange.bolder', Y400),
    text: token('color.text.inverse', N800),
    icon: token('color.background.accent.yellow.subtler', Y300),
    appearance: 'moved',
  },
  on_track: {
    light: token('color.background.success', G50),
    dark: token('color.background.success.hovered', G75),
    background: token('color.background.success.bold', G400),
    text: token('color.text.inverse', N0),
    icon: token('color.background.accent.green.subtler', G300),
    appearance: 'success',
  },
};

export const getStatusColor = (status: ScoreStatus) => scoreMap[status];

const fixScore = (score: number | null | undefined) => (score === null || score === undefined ? null : score / 100);

const GOAL_STATUSES: {
  [key in ScoreStatus]: Status & { name: ScoreStatus };
} = {
  // There's no such thing as 'pending' status. This is kept here for legacy / backward-compatibility reason
  pending: {
    id: 'pending',
    displayName: 'Pending',
    name: 'pending',
  },
  on_track: {
    id: 'on_track',
    displayName: 'On track',
    name: 'on_track',
  },
  at_risk: {
    id: 'at_risk',
    displayName: 'At risk',
    name: 'at_risk',
  },
  off_track: {
    id: 'off_track',
    displayName: 'Off track',
    name: 'off_track',
  },
};

// Convert a goal score to a status object
export const goalScoreToStatusEntity = (score?: number | null, phase?: GoalStateValue | null) => {
  if (
    (phase &&
      ![GoalStateValue.Done, GoalStateValue.AtRisk, GoalStateValue.OffTrack, GoalStateValue.OnTrack].includes(phase)) ||
    score === null ||
    score === undefined
  ) {
    return GOAL_STATUSES.pending;
  }

  if (score > 1) {
    score = fixScore(score)!;
  }

  if (score < 0.4) {
    return GOAL_STATUSES.off_track;
  }

  if (score < 0.7) {
    return GOAL_STATUSES.at_risk;
  }

  return GOAL_STATUSES.on_track;
};
