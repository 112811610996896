import { createRef, RefObject, SyntheticEvent, useCallback, useEffect, useLayoutEffect, useState } from 'react';

export const useUpdateCard = (isExpanded?: boolean, isCollapsible?: boolean) => {
  const [expanded, setExpanded] = useState(isExpanded);
  const [contentHeight, setContentHeight] = useState<number | string | undefined>(expanded ? undefined : 0);
  const [shouldShowPointer, setShouldShowPointer] = useState<boolean>(false);

  const cardTopRef: RefObject<HTMLDivElement> = createRef();
  const ref: RefObject<HTMLDivElement> = createRef();

  useEffect(() => {
    setExpanded(isExpanded);
  }, [isExpanded]);

  useLayoutEffect(() => {
    if (expanded) {
      setTimeout(() => setContentHeight(ref.current?.getBoundingClientRect()?.height), 200);
    } else {
      setContentHeight(0);
    }
  }, [expanded, ref]);

  const toggleCollapsed = useCallback(() => {
    if (!expanded) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [expanded]);

  const handleMouseOver = useCallback(
    (e: SyntheticEvent<HTMLDivElement>) => {
      if (!isCollapsible) {
        return;
      }

      setShouldShowPointer(e.target === e.currentTarget || e.target === cardTopRef.current);
    },
    [isCollapsible, cardTopRef]
  );

  const handleMouseLeave = useCallback(() => {
    if (!isCollapsible) {
      return;
    }

    setShouldShowPointer(false);
  }, [isCollapsible]);

  const handleCardClick = useCallback(() => {
    if (shouldShowPointer) {
      toggleCollapsed();
    }
  }, [shouldShowPointer, toggleCollapsed]);

  const scheduleUpdate = useCallback(() => {
    setTimeout(() => {
      if (ref && ref.current) {
        const { height } = ref.current.getBoundingClientRect();

        setContentHeight(height);
      }
    });
  }, [ref]);

  return {
    contentHeight,
    scheduleUpdate,
    shouldShowPointer,
    handleMouseOver,
    handleMouseLeave,
    handleCardClick,
    expanded,
    toggleCollapsed,
    ref,
    cardTopRef,
  };
};
