/* eslint-disable max-len */
import Lozenge from '@atlaskit/lozenge';
import styled from 'styled-components';

import { defaultProps, Props, Wrapper } from './Wrapper';
import { SantaHat } from './xmas';

const LozengeWrapper = styled.div`
  padding: 4px 0px 4px 8px;
  line-height: 16px;
`;

const globalStateSvg = () => `
<canvas height="28" width="140" aria-hidden="true"></canvas>
<svg width="140" height="28" viewBox="0 0 140 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M40.2321 21V14.0833H34.6644V16.1667H37.8701V19.5208C37.1741 19.6875 36.4359 19.7292 35.9087 19.7292C32.2391 19.7292 30.5097 17.7917 30.5097 14.8958C30.5097 11.9583 32.218 10.1042 36.0774 10.1042C37.3217 10.1042 38.6715 10.2917 39.7892 10.7708V8.47917C38.5238 8 37.1108 7.85417 35.9509 7.85417C30.9526 7.85417 28 10.5 28 14.9583C28 19.1042 30.5941 21.9792 35.4236 21.9792C37.0265 21.9792 39.1144 21.6667 40.2321 21Z" fill="#344563"/>
  <path d="M46.9522 21.7292V19.7083C46.7413 19.7292 46.6359 19.7292 46.446 19.7292C45.7712 19.7292 45.2228 19.4375 45.2228 18.625V7H42.8397V18.9167C42.8397 20.9792 44.0207 21.8333 45.8766 21.8333C46.425 21.8333 46.7835 21.7917 46.9522 21.7292Z" fill="#344563"/>
  <path d="M52.9775 22C56.2042 22 58.1023 19.6875 58.1023 16.5C58.1023 13.2917 56.2042 11.0417 52.9775 11.0417C49.7296 11.0417 47.8316 13.2917 47.8316 16.5C47.8316 19.6875 49.7296 22 52.9775 22ZM52.9775 13.1458C54.9177 13.1458 55.7613 14.6875 55.7613 16.5C55.7613 18.3125 54.9177 19.875 52.9775 19.875C51.0161 19.875 50.1514 18.3125 50.1514 16.5C50.1514 14.6875 51.0161 13.1458 52.9775 13.1458Z" fill="#344563"/>
  <path d="M67.5053 16.5208C67.5053 18.6875 66.5773 19.875 64.8269 19.875C63.4771 19.875 62.3382 18.9792 62.3382 16.9375V16.0833C62.3382 14.0417 63.5615 13.1458 64.9745 13.1458C66.6195 13.1458 67.5053 14.2708 67.5053 16.5208ZM65.6283 21.9792C68.4754 21.9792 69.8673 19.5208 69.8673 16.5208C69.8673 13.4375 68.5176 11.0417 65.7548 11.0417C64.1731 11.0417 62.992 11.7917 62.3382 13.1458V7H59.9551V21.7708H62.3382V19.9375C62.9709 21.2917 64.1309 21.9792 65.6283 21.9792Z" fill="#344563"/>
  <path d="M73.5284 16.5208C73.5284 14.3333 74.4353 13.1458 76.2068 13.1458C77.5566 13.1458 78.6954 14.0417 78.6954 16.0833V16.9375C78.6954 18.9792 77.43 19.875 76.0381 19.875C74.4142 19.875 73.5284 18.75 73.5284 16.5208ZM78.6954 21.7708H81.0786V11.25H78.6954V13.125C78.0838 11.7917 76.9449 11.0417 75.4476 11.0417C72.5583 11.0417 71.1663 13.5417 71.1663 16.5208C71.1663 19.625 72.495 21.9792 75.2367 21.9792C76.7973 21.9792 78.0416 21.2708 78.6954 19.875V21.7708Z" fill="#344563"/>
  <path d="M87.6078 21.7292V19.7083C87.3969 19.7292 87.2915 19.7292 87.1017 19.7292C86.4268 19.7292 85.8785 19.4375 85.8785 18.625V7H83.4953V18.9167C83.4953 20.9792 84.6764 21.8333 86.5323 21.8333C87.0806 21.8333 87.4391 21.7917 87.6078 21.7292Z" fill="#344563"/>
  <path d="M102.405 17.875C102.405 15.5208 100.929 14.4167 97.7018 13.7083C95.5296 13.1875 94.7492 12.7083 94.7492 11.75C94.7492 10.6875 95.6561 10.1458 97.2378 10.1458C98.6087 10.1458 100.254 10.4583 101.751 11.2083V8.875C100.739 8.33333 99.3046 7.875 97.3222 7.875C93.9689 7.875 92.2395 9.45833 92.2395 11.7292C92.2395 13.9375 93.5682 15.1458 96.9637 15.875C99.1781 16.3958 99.853 16.9375 99.853 17.9583C99.853 18.9792 99.1992 19.6667 97.3855 19.6667C95.6139 19.6667 93.526 19.1458 92.2395 18.5V20.8958C93.3362 21.4583 94.7071 21.9792 97.3222 21.9792C100.907 21.9792 102.405 20.3542 102.405 17.875Z" fill="#344563"/>
  <path d="M107.354 18.25V13.3125H109.906V11.25H107.354V9.04167H105.013V11.25H103.494V13.3125H105.013V18.4375C105.013 20.4792 106.067 21.8542 108.451 21.8542C109.104 21.8542 109.526 21.7708 109.906 21.6875V19.5208C109.611 19.5833 109.252 19.6458 108.851 19.6458C107.86 19.6458 107.354 19.1042 107.354 18.25Z" fill="#344563"/>
  <path d="M113.463 16.5208C113.463 14.3333 114.37 13.1458 116.142 13.1458C117.491 13.1458 118.63 14.0417 118.63 16.0833V16.9375C118.63 18.9792 117.365 19.875 115.973 19.875C114.349 19.875 113.463 18.75 113.463 16.5208ZM118.63 21.7708H121.013V11.25H118.63V13.125C118.019 11.7917 116.88 11.0417 115.382 11.0417C112.493 11.0417 111.101 13.5417 111.101 16.5208C111.101 19.625 112.43 21.9792 115.171 21.9792C116.732 21.9792 117.976 21.2708 118.63 19.875V21.7708Z" fill="#344563"/>
  <path d="M126.446 18.25V13.3125H128.998V11.25H126.446V9.04167H124.105V11.25H122.587V13.3125H124.105V18.4375C124.105 20.4792 125.159 21.8542 127.543 21.8542C128.196 21.8542 128.618 21.7708 128.998 21.6875V19.5208C128.703 19.5833 128.344 19.6458 127.943 19.6458C126.952 19.6458 126.446 19.1042 126.446 18.25Z" fill="#344563"/>
  <path d="M135.234 12.9583C136.794 12.9583 137.596 13.9375 137.701 15.75H132.534C132.703 14.0417 133.547 12.9583 135.234 12.9583ZM139.452 21.3542V19.3333C138.46 19.6458 137.343 19.875 136.098 19.875C133.863 19.875 132.808 18.9583 132.555 17.3542H140V16.5417C140 13.3125 138.65 11.0417 135.297 11.0417C131.986 11.0417 130.193 13.3333 130.193 16.5417C130.193 19.8333 131.965 21.9792 136.035 21.9792C137.195 21.9792 138.545 21.7917 139.452 21.3542Z" fill="#344563"/>
  <rect width="24" height="24" transform="translate(0 2)" fill="white" fill-opacity="0.01"/>
  <path d="M8.36864 12.5347C8.09807 12.2371 7.69222 12.2641 7.50282 12.6159L3.0925 21.4094C2.93016 21.7612 3.17367 22.167 3.55247 22.167H9.66739C9.85679 22.167 10.0462 22.0588 10.1274 21.8694C11.4802 19.1366 10.6685 14.9969 8.36864 12.5347Z" fill="url(#paint0_linear)"/>
  <path d="M11.5891 4.52093C9.12687 8.41716 9.28922 12.7193 10.9126 15.9661C12.5361 19.213 13.7807 21.6752 13.8619 21.8646C13.943 22.054 14.1324 22.1622 14.3218 22.1622H20.4368C20.8156 22.1622 21.0861 21.7563 20.8967 21.4046C20.8967 21.4046 12.6714 4.95385 12.4549 4.54799C12.2926 4.16919 11.8326 4.14213 11.5891 4.52093Z" fill="#2684FF"/>
  <defs>
    <linearGradient id="paint0_linear" x1="10.769" y1="13.8791" x2="6.13383" y2="21.9075" gradientUnits="userSpaceOnUse">
      <stop stop-color="#0052CC"/>
      <stop offset="0.9228" stop-color="#2684FF"/>
    </linearGradient>
  </defs>
</svg>

`;

export const Logo = (props: Props) => (
  <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
    <SantaHat size="small" />
    <Wrapper
      {...defaultProps}
      iconGradientStart={'#0052CC'}
      iconGradientStop={'#2684FF'}
      {...props}
      svg={globalStateSvg}
    />
    <LozengeWrapper>
      <Lozenge appearance="new">Beta</Lozenge>
    </LozengeWrapper>
  </div>
);
