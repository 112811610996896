import { useEffect, useRef } from 'react';
import { CreateFlagArgs, DismissFn, useFlags } from '@atlaskit/flag';
import { createHook, createStore, Action } from 'react-sweet-state';
import { G300, R400 } from '@atlaskit/theme/colors';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import sortBy from 'lodash.sortby';
import EditorSuccessIcon from '@atlaskit/icon/glyph/editor/success';

import { gsClient } from 'src/clients/GSClient';

import { useWorkspaceStore } from './workspaceStore';
import { Error } from './departmentStore';
import { getTawPhasesWithNewPriorities } from './utils/getTawPhasesWithNewPriorities';
import { parse, parseBack } from './utils/parsers';

export type TawPhase = {
  cloudId: string;
  tawPhase: string;
  priority: number;
};

export type TawPhaseStore = {
  tawPhases: TawPhase[] | null;
  isLoading: boolean;
};

type FetchTawPhaseArgs = {
  cloudId: string;
  showFlag: (args: CreateFlagArgs) => DismissFn;
};

type createTawPhaseArgs = {
  cloudId: string;
  tawPhase: string;
  showFlag: (args: CreateFlagArgs) => DismissFn;
  handleCloseModal: () => void;
};

type updateTawPhaseArgs = {
  cloudId: string;
  tawPhase: string;
  showFlag: (args: CreateFlagArgs) => DismissFn;
  previousPriority: number;
  newPriority: number;
};

type DeleteTawPhaseArgs = {
  tawPhaseToDelete: TawPhase;
  showFlag: (args: CreateFlagArgs) => DismissFn;
  handleCloseModal: () => void;
};

const initialState = { tawPhases: null, isLoading: true } as TawPhaseStore;

export const actions = {
  fetchTawPhases: ({ cloudId, showFlag }: FetchTawPhaseArgs): Action<TawPhaseStore> => async ({ setState }) => {
    try {
      setState({ isLoading: true });
      const response = await gsClient.fetchTawPhases(cloudId);

      if (response.ok) {
        const tawPhases: TawPhase[] = await response.json();
        const parsedPhases: TawPhase[] = tawPhases.map((phase) => ({
          cloudId: phase.cloudId,
          tawPhase: parseBack(phase.tawPhase),
          priority: phase.priority,
        }));

        setState({ tawPhases: sortBy(parsedPhases, ['priority']), isLoading: false });

        return;
      } else {
        const error: Error = await response.json();

        throw error;
      }
    } catch (error) {
      showFlag({
        title: 'Error',
        description: 'Something went wrong',
        icon: <ErrorIcon label="Error" primaryColor={R400} size="medium" />,
        isAutoDismiss: true,
      });

      setState({ isLoading: false });

      return;
    }
  },

  createTawPhases: ({
    cloudId,
    tawPhase,
    showFlag,
    handleCloseModal,
  }: createTawPhaseArgs): Action<TawPhaseStore> => async ({ setState, getState }) => {
    try {
      setState({ isLoading: true });
      handleCloseModal();

      const response = await gsClient.createTawPhases(cloudId, parse(tawPhase));
      const tawPhases = getState().tawPhases || [];
      const lowestPriority = tawPhases.length + 1;

      if (response.ok) {
        setState({
          tawPhases: [
            ...(getState().tawPhases as TawPhase[]),
            { cloudId, tawPhase: parseBack(tawPhase), priority: lowestPriority },
          ],
        });
      } else {
        const error = await response.json();

        throw error;
      }

      setState({ isLoading: false });

      return;
    } catch (error) {
      const err = error as Error;
      const errorMessage = 'message' in err && err.statusCode !== 500 ? err.message : 'Something went wrong';

      showFlag({
        title: 'Error',
        description: errorMessage,
        icon: <ErrorIcon label="Error" primaryColor={R400} size="medium" />,
        isAutoDismiss: true,
      });

      setState({ isLoading: false });

      return;
    }
  },

  deleteTawPhase: ({
    tawPhaseToDelete,
    showFlag,
    handleCloseModal,
  }: DeleteTawPhaseArgs): Action<TawPhaseStore> => async ({ setState, getState }) => {
    try {
      const { tawPhase, cloudId, priority } = tawPhaseToDelete;

      setState({ isLoading: true });
      handleCloseModal();

      const response = await gsClient.deleteTawPhase(cloudId, parse(tawPhase));

      if (response.ok) {
        const tawPhases = getState().tawPhases || [];
        const highestExistingPriority = Math.max(...tawPhases.map(({ priority }) => priority));

        const newTawPhases = getTawPhasesWithNewPriorities({
          allTawPhases: tawPhases,
          isIncreasingOperation: false,
          newPriority: highestExistingPriority,
          previousPriority: priority,
        });

        setState({
          tawPhases: [...tawPhases.slice(0, priority - 1), ...newTawPhases],
        });
      } else {
        const error: Error = await response.json();

        throw error;
      }

      showFlag({
        title: 'Done!',
        description: `TAW phase ${tawPhase} was successfully deleted`,
        icon: <EditorSuccessIcon size="medium" primaryColor={G300} label="Info" />,
        isAutoDismiss: true,
      });

      setState({ isLoading: false });
    } catch (error) {
      const err = error as Error;
      const errorMessage = 'message' in err && err.statusCode !== 500 ? err.message : 'Something went wrong';

      showFlag({
        title: 'Error',
        description: errorMessage,
        icon: <ErrorIcon label="Error" primaryColor={R400} size="medium" />,
        isAutoDismiss: true,
      });

      setState({ isLoading: false });

      return;
    }
  },

  updateTawPhases: ({
    cloudId,
    tawPhase,
    showFlag,
    previousPriority,
    newPriority,
  }: updateTawPhaseArgs): Action<TawPhaseStore> => async ({ setState, getState }) => {
    try {
      setState({ isLoading: true });
      const response = await gsClient.updateTawPhases(cloudId, parse(tawPhase), newPriority);

      if (response.ok) {
        const tawPhases = getState().tawPhases || [];
        const isIncreasingOperation = previousPriority - newPriority > 0;

        const tawPhasesWithNewPriorities = getTawPhasesWithNewPriorities({
          allTawPhases: tawPhases,
          isIncreasingOperation: isIncreasingOperation,
          newPriority: newPriority,
          previousPriority: previousPriority,
        });

        const tawPhasesWithOldPriorities = tawPhases.filter(
          (oldTawPhase) =>
            ![...tawPhasesWithNewPriorities, { cloudId, tawPhase, priority: newPriority }].find(
              (newTawPhase) => oldTawPhase.tawPhase === newTawPhase.tawPhase
            )
        );

        setState({
          tawPhases: sortBy(
            [
              ...tawPhasesWithOldPriorities,
              ...tawPhasesWithNewPriorities,
              { cloudId, tawPhase, priority: newPriority },
            ],
            ['priority']
          ),
        });
      } else {
        const error = await response.json();

        throw error;
      }

      showFlag({
        title: 'Done!',
        description: `${tawPhase} priority was successfully updated to ${newPriority}`,
        icon: <EditorSuccessIcon size="medium" primaryColor={G300} label="Info" />,
        isAutoDismiss: true,
      });

      setState({ isLoading: false });
    } catch (error) {
      const err = error as Error;
      const errorMessage = 'message' in err && err.statusCode !== 500 ? err.message : 'Something went wrong';

      showFlag({
        title: 'Error',
        description: errorMessage,
        icon: <ErrorIcon label="Error" primaryColor={R400} size="medium" />,
        isAutoDismiss: true,
      });

      setState({ isLoading: false });

      return;
    }
  },
};

const tawPhaseStore = createStore({
  name: 'taw-phase-store',
  initialState,
  actions,
});

export const useTawPhaseStore = createHook(tawPhaseStore);

// this variable save promise returned from fetchTawPhases action and timeout
let fetchTawPromise: null | Promise<void> = null;
const waitAndResetFetchTawPromise = () =>
  new Promise<void>((resolve) =>
    setTimeout(() => {
      fetchTawPromise = null;
      resolve();
    }, 5000)
  );

export const useFetchTawPhases = () => {
  const [, { fetchTawPhases }] = useTawPhaseStore();
  const [
    {
      workspace: { cloudId },
    },
  ] = useWorkspaceStore();
  const { showFlag } = useFlags();
  const isInitialized = useRef(false);

  useEffect(() => {
    if (isInitialized.current) {
      fetchTawPromise = null;
    }

    isInitialized.current = true;
  }, [cloudId]);

  useEffect(() => {
    if (fetchTawPromise) {
      fetchTawPromise = waitAndResetFetchTawPromise();

      return;
    }

    fetchTawPromise = (fetchTawPhases({ cloudId, showFlag }) as Promise<void>).finally(waitAndResetFetchTawPromise);
  }, [fetchTawPhases, cloudId, showFlag]);
};
