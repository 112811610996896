import { N200, N20A, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import styled, { css } from 'styled-components';

import { UserAvatarProps } from '@tc/UserAvatar/';

export const ellipsis = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const AvatarWrapper = styled.div<{ avatarMargin?: string }>`
  margin: ${(props) => (props.avatarMargin ? props.avatarMargin : '5px 0')};
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
  }

  div:nth-child(2) {
    line-height: normal;
    ${ellipsis}
  }
`;

export type Size = 'xsmall' | 'small' | 'medium' | 'large';
type SizeMap = {
  [size in Size]: {
    color: string;
    fontWeight: string;
    margin: string;
    padding: string;
    jobTitle: { color: string; padding: string };
    name: { maxWidth: string };
  };
};

const sizeStyle: SizeMap = {
  xsmall: {
    color: token('color.text', N800),
    margin: '-2px 0 0 0',
    padding: '0 0 0 8px',
    fontWeight: 'normal',
    jobTitle: {
      color: token('color.text.subtlest', N200),
      padding: '2px 0 0 8px',
    },
    name: {
      maxWidth: 'calc(100% - 8px)',
    },
  },
  small: {
    color: token('color.text', N800),
    margin: '0',
    padding: '0 0 0 12px',
    fontWeight: '500',
    jobTitle: {
      color: token('color.text.subtlest', N200),
      padding: '2px 0 0 12px',
    },
    name: {
      maxWidth: 'calc(100% - 12px)',
    },
  },
  medium: {
    color: token('color.text', N800),
    margin: '0',
    padding: '0 0 0 12px',
    fontWeight: '500',
    jobTitle: {
      color: token('color.text.subtlest', N200),
      padding: '2px 0 0 12px',
    },
    name: {
      maxWidth: 'calc(100% - 12px)',
    },
  },
  large: {
    color: token('color.text', N800),
    margin: '0',
    padding: '0 0 0 12px',
    fontWeight: '500',
    jobTitle: {
      color: token('color.text.subtlest', N200),
      padding: '2px 0 0 12px',
    },
    name: {
      maxWidth: 'calc(100% - 12px)',
    },
  },
};

const getSizeStyle = ({ size }: { size: Size }) => sizeStyle[size];

export const AvatarName = styled.div<{ size: Size; isBold?: boolean }>`
  max-width: 100%;
  ${getSizeStyle};
  ${ellipsis};

  ${(props) => props.isBold && 'font-weight: 600;'};
`;

const getDimension = (length: number) => `width: ${length}px; height: ${length}px`;
const overrideWithBorderelessDimension = (props: Pick<UserAvatarProps, 'hideBorder' | 'size'>) => {
  if (props.hideBorder) {
    switch (props.size) {
      case 'small':
        return getDimension(24);
      case 'medium':
        return getDimension(36);
      default:
        return '';
    }
  }

  return '';
};

export const BorderlessAvatar = styled.div`
  ${overrideWithBorderelessDimension};
  margin-right: ${(props: Pick<UserAvatarProps, 'hideBorder' | 'size'>) => (props.hideBorder ? 0 : 2)}px;

  span {
    ${(props: Pick<UserAvatarProps, 'hideBorder' | 'size'>) => (props.hideBorder ? 'padding: 0' : '')};
  }

  > div:first-child,
  > span:first-child,
  > a:first-child {
    display: flex !important;
  }

  > div > span:first-child,
  > a > div > span:first-child {
    margin: 0 !important;
  }
`;

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  background: ${token('color.background.neutral', N20A)};
`;

export const Description = styled.span`
  font-size: 11px;
`;

export const NameAndDescription = styled.div<{ size: Size }>`
  display: flex;
  flex-direction: column;
  max-width: ${(props) => getSizeStyle(props).name.maxWidth};

  ${Description} {
    color: ${(props) => getSizeStyle(props).jobTitle.color} !important;
    padding: ${(props) => getSizeStyle(props).jobTitle.padding};
  }

  .ellipsis-tooltip-container {
    display: flex;
  }
`;

export const InvitedLozengeWrapper = styled.div`
  position: relative;
  left: 10px;
`;
