import styled from 'styled-components';
import { gridSize } from '@atlaskit/theme/constants';

import { Initiative, Program } from 'src/pages/program/children/utils/program';
import { DescriptionWithTitle } from 'src/components/shared/AboutTab/DescriptionWithTitle';

export const TabBodyContainer = styled.div`
  flex: 1 1 auto;
  margin-top: 40px;
  width: 100%;
`;

export const AboutElementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${gridSize() * 6}px;
`;

type Props = {
  project: Program | Initiative;
};

export const About = (props: Props) => {
  const { project } = props;
  const { theWhat, theWhy, theMeasurement } = project;

  const context = {
    objectUUID: project.id && project.uuid,
    workspaceUUID: project.id && project.workspace.uuid,
  };

  return (
    <TabBodyContainer data-testid="project-about">
      <AboutElementsWrapper>
        <DescriptionWithTitle
          title="What are we doing?"
          testId="project-what-section"
          placeholder="Explain what you're doing."
          value={theWhat ?? ''}
          context={context}
        />
        <DescriptionWithTitle
          title="Why are we doing it?"
          testId="project-why-section"
          placeholder="Explain why you're doing it."
          value={theWhy ?? ''}
          context={context}
        />
        <DescriptionWithTitle
          title="What will success look like?"
          testId="project-measure-section"
          placeholder="How will you know when you're done."
          value={theMeasurement ?? ''}
          context={context}
        />
      </AboutElementsWrapper>
    </TabBodyContainer>
  );
};
