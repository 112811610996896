import styled from 'styled-components';

import { responsiveContentWidthThreshold, responsiveMobileWidthThreshold } from '@tc/ResponsiveGrid';

export const WorkspaceButton = styled.div`
  max-width: 160px;
  @media (max-width: ${responsiveContentWidthThreshold}px) {
    max-width: 60px;
  }
  @media (max-width: ${responsiveMobileWidthThreshold}px) {
    display: none !important;
  }
`;
