import { PrimaryDropdownButton } from '@atlaskit/atlassian-navigation';
import { TriggerProps } from '@atlaskit/popup';
import Tooltip from '@atlaskit/tooltip';

import { WorkspaceButton } from '@tc/Navigation/ProfileAndWorkspaceSwitcher/styles';
import { UserWorkspaceDetails } from 'src/store/workspaceStore';

type Props = {
  isLoading: boolean;
  handleClick: () => void;
  currentWorkspace: UserWorkspaceDetails;
  triggerProps: TriggerProps;
};

export const SwitcherTrigger = ({ isLoading, handleClick, currentWorkspace, triggerProps }: Props) => (
  <Tooltip content="Switch workspace">
    <WorkspaceButton>
      <PrimaryDropdownButton
        {...triggerProps}
        id="workspace-switcher-dropdown-button"
        testId="workspace-switcher-dropdown-button"
        onClick={handleClick}
        isLoading={isLoading}
      >
        {currentWorkspace.name}
      </PrimaryDropdownButton>
    </WorkspaceButton>
  </Tooltip>
);
