import { createHook, createStore, Action } from 'react-sweet-state';

import { apolloClient } from 'src/clients/apolloClient';
import { WorkspaceDetails } from '@shared/ProfileAndWorkspaceSwitcher';
import { timezoneHolder } from '@tc/util/timezoneHolder';
import { TEST_WORKSPACE_ID } from 'src/constants';
import { UserWorkspaceByContextQuery, UserWorkspaceByContextQueryVariables } from '@generated/GetUserWorkspace';
import { UpdateUserWorkspacePreferenceMutationVariables } from '@generated/UpdateUserWorkspace';
import { UPDATE_USER_WORKSPACE } from 'src/graphql/mutations/UpdateUserWorkspace';
import { GET_USER_WORKSPACE_BY_CONTEXT } from '@queries/GetUserWorkspace';

import { isTestUser, User } from './userStore';

export type UserWorkspaceDetails = {
  id: string;
  cloudId: string;
  organisationId: string;
  name: string;
  timezone?: string;
};

type WorkspaceStore = {
  workspace: UserWorkspaceDetails;
};

export const actions = {
  fetchCurrentWorkspace: (user: User): Action<WorkspaceStore> => async ({ setState }) => {
    const workspace = await apolloClient
      .query<UserWorkspaceByContextQuery, UserWorkspaceByContextQueryVariables>({
        query: GET_USER_WORKSPACE_BY_CONTEXT,
      })
      .then(({ data: { userWorkspaceByContext } }) => userWorkspaceByContext.workspace);

    if (isTestUser(user.email)) {
      setState({ workspace: { ...workspace, id: TEST_WORKSPACE_ID } });

      return;
    }

    timezoneHolder.setWorkspaceTimezone(workspace.timezone);

    setState({ workspace });
  },
  setCurrentWorkspace: (workspace: WorkspaceDetails): Action<WorkspaceStore> => async ({ setState }) => {
    await apolloClient.mutate<null, UpdateUserWorkspacePreferenceMutationVariables>({
      mutation: UPDATE_USER_WORKSPACE,
      variables: { workspaceId: workspace.globalId },
    });

    timezoneHolder.setWorkspaceTimezone(workspace.timezone);

    setState({
      workspace: {
        id: workspace.globalId,
        name: workspace.name,
        organisationId: workspace.organisationId,
        cloudId: workspace.cloudId,
        timezone: workspace.timezone,
      },
    });
  },
};

const workspaceStore = createStore({
  name: 'workspace-store',
  initialState: {} as WorkspaceStore,
  actions,
});

export const useWorkspaceStore = createHook(workspaceStore);
