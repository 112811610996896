import styled from 'styled-components';

import { Wrapper, defaultProps, Props } from './Wrapper';
import { SantaHat } from './xmas';

/* eslint-disable max-len */

const Container = styled.span`
  position: relative;

  > span {
    display: flex;
    max-height: 24px;
  }
`;

const svg = `
<canvas height="28" width="28" aria-hidden="true"></canvas>
<svg fill="#172B4D" height="28" width="28.02304906157392" viewBox="0 0 182.36 182.22">
	<defs>
		<style>.cls-1_0d0fc6{fill:url(#linear-gradient_0d0fc6)}.cls-2_0d0fc6{fill:#2681ff}</style>
		<linearGradient id="linear-gradient_0d0fc6" x1="78.58" y1="97.95" x2="31.41" y2="179.65" gradientUnits="userSpaceOnUse">
			<stop offset="0" stop-color="#0052cc"></stop>
			<stop offset=".92" stop-color="#2684ff"></stop>
		</linearGradient>
	</defs>
	<title>Atlassian-vertical-blue-white</title>
	<g id="Layer_2" data-name="Layer 2">
		<g id="Blue">
			<path class="cls-1_0d0fc6" d="M54.08,84.15c-2.72-2.91-7-2.75-8.82,1L.57,174.48a5.34,5.34,0,0,0,4.78,7.73H67.58a5.15,5.15,0,0,0,4.78-3C85.78,151.52,77.65,109.33,54.08,84.15Z"></path>
			<path class="cls-2_0d0fc6" d="M86.87,2.86c-25,39.6-23.35,83.46-6.88,116.38l30,60a5.34,5.34,0,0,0,4.78,3H177a5.34,5.34,0,0,0,4.78-7.73S98.06,7,96,2.83C94.07-.92,89.29-1,86.87,2.86Z"></path>
		</g>
	</g>
</svg>
`;

export const Icon = (props: Props) => (
  <Container>
    <SantaHat size="big" />
    <Wrapper {...defaultProps} iconGradientStart="#0052CC" iconGradientStop="#2684FF" {...props} svg={svg} />
  </Container>
);
