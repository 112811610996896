export const resolveUserTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * A way to hold timezone information so it's accessible outside of a react component
 */
class TimezoneHolder {
  private workspaceTimezone?: string;
  private userAccountTimezone?: string;
  constructor(workspaceTimezone?: string, userAccountTimezone?: string) {
    this.workspaceTimezone = workspaceTimezone;
    this.userAccountTimezone = userAccountTimezone;
  }

  getWorkspaceTimezone() {
    // Resolve to user timezone if global/workspace timezone is undefined
    return this.workspaceTimezone || resolveUserTimezone();
  }

  getUserTimezone() {
    return this.userAccountTimezone || resolveUserTimezone();
  }

  setWorkspaceTimezone(workspaceTimezone?: string) {
    // this.timezone = 'America/New_York';
    // this.timezone = 'Australia/Sydney';
    this.workspaceTimezone = workspaceTimezone;
  }

  setUserTimezone(userAccountTimezone?: string) {
    this.userAccountTimezone = userAccountTimezone;
  }
}

export const timezoneHolder = new TimezoneHolder();
