import { ApolloClient, from, HttpLink, InMemoryCache, ServerError } from '@apollo/client';
import { defaultRegistry } from 'react-sweet-state';
import { onError } from '@apollo/client/link/error';

import { getConfig } from 'src/config';
import possibleTypesResultData from '@generated/fragment';
import { userStore } from '@store/userStore';

const httpLink = new HttpLink({
  uri: (operation) => `${getConfig().graphqlUrl}?operationName=${operation.operationName}`,
});

const UserStore = defaultRegistry.getStore(userStore);

const handleNetworkErrors = (code: number) => {
  if (code === 504) {
    UserStore.actions.setRequestError({ code, message: 'Request execution timeout. Please try again!' });
  } else {
    UserStore.actions.setRequestError({
      code,
      message: 'Something went wrong. Please contact slack #help-global-state',
    });
  }
};

const errorLink = onError(({ networkError }) => {
  if (networkError) {
    handleNetworkErrors((networkError as ServerError)?.statusCode);
  }
});

export const apolloClient = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache({
    possibleTypes: possibleTypesResultData.possibleTypes,
    dataIdFromObject: (obj) =>
      (obj.__typename !== 'Icon' ? obj.id : `${obj.id}:${obj.shortName}|${obj.color}`) as string,
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
  },
});
