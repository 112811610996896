import { useCallback, useMemo } from 'react';
import { ProfileCardTrigger, ProfilecardTriggerPosition } from '@atlaskit/profilecard';

import { getConfig } from 'src/config';
import { profileClient } from '@tc/providers';

interface Props {
  children: JSX.Element;
  position?: ProfilecardTriggerPosition;
  userId: string;
  isShowProfileCard?: boolean;
  isEmbeddedPage?: boolean;
}

const PEOPLE_PROFILE_PATH = '/people/';

export const WrappedWithProfileCard = (props: Props) => {
  const { userId, position, children, isShowProfileCard = true, isEmbeddedPage = false } = props;

  const profileClickCallback = (clickedUserId: string) => {
    window.open(`${getConfig().teamCentralUrl}/people/${clickedUserId}`, '_blank', 'noreferrer');
  };

  const profileCardTriggerActions = useMemo(
    () => [
      {
        label: 'View profile',
        id: 'view-profile',
        callback() {
          profileClickCallback(userId);
        },
        link: isEmbeddedPage ? undefined : `${getConfig().teamCentralUrl}/people/${userId}`,
      },
    ],
    [isEmbeddedPage, userId]
  );
  const onReportingLinesClick = useCallback((user) => profileClickCallback(user.accountIdentifier), []);

  return isShowProfileCard ? (
    <ProfileCardTrigger
      resourceClient={profileClient}
      position={position}
      userId={userId}
      actions={profileCardTriggerActions}
      reportingLinesProfileUrl={PEOPLE_PROFILE_PATH}
      onReportingLinesClick={onReportingLinesClick}
    >
      {children}
    </ProfileCardTrigger>
  ) : (
    children
  );
};
