import { getConfig } from 'src/config';

class TeamCentralClient {
  authCheck() {
    return fetch(getConfig().authCheckUrl, {
      headers: {
        Accept: 'application/json',
      },
    });
  }
}

export const teamCentralClient = new TeamCentralClient();
