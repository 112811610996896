import Tooltip, { PositionType } from '@atlaskit/tooltip';
import { forwardRef } from 'react';

const EllipsisTooltipTag = forwardRef((props, ref: React.Ref<HTMLElement>) => {
  const { children, ...rest } = props;

  return (
    <div
      {...rest}
      className="ellipsis-tooltip-container"
      ref={ref as React.Ref<HTMLDivElement>}
      style={{
        lineHeight: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {children}
    </div>
  );
});

interface Props {
  content?: string | null;
  children?: JSX.Element | string | null;
  position?: PositionType;
}

export const EllipsisTooltip = (props: Props) => (
  <Tooltip delay={500} content={props.content} tag={EllipsisTooltipTag} position={props.position || 'bottom-start'}>
    {props.children}
  </Tooltip>
);
