import { Operators } from '@tc/Filters/MetaFilter/types';

import { Tags } from './graphql/types';

export const PROGRAM_TAG_PREFIX = 'pgm--';
export const DEPARTMENT_TAG_PREFIX = 'dept--';
export const TAW_TAG_PREFIX = 'taw-';
export const INDEPENDENT_INITIATIVE_TAG_PREFIX = 'init--';
export const NO_TAW_PHASE_TAG = 'taw-no-phase';
export const NO_TAW_PHASE = 'No Phase';
export const ROWS_PER_PAGE = 20;
export const EMPTY_SEARCH_LIMIT = 4;
export const NOT_EMPTY_SEARCH_LIMIT = 10;
export const DEFAULT_TQL_PROGRAM_LIST_QUERY = `(archived = false) ${Operators.AND} (label = ${Tags.program} ${Operators.AND} label LIKE ${PROGRAM_TAG_PREFIX})`;
export const DEFAULT_TQL_INITIATIVE_LIST_QUERY_NL = `(archived = false) ${Operators.AND} (label = ${Tags.initiative} ${Operators.AND} (label LIKE ${INDEPENDENT_INITIATIVE_TAG_PREFIX}))`;
export const ATLASSIAN_WORKSPACE_ID = 'V29ya3NwYWNlOjE=';
export const TEST_WORKSPACE_ID = 'V29ya3NwYWNlOjIyMTY=';
export const LEVEL_THREE_TAG = 'level-3';
export const NEW = 'new';
export const THIS_WEEK = 'This week';
export const FAVOURITES_ASC = 'favourites-ASC';
export const PROJECT_ASC = 'project-ASC';
export const SHIP_DATE_ASC = 'shipDate-ASC';
export const MOBILE_MODE_WIDTH_THRESHOLD = 1000;
export const PROJECT_NAME = 'Global State';

export enum Entities {
  INITIATIVES = 'initiatives',
  PROGRAMS = 'programs',
  PROJECTS = 'projects',
  MILESTONES = 'milestones',
  INVALID = 'invalid',
}

export enum Entity {
  INITIATIVE = 'initiative',
  MILESTONE = 'milestone',
  PROGRAM = 'program',
  PROJECT = 'project',
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum AriaLabelWarningIcon {
  PURE_WARNING = 'Warning icon',
  DUPLICATED_INITIATIVE = 'Duplicated initiative warning icon',
  DUPLICATED_PROGRAM = 'Duplicated program warning icon',
  DUPLICATED_ENTITY = 'Duplicated entity warning icon',
  DEPARTMENT_LABEL_MISSING = 'Department label missing warning icon',
}

export enum TooltipContentWarningIcon {
  PURE_WARNING = 'Duplication warning',
  DUPLICATED_INITIATIVE = 'Duplicated initiative',
  DUPLICATED_PROGRAM = 'Duplicated program',
  DUPLICATED_ENTITY = 'Duplicated entity',
  DEPARTMENT_LABEL_MISSING = 'Department label missing',
}
