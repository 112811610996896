import Lozenge from '@atlaskit/lozenge';
import styled from 'styled-components';
import Tooltip from '@atlaskit/tooltip';

import { GoalState, Maybe, ProjectPhase, ProjectPhaseDetails, ProjectStateValue } from '@generated/graphql';
import { GqlPhaseType } from 'src/graphql/types';
import { MiniLozenge } from '@tc/MiniLozenge';
import { State } from '@queries/GetProjectPageItems';

export type Phase = {
  displayName: string;
  name: string;
  id: string;
};

export const PENDING: ProjectPhaseDetails = {
  displayName: 'Pending',
  id: 1,
  name: ProjectPhase.Pending,
};

export const IN_PROGRESS: ProjectPhaseDetails = {
  displayName: 'In progress',
  id: 2,
  name: ProjectPhase.InProgress,
};

export const phaseToDetails = (state: Maybe<State> | undefined): State | null => {
  if (!state) {
    return null;
  }

  const phaseTypeKey = Object.keys(ProjectPhase).find(
    (key) => ProjectStateValue[key as keyof typeof ProjectStateValue] === state.value
  );
  const phaseType = ProjectStateValue[phaseTypeKey as keyof typeof ProjectStateValue];

  return {
    value: phaseType,
    label: state.label,
  };
};

export type PhaseType = GqlPhaseType;
export const goalStateSanitizer = (state: string) => state.slice(0, -6);

export const LozengeWrapper = styled.div<{ isGroup?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ isGroup }) => (isGroup ? 'end' : 'start')};

  & > span {
    padding: 4px 8px;
    line-height: 16px;
    > span {
      padding: 0;
    }
  }
`;

interface Props {
  state: State | GoalState;
  isDashboard?: boolean;
  isGroup?: boolean;
  isSidebarGoal?: boolean;
  score?: number | null;
}

export const PhaseLozenge = ({ state, isDashboard, isSidebarGoal, score, isGroup }: Props) => (
  <Tooltip content={state.label} position="bottom-start">
    <LozengeWrapper isGroup={isGroup}>
      <Lozenge appearance="default" maxWidth={isDashboard ? 75 : 'none'}>
        {state.label}
        {isSidebarGoal && typeof score === 'number' && <MiniLozenge score={score} />}
      </Lozenge>
    </LozengeWrapper>
  </Tooltip>
);
