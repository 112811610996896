import '@atlaskit/css-reset';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client/react';
import Heartbeat from '@atlassian/heartbeat';

import {
  documentHasStorageAccess,
  ensureCorrectAuth,
  isEmbed,
  requestStorageAccess,
  StorageAccessState,
  returnAfterLogin,
} from '@shared/utils/auth';
import { RequestStorageAccess } from '@tc/RequestStorageAccess';
import { AppIntlProvider } from '@tc/AppIntlProvider';

import { App } from './App';
import { apolloClient } from './clients/apolloClient';
import { reportWebVitals } from './reportWebVitals';

const initApp = async () => {
  if (isEmbed() && documentHasStorageAccess()) {
    const needsAccess = (await requestStorageAccess()) === StorageAccessState.UserMustGrant;
    const isAuthed = await ensureCorrectAuth(false);

    if (needsAccess || !isAuthed) {
      ReactDOM.render(<RequestStorageAccess />, document.getElementById('root'));

      return;
    }
  }

  await ensureCorrectAuth();

  ReactDOM.render(
    <StrictMode>
      <ApolloProvider client={apolloClient}>
        <AppIntlProvider>
          <ModalTransition>
            <App />
            <Heartbeat onAuthenticationFailed={returnAfterLogin} />
          </ModalTransition>
        </AppIntlProvider>
      </ApolloProvider>
    </StrictMode>,
    document.getElementById('root')
  );
};

initApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
