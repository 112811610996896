import Loadable from 'react-loadable';
import { RouteContext } from 'react-resource-router';

import { LoadableErrorHandler } from 'src/components/shared/LoadableErrorHandler';

export enum SettingsTabs {
  DEPARTMENTS = 'departments',
  TAW_PHASES = 'taw-phases',
  ASSETS_IMPORT = 'assets-import',
}

export const SettingsPage = Loadable<RouteContext, never>({
  loader: () =>
    import(/* webpackChunkName: "SettingsPage" */ './SettingsPage').then(({ SettingsPage }) => SettingsPage),
  loading: LoadableErrorHandler,
});
