import { h100 } from '@atlaskit/theme/typography';
import styled, { css } from 'styled-components';

export const Card = styled.div<{
  isDisabled?: boolean;
  isDashboardCard?: boolean;
  isTargetWeekUpdate?: boolean;
  shouldShowPointer?: boolean;
}>`
  width: 100%;
  box-sizing: border-box;
  padding: ${({ isDashboardCard, isTargetWeekUpdate }) =>
    isDashboardCard || !isTargetWeekUpdate ? '24px 24px 24px 35px' : '24px '};
  border-radius: 3px;
  box-shadow: rgba(9, 30, 66, 0.25) 0 1px 1px, rgba(9, 30, 66, 0.31) 0 0 1px 0;
  cursor: ${({ shouldShowPointer }) => (shouldShowPointer ? 'pointer' : 'default')};

  & > h5 {
    ${h100};
    text-transform: uppercase;
  }

  & + & {
    margin-top: 16px;
  }

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          opacity: 0.5;
          pointer-events: none;
        `
      : ''};
`;
