import { useCallback } from 'react';
import { Link } from 'react-resource-router';
import Tooltip from '@atlaskit/tooltip';
import styled from 'styled-components';
import { N300 } from '@atlaskit/theme/colors';

import { ProjectName } from '@shared/CardHeader';
import { TCLinkContentWrapper, TCLinkWrapper } from '@shared/TCLinkWrapper';
import { isProgram } from '@shared/utils/initiative';
import { AssetsParentProgram, BreadcrumbWrapper, Home, IconWrapper, Name, ParentPrograms } from '@shared/Header/Header';
import { ProjectIcon } from '@tc/ProjectIcon';
import { Initiative, Program } from '@program/children/utils/program';
import { IntiativeBreadcrumbs } from '@initiative/children/IntiativeBreadcrumbs';
import { ellipsis } from '@tc/UserAvatar/styles';
import { Actions, useAnalytics } from '@hooks/useAnalytics';
import { AriaLabelWarningIcon, TooltipContentWarningIcon } from 'src/constants';
import { useWarningIcon } from '@hooks/useWarningIcon';
import { ENABLE_ASSETS } from 'src/featureFlagsKeys';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { VerifiedIcon } from '@shared/VerifiedIcon';

const InitBreadcrumbsWrapper = styled.div`
  display: flex;
  align-items: center;

  div {
    ${ellipsis}
  }
`;

type HeaderProps = {
  project: Program | Initiative;
  breadcrumbText?: string;
  breadcrumbLink?: string;
  parentPrograms?: (ParentPrograms | AssetsParentProgram)[];
  isInitiativePage?: boolean;
  isIndependentInit?: boolean;
  source: string;
  isVerified?: boolean;
};

export const HeaderTitle = ({
  project,
  breadcrumbText,
  breadcrumbLink = '/',
  parentPrograms,
  isInitiativePage,
  isIndependentInit,
  source,
  isVerified,
}: HeaderProps) => {
  const { name, uuid, icon, isDuplicated } = project;

  const { sendUIEvent } = useAnalytics();
  const showAssets = useFeatureFlag(ENABLE_ASSETS, false);

  const handleClick = useCallback(() => {
    sendUIEvent({
      action: Actions.CLICKED,
      actionSubject: 'button',
      actionSubjectId: 'breadcrumb',
      source: source,
    });
  }, [sendUIEvent, source]);

  const tooltipContent = isProgram(project)
    ? TooltipContentWarningIcon.DUPLICATED_PROGRAM
    : TooltipContentWarningIcon.DUPLICATED_INITIATIVE;
  const labelContent = isProgram(project)
    ? AriaLabelWarningIcon.DUPLICATED_PROGRAM
    : AriaLabelWarningIcon.DUPLICATED_INITIATIVE;

  const renderWarningIcon = useWarningIcon(isDuplicated, tooltipContent, labelContent);
  const tooltip = isProgram(project) ? 'Atlassian Program' : 'Atlassian Milestone';
  const isVerifiedIcon = isProgram(project) ? !!showAssets && isVerified : isVerified;

  return (
    <>
      <IconWrapper>
        <ProjectIcon projectUuid={uuid} {...icon} />
      </IconWrapper>
      <BreadcrumbWrapper>
        <Home isInitiativePage={isIndependentInit || isInitiativePage}>
          {isInitiativePage && !isIndependentInit && parentPrograms ? (
            <InitBreadcrumbsWrapper>
              <Tooltip content={parentPrograms[0].name} position="top-start">
                <Link
                  href={parentPrograms[0].link}
                  target="_self"
                  style={{ textDecorationColor: N300, marginRight: '2px' }}
                  onClick={handleClick}
                >
                  {parentPrograms[0].name}
                </Link>
              </Tooltip>

              {parentPrograms && parentPrograms.length > 1 && (
                <IntiativeBreadcrumbs parentPrograms={parentPrograms} source={source} />
              )}
            </InitBreadcrumbsWrapper>
          ) : (
            <Tooltip content={breadcrumbText} position="top-start">
              <ProjectName>
                <Link href={breadcrumbLink} onClick={handleClick}>
                  {breadcrumbText}
                </Link>
              </ProjectName>
            </Tooltip>
          )}
        </Home>
        <Tooltip content={name} position="bottom-start">
          <Name href={project.link} target="_blank" rel="noopener noreferrer">
            <ProjectName data-testid="project-name">
              <TCLinkWrapper isLabel={true} content={<TCLinkContentWrapper>{name}</TCLinkContentWrapper>} />
            </ProjectName>
            <VerifiedIcon content={tooltip} isVerified={isVerifiedIcon} />
            {renderWarningIcon()}
          </Name>
        </Tooltip>
      </BreadcrumbWrapper>
    </>
  );
};
