import Loadable from 'react-loadable';

import { Context } from '@tc/providers';
import { LoadableErrorHandler } from '@shared/LoadableErrorHandler';

export type Props = {
  context?: Context;
  document: string;
  mode: 'normal' | 'light';
};

export const Renderer = Loadable<Props, never>({
  loader: () => import(/* webpackChunkName: "renderer" */ './Renderer').then(({ Renderer }) => Renderer),
  loading: LoadableErrorHandler,
});
