import { getMilestoneLinkByKey, getProgramLinkByKey } from '@shared/CreationFlow/utils/link';
import { getEntityTypeAndTags } from '@shared/utils/entity';
import { findSizeTagUuid, getDependedOnProjectKeys } from '@shared/utils/project';
import { getConfig } from 'src/config';
import { Tag } from '@queries/GetProjectPageItems';
import { Entity, Tags } from 'src/graphql/types';
import { getOldestProject } from '@program/children/utils/program';

import { DepartmentProject } from './filters';

export enum ProjectType {
  PROGRAM = 'Pgm',
  MILESTONE = 'Mile',
  INITIATIVE = 'Init',
  PROJECT = 'Proj',
  INVALID = '-',
}

export const entityDelimiter = {
  [ProjectType.PROGRAM]: 'PROGRAMS',
  [ProjectType.MILESTONE]: 'MILESTONES',
  [ProjectType.INITIATIVE]: 'INITIATIVES',
  [ProjectType.PROJECT]: 'OTHER',
};

export type HierarchyProject = DepartmentProject & {
  customTableId: string;
  type: ProjectType;
  children: { [key: string]: HierarchyProject };
  isDuplicated?: boolean;
  delimiterType?: string;
};

export type HierarchyProjectWithChildrenArray = Omit<HierarchyProject, 'children'> & {
  children: HierarchyProjectWithChildrenArray[];
};

type TagToProjectMap = {
  [key: string]: HierarchyProject;
};

type TagToProjectsMaps = {
  initiativeTagToProjectsMap: { [key: string]: { [key: string]: HierarchyProject } };
  programTagToProjectsMap: { [key: string]: { [key: string]: HierarchyProject } };
};

export type HierarchyEntities = {
  programs: DepartmentProject[];
  initiatives: DepartmentProject[];
  projects: DepartmentProject[];
  invalidEntities: DepartmentProject[];
};

export type HierarchyEntitiesWithAssets = HierarchyEntities & {
  isAssetsEnable?: boolean;
  milestones: DepartmentProject[];
  departmentMilestonesHierarchy: { [key: string]: string[] };
};

const createDuplicatedEntityLink = (tagUuid: string, tags: DepartmentProject['tags'], sizeTag: Tags) => {
  const { teamCentralUrl } = getConfig();

  const link = new URL(`${teamCentralUrl}/projects`);
  const sizeProgramTagUuid = findSizeTagUuid(tags, sizeTag);

  const tqlQueryParam = `(archived = false) AND ((label = ${sizeProgramTagUuid}) AND (label = ${tagUuid}))`;

  link.searchParams.append('tql', tqlQueryParam);

  return link;
};

const createProgramLink = (tags: DepartmentProject['tags'], programTag: Tag, isDuplicated: boolean, key: string) => {
  let link: URL | string = getProgramLinkByKey(key);

  if (isDuplicated) {
    link = createDuplicatedEntityLink(programTag.uuid, tags, Tags.program);
  }

  return link.toString();
};

const createInitiativeLink = (tags: DepartmentProject['tags'], initiativeTag: Tag, isDuplicated: boolean) => {
  const [, programOrInitiativeName, initiativeName] = initiativeTag.name.split('--');
  // TODO: remove initiativeName existence check after https://pi-dev-sandbox.atlassian.net.atl.zbizdev.zengel.myshn.net/browse/GST-736 be merged
  let link: URL | string = initiativeName
    ? `/program/${programOrInitiativeName}/${initiativeName}`
    : `/initiative/${programOrInitiativeName}`;

  if (isDuplicated) {
    link = createDuplicatedEntityLink(initiativeTag.uuid, tags, Tags.initiative);
  }

  return link.toString();
};

export const createProjectLink = (projectKey: string) => `${getConfig().teamCentralUrl}/project/${projectKey}/updates`;

const getTagToProgramMap = (programs: DepartmentProject[]) =>
  programs.reduce<TagToProjectMap>((acc, program) => {
    const { type, programTags } = getEntityTypeAndTags(program);

    // TODO: get rid of previous loop through programs
    if (type === Entity.PROGRAM) {
      const [programTag] = programTags;
      const isDuplicated = !!acc[programTag.name];

      acc[programTag.name] = {
        ...getOldestProject(program, acc[programTag.name]),
        url: createProgramLink(program.tags, programTag, isDuplicated, program.key),
        target: isDuplicated ? '_blank' : '_self',
        customTableId: programTag.name,
        isDuplicated,
        children: {},
      };
    }

    return acc;
  }, {});

const getTopLevelInitiativesAndProjects = (
  initiatives: DepartmentProject[],
  tagToProgramMap: TagToProjectMap,
  { initiativeTagToProjectsMap, programTagToProjectsMap }: TagToProjectsMaps
) => {
  const topLevelInitiatives: TagToProjectMap = {};
  const allInitiativesList: HierarchyProject[] = [];
  // check if projects were assigned to initiative
  const wereProjectsAssignedByInitiativeTag: { [key: string]: boolean } = {};

  initiatives.forEach((initiative) => {
    const { type, initiativeTags, programTags } = getEntityTypeAndTags(initiative);

    // TODO: get rid of previous loop through initiatives
    if (type !== Entity.INITIATIVE) {
      return;
    }

    const [initiativeTag] = initiativeTags;
    const tagName = initiativeTag.name;

    const { [tagName]: children = {} } = initiativeTagToProjectsMap;

    wereProjectsAssignedByInitiativeTag[tagName] = true;

    let wasAssignedToProgram = false;

    programTags.forEach(({ name: parentTagName }) => {
      // assign initiative to program
      if (tagToProgramMap[parentTagName]) {
        const isDuplicated = !!tagToProgramMap[parentTagName].children[tagName];

        tagToProgramMap[parentTagName] = {
          ...tagToProgramMap[parentTagName],
          initiativeTag,
          children: {
            ...(tagToProgramMap[parentTagName].children || {}),
            [tagName]: {
              ...getOldestProject(initiative, tagToProgramMap[parentTagName].children[tagName]),
              url: createInitiativeLink(initiative.tags, initiativeTag, isDuplicated),
              target: isDuplicated ? '_blank' : '_self',
              customTableId: `${parentTagName}|${tagName}`,
              isDuplicated,
              children,
            },
          },
        };
        wasAssignedToProgram = true;
      }
    });

    const isDuplicated = !!topLevelInitiatives[tagName];
    const initiativeObject = {
      ...getOldestProject(initiative, topLevelInitiatives[tagName]),
      url: createInitiativeLink(initiative.tags, initiativeTag, isDuplicated),
      target: isDuplicated ? '_blank' : '_self',
      customTableId: tagName,
      initiativeTag,
      isDuplicated,
      children,
    } as HierarchyProject;

    allInitiativesList.push(initiativeObject);

    // if initiative wasn't assigned to program, move it to top level
    if (!wasAssignedToProgram) {
      topLevelInitiatives[tagName] = initiativeObject;
    }
  });

  const topLevelProject: TagToProjectMap = {};
  const wasProjectAssignedToProgram: { [key: string]: boolean } = {};

  Object.entries(programTagToProjectsMap).forEach(([programTag, projects]) => {
    Object.entries(projects).forEach(([key, project]) => {
      const { initiativeTags } = getEntityTypeAndTags(project);
      const wasProjectAssignedToInitiative = initiativeTags.some(
        ({ name }) => !!wereProjectsAssignedByInitiativeTag[name]
      );
      const isProjectAssignedToProgramThroughInitiative =
        wasProjectAssignedToInitiative &&
        initiativeTags.some(({ name }) => !!tagToProgramMap[programTag]?.children[name]);

      // if project doesn't belong to program through its initiatives then show this project under program
      if (!isProjectAssignedToProgramThroughInitiative && tagToProgramMap[programTag]) {
        tagToProgramMap[programTag] = {
          ...tagToProgramMap[programTag],
          children: {
            ...(tagToProgramMap[programTag].children || {}),
            [key]: project,
          },
        };
        wasProjectAssignedToProgram[key] = true;
      }
      // move project to top level if it can't be assigned to any program or initiative
      else if (!wasProjectAssignedToInitiative && !topLevelProject[key]) {
        topLevelProject[key] = project;
      }
    });
  });

  // find all projects that weren't assigned to initiative or program and move them to top level
  Object.entries(initiativeTagToProjectsMap).forEach(([initiativeTag, projects]) => {
    if (wereProjectsAssignedByInitiativeTag[initiativeTag]) {
      return;
    }

    Object.entries(projects).forEach(([key, project]) => {
      if (!wasProjectAssignedToProgram[key] && !topLevelProject[key]) {
        topLevelProject[key] = project;
      }
    });
  });

  return { topLevelInitiatives, topLevelProject, allInitiativesList };
};

const getTopLevelInvalidEntities = (invalidEntities: DepartmentProject[]) =>
  invalidEntities.map((project) => ({
    ...project,
    customTableId: project.uuid,
    isDuplicated: false,
    children: {},
  }));

export const flattenChildren = (project: HierarchyProject): HierarchyProjectWithChildrenArray => ({
  ...project,
  children: Object.values(project.children).map(flattenChildren),
});

export const getTagToProjectsMaps = (projects: DepartmentProject[]) =>
  projects.reduce<TagToProjectsMaps>(
    (acc, project) => {
      const { type, initiativeTags, programTags } = getEntityTypeAndTags(project);

      if (type !== Entity.PROJECT) {
        return acc;
      }

      // handle projects that belong to initiative
      initiativeTags.forEach(({ name }) => {
        acc.initiativeTagToProjectsMap[name] = {
          ...(acc.initiativeTagToProjectsMap[name] || {}),
          [project.key]: {
            ...project,
            url: createProjectLink(project.key),
            target: '_blank',
            children: {},
            customTableId: `${name}|${project.key}`,
          },
        };
      });

      // handle projects that belong to program directly
      programTags.forEach(({ name }) => {
        acc.programTagToProjectsMap[name] = {
          ...(acc.programTagToProjectsMap[name] || {}),
          [project.key]: {
            ...project,
            url: createProjectLink(project.key),
            target: '_blank',
            children: {},
            customTableId: `${name}|${project.key}`,
          },
        };
      });

      return acc;
    },
    { initiativeTagToProjectsMap: {}, programTagToProjectsMap: {} } as TagToProjectsMaps
  );

export const buildHierarchyNL = ({ programs, initiatives, projects, invalidEntities }: HierarchyEntities) => {
  const tagToProgramMap = getTagToProgramMap(programs);
  const tagToProjectsMaps = getTagToProjectsMaps(projects);
  // NOTE: that getTopLevelInitiativesAndProjects mutates tagToProgramMap
  const { topLevelInitiatives, topLevelProject, allInitiativesList } = getTopLevelInitiativesAndProjects(
    initiatives,
    tagToProgramMap,
    tagToProjectsMaps
  );

  const topLevelInvalidEntities = getTopLevelInvalidEntities(invalidEntities);

  return {
    hierarchyData: [
      ...Object.values(tagToProgramMap),
      ...Object.values(topLevelInitiatives),
      ...Object.values(topLevelProject),
      ...topLevelInvalidEntities,
    ],
    allInitiativesList,
  };
};

const getOutgoingProjectsHierarchy = (projects: DepartmentProject[], keys: string[]) =>
  projects.reduce((acc: { [key: string]: HierarchyProject }, project) => {
    if (keys.includes(project.key)) {
      acc[project.key] = {
        ...project,
        url: createProjectLink(project.key),
        target: '_blank',
        customTableId: project.key,
        isDuplicated: false,
        type: ProjectType.PROJECT,
        children: {},
      };
    }

    return acc;
  }, {});

export const getMilestoneHierarchy = (projects: DepartmentProject[], keys: string[]) =>
  projects.reduce((acc: { [key: string]: HierarchyProject }, project) => {
    if (keys.includes(project.key)) {
      const outgoingProjectsKeys = getDependedOnProjectKeys(project.dependencies);

      const dependencies = getOutgoingProjectsHierarchy(projects, outgoingProjectsKeys);

      acc[project.key] = {
        ...project,
        url: getMilestoneLinkByKey(project.key),
        target: '_blank',
        customTableId: project.key,
        isDuplicated: false,
        children: dependencies,
      };
    }

    return acc;
  }, {});

export const buildHierarchyWithAssets = ({
  programs,
  initiatives,
  milestones,
  projects,
  invalidEntities,
  departmentMilestonesHierarchy,
}: HierarchyEntitiesWithAssets) => {
  const allProjects = [...programs, ...initiatives, ...milestones, ...projects, ...invalidEntities];

  const programsKeys = programs.map(({ key }) => key);
  const milestonesKeys = milestones.map(({ key }) => key);
  const projectsKeys = projects.map(({ key }) => key);

  if (programsKeys.length === 0 && milestonesKeys.length === 0) {
    return Object.values(getOutgoingProjectsHierarchy(allProjects, projectsKeys));
  }

  if (programsKeys.length === 0) {
    return Object.values(getMilestoneHierarchy(allProjects, milestonesKeys));
  }

  return generateProgramsHierarchy(allProjects, departmentMilestonesHierarchy);
};

const generateProgramsHierarchy = (
  allProjects: DepartmentProject[],
  departmentMilestonesHierarchy: { [key: string]: string[] }
) => {
  const programsKeys = Object.keys(departmentMilestonesHierarchy);

  return allProjects.reduce((acc: HierarchyProject[], project, _, projects) => {
    if (programsKeys.includes(project.key)) {
      const outgoingProjectsKeys = getDependedOnProjectKeys(project.dependencies);

      const dependencies = getOutgoingProjectsHierarchy(projects, outgoingProjectsKeys);

      const milestones = getMilestoneHierarchy(projects, departmentMilestonesHierarchy[project.key]);

      acc.push({
        ...project,
        url: getProgramLinkByKey(project.key).toString(),
        target: '_self',
        customTableId: project.key,
        isDuplicated: false,
        children: {
          ...milestones,
          ...dependencies,
        },
      });
    }

    return acc;
  }, []);
};

export const updateParentCustomId = (
  items: HierarchyProjectWithChildrenArray[],
  parentCustomTableId: string = ''
): HierarchyProjectWithChildrenArray[] =>
  items.map((item: HierarchyProjectWithChildrenArray) => ({
    ...item,
    customTableId: parentCustomTableId ? `${parentCustomTableId}|${item.customTableId}` : item.customTableId,
    children: updateParentCustomId(item.children ?? [], item.customTableId),
  }));

export const generateDepths = (
  depthObject: { [key: string]: number },
  edges: HierarchyProjectWithChildrenArray[],
  parentDepth: number
) => {
  const interstitialDepthObject: { [key: string]: number } = { ...depthObject };

  edges.forEach((edge) => {
    Object.assign(interstitialDepthObject, { [edge.customTableId]: parentDepth + 1 });
  });

  return interstitialDepthObject;
};

export const stitchProjects = (
  projects: HierarchyProjectWithChildrenArray[],
  expandedProjects: string[],
  childrenEdgesObject: { [key: string]: HierarchyProjectWithChildrenArray[] }
): HierarchyProjectWithChildrenArray[] => {
  const clonedEdges = [...projects];

  expandedProjects.forEach((key) => {
    const idx = clonedEdges.findIndex((edge) => edge?.customTableId === key);

    if (idx > -1) {
      clonedEdges.splice(idx + 1, 0, ...(childrenEdgesObject[key] ?? []));
    }
  });

  return clonedEdges;
};

export const findIndexOfFirstType = (projects: HierarchyProjectWithChildrenArray[], type: ProjectType) =>
  projects.findIndex((project) => project.type === type);

export const insertDelimiter = (projects: HierarchyProjectWithChildrenArray[]) => {
  const projectsSeparatedByTypes = [...projects];

  const programIndex = findIndexOfFirstType(projectsSeparatedByTypes, ProjectType.PROGRAM);

  programIndex !== -1 &&
    projectsSeparatedByTypes.splice(programIndex, 0, {
      delimiterType: entityDelimiter[ProjectType.PROGRAM],
      customTableId: entityDelimiter[ProjectType.PROGRAM],
    } as HierarchyProjectWithChildrenArray);

  const milestoneIndex = findIndexOfFirstType(projectsSeparatedByTypes, ProjectType.MILESTONE);

  milestoneIndex !== -1 &&
    projectsSeparatedByTypes.splice(milestoneIndex, 0, {
      delimiterType: entityDelimiter[ProjectType.MILESTONE],
      customTableId: entityDelimiter[ProjectType.MILESTONE],
    } as HierarchyProjectWithChildrenArray);

  const initiativeIndex = findIndexOfFirstType(projectsSeparatedByTypes, ProjectType.INITIATIVE);

  initiativeIndex !== -1 &&
    projectsSeparatedByTypes.splice(initiativeIndex, 0, {
      delimiterType: entityDelimiter[ProjectType.INITIATIVE],
      customTableId: entityDelimiter[ProjectType.INITIATIVE],
    } as HierarchyProjectWithChildrenArray);

  const projectIndex = findIndexOfFirstType(projectsSeparatedByTypes, ProjectType.PROJECT);
  const invalidIndex = findIndexOfFirstType(projectsSeparatedByTypes, ProjectType.INVALID);
  const otherIndex = projectIndex === -1 ? invalidIndex : projectIndex;

  otherIndex !== -1 &&
    projectsSeparatedByTypes.splice(otherIndex, 0, {
      delimiterType: entityDelimiter[ProjectType.PROJECT],
      customTableId: entityDelimiter[ProjectType.PROJECT],
    } as HierarchyProjectWithChildrenArray);

  return projectsSeparatedByTypes;
};

export const getAllChildrenKeys = (projects: HierarchyProjectWithChildrenArray[]): string[] =>
  projects.flatMap((project): string[] => {
    const keys = project.key ? [project.key] : [];

    if (project.children && project.children.length > 0) {
      return [...keys, ...getAllChildrenKeys(project.children)];
    }

    return keys;
  });
