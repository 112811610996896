import { useEffect } from 'react';
import styled from 'styled-components';

import { Sources, useAnalytics } from 'src/hooks/useAnalytics';
import { getConfig } from 'src/config';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  text-align: center;
`;

type Props = {
  email: string;
};

export const NotAtlassianUser = ({ email }: Props) => {
  const { sendScreenEvent } = useAnalytics();

  useEffect(() => {
    sendScreenEvent({ name: Sources.NOT_ATLASSIAN_USER_PAGE });
  }, [sendScreenEvent]);

  return (
    <Wrapper>
      <img src="/error.png" alt="error" width="115" />
      <p>Looks like you don't have access to this Workspace</p>
      <p>
        You're currently logged in as <a href={getConfig().atlassianAccountUrl}>{email}</a>. Not you?
      </p>
      <p>
        Click{' '}
        <a
          href={`${getConfig().atlassianAccountUrl}/login/select-account?continue=${encodeURIComponent(
            window.location.href
          )}&application=global-state`}
        >
          here{' '}
        </a>
        to log in with a different account
      </p>
    </Wrapper>
  );
};
