import { NO_TAW_PHASE } from 'src/constants';

export const sliceName = (name: string) => (name.length > 130 ? `${name.slice(0, 130)}...` : name);

export const parseBack = (str: string) => {
  const lowerCaseString = str.split('-').pop()?.replace(/\s/g, '').toLowerCase();

  return str === 'taw-no-phase'
    ? NO_TAW_PHASE
    : `${lowerCaseString?.charAt(0).toUpperCase()}${lowerCaseString?.slice(1)}`;
};

export const parse = (str: string) =>
  str === NO_TAW_PHASE
    ? 'taw-no-phase'
    : `taw-${str
        .toLowerCase()
        .replace(/\s/g, '')
        .replace(/[^a-z0-9]/g, '')}`;
