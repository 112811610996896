import { EmojiResource, EmojiResourceConfig } from '@atlaskit/emoji/resource';
import SmartCardClient from '@atlaskit/smart-card/dist/cjs/client';
import { ReactionServiceClient, ReactionsStore, MemoryReactionsStore } from '@atlaskit/reactions';
import { ProfileClient } from '@atlaskit/profilecard';

import { getConfig } from 'src/config';

import { ProfileCardClient } from './profilecardProvider';
import { MentionProvider } from './mentionProvider';

export { getMediaProviderForContext } from './mediaProvider';

export type Context = {
  objectUUID: string;
  workspaceUUID: string;
};

const baseEmojiConfig: EmojiResourceConfig = Object.freeze({
  providers: [
    {
      url: `${getConfig().emojiUrl}/standard`,
    },
    {
      url: `${getConfig().emojiUrl}/atlassian`,
    },
  ],
});

export const getEmojiConfig = (cloudId?: string): Readonly<EmojiResourceConfig> => {
  const config = baseEmojiConfig;

  if (cloudId) {
    return Object.freeze({
      ...config,
      providers: [
        ...config.providers,
        {
          url: `${getConfig().emojiUrl}/${cloudId}/site`,
        },
      ],
    });
  }

  return config;
};

const emojiCloudIdToResourceMap: Map<string | undefined, EmojiResource> = new Map<string, EmojiResource>();

export const getEmojiResource = async (cloudId?: string): Promise<EmojiResource> => {
  const existingResource = emojiCloudIdToResourceMap.get(cloudId);

  if (existingResource) {
    return existingResource;
  }

  const config = getEmojiConfig(cloudId);
  const emojiResource = new EmojiResource(config);

  emojiCloudIdToResourceMap.set(cloudId, emojiResource);

  return emojiResource;
};

export const getEmojiProvider = (cloudId?: string) =>
  //eslint-disable-next-line
  new Promise<any>(async (resolve) => {
    const emojiResource = await getEmojiResource(cloudId);

    emojiResource.fetchEmojiProvider().then(() => resolve(emojiResource));
  });

export const reactionClient = new ReactionServiceClient(getConfig().reactionsUrl);
export const reactionStore: ReactionsStore = new MemoryReactionsStore(reactionClient);

export const resolvedMentionProvider = new MentionProvider();
export const mentionProvider = Promise.resolve(resolvedMentionProvider);

export const profileClient = new ProfileClient({
  cacheMaxAge: 10000,
  cacheSize: 20,
  url: `${getConfig().directoryServiceUrl}/graphql`,
  teamCentralUrl: `${getConfig().graphqlUrl}`,
});

export const profilecardClient = new ProfileCardClient({ url: getConfig().profileCardUrl });
export const defaultProfilecardProvider = {
  resourceClient: profilecardClient,
  cloudId: 'none',
};
export const getProfilecardProvider = () => ({
  ...defaultProfilecardProvider,
  getActions(id: string) {
    return [
      {
        label: 'View profile',
        id: 'view-profile',
        callback() {
          window.open(`${getConfig().teamCentralUrl}/people/${id}`, '_blank', 'noopener, noreferrer');
        },
        link: `${getConfig().teamCentralUrl}/people/${id}`,
      },
    ];
  },
});

export const smartCardClient = new SmartCardClient();
