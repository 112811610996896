import { getConfig } from 'src/config';
import { PROGRAM_TAG_PREFIX } from 'src/constants';
import { Tag } from '@queries/GetProjectPageItems';
import { findUniqueInitiativeTag } from '@shared/utils/initiative';

export const getProgramLink = (programTag: Tag) =>
  `${window.location.origin}/program/${programTag.name.replace(PROGRAM_TAG_PREFIX, '')}`;

export const getProgramLinkByKey = (key: string) => `${window.location.origin}/program/${key}`;
export const getMilestoneLinkByKey = (key: string) => `${window.location.origin}/milestone/${key}`;

export const getInitiativeLink = (initiativeTags: Tag[], projectKey: string) => {
  const uniqueTag = findUniqueInitiativeTag(initiativeTags);

  if (uniqueTag) {
    const [, initiativeName] = uniqueTag.name.split('--');

    return `${window.location.origin}/initiative/${initiativeName}`;
  }

  return `${getConfig().teamCentralUrl}/project/${projectKey}/updates`;
};
