import { useCallback, Dispatch, SetStateAction } from 'react';
import { useRouterActions } from 'react-resource-router';

import { CreationDialog } from '@shared/CreationFlow/CreationDialog';
import { Entity } from 'src/graphql/types';
import { INDEPENDENT_INITIATIVE_TAG_PREFIX } from 'src/constants';
import { validateUniqueness } from '@shared/CreationFlow/utils/validation';
import { generateInitiativePageTql } from '@initiative/children/utils/initiative';
import { useWorkspaceStore } from 'src/store/workspaceStore';

type Props = {
  setInitialFocusRef: Dispatch<SetStateAction<HTMLElement | null>>;
  scheduleUpdate: () => void;
  handleBackToMainMenu: () => void;
  onClosePopup: () => void;
};

export const handleOnValidationMessage = (type: Entity) => {
  if (type === Entity.PROJECT) {
    return 'This ticket is defined as a project, so it can’t be added as an independent initiative';
  }

  if (type === Entity.INITIATIVE) {
    return 'This initiative already exists in Global State';
  }

  return 'This ticket is defined as a program, so it can’t be added as an independent initiative';
};

export const IndependentInitiativeCreation = ({
  setInitialFocusRef,
  scheduleUpdate,
  handleBackToMainMenu,
  onClosePopup,
}: Props) => {
  const { push } = useRouterActions();
  const [{ workspace }] = useWorkspaceStore();
  const { id: workspaceId } = workspace;

  const handleSubmitSuccess = useCallback(
    (tagName: string) => push(`/initiative/${tagName.replace(INDEPENDENT_INITIATIVE_TAG_PREFIX, '')}`),
    [push]
  );

  const handleValidateUniqueness = useCallback(
    (tagName: string): Promise<boolean | undefined> => {
      const { initiativesQ } = generateInitiativePageTql(tagName);

      return validateUniqueness({ query: initiativesQ, workspaceId });
    },
    [workspaceId]
  );

  return (
    <CreationDialog
      type={Entity.INITIATIVE}
      setInitialFocusRef={setInitialFocusRef}
      scheduleUpdate={scheduleUpdate}
      onSubmitSuccess={handleSubmitSuccess}
      validateUniqueness={handleValidateUniqueness}
      projectsListHeaderText="New Global State for independent initiative"
      handleBackToMainMenu={handleBackToMainMenu}
      onValidationMessage={handleOnValidationMessage}
      onClosePopup={onClosePopup}
    />
  );
};
