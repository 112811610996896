export const ENABLE_DEPARTMENTS_LIST_PAGE = 'departments-list.enable';
export const ENABLE_ASSETS = 'assets.enabled';
export const ENABLE_SETTINGS_PAGE = 'settings.enable';
export const ENABLE_XMAS_LOGO = 'xmas-logo.enabled';
export const ENABLE_EXPORT_DEPARTMENT_CSV = 'export-department-csv.enabled';
export const ENABLE_EXPORT_INITIATIVE_CSV = 'export-initiative-csv.enabled';
export const ENABLE_EXPORT_PROGRAM_CSV = 'export-program-csv.enabled';
export const ENABLE_DASHBOARD_VIEW = 'enable-dashboard-view.enabled';
export const ENABLE_HELPER = 'helper.enabled';
export const ENABLE_MILESTONES = 'milestones.enabled';
