import { N200, N30, N300 } from '@atlaskit/theme/colors';
import styled from 'styled-components';

import { ProjectUpdate } from '@queries/GetProjectPageItems';

import { FuzzyDate } from './FuzzyDate';
import { FieldChanged } from './ProjectUpdate/FieldChanged';

export const TargetDateUpdatedCard = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid ${N30};

  > p {
    font-size: 11px;
    color: ${N300};
    margin-right: 40px;
    font-weight: 600;
  }
`;

export const TargetDateUpdatedText = styled.p`
  font-size: 11px;
  color: ${N200};
  margin-bottom: 16px;

  > span {
    margin-right: 4px;
  }

  > a {
    margin-left: 4px;
  }
`;

type Props = {
  oldDate?: string | null;
  newDate?: string;
  issue?: ProjectUpdate['changelog'][0]['projectFusion']['issue'] | null;
  isMissedOrNewUpdate?: boolean;
};

export const TargetDateAutoUpdatedCard = ({ oldDate, newDate, issue, isMissedOrNewUpdate }: Props) => {
  if (!newDate || !oldDate || newDate === oldDate) {
    return null;
  }

  return (
    <div style={{ marginTop: '16px' }}>
      {isMissedOrNewUpdate && (
        <TargetDateUpdatedCard>
          <p>Date changed</p>
          <FieldChanged from={<FuzzyDate value={{ date: oldDate }} />} to={<FuzzyDate value={{ date: newDate }} />} />
        </TargetDateUpdatedCard>
      )}
      <TargetDateUpdatedText>
        <span>✨</span> Automatically updated based on linked Jira issue
        {issue && (
          <a href={issue.href} target="_blank" rel="noopener noreferrer">
            {issue.key} {issue.fields?.summary}
          </a>
        )}
      </TargetDateUpdatedText>
    </div>
  );
};
