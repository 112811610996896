import styled from 'styled-components';

import { GoalStateValue } from '@generated/graphql';
import { goalScoreToStatusEntity, getStatusColor, StatusColour } from '@tc/util/goalScoreToStatus';

const MiniLozengeWrapper = styled.span<{ palette: StatusColour }>`
  background: ${({ palette }) => palette.background};
  color: ${({ palette }) => palette.text};
  display: inline-flex;
  padding: 0 4px;
  border-radius: 3px;
  margin-left: 4px;
`;

type Props = {
  score: number | null | undefined;
  phase?: GoalStateValue | null;
};

export const MiniLozenge = ({ score, phase }: Props) => {
  const status = goalScoreToStatusEntity(score, phase);

  return <MiniLozengeWrapper palette={getStatusColor(status.name)}>{score === 1 ? '1.0' : score}</MiniLozengeWrapper>;
};
