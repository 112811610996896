import Badge from '@atlaskit/badge';
import { G400, Y400, R400, N0 } from '@atlaskit/theme/colors';
import styled from 'styled-components';
import Tooltip from '@atlaskit/tooltip';

import { SummaryItemType } from '@tc/WeeklySummary/WeeklySummary';

const TraficLightWrapper = styled.div`
  display: flex;
`;

export const BadgeWrapper = styled.span`
  margin-left: 12px;
  line-height: 1;

  > span {
    color: ${N0};
  }
`;

export const OnTrackBadge = styled(BadgeWrapper)`
  margin-left: 0;

  > span {
    background-color: ${G400};
  }
`;

export const OffTrackBadge = styled(BadgeWrapper)`
  > span {
    background-color: ${R400};
  }
`;

export const AtRiskBadge = styled(BadgeWrapper)`
  > span {
    background-color: ${Y400};
  }
`;

const StyledDashboardContainer = styled('div')<{ isDashboard?: boolean }>`
  display: ${({ isDashboard }) => (isDashboard ? 'flex' : 'block')};
  justify-content: flex-start;
`;

export type SummaryItemTypeToCountMap = {
  [key in SummaryItemType]?: number;
};

type Props = {
  summaryItemTypeToCountMap?: SummaryItemTypeToCountMap;
  isDashboard?: boolean;
  id?: string;
};

export const StatusSummaryBadges = ({ summaryItemTypeToCountMap = {}, isDashboard = false, id }: Props) => (
  <StyledDashboardContainer isDashboard={isDashboard}>
    <TraficLightWrapper data-testid={isDashboard ? `status-summary-badges-${id}` : 'status-summary-badges'}>
      <Tooltip content={`${summaryItemTypeToCountMap.onTrack ?? 0} on track`}>
        <OnTrackBadge data-testid="on-track-badge">
          <Badge>{summaryItemTypeToCountMap.onTrack ?? 0}</Badge>
        </OnTrackBadge>
      </Tooltip>
      <Tooltip content={`${summaryItemTypeToCountMap.atRisk ?? 0} at risk`}>
        <AtRiskBadge data-testid="at-risk-badge">
          <Badge>{summaryItemTypeToCountMap.atRisk ?? 0}</Badge>
        </AtRiskBadge>
      </Tooltip>
      <Tooltip content={`${summaryItemTypeToCountMap.offTrack ?? 0} off track`}>
        <OffTrackBadge data-testid="off-track-badge">
          <Badge>{summaryItemTypeToCountMap.offTrack ?? 0}</Badge>
        </OffTrackBadge>
      </Tooltip>
    </TraficLightWrapper>
  </StyledDashboardContainer>
);
