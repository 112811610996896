import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useFlags } from '@atlaskit/flag';
import { Y300, R400, N400 } from '@atlaskit/theme/colors';
import Form, { FormFooter } from '@atlaskit/form';
import ButtonGroup from '@atlaskit/button/button-group';
import Button from '@atlaskit/button/standard-button';
import EditorWarningIcon from '@atlaskit/icon/glyph/editor/warning';
import ArrowLeftIcon from '@atlaskit/icon/glyph/arrow-left';
import ErrorIcon from '@atlaskit/icon/glyph/error';

import { Entity, Tags } from 'src/graphql/types';
import { CREATE_TAG, SET_TAGS } from 'src/graphql/mutations/Tags';
import { CREATE_PROJECT_LINK } from 'src/graphql/mutations/ProjectLink';
import {
  CreateTagMutation,
  CreateTagMutationVariables,
  SetTagsMutation,
  SetTagsMutationVariables,
} from '@generated/Tags';
import { Project } from '@queries/SearchProjects';
import { INDEPENDENT_INITIATIVE_TAG_PREFIX, PROGRAM_TAG_PREFIX } from 'src/constants';
import { isActiveProject } from '@shared/utils/sidebar';
import { CreateProjectLinkMutation, CreateProjectLinkMutationVariables } from '@generated/ProjectLink';
import { Actions, Sources, useAnalytics } from 'src/hooks/useAnalytics';
import { useUserStore } from 'src/store/userStore';
import { isProjectContainTagName } from '@shared/utils/project';
import { useWorkspaceStore } from 'src/store/workspaceStore';
import { checkIfAtlasKey } from 'src/utils/router/parseRouter';

import { SelectedProjectField } from './SelectedProjectField';
import { ShortNameField } from './ShortNameField';
import { BackButtonWrapper, Header, HeaderText, ProjectForm, ProjectFormFirstFieldWrapper } from './styles';
import { LabelsField } from './LabelsField';
import { isProjectUnique, validateEntity } from './utils/validation';
import { generateTagName, sanitizeShortName } from './utils/shortName';

type Props = {
  type: Entity;
  project: Project;
  programNameTagName?: string;
  initiativeNameTagName?: string;
  onBackButtonClick: () => void;
  onSubmitSuccess: (tagName: string) => void;
  validateUniqueness?: (tagName: string) => Promise<boolean | undefined>;
  onValidationMessage: (type: Entity, project?: Project) => string | ReactNode;
  onClosePopup: () => void;
};

const typeToTagMap = {
  [Entity.PROGRAM]: Tags.program,
  [Entity.MILESTONE]: Tags.initiative,
  [Entity.INITIATIVE]: Tags.initiative,
  [Entity.PROJECT]: Tags.project,
};

const entityToSourceMap = {
  [Entity.PROGRAM]: Sources.PROGRAM_LIST_PAGE,
  [Entity.MILESTONE]: Sources.MILESTONE_PAGE,
  [Entity.INITIATIVE]: Sources.PROGRAM_PAGE,
  [Entity.PROJECT]: Sources.INITIATIVE_PAGE,
};

export const getEntityTagPrefix = (type: Entity) => {
  if (type === Entity.PROGRAM) {
    return PROGRAM_TAG_PREFIX;
  } else if (type === Entity.INITIATIVE || type === Entity.PROJECT) {
    return INDEPENDENT_INITIATIVE_TAG_PREFIX;
  }
};

export const CreationForm = ({
  type,
  project,
  programNameTagName,
  initiativeNameTagName,
  onBackButtonClick,
  onSubmitSuccess,
  validateUniqueness,
  onValidationMessage,
  onClosePopup,
}: Props) => {
  const { showFlag } = useFlags();
  const { sendTrackEvent } = useAnalytics();
  const isProgramCreation = type === Entity.PROGRAM;
  const isProjectCreation = type === Entity.PROJECT;
  const isInitiativeCreation = type === Entity.INITIATIVE;

  const isDirectProjectCreation = isProjectCreation && programNameTagName && !initiativeNameTagName;
  const isIndependentInitiativeCreation = isInitiativeCreation && !programNameTagName;
  const sizeTagName = useMemo(() => typeToTagMap[type], [type]);
  const tagPrefix = useMemo(() => getEntityTagPrefix(type), [type]);
  const labelDefaultValue = isProjectCreation
    ? (initiativeNameTagName || programNameTagName)!
    : generateTagName(sanitizeShortName(project.name), tagPrefix!);

  const labelDefaultValueNL = isProjectCreation ? '' : programNameTagName!;
  const initiativeTagName = generateTagName(sanitizeShortName(project.name), tagPrefix!)!;
  const initiativeNameNL = isProjectContainTagName(project) ? '' : initiativeTagName;
  const [createTag] = useMutation<CreateTagMutation, CreateTagMutationVariables>(CREATE_TAG);
  const [setTags] = useMutation<SetTagsMutation, SetTagsMutationVariables>(SET_TAGS);
  const [createRelatedLink] = useMutation<CreateProjectLinkMutation, CreateProjectLinkMutationVariables>(
    CREATE_PROJECT_LINK
  );

  const mountedRef = useRef(true);
  const [{ user }] = useUserStore();
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [{ workspace }] = useWorkspaceStore();
  const { id: workspaceId } = workspace;

  const { projectError, link, unsuitableTags, shouldShowSizeTag, initiativeTags } = useMemo(
    () =>
      validateEntity(
        type,
        project,
        user.account_id,
        {
          programNameTagName,
          initiativeNameTagName,
        },
        onValidationMessage
      ),
    [type, project, programNameTagName, initiativeNameTagName, user, onValidationMessage]
  );

  const validation = useCallback(async (): Promise<void> => {
    if (!mountedRef.current) {
      return;
    }

    setIsValid(false);
    setIsLoading(true);

    const { isUniqueProject, loading } = await isProjectUnique(programNameTagName || '', initiativeTags, workspaceId);

    if (!isUniqueProject) {
      setIsValid(false);
      setIsLoading(loading);

      return;
    }

    setIsValid(true);
    setIsLoading(false);
  }, [initiativeTags, programNameTagName, workspaceId]);

  useEffect(() => {
    if (type === Entity.PROJECT) {
      validation().then(() => {});
    }

    return () => {
      mountedRef.current = false;
    };
  }, [type, validation]);

  const createLink = useCallback(
    (label: string) => {
      const isAtlasKey = checkIfAtlasKey(label);
      const link = isAtlasKey ? label : label.split('--').splice(1).join('/');
      const baseUrl = window.location.origin;

      return createRelatedLink({
        variables: {
          url: isIndependentInitiativeCreation ? `${baseUrl}/initiative/${link}` : `${baseUrl}/program/${link}`,
          projectId: project.id,
          iconUrl: `${baseUrl}/gs-link-icon.png`,
          name: `${type[0].toUpperCase()}${type.slice(1)}: ${project.name}`,
        },
      });
    },
    [createRelatedLink, isIndependentInitiativeCreation, project.id, project.name, type]
  );

  const createEntityTags = useCallback(
    async (name: string): Promise<string[]> => {
      const workspaceId = project.workspace.id;

      const tagUuids = await Promise.all([
        ...(!isProjectCreation && name
          ? [createTag({ variables: { name, workspaceId } }).then(({ data }) => data?.createTag.tag.uuid)]
          : []),
        ...(isInitiativeCreation && !isIndependentInitiativeCreation
          ? [
              createTag({ variables: { name: programNameTagName!, workspaceId } }).then(
                ({ data }) => data?.createTag.tag.uuid
              ),
            ]
          : []),
        ...(isProjectCreation && name
          ? [createTag({ variables: { name, workspaceId } }).then(({ data }) => data?.createTag.tag.uuid)]
          : []),
        ...(shouldShowSizeTag
          ? [createTag({ variables: { name: sizeTagName, workspaceId } }).then(({ data }) => data?.createTag.tag.uuid)]
          : []),
      ]);

      return tagUuids.filter(Boolean);
    },
    [
      project.workspace.id,
      isProjectCreation,
      createTag,
      isInitiativeCreation,
      programNameTagName,
      shouldShowSizeTag,
      sizeTagName,
      isIndependentInitiativeCreation,
    ]
  );

  const assignTagsToEntity = useCallback(
    (tagUuids: string[]) => {
      const projectTagUuids = project.tags.edges.map(({ node: { uuid } }) => uuid);
      const uniqueUuids = Array.from(new Set([...projectTagUuids, ...tagUuids]));

      return setTags({ variables: { id: project.id, tagUuids: uniqueUuids } });
    },
    [project.tags.edges, project.id, setTags]
  );

  const showInactiveProjectWarning = useCallback(() => {
    showFlag({
      title: 'Added ticket is in inactive phase',
      ...(isDirectProjectCreation
        ? { description: "You can't see direct project" }
        : { description: 'It’s displayed in sidebar inside corresponding section' }),
      icon: <EditorWarningIcon label="Info" primaryColor={Y300} />,
      isAutoDismiss: true,
    });
  }, [isDirectProjectCreation, showFlag]);

  const showFetchError = useCallback(
    async (cb: () => Promise<void>, description: string, action: string): Promise<void> => {
      onClosePopup();
      const dismissFlag = showFlag({
        title: 'Request error',
        icon: <ErrorIcon label="Error" primaryColor={R400} />,
        description,
        isAutoDismiss: true,
        actions: [
          {
            content: <Button appearance="link">{action}</Button>,
            onClick: async () => {
              dismissFlag();
              await cb();
            },
          },
        ],
      });
    },
    [showFlag, onClosePopup]
  );

  const handleSubmit = useCallback(
    async ({ label: name }: { label: string }) => {
      try {
        const tagUuids = await createEntityTags(name);
        const entityID = isProgramCreation ? (project.key as string) : name;

        if (!isProjectCreation) {
          await createLink(entityID);
        }
        await assignTagsToEntity(tagUuids);
        onSubmitSuccess(entityID);

        if (!isProgramCreation && !isIndependentInitiativeCreation && !isActiveProject(project)) {
          showInactiveProjectWarning();
        }

        sendTrackEvent({
          action: Actions.CREATED,
          actionSubject: type,
          source: isDirectProjectCreation ? Sources.PROGRAM_PAGE : entityToSourceMap[type],
          attributes: {
            name: project.name,
          },
        });

        onClosePopup();
      } catch (err) {
        await showFetchError(
          handleSubmit.bind(null, { label: name }),
          `Something went wrong with this server request, ${type} was not created.`,
          'Click to try again.'
        );

        sendTrackEvent({
          action: Actions.ERROR,
          actionSubject: type,
          source: isDirectProjectCreation ? Sources.PROGRAM_PAGE : entityToSourceMap[type],
          attributes: {
            errorMessage: (err as { message: string }).message,
          },
        });
      }
    },
    [
      createEntityTags,
      isProjectCreation,
      assignTagsToEntity,
      onSubmitSuccess,
      isIndependentInitiativeCreation,
      isProgramCreation,
      project,
      sendTrackEvent,
      type,
      isDirectProjectCreation,
      onClosePopup,
      createLink,
      showInactiveProjectWarning,
      showFetchError,
    ]
  );
  const isDisabled = useMemo(() => !!projectError || !isValid || isLoading, [isValid, isLoading, projectError]);

  const isNoError = useMemo(() => !projectError && isValid && !isLoading, [isLoading, isValid, projectError]);

  const validationErrorMessage = useMemo(() => (isDisabled ? onValidationMessage(Entity.PROJECT, project) : ''), [
    isDisabled,
    onValidationMessage,
    project,
  ]);

  return (
    <>
      <Header>
        <BackButtonWrapper onClick={onBackButtonClick}>
          <ArrowLeftIcon label="back-button" primaryColor={N400} />
        </BackButtonWrapper>

        <HeaderText>Edit label</HeaderText>
      </Header>
      <Form onSubmit={handleSubmit}>
        {({ formProps: { ref, ...formProps }, setFieldValue }) => (
          <ProjectForm innerRef={ref} {...formProps}>
            <ProjectFormFirstFieldWrapper>
              <SelectedProjectField
                project={project}
                error={projectError}
                link={link}
                unsuitableTags={unsuitableTags}
                validationErrorMessage={validationErrorMessage}
                isLoading={isLoading}
                isValid={isValid}
              />
            </ProjectFormFirstFieldWrapper>

            {!projectError && !isProjectCreation && validateUniqueness && !isProjectContainTagName(project) && (
              <ShortNameField
                defaultValue={project.name}
                tagPrefix={tagPrefix!}
                setFieldValue={setFieldValue}
                validateUniqueness={validateUniqueness}
              />
            )}
            {isNoError && (
              <LabelsField
                defaultValue={!isProjectCreation ? initiativeNameNL : labelDefaultValue}
                programName={!isProjectCreation ? labelDefaultValueNL : undefined}
                sizeTagName={sizeTagName}
                shouldShowSizeTag={shouldShowSizeTag}
              />
            )}

            <FormFooter>
              <ButtonGroup>
                <Button appearance="subtle" onClick={onClosePopup}>
                  Cancel
                </Button>
                <Button
                  className="js-creation-form-submit-button"
                  data-testId="js-creation-form-submit-button"
                  type="submit"
                  appearance="primary"
                  isDisabled={isDisabled}
                >
                  Add Global State
                </Button>
              </ButtonGroup>
            </FormFooter>
          </ProjectForm>
        )}
      </Form>
    </>
  );
};
