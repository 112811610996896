import { gql } from '@apollo/client';

export const FIND_PROJECTS_BY_TAG = gql`
  query FindProjectsByTag($q: String!, $workspaceId: ID) {
    projectTql(q: $q, workspaceId: $workspaceId) {
      edges {
        node {
          tags {
            edges {
              node {
                name
                id
                uuid
              }
            }
          }
        }
      }
    }
  }
`;
