import { N400 } from '@atlaskit/theme/colors';
import styled from 'styled-components';

export const CreationDialogContainer = styled.div`
  box-sizing: border-box;
  width: 320px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  line-height: 2;
`;

export const ProjectName = styled.span`
  margin-left: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 20px;
`;

export const ProjectForm = styled.form`
  padding: 0 20px 20px 20px;
`;

export const ProjectFormFirstFieldWrapper = styled.div`
  > div:first-child {
    margin: 0;
  }
`;

export const SelectedProject = styled.div`
  display: flex;
  align-items: center;
  margin: 19px 0 24px 0;
`;

export const Header = styled.div`
  display: flex;
  padding: 20px 20px 4px 20px;
`;

export const HeaderText = styled.span`
  color: ${N400};
  font-size: 16px;
  line-height: 20px;
`;

export const BackButtonWrapper = styled.div`
  margin-right: 10px;
  position: relative;
  bottom: 2px;
  cursor: pointer;
`;
