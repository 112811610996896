import { ChangeEvent, useCallback } from 'react';
import { Field, ErrorMessage, ValidMessage } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';

import { generateTagName, sanitizeShortName } from './utils/shortName';

type Props = {
  defaultValue: string;
  tagPrefix: string;
  setFieldValue: (name: string, value: string) => void;
  validateUniqueness?: (tagName: string) => Promise<boolean | undefined>;
};

enum ValidationError {
  EMPTY = 'EMPTY',
  UNIQUE = 'UNIQUE',
  LONG = 'LONG',
}

const switchSubmitButtonDisabled = (error?: string) => {
  const submitButton = document.querySelector<HTMLButtonElement>('.js-creation-form-submit-button');

  if (error && submitButton) {
    submitButton.disabled = true;
  }

  if (!error && submitButton) {
    submitButton.disabled = false;
  }
};

const getTagPrefixWithoutLastDash = (tagPrefix: string) => tagPrefix.slice(0, -1);

export const ShortNameField = ({ defaultValue, tagPrefix, setFieldValue, validateUniqueness }: Props) => {
  const validation = useCallback(
    async (value: string | undefined) => {
      if (!value) {
        return ValidationError.EMPTY;
      }

      if (value && validateUniqueness) {
        const sanitizedValue = sanitizeShortName(value);
        const tagName = generateTagName(sanitizedValue, tagPrefix);

        if (tagName.length >= 255) {
          return ValidationError.LONG;
        }

        const hasTheSameProject = await validateUniqueness(tagName);

        if (hasTheSameProject) {
          return ValidationError.UNIQUE;
        }
      }
    },
    [tagPrefix, validateUniqueness]
  );

  return (
    <Field
      name="shortname"
      defaultValue={sanitizeShortName(defaultValue)}
      label="Shorten label name if needed"
      validate={validation}
    >
      {({ fieldProps: { isRequired, isDisabled, onChange, ...others }, error, valid }) => {
        switchSubmitButtonDisabled(error);

        return (
          <>
            <TextField
              isDisabled={isDisabled}
              required={isRequired}
              autoFocus={true}
              {...others}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const sanitizedValue = sanitizeShortName(e.target.value);
                const tagName = generateTagName(sanitizedValue, tagPrefix);
                const tagPrefixWithoutLastDash = getTagPrefixWithoutLastDash(tagPrefix);
                const isTagValid = new RegExp(`${tagPrefixWithoutLastDash}(-[a-z0-9]+)+$`, 'i').test(tagName);

                onChange(isTagValid ? sanitizedValue : '');
                setFieldValue('label', isTagValid ? tagName : tagPrefix);
              }}
            />
            {valid && !error && <ValidMessage>You can use this name for label</ValidMessage>}
            {error === ValidationError.EMPTY && !valid && <ErrorMessage>Required</ErrorMessage>}
            {error === ValidationError.UNIQUE && !valid && <ErrorMessage>This label exists</ErrorMessage>}
            {error === ValidationError.LONG && <ErrorMessage>This label longer than 255 characters</ErrorMessage>}
          </>
        );
      }}
    </Field>
  );
};
