import Tooltip from '@atlaskit/tooltip';
import { formatDistance } from 'date-fns';

import { endOfWeek, startOfWeek, tzDate } from '@tc/util/date';
import { formatFullNiceDate, formatWeekRange } from '@tc/util/dateFormat';
import { wasMissedUpdate } from '@tc/util/truthUtil';

import { MissedUpdateCardTop } from './styles';
import { ProjectUpdateCardProps } from './';

export const MissedUpdateLabel = (props: ProjectUpdateCardProps) => {
  const { update, project } = props;
  const { latestUpdateDate } = project;

  // Not a missed update. Skip.
  if (!update.missedUpdate) {
    return null;
  }

  const creationDate = tzDate(update.creationDate);

  // Has the user added an update to this missed update?
  if (wasMissedUpdate(update)) {
    const weekRange = formatWeekRange(startOfWeek(creationDate), endOfWeek(creationDate));

    return <MissedUpdateCardTop showDot={true}>Update for {weekRange.toLocaleLowerCase()}</MissedUpdateCardTop>;
  } else {
    return (
      <MissedUpdateCardTop data-testid="missed-update-card" showDot={true}>
        {formatFullNiceDate(creationDate, true)}
        {latestUpdateDate && creationDate > tzDate(latestUpdateDate) && (
          <Tooltip content={`Last updated ${formatDistance(creationDate, tzDate(latestUpdateDate))} ago`}>
            <span>
              &nbsp;
              {`(Last updated ${formatDistance(creationDate, tzDate(latestUpdateDate))} ago)`}
            </span>
          </Tooltip>
        )}
      </MissedUpdateCardTop>
    );
  }
};
