import { G400, N300, R300, Y400, B300 } from '@atlaskit/theme/colors';
import { useRef, RefObject } from 'react';
import styled from 'styled-components';
import { useQueryParam } from 'react-resource-router';

import { GqlStatusType, Status } from 'src/graphql/types';
import { Card } from '@tc/Card/styles';
import { isThisWeek, tzDate } from '@tc/util/date';
import { StatusToStatusChangeCounterMapType } from 'src/pages/department/children/utils/weeklySummary';

import { Item, ItemProps } from './Item';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  margin-left: -5px;
  margin-right: -5px;
  padding: 0;

  ${Card} {
    margin: 5px;
  }

  flex-flow: wrap;
`;

export enum SummaryItemType {
  ALL = 'active',
  ON_TRACK = 'onTrack',
  AT_RISK = 'atRisk',
  OFF_TRACK = 'offTrack',
  NO_UPDATE = 'missed',
  DONE = 'done',
  PENDING = 'pending',
  PAUSED = 'paused',
  CANCELLED = 'cancelled',
}

export const statusCamelCaseToStatusSnakeCaseMap: {
  [key in Exclude<SummaryItemType, SummaryItemType.ALL>]: Status;
} = {
  [SummaryItemType.ON_TRACK]: Status.ON_TRACK,
  [SummaryItemType.AT_RISK]: Status.AT_RISK,
  [SummaryItemType.OFF_TRACK]: Status.OFF_TRACK,
  [SummaryItemType.DONE]: Status.DONE,
  [SummaryItemType.NO_UPDATE]: Status.NO_UPDATE,
  [SummaryItemType.PENDING]: Status.PENDING,
  [SummaryItemType.PAUSED]: Status.PAUSED,
  [SummaryItemType.CANCELLED]: Status.CANCELLED,
};

export type Summary = {
  [key in Exclude<SummaryItemType, SummaryItemType.ALL>]: number;
};

export const summaryItemTypeToStatusMap: {
  [key in SummaryItemType]?: GqlStatusType;
} = {
  [SummaryItemType.ON_TRACK]: 'on_track',
  [SummaryItemType.AT_RISK]: 'at_risk',
  [SummaryItemType.OFF_TRACK]: 'off_track',
};

export interface SummaryItem {
  title: string;
  key: keyof Summary;
  color: string;
  overrideItemProps?: (
    itemProps: ItemProps,
    summaryProps: Props,
    meta?: { ref?: RefObject<HTMLDivElement | undefined> }
  ) => ItemProps;
}

export const summaryItems: SummaryItem[] = [
  {
    title: 'On track',
    key: SummaryItemType.ON_TRACK,
    color: G400,
  },
  {
    title: 'At risk',
    key: SummaryItemType.AT_RISK,
    color: Y400,
  },
  {
    title: 'Off track',
    key: SummaryItemType.OFF_TRACK,
    color: R300,
  },
  {
    title: 'No update',
    key: SummaryItemType.NO_UPDATE,
    color: N300,
    overrideItemProps: (itemProps, summaryProps) =>
      isThisWeek(tzDate(summaryProps.startDate))
        ? {
            ...itemProps,
            title: 'Awaiting update',
            previousValue: undefined,
          }
        : itemProps,
  },
  {
    title: 'Completed 🎉',
    key: SummaryItemType.DONE,
    color: B300,
  },
];

export const summaryStatuses: SummaryItem[] = [
  {
    title: 'Pending',
    key: SummaryItemType.PENDING,
    color: N300,
  },
  {
    title: 'Paused',
    key: SummaryItemType.PAUSED,
    color: N300,
  },
  {
    title: 'Cancelled',
    key: SummaryItemType.CANCELLED,
    color: N300,
  },
];

type Props = {
  onClick?: (item: keyof Summary) => void;
  startDate: string;
  statusToStatusChangeCounterMapType: StatusToStatusChangeCounterMapType;
  isClickable?: boolean;
  hideNewStatuses?: boolean;
  className?: string;
};

export const WeeklySummary = (props: Props) => {
  const [statusPhase] = useQueryParam('status_phase');
  const parsedStatusPhase = statusPhase ? decodeURIComponent(statusPhase).split(',') : [];

  const { onClick = () => {}, statusToStatusChangeCounterMapType, className } = props;
  const containerRef = useRef<HTMLDivElement>();

  return (
    <Container className={className} data-testid="goals-monthly-summary-bar">
      {[...summaryItems, ...(props.hideNewStatuses ? [] : summaryStatuses)].map((item) => {
        const snakeCaseValue = statusCamelCaseToStatusSnakeCaseMap[item.key];

        const itemProps: ItemProps = {
          title: item.title,
          statusChangeSummary: statusToStatusChangeCounterMapType[snakeCaseValue],
          color: item.color,
          id: item.key,
          onClick: () => onClick(item.key),
          isClickable: props.isClickable,
          isTransparent: !parsedStatusPhase.includes(snakeCaseValue) && !!parsedStatusPhase.length,
        };

        return (
          <Item
            hideNewStatuses={props.hideNewStatuses}
            key={item.key}
            {...(item.overrideItemProps
              ? item.overrideItemProps(itemProps, props, {
                  ref: containerRef,
                })
              : itemProps)}
          />
        );
      })}
    </Container>
  );
};
