import { useCallback, useState } from 'react';
import { ContentProps } from '@atlaskit/popup';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';

import { Actions, Sources, useAnalytics } from 'src/hooks/useAnalytics';

import { CreateInitiativeIcon } from './CreateInitiativeIcon';
import { CreateProgramIcon } from './CreateProgramIcon';
import { ProgramCreation } from './ProgramCreation';
import { IndependentInitiativeCreation } from './IndependentInitiativeCreation';

enum MenuScreens {
  MENU = 'menu',
  CREATE_PROGRAM = 'create-program',
  CREATE_INDEPENDENT_INITIATIVE = 'create-independent-initiative',
}

export const PopupContent = ({ update, setInitialFocusRef, onClose }: ContentProps) => {
  const [screen, setScreen] = useState<MenuScreens>(MenuScreens.MENU);

  const { sendUIEvent } = useAnalytics();

  const handleBackToMainMenu = useCallback(() => setScreen(MenuScreens.MENU), []);

  const handleSwitchToProgramCreation = useCallback(() => {
    setScreen(MenuScreens.CREATE_PROGRAM);

    sendUIEvent({
      action: Actions.CLICKED,
      actionSubject: 'button',
      actionSubjectId: 'createProgram',
      source: Sources.NAVBAR,
    });
  }, [sendUIEvent]);

  const handleSwitchToInitiativeCreation = useCallback(() => {
    setScreen(MenuScreens.CREATE_INDEPENDENT_INITIATIVE);

    sendUIEvent({
      action: Actions.CLICKED,
      actionSubject: 'button',
      actionSubjectId: 'createInitiative',
      source: Sources.NAVBAR,
    });
  }, [sendUIEvent]);

  return (
    <>
      {screen === MenuScreens.MENU && (
        <MenuGroup maxWidth={320} testId="menu-items">
          <Section>
            <ButtonItem
              iconBefore={<CreateProgramIcon />}
              testId="new-program-button"
              description={<p>New global state view of your program</p>}
              onClick={handleSwitchToProgramCreation}
            >
              Program
            </ButtonItem>
            <ButtonItem
              iconBefore={<CreateInitiativeIcon />}
              testId="new-initiative-button"
              description={
                <p>
                  New global state view of your
                  <br />
                  independent initiative
                </p>
              }
              onClick={handleSwitchToInitiativeCreation}
            >
              Initiative (independent)
            </ButtonItem>
          </Section>
        </MenuGroup>
      )}

      {screen === MenuScreens.CREATE_PROGRAM && (
        <ProgramCreation
          scheduleUpdate={update}
          setInitialFocusRef={setInitialFocusRef}
          handleBackToMainMenu={handleBackToMainMenu}
          onClosePopup={(onClose! as unknown) as () => void}
        />
      )}

      {screen === MenuScreens.CREATE_INDEPENDENT_INITIATIVE && (
        <IndependentInitiativeCreation
          scheduleUpdate={update}
          setInitialFocusRef={setInitialFocusRef}
          handleBackToMainMenu={handleBackToMainMenu}
          onClosePopup={(onClose! as unknown) as () => void}
        />
      )}
    </>
  );
};
