import { RefObject, useCallback, useState } from 'react';
import Popup, { TriggerProps } from '@atlaskit/popup';
import { B500, N0 } from '@atlaskit/theme/colors';
import styled from 'styled-components';
import { PrimaryDropdownButton } from '@atlaskit/atlassian-navigation';

import { responsiveContentWidthThreshold } from '@tc/ResponsiveGrid';

import { PopupContent } from './PopupContent';

export const NewDropdown = styled(PrimaryDropdownButton)`
  padding: 0px 8px !important;
  background-color: ${B500} !important;
  color: ${N0} !important;

  > span:first-of-type {
    margin-right: 8px !important;
  }

  &:hover,
  &:focus,
  &[aria-expanded='true'] {
    background-color: rgba(0, 82, 204, 0.9) !important;
    color: ${N0} !important;
  }

  &:focus {
    background-color: ${B500} !important;
    color: ${N0} !important;
  }

  @media (max-width: ${responsiveContentWidthThreshold}px) {
    display: none !important;
  }
`;

export const CreateButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const renderPopupContent = useCallback((props) => <PopupContent {...props} />, []);
  const handleOpenDrpdown = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const handleCloseDropdown = useCallback(() => setIsOpen(false), []);

  const triggerFunction = useCallback(
    ({ ref, ...rest }: TriggerProps) => (
      <div ref={ref as RefObject<HTMLDivElement>}>
        <NewDropdown {...rest} aria-label="Toggle new menu" testId="create-button" onClick={handleOpenDrpdown}>
          Create
        </NewDropdown>
      </div>
    ),
    [handleOpenDrpdown]
  );

  return (
    <Popup
      placement="bottom-start"
      content={renderPopupContent}
      isOpen={isOpen}
      onClose={handleCloseDropdown}
      trigger={triggerFunction}
      testId="create-menu"
    />
  );
};
