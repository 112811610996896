import Loadable from 'react-loadable';
import { RouteContext } from 'react-resource-router';

import { LoadableErrorHandler } from 'src/components/shared/LoadableErrorHandler';

export const UnderConstructionStaticPage = Loadable<RouteContext, never>({
  loader: () =>
    import(/* webpackChunkName: "UnderConstructionStaticPage" */ './UnderConstructionStatic').then(
      ({ UnderConstructionStatic }) => UnderConstructionStatic
    ),
  loading: LoadableErrorHandler,
});
