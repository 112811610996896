import Loadable from 'react-loadable';
import { RouteContext } from 'react-resource-router';

import { LoadableErrorHandler } from '@shared/LoadableErrorHandler';

export const ProgramPageEmbed = Loadable<RouteContext, never>({
  loader: () =>
    import(/* webpackChunkName: "ProgramPageEmbed" */ './ProgramPageEmbed').then(
      ({ ProgramPageEmbed }) => ProgramPageEmbed
    ),
  loading: LoadableErrorHandler,
});
