import styled, { css } from 'styled-components';

export type Props = {
  /** CSS color to be applied to the non-gradient icon portion of the logo SVG */
  iconColor?: string;
  /** CSS color to start the gradient/shadow on the icon */
  iconGradientStart?: string;
  /** CSS color to end the gradient/shadow on the icon. Should usually match iconColor to avoid rendering issues in some browsers such as Safari. */
  iconGradientStop?: string;
  /** Accessible text to be used for screen readers (it's optional since DefaultProps contains empty string for it) */
  label?: string;
  /** The size of the icon, uses the same sizing scheme as in @atlaskit/icon */
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  /** CSS color to be applied to the wordmark portion of the logo SVG */
  textColor?: string;
};

export type WrapperProps = Props & {
  svg: string | ((a: string, b: string) => string);
};

export const sizes: { [key: string]: number } = {
  xsmall: 16,
  small: 24,
  medium: 32,
  large: 40,
  xlarge: 48,
};

export const defaultProps: Partial<Props> = {
  iconColor: 'inherit',
  iconGradientStart: 'inherit',
  iconGradientStop: 'inherit',
  label: '',
  size: 'medium',
  textColor: 'currentColor',
};

type SpanProps = Partial<WrapperProps>;

const Span = styled.span<SpanProps>`
  color: ${(p) => p.iconColor};
  display: inline-block;
  fill: ${(p) => p.textColor};
  height: ${(p) => p.size && sizes[p.size]}px;
  position: relative;
  user-select: none;

  > svg {
    fill: inherit;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  > canvas {
    display: block;
    height: 100%;
    visibility: hidden;
  }
  ${(p) =>
    /* Only apply this if our stop-colors are inherit, if they aren't we don't need to set stop-color via css */
    p.iconGradientStart === 'inherit' &&
    p.iconGradientStop === 'inherit' &&
    css`
      /* Stop-color doesn't properly apply in chrome when the inherited/current color changes.
      * We have to initially set stop-color to inherit (either via DOM attribute or an initial CSS
      * rule) and then override it with currentColor for the color changes to be picked up.
      */
      stop {
        stop-color: currentColor;
      }
    `};
`;

export const Wrapper = ({ label, svg, ...rest }: WrapperProps) => (
  <Span
    // We want to not add the aria-label if it does not exist for consistency
    aria-label={label ? label : undefined}
    dangerouslySetInnerHTML={{
      __html: typeof svg === 'function' ? svg(String(rest.iconGradientStart), String(rest.iconGradientStop)) : svg,
    }}
    {...rest}
  />
);
