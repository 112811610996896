import styled from 'styled-components';
import { N20 } from '@atlaskit/theme/colors';

import { Divider } from '@tc/Divider';
import { StatusCheckbox } from '@shared/StatusOptions/StatusCheckbox';

const Wrapper = styled.div``;

const CheckboxContainer = styled.div`
  padding: 23px 0 0 0;
  display: flex;
  align-content: space-between;
`;

const StyledDivider = styled(Divider)`
  height: 1px;
  margin: 20px -20px 0;
  background-color: ${N20};
`;

export const Statuses = {
  STARTING: 'starting',
  ACTIVE_DURING: 'active_during',
  ENDING: 'ending',
};

export const StatusLabels = {
  [Statuses.STARTING]: 'Starting',
  [Statuses.ACTIVE_DURING]: 'Active during',
  [Statuses.ENDING]: 'Ending',
};

export enum STATUS_ORDER {
  active_during = 1,
  starting = 2,
  ending = 3,
}

export type StatusKeys = keyof typeof STATUS_ORDER;

type Props = {
  onSelectStatus: (status: string) => void;
  statuses: string[];
  confidence: number;
};

export const DEFAULT_STATUS = Statuses.ENDING;

const options = [
  { value: Statuses.STARTING, label: StatusLabels[Statuses.STARTING] },
  {
    value: Statuses.ACTIVE_DURING,
    label: StatusLabels[Statuses.ACTIVE_DURING],
  },
  { value: Statuses.ENDING, label: StatusLabels[Statuses.ENDING] },
];

export const StatusOptions = ({ onSelectStatus, confidence, statuses }: Props) => (
  <Wrapper>
    <CheckboxContainer>
      {options.map((item) => (
        <StatusCheckbox
          key={item.value}
          value={item.value}
          label={item.label}
          onSelectStatus={onSelectStatus}
          confidence={confidence}
          statuses={statuses}
        />
      ))}
    </CheckboxContainer>
    <StyledDivider />
  </Wrapper>
);
