import { Project, State } from '@queries/GetProjectPageItems';
import { isActive } from '@tc/Filters/MetaFilter/util';

import { isActiveUser } from './filter';

export const isActiveProject = ({ state }: { state?: State }): Boolean => isActive(state ? state.value : '');

export const filterNotActiveProjects = <T extends Pick<Project, 'state'>>(projects: T[]) =>
  projects.filter(isActiveProject);

export const filterActiveProjects = <T extends Project>(projects: T[]): T[] =>
  projects.filter((project) => !isActiveProject(project));

export const countContributors = (projects: Project[]) =>
  new Set(projects.flatMap(({ members }) => members.edges.filter(isActiveUser).map(({ node: { id } }) => id))).size;
