import Lozenge from '@atlaskit/lozenge';
import Tooltip from '@atlaskit/tooltip';

import { Status as GqlStatusGenerated, GoalState } from '@generated/graphql';
import { GqlStatusType } from 'src/graphql/types';
import { State } from '@queries/GetProjectPageItems';

import { MiniLozenge } from './MiniLozenge';
import { LozengeWrapper, goalStateSanitizer } from './PhaseLozenge';

export type Status = Pick<GqlStatusGenerated, 'displayName' | 'name' | 'id'>;
export type StatusType = GqlStatusType | string | null | undefined;

const getLozengeAppearance = (statusType: StatusType) => {
  switch (statusType) {
    case 'at_risk':
      return 'moved';
    case 'off_track':
      return 'removed';
    case 'on_track':
      return 'success';
    default:
      return 'default';
  }
};

interface Props {
  state: State | GoalState;
  score?: number | null;
  isDashboard?: boolean;
  isGroup?: boolean;
  isGoal?: boolean;
}

export const StatusLozenge = ({ state, score, isDashboard, isGroup, isGoal }: Props) => (
  <Tooltip content={state.label} position="bottom-start">
    <LozengeWrapper isGroup={isGroup}>
      <Lozenge appearance={getLozengeAppearance(state.value)} maxWidth={isDashboard ? 75 : 'none'}>
        {isGoal && score ? goalStateSanitizer(state.label) : state.label}{' '}
        {typeof score === 'number' && <MiniLozenge score={score} />}
      </Lozenge>
    </LozengeWrapper>
  </Tooltip>
);
