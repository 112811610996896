import { B50, B200, N0, N20 } from '@atlaskit/theme/colors';
import styled from 'styled-components';

export const Tag = styled.div<{ isEmpty: boolean; isEmbed?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 4px 2px 8px;
  margin-top: 16px;
  border-radius: 16px;
  background-color: ${({ isEmpty }) => (isEmpty ? N20 : B50)};
  cursor: ${({ isEmpty, isEmbed }) => (isEmpty || isEmbed ? 'default' : 'pointer')};
  margin-right: 2px;
`;

export const Badge = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  padding: 0 6px;
  margin-left: 4px;
  border-radius: 8px;
  background-color: ${B200};
  font-size: 12px;
  line-height: 1.5;
  color: ${N0};
`;

type TawItemProps = {
  name: string;
  count: number;
  isEmpty: boolean;
  onClick?: () => void;
  className?: string;
  isEmbed?: boolean;
};

export const TawTag = ({ name, count, isEmpty, onClick, className, isEmbed }: TawItemProps) => (
  <Tag isEmpty={isEmpty} isEmbed={isEmbed} onClick={onClick} className={className} data-testid={`taw-phase-${name}`}>
    {name}
    <Badge>{count}</Badge>
  </Tag>
);
