import FeatureFlagClient, {
  EnvironmentType,
  GetValueOptions,
  Identifiers,
  SupportedFlagTypes,
} from '@atlassiansox/feature-flag-web-client';
import { useLayoutEffect, useState, ReactNode, createContext, useContext, useMemo } from 'react';

import { getEnv, Env } from 'src/config';
import { analyticsClient } from 'src/clients/analyticsClient';
import { useUserStore } from 'src/store/userStore';
import { useWorkspaceStore } from 'src/store/workspaceStore';

type FeatureFlagProviderType = {
  children: ReactNode;
};

const env = getEnv();

const featureFlagApiKey =
  env === Env.STAGING ? process.env.LAUNCHDARKLY_STG_API_KEY! : process.env.LAUNCHDARKLY_PROD_API_KEY!;
const featureFlagEnvironment = env === Env.STAGING ? EnvironmentType.STAGING : EnvironmentType.PROD;

export const FeatureFlagContext = createContext<FeatureFlagClient>({} as FeatureFlagClient);

export const useFeatureFlag = <T extends SupportedFlagTypes>(
  flagKey: string,
  defaultValue: T,
  options?: GetValueOptions<T>
) => {
  const client = useContext(FeatureFlagContext);

  const [flagValue, setFlagValue] = useState<boolean | undefined>(undefined);

  useLayoutEffect(() => {
    client.ready().then(() => {
      const value = client.getFlagValue(flagKey, defaultValue, options);

      setFlagValue(Boolean(value));
    });

    const unsubscribe = client.on(
      flagKey,
      defaultValue,
      (newFlagValue) => {
        setFlagValue(Boolean(newFlagValue));
      },
      options
    );

    return unsubscribe;
  }, [client, defaultValue, flagKey, options]);

  return flagValue;
};

export const FeatureFlagProvider = ({ children }: FeatureFlagProviderType) => {
  const [{ user }] = useUserStore();

  const [
    {
      workspace: { cloudId, id },
    },
  ] = useWorkspaceStore();

  const featureFlagUser = useMemo(
    () => ({
      identifier: {
        type: Identifiers.ATLASSIAN_ACCOUNT_ID,
        value: user.account_id,
      },
      isAnonymous: false,
      additionalIdentifiers: cloudId ? { [Identifiers.TENANT_ID]: cloudId } : undefined,
      custom: {
        workspaceUuid: id,
      },
    }),
    [cloudId, id, user.account_id]
  );

  const client = useMemo(
    () =>
      new FeatureFlagClient(featureFlagApiKey, analyticsClient, featureFlagUser, {
        productKey: 'watermelon',
        environment: featureFlagEnvironment,
      }),
    [featureFlagUser]
  );

  return <FeatureFlagContext.Provider value={client}>{children}</FeatureFlagContext.Provider>;
};
