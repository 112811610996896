import { ProjectStateValue, ProjectUpdate, UpdateType } from '@generated/graphql';
import { State } from '@queries/GetProjectPageItems';

/**
 * Update is considered to be a missed update when the relevant flag is true
 * AND user hasn't added an update yet
 */
export const isMissedUpdate = (update: Pick<ProjectUpdate, 'missedUpdate' | 'updateType'>): boolean =>
  Boolean(update.missedUpdate && update.updateType === UpdateType.System);

export const wasMissedUpdate = (update: Pick<ProjectUpdate, 'missedUpdate' | 'updateType'>): boolean =>
  Boolean(update.missedUpdate && update.updateType === UpdateType.User);

export const isDueDateSet = (project: {
  creationDate?: string | null;
  targetDate?: string | null;
  state?: State | null;
}) => (project?.state?.value === ProjectStateValue.Pending ? project?.creationDate !== project?.targetDate : true);
