import { getConfig } from 'src/config';
import { MilestonesArgs } from '@store/milestonesStore';

const URLS = {
  hierarchy: `${getConfig().gsApiBaseUrl}/assets/hierarchy`,
  assetsImport: `${getConfig().gsApiBaseUrl}/assets/import`,
  syncAssetsURL: `${getConfig().gsApiBaseUrl}/assets/synchronization`,
  programList: `${getConfig().gsApiBaseUrl}/assets/programs`,
  milestoneList: `${getConfig().gsApiBaseUrl}/assets/milestones`,
  milestonesList: `${getConfig().gsApiBaseUrl}/assets/milestoneList`,
  initiativeList: `${getConfig().gsApiBaseUrl}/assets/initiatives`,
  verifyProgram: `${getConfig().gsApiBaseUrl}/assets/program`,
  verifiedMilestonesKeys: `${getConfig().gsApiBaseUrl}/assets/milestones`,
  verifyDepartmentEntities: `${getConfig().gsApiBaseUrl}/assets/department-verification`,
  milestonesHierarchy: `${getConfig().gsApiBaseUrl}/assets/milestone-hierarchy`,
  parentProgram: `${getConfig().gsApiBaseUrl}/assets/milestone-parent`,
  commits: `${getConfig().gsApiBaseUrl}/assets/commits`,
  paginatedCommits: `${getConfig().gsApiBaseUrl}/assets/paginated-commits`,
  importStatus: `${getConfig().gsApiBaseUrl}/assets/import/status`,
};

class AssetsClient {
  fetchProgramList() {
    return fetch(URLS.programList, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  fetchInitiativeList() {
    return fetch(URLS.initiativeList, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  fetchParentProgramByKey(projectKey: string) {
    return fetch(`${URLS.parentProgram}/${projectKey}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  fetchMilestoneList() {
    return fetch(URLS.milestoneList, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  fetchMilestonesList(milestoneParams: MilestonesArgs) {
    return fetch(URLS.milestonesList, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(milestoneParams),
    });
  }

  fetchVerifyProgram(projectKey: string) {
    return fetch(`${URLS.verifyProgram}/${projectKey}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  fetchVerifiedInitiativesKeys(projectKey: string) {
    return fetch(`${URLS.verifiedMilestonesKeys}/${projectKey}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  fetchAssetsCommits(projectKey: string) {
    return fetch(`${URLS.commits}/${projectKey}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  fetchPaginatedAssetsCommits(projectKey: string, offset: number, limit: number) {
    return fetch(`${URLS.paginatedCommits}/${projectKey}?offset=${offset}&limit=${limit}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  verifyDepartmentEntities(entitiesKeys: string[]) {
    return fetch(URLS.verifyDepartmentEntities, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ entitiesKeys }),
    });
  }

  fetchMilestonesHierarchy(entitiesKeys: string[]) {
    return fetch(URLS.milestonesHierarchy, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ entitiesKeys }),
    });
  }

  triggerAssetsImport() {
    return fetch(URLS.assetsImport);
  }

  syncAssets() {
    return fetch(URLS.syncAssetsURL, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  checkImportStatus() {
    return fetch(URLS.importStatus, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}

export const assetsClient = new AssetsClient();
