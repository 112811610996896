import { gql } from '@apollo/client';

export const GET_ALL_WORKSPACES = gql`
  query getAllWorkspaces {
    allWorkspaces {
      edges {
        node {
          id
          name
          cloudId
          organisationId
        }
      }
      count
    }
  }
`;
