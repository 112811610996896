export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Instant: any;
  JSONString: any;
  URI: any;
  URL: any;
  UUID: any;
};

export enum AccountIdentifierType {
  AtlassianId = 'ATLASSIAN_ID',
  Base64Hash = 'BASE64_HASH',
  Unknown = 'UNKNOWN',
}

export enum AccountStatus {
  Active = 'active',
  Closed = 'closed',
  Inactive = 'inactive',
}

export type AddMembersResult = {
  __typename?: 'AddMembersResult';
  aaid: Maybe<Scalars['String']>;
  addedAsMember: Scalars['Boolean'];
  addedAsWatcher: Scalars['Boolean'];
  error: Maybe<Scalars['String']>;
};

export type AddWatcherResult = {
  __typename?: 'AddWatcherResult';
  accountId: Maybe<Scalars['String']>;
  isPendingMember: Scalars['Boolean'];
  isWatching: Scalars['Boolean'];
};

export type AggregatedInfoDto = {
  __typename?: 'AggregatedInfoDto';
  numContributors: Scalars['Int'];
  numProjects: Scalars['Int'];
};

export type Answer = Node & {
  __typename?: 'Answer';
  ari: Scalars['String'];
  creationDate: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  hasUpvoted: Scalars['Boolean'];
  id: Scalars['ID'];
  lastEditedDate: Maybe<Scalars['DateTime']>;
  owner: Maybe<User>;
  upvoteCount: Scalars['Int'];
  upvoteUsers: Maybe<UserConnection>;
  uuid: Maybe<Scalars['UUID']>;
};

export type AnswerUpvoteUsersArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type AnswerConnection = {
  __typename?: 'AnswerConnection';
  edges: Maybe<Array<Maybe<AnswerEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type AnswerEdge = {
  __typename?: 'AnswerEdge';
  node: Maybe<Answer>;
  cursor: Scalars['String'];
};

export type CanCreateProjectFusionResponse = {
  __typename?: 'CanCreateProjectFusionResponse';
  issue: Maybe<GqlJiraIssue>;
  result: Maybe<CanCreateProjectFusionResult>;
};

export enum CanCreateProjectFusionResult {
  CanCreate = 'CAN_CREATE',
  IntegrationNotInstalled = 'INTEGRATION_NOT_INSTALLED',
  IssueAlreadyLinked = 'ISSUE_ALREADY_LINKED',
  NotInSameOrg = 'NOT_IN_SAME_ORG',
  UnexpectedIssueType = 'UNEXPECTED_ISSUE_TYPE',
}

export type Comment = Node & {
  __typename?: 'Comment';
  ari: Scalars['String'];
  commentText: Maybe<Scalars['String']>;
  container: Maybe<CommentContainer>;
  creationDate: Maybe<Scalars['DateTime']>;
  creator: Maybe<User>;
  editDate: Maybe<Scalars['DateTime']>;
  goal: Maybe<Goal>;
  goalUpdate: Maybe<GoalUpdate>;
  id: Scalars['ID'];
  project: Maybe<Project>;
  projectUpdate: Maybe<ProjectUpdate>;
  url: Maybe<Scalars['String']>;
  uuid: Maybe<Scalars['UUID']>;
};

export type CommentConnection = {
  __typename?: 'CommentConnection';
  edges: Maybe<Array<Maybe<CommentEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type CommentContainer = Goal | Project;

export type CommentEdge = {
  __typename?: 'CommentEdge';
  node: Maybe<Comment>;
  cursor: Scalars['String'];
};

export enum CommunicationHealth {
  Dormant = 'DORMANT',
  Healthy = 'HEALTHY',
  Unwell = 'UNWELL',
}

export type ComparatorsInput = {
  gt: Maybe<Scalars['Int']>;
};

export type CompletionProjectState = {
  __typename?: 'CompletionProjectState';
  label: Maybe<Scalars['String']>;
  localizedLabel: Maybe<LocalizationField>;
  value: Maybe<GoalStateValue>;
};

export type CpusSearchUser = {
  __typename?: 'CpusSearchUser';
  account_id: Maybe<Scalars['String']>;
  account_status: Maybe<Scalars['String']>;
  department: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  highResolutionProfilePicture: Maybe<HighResolutionProfilePicture>;
  job_title: Maybe<Scalars['String']>;
  location: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  nickname: Maybe<Scalars['String']>;
  picture: Maybe<Scalars['String']>;
  reportCounts: Maybe<ReportCounts>;
  teams: Maybe<TeamConnection>;
  zoneinfo: Maybe<Scalars['String']>;
};

export type CpusSearchUserHighResolutionProfilePictureArgs = {
  size: Maybe<ProfilePictureSize>;
};

export type CpusSearchUserTeamsArgs = {
  organisationId: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type CpusSearchUserConnection = {
  __typename?: 'CpusSearchUserConnection';
  edges: Maybe<Array<Maybe<CpusSearchUserEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type CpusSearchUserEdge = {
  __typename?: 'CpusSearchUserEdge';
  node: Maybe<CpusSearchUser>;
  cursor: Scalars['String'];
};

export type CreatedUser = Node & {
  __typename?: 'CreatedUser';
  aaid: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  pii: Maybe<UserPii>;
  workspaces: Maybe<WorkspaceConnection>;
};

export type CustomField = Node & {
  __typename?: 'CustomField';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  required: Scalars['Boolean'];
  type: Maybe<CustomFieldType>;
  value: Maybe<Scalars['String']>;
};

export type CustomFieldConnection = {
  __typename?: 'CustomFieldConnection';
  edges: Maybe<Array<Maybe<CustomFieldEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type CustomFieldEdge = {
  __typename?: 'CustomFieldEdge';
  node: Maybe<CustomField>;
  cursor: Scalars['String'];
};

export enum CustomFieldType {
  Hidden = 'hidden',
  TextField = 'text_field',
  UserPicker = 'user_picker',
}

export type DefaultCustomField = Node & {
  __typename?: 'DefaultCustomField';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  required: Scalars['Boolean'];
  type: Maybe<CustomFieldType>;
  workspace: Maybe<Workspace>;
};

export enum DirectorySortEnum {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum DirectoryTeamSortEnum {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum Edition {
  Beta = 'BETA',
  Custom = 'CUSTOM',
  Free = 'FREE',
  Premium = 'PREMIUM',
  Standard = 'STANDARD',
}

export type ExtendedProfile = {
  __typename?: 'ExtendedProfile';
  department: Maybe<Scalars['String']>;
  jobTitle: Maybe<Scalars['String']>;
  location: Maybe<Scalars['String']>;
  organization: Maybe<Scalars['String']>;
  teamType: Maybe<Scalars['String']>;
};

export type FeatureContext = {
  __typename?: 'FeatureContext';
  edition: Maybe<Edition>;
  entitlementId: Maybe<Scalars['String']>;
  features: Maybe<WorkspaceFeatures>;
  transactionAccountId: Maybe<Scalars['String']>;
};

export type Goal = Node & {
  __typename?: 'Goal';
  aggregatedInfo: Maybe<AggregatedInfoDto>;
  archived: Scalars['Boolean'];
  ari: Scalars['String'];
  comments: Maybe<CommentConnection>;
  /** @deprecated Please use slackChannels instead. */
  connectedSlackChannels: Maybe<Array<Maybe<SlackConnection>>>;
  creationDate: Scalars['DateTime'];
  description: Maybe<Scalars['String']>;
  dueDate: Maybe<TargetDate>;
  goalDepthData: Maybe<Array<Maybe<GoalDepthData>>>;
  hasSubgoalMatches: Maybe<Scalars['Boolean']>;
  hierarchy: Scalars['String'];
  hierarchyLevel: Maybe<Scalars['Int']>;
  iconData: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  latestUpdateDate: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  number: Scalars['Int'];
  numberOfParents: Maybe<Scalars['Int']>;
  owner: Maybe<User>;
  parentGoal: Maybe<Goal>;
  parentGoals: Maybe<GoalConnection>;
  phase: Maybe<GoalPhaseDetails>;
  phaseId: Maybe<Scalars['Int']>;
  projects: Maybe<ProjectConnection>;
  score: Maybe<Scalars['Int']>;
  slackChannels: Maybe<SlackConnectionConnection>;
  state: Maybe<GoalState>;
  subGoals: Maybe<GoalConnection>;
  tags: Maybe<TagConnection>;
  tagsWatchedByUser: Maybe<TagConnection>;
  targetDate: Scalars['Date'];
  targetDateConfidence: Scalars['Int'];
  teams: Maybe<TeamConnection>;
  teamsWatchedByUser: Maybe<TeamConnection>;
  updates: Maybe<GoalUpdateConnection>;
  url: Maybe<Scalars['String']>;
  uuid: Scalars['UUID'];
  watcherWorkspaceMemberships: Maybe<WorkspaceMemberConnection>;
  watchers: Maybe<UserConnection>;
  watching: Scalars['Boolean'];
  workspace: Workspace;
};

export type GoalCommentsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type GoalGoalDepthDataArgs = {
  managerAaids: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GoalParentGoalsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type GoalProjectsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  tql: Maybe<Scalars['String']>;
};

export type GoalSlackChannelsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type GoalSubGoalsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type GoalTagsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type GoalTagsWatchedByUserArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type GoalTeamsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type GoalTeamsWatchedByUserArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type GoalUpdatesArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type GoalWatcherWorkspaceMembershipsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type GoalWatchersArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type GoalConnection = {
  __typename?: 'GoalConnection';
  edges: Maybe<Array<Maybe<GoalEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type GoalDepthData = {
  __typename?: 'GoalDepthData';
  depth: Scalars['Int'];
  managerProfile: Maybe<UserPii>;
};

export type GoalEdge = {
  __typename?: 'GoalEdge';
  node: Maybe<Goal>;
  cursor: Scalars['String'];
};

export enum GoalPhase {
  Done = 'done',
  InProgress = 'in_progress',
  Paused = 'paused',
  Pending = 'pending',
}

export type GoalPhaseDetails = {
  __typename?: 'GoalPhaseDetails';
  displayName: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Maybe<GoalPhase>;
};

export type GoalPhaseDetailsConnection = {
  __typename?: 'GoalPhaseDetailsConnection';
  edges: Maybe<Array<Maybe<GoalPhaseDetailsEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type GoalPhaseDetailsEdge = {
  __typename?: 'GoalPhaseDetailsEdge';
  node: Maybe<GoalPhaseDetails>;
  cursor: Scalars['String'];
};

export enum GoalScoringMode {
  Okr = 'OKR',
  Simple = 'SIMPLE',
}

export enum GoalSortEnum {
  CreationDateAsc = 'CREATION_DATE_ASC',
  CreationDateDesc = 'CREATION_DATE_DESC',
  HierarchyAsc = 'HIERARCHY_ASC',
  HierarchyDesc = 'HIERARCHY_DESC',
  HierarchyLevelAsc = 'HIERARCHY_LEVEL_ASC',
  HierarchyLevelDesc = 'HIERARCHY_LEVEL_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LatestUpdateDateAsc = 'LATEST_UPDATE_DATE_ASC',
  LatestUpdateDateDesc = 'LATEST_UPDATE_DATE_DESC',
  MemberCountAsc = 'MEMBER_COUNT_ASC',
  MemberCountDesc = 'MEMBER_COUNT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PhaseIdAsc = 'PHASE_ID_ASC',
  PhaseIdDesc = 'PHASE_ID_DESC',
  ProjectCountAsc = 'PROJECT_COUNT_ASC',
  ProjectCountDesc = 'PROJECT_COUNT_DESC',
  ScoreAsc = 'SCORE_ASC',
  ScoreDesc = 'SCORE_DESC',
  TargetDateAsc = 'TARGET_DATE_ASC',
  TargetDateDesc = 'TARGET_DATE_DESC',
  WatchingAsc = 'WATCHING_ASC',
  WatchingDesc = 'WATCHING_DESC',
}

export type GoalState = {
  __typename?: 'GoalState';
  label: Maybe<Scalars['String']>;
  score: Maybe<Scalars['Float']>;
  value: Maybe<GoalStateValue>;
};

export type GoalStateLabelArgs = {
  includeScore: Maybe<Scalars['Boolean']>;
};

export type GoalStateConnection = {
  __typename?: 'GoalStateConnection';
  edges: Maybe<Array<Maybe<GoalStateEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type GoalStateEdge = {
  __typename?: 'GoalStateEdge';
  node: Maybe<GoalState>;
  cursor: Scalars['String'];
};

export enum GoalStateValue {
  Archived = 'archived',
  AtRisk = 'at_risk',
  Done = 'done',
  OffTrack = 'off_track',
  OnTrack = 'on_track',
  Paused = 'paused',
  Pending = 'pending',
}

export type GoalUpdate = Node & {
  __typename?: 'GoalUpdate';
  ari: Scalars['String'];
  comments: Maybe<CommentConnection>;
  creationDate: Maybe<Scalars['DateTime']>;
  creator: Maybe<User>;
  editDate: Maybe<Scalars['DateTime']>;
  goal: Maybe<Goal>;
  id: Scalars['ID'];
  lastEditedBy: Maybe<User>;
  missedUpdate: Scalars['Boolean'];
  newDueDate: Maybe<TargetDate>;
  newPhase: Maybe<GoalPhaseDetails>;
  newScore: Scalars['Int'];
  newState: Maybe<GoalState>;
  newTargetDate: Maybe<Scalars['Date']>;
  newTargetDateConfidence: Scalars['Int'];
  notes: Maybe<Array<Maybe<UpdateNote>>>;
  oldDueDate: Maybe<TargetDate>;
  oldPhase: Maybe<GoalPhaseDetails>;
  oldScore: Maybe<Scalars['Int']>;
  oldState: Maybe<GoalState>;
  oldTargetDate: Maybe<Scalars['Date']>;
  oldTargetDateConfidence: Maybe<Scalars['Int']>;
  summary: Maybe<Scalars['String']>;
  updateType: Maybe<UpdateType>;
  uuid: Maybe<Scalars['UUID']>;
};

export type GoalUpdateCommentsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type GoalUpdateNotesArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type GoalUpdateConnection = {
  __typename?: 'GoalUpdateConnection';
  edges: Maybe<Array<Maybe<GoalUpdateEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type GoalUpdateEdge = {
  __typename?: 'GoalUpdateEdge';
  node: Maybe<GoalUpdate>;
  cursor: Scalars['String'];
};

export type GqlJiraIssue = {
  __typename?: 'GqlJiraIssue';
  fields: Maybe<GqlJiraIssueFields>;
  href: Maybe<Scalars['String']>;
  issueId: Scalars['Int'];
  key: Maybe<Scalars['String']>;
  self: Maybe<Scalars['String']>;
};

export type GqlJiraIssueFields = {
  __typename?: 'GqlJiraIssueFields';
  duedate: Maybe<Scalars['Date']>;
  issuetype: Maybe<GqlJiraIssueType>;
  labels: Maybe<Array<Maybe<Scalars['String']>>>;
  startDate: Maybe<Scalars['Date']>;
  status: Maybe<GqlJiraStatus>;
  summary: Maybe<Scalars['String']>;
};

export type GqlJiraIssueType = {
  __typename?: 'GqlJiraIssueType';
  iconUrl: Maybe<Scalars['String']>;
  issueTypeId: Scalars['Int'];
  name: Maybe<Scalars['String']>;
  self: Maybe<Scalars['String']>;
};

export type GqlJiraStatus = {
  __typename?: 'GqlJiraStatus';
  name: Maybe<Scalars['String']>;
  self: Maybe<Scalars['String']>;
  statusCategory: Maybe<GqlJiraStatusCategory>;
  statusId: Scalars['Int'];
};

export type GqlJiraStatusCategory = {
  __typename?: 'GqlJiraStatusCategory';
  key: Scalars['String'];
  name: Maybe<Scalars['String']>;
  self: Maybe<Scalars['String']>;
  statusCategoryId: Scalars['Int'];
};

export type HasRecords = {
  __typename?: 'HasRecords';
  hasRecords: Scalars['Boolean'];
};

export type HelpPointer = Node & {
  __typename?: 'HelpPointer';
  cloudId: Maybe<Scalars['String']>;
  creationDate: Maybe<Scalars['DateTime']>;
  creator: Maybe<User>;
  creatorAaid: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  editable: Maybe<Scalars['Boolean']>;
  icon: Maybe<Icon>;
  iconData: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  link: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  owner: Maybe<HelpPointerOwner>;
  tags: Maybe<TagConnection>;
  teamId: Maybe<Scalars['String']>;
  type: Maybe<HelpPointerType>;
  uuid: Maybe<Scalars['UUID']>;
  workspace: Workspace;
};

export type HelpPointerTagsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type HelpPointerConnection = {
  __typename?: 'HelpPointerConnection';
  edges: Maybe<Array<Maybe<HelpPointerEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type HelpPointerEdge = {
  __typename?: 'HelpPointerEdge';
  node: Maybe<HelpPointer>;
  cursor: Scalars['String'];
};

export type HelpPointerOwner = {
  __typename?: 'HelpPointerOwner';
  displayName: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  largeAvatarImageUrl: Maybe<Scalars['String']>;
  smallAvatarImageUrl: Maybe<Scalars['String']>;
};

export type HelpPointerSearchResponse = {
  __typename?: 'HelpPointerSearchResponse';
  results: Maybe<HelpPointerConnection>;
  tags: Maybe<Array<Maybe<Tag>>>;
  teams: Maybe<Array<Maybe<Team>>>;
};

export enum HelpPointerSortEnum {
  CreationDateAsc = 'CREATION_DATE_ASC',
  CreationDateDesc = 'CREATION_DATE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC',
}

export enum HelpPointerType {
  Action = 'ACTION',
  Information = 'INFORMATION',
}

export type HighResolutionProfilePicture = {
  __typename?: 'HighResolutionProfilePicture';
  url: Maybe<Scalars['String']>;
};

export type Icon = {
  __typename?: 'Icon';
  color: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  shortName: Maybe<Scalars['String']>;
};

export type IconInput = {
  color: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  shortName: Maybe<Scalars['String']>;
};

export type IconUrIs = {
  __typename?: 'IconURIs';
  roundedSquare: Maybe<ThemeUrIs>;
  square: Maybe<ThemeUrIs>;
};

export enum InviteTypeEnum {
  GoalWatcher = 'goal_watcher',
  ProjectMember = 'project_member',
  ProjectWatcher = 'project_watcher',
  WorkspaceMember = 'workspace_member',
}

export type IsFeatureEnabledContextInput = {
  key: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};

export type IsFeatureEnabledResponse = {
  __typename?: 'IsFeatureEnabledResponse';
  enabled: Scalars['Boolean'];
};

export type Kudos = Node & {
  __typename?: 'Kudos';
  archived: Scalars['Boolean'];
  ari: Scalars['String'];
  bgTheme: Maybe<KudosBgThemeType>;
  creationDate: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  giver: Maybe<User>;
  id: Scalars['ID'];
  imageUrl: Maybe<Scalars['URL']>;
  mediaImageId: Maybe<Scalars['UUID']>;
  recipients: Maybe<KudosRecipientConnection>;
  stillImageUrl: Maybe<Scalars['URL']>;
  type: Maybe<KudosType>;
  uuid: Maybe<Scalars['UUID']>;
};

export type KudosRecipientsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export enum KudosBgThemeType {
  Blue = 'blue',
  Green = 'green',
  Pink = 'pink',
  Purple = 'purple',
}

export type KudosConnection = {
  __typename?: 'KudosConnection';
  edges: Maybe<Array<Maybe<KudosEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type KudosEdge = {
  __typename?: 'KudosEdge';
  node: Maybe<Kudos>;
  cursor: Scalars['String'];
};

export type KudosRecipient = Node & {
  __typename?: 'KudosRecipient';
  id: Scalars['ID'];
  recipient: User;
  team: Maybe<Team>;
};

export type KudosRecipientConnection = {
  __typename?: 'KudosRecipientConnection';
  edges: Maybe<Array<Maybe<KudosRecipientEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type KudosRecipientEdge = {
  __typename?: 'KudosRecipientEdge';
  node: Maybe<KudosRecipient>;
  cursor: Scalars['String'];
};

export enum KudosSortEnum {
  CreationDateAsc = 'CREATION_DATE_ASC',
  CreationDateDesc = 'CREATION_DATE_DESC',
}

export enum KudosType {
  Individual = 'INDIVIDUAL',
  Team = 'TEAM',
}

export type Learning = Node & {
  __typename?: 'Learning';
  ari: Scalars['String'];
  /** @deprecated Legacy field, ensuring backwards compat with previous version of learnings schema */
  couldImprove: Maybe<Scalars['String']>;
  creationDate: Maybe<Scalars['DateTime']>;
  creator: Maybe<User>;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastEditedBy: Maybe<User>;
  lastEditedDate: Maybe<Scalars['DateTime']>;
  /** @deprecated Legacy field, ensuring backwards compat with previous version of learnings schema */
  learned: Maybe<Scalars['String']>;
  project: Maybe<Project>;
  summary: Maybe<Scalars['String']>;
  uuid: Scalars['UUID'];
  /** @deprecated Legacy field, ensuring backwards compat with previous version of learnings schema */
  wentWell: Maybe<Scalars['String']>;
};

export type LearningConnection = {
  __typename?: 'LearningConnection';
  edges: Maybe<Array<Maybe<LearningEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type LearningEdge = {
  __typename?: 'LearningEdge';
  node: Maybe<Learning>;
  cursor: Scalars['String'];
};

export enum LearningSortEnum {
  CreationDateAsc = 'CREATION_DATE_ASC',
  CreationDateDesc = 'CREATION_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SummaryAsc = 'SUMMARY_ASC',
  SummaryDesc = 'SUMMARY_DESC',
}

export type Link = Node & {
  __typename?: 'Link';
  iconUrl: Maybe<Scalars['URL']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  provider: Maybe<Scalars['String']>;
  type: Maybe<LinkType>;
  url: Maybe<Scalars['URL']>;
};

export type LinkConnection = {
  __typename?: 'LinkConnection';
  edges: Maybe<Array<Maybe<LinkEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type LinkEdge = {
  __typename?: 'LinkEdge';
  node: Maybe<Link>;
  cursor: Scalars['String'];
};

export enum LinkType {
  Related = 'RELATED',
  WorkTracking = 'WORK_TRACKING',
}

export type LocalizationField = {
  __typename?: 'LocalizationField';
  messageId: Maybe<Scalars['String']>;
};

export enum MembershipState {
  Alumni = 'ALUMNI',
  FullMember = 'FULL_MEMBER',
  Invited = 'INVITED',
  RequestingToJoin = 'REQUESTING_TO_JOIN',
}

export type Milestone = Node & {
  __typename?: 'Milestone';
  completionDate: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  targetDate: Scalars['Date'];
  targetDateType: TargetDateType;
  title: Scalars['String'];
  uuid: Maybe<Scalars['UUID']>;
};

export type MilestoneConnection = {
  __typename?: 'MilestoneConnection';
  edges: Maybe<Array<Maybe<MilestoneEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type MilestoneEdge = {
  __typename?: 'MilestoneEdge';
  node: Maybe<Milestone>;
  cursor: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  removeTeamMember: Maybe<RemoveTeamMemberPayload>;
  addWatcher: Maybe<AddWatcherPayload>;
  createProjectLink: Maybe<CreateProjectLinkPayload>;
  editProjectIcon: Maybe<EditProjectIconPayload>;
  shareUpdate: Maybe<ShareUpdatePayload>;
  addWatcherByAccountId: Maybe<AddWatcherByAccountIdPayload>;
  joinWorkspaceBySite: Maybe<JoinWorkspaceBySitePayload>;
  createProject: Maybe<CreateProjectPayload>;
  createTagForCloudId: Maybe<CreateTagForCloudIdPayload>;
  updateAnswer: Maybe<UpdateAnswerPayload>;
  removeTeamWatcher: Maybe<RemoveTeamWatcherPayload>;
  updateQuestion: Maybe<UpdateQuestionPayload>;
  createUserTag: Maybe<CreateUserTagPayload>;
  createDefaultCustomField: Maybe<CreateDefaultCustomFieldPayload>;
  deleteQuestion: Maybe<DeleteQuestionPayload>;
  setParentGoal: Maybe<SetParentGoalPayload>;
  addUserTag: Maybe<AddUserTagPayload>;
  saveUserOnboarding: Maybe<SaveUserOnboardingPayload>;
  connectSlackChannel: Maybe<ConnectSlackChannelPayload>;
  shareProject: Maybe<ShareProjectPayload>;
  editProjectLink: Maybe<EditProjectLinkPayload>;
  createUserTagForCloudId: Maybe<CreateUserTagForCloudIdPayload>;
  addProjectTeamLink: Maybe<AddProjectTeamLinkPayload>;
  sendAtlasUpgradeRequest: Maybe<SendAtlasUpgradeRequestPayload>;
  createHelpPointer: Maybe<CreateHelpPointerPayload>;
  inviteUser: Maybe<InviteUserPayload>;
  updateHelpPointer: Maybe<UpdateHelpPointerPayload>;
  editCustomField: Maybe<EditCustomFieldPayload>;
  deleteLearning: Maybe<DeleteLearningPayload>;
  editWorkspace: Maybe<EditWorkspacePayload>;
  createAnswer: Maybe<CreateAnswerPayload>;
  addGoalProjectLink: Maybe<AddGoalProjectLinkPayload>;
  deleteMilestone: Maybe<DeleteMilestonePayload>;
  editProjectMetadata: Maybe<EditProjectMetadataPayload>;
  updateComment: Maybe<UpdateCommentPayload>;
  setIsProjectArchived: Maybe<SetIsProjectArchivedPayload>;
  inviteTeamMembers: Maybe<InviteTeamMembersPayload>;
  editProjectUpdate: Maybe<EditProjectUpdatePayload>;
  updateLearning: Maybe<UpdateLearningPayload>;
  createGoalUpdate: Maybe<CreateGoalUpdatePayload>;
  createGoal: Maybe<CreateGoalPayload>;
  addProjectMember: Maybe<AddProjectMemberPayload>;
  createWorkspace: Maybe<CreateWorkspacePayload>;
  shareGoal: Maybe<ShareGoalPayload>;
  createTeamKudos: Maybe<CreateTeamKudosPayload>;
  removeProjectMember: Maybe<RemoveProjectMemberPayload>;
  cloneGoal: Maybe<CloneGoalPayload>;
  setProjectOwner: Maybe<SetProjectOwnerPayload>;
  deleteComment: Maybe<DeleteCommentPayload>;
  addUpvote: Maybe<AddUpvotePayload>;
  removeWatcher: Maybe<RemoveWatcherPayload>;
  deleteProjectLink: Maybe<DeleteProjectLinkPayload>;
  removeTags: Maybe<RemoveTagsPayload>;
  editTag: Maybe<EditTagPayload>;
  deleteLatestGoalUpdate: Maybe<DeleteLatestGoalUpdatePayload>;
  editGoal: Maybe<EditGoalPayload>;
  setTags: Maybe<SetTagsPayload>;
  setProjectDependency: Maybe<SetProjectDependencyPayload>;
  createComment: Maybe<CreateCommentPayload>;
  createProjectFusion: Maybe<CreateProjectFusionPayload>;
  renameTeam: Maybe<RenameTeamPayload>;
  updateUserWorkspacePreference: Maybe<UpdateUserWorkspacePreferencePayload>;
  addTags: Maybe<AddTagsPayload>;
  createTag: Maybe<CreateTagPayload>;
  deleteProjectRole: Maybe<DeleteProjectRolePayload>;
  updateUserTag: Maybe<UpdateUserTagPayload>;
  deleteProjectDependency: Maybe<DeleteProjectDependencyPayload>;
  removeUserTag: Maybe<RemoveUserTagPayload>;
  setProjectRole: Maybe<SetProjectRolePayload>;
  removeProjectTeamLink: Maybe<RemoveProjectTeamLinkPayload>;
  createProjectUpdate: Maybe<CreateProjectUpdatePayload>;
  createIndividualKudos: Maybe<CreateIndividualKudosPayload>;
  deleteLatestProjectUpdate: Maybe<DeleteLatestProjectUpdatePayload>;
  markUserAsOnboarded: Maybe<MarkUserAsOnboardedPayload>;
  createTeam: Maybe<CreateTeamPayload>;
  deleteHelpPointer: Maybe<DeleteHelpPointerPayload>;
  sendSampleDigest: Maybe<SendSampleDigestPayload>;
  editGoalUpdate: Maybe<EditGoalUpdatePayload>;
  createLearning: Maybe<CreateLearningPayload>;
  deleteAnswer: Maybe<DeleteAnswerPayload>;
  addProjectMembers: Maybe<AddProjectMembersPayload>;
  setIsGoalArchived: Maybe<SetIsGoalArchivedPayload>;
  cloneProject: Maybe<CloneProjectPayload>;
  deleteProjectFusion: Maybe<DeleteProjectFusionPayload>;
  updateUserPreferences: Maybe<UpdateUserPreferencesPayload>;
  addWatcherByAccountIds: Maybe<AddWatcherByAccountIdsPayload>;
  updateMilestone: Maybe<UpdateMilestonePayload>;
  addTeamWatcher: Maybe<AddTeamWatcherPayload>;
  createUser: Maybe<CreateUserPayload>;
  deleteUserOnboarding: Maybe<DeleteUserOnboardingPayload>;
  createMilestone: Maybe<CreateMilestonePayload>;
  addProjectViewers: Maybe<AddProjectViewersPayload>;
  removeUpvote: Maybe<RemoveUpvotePayload>;
  createQuestion: Maybe<CreateQuestionPayload>;
  disconnectSlackChannel: Maybe<DisconnectSlackChannelPayload>;
  deleteGoalProjectLink: Maybe<DeleteGoalProjectLinkPayload>;
};

export type MutationRemoveTeamMemberArgs = {
  input: RemoveTeamMemberInput;
};

export type MutationAddWatcherArgs = {
  input: AddWatcherInput;
};

export type MutationCreateProjectLinkArgs = {
  input: CreateProjectLinkInput;
};

export type MutationEditProjectIconArgs = {
  input: EditProjectIconInput;
};

export type MutationShareUpdateArgs = {
  input: ShareUpdateInput;
};

export type MutationAddWatcherByAccountIdArgs = {
  input: AddWatcherByAccountIdInput;
};

export type MutationJoinWorkspaceBySiteArgs = {
  input: JoinWorkspaceBySiteInput;
};

export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};

export type MutationCreateTagForCloudIdArgs = {
  input: CreateTagForCloudIdInput;
};

export type MutationUpdateAnswerArgs = {
  input: UpdateAnswerInput;
};

export type MutationRemoveTeamWatcherArgs = {
  input: RemoveTeamWatcherInput;
};

export type MutationUpdateQuestionArgs = {
  input: UpdateQuestionInput;
};

export type MutationCreateUserTagArgs = {
  input: CreateUserTagInput;
};

export type MutationCreateDefaultCustomFieldArgs = {
  input: CreateDefaultCustomFieldInput;
};

export type MutationDeleteQuestionArgs = {
  input: DeleteQuestionInput;
};

export type MutationSetParentGoalArgs = {
  input: SetParentGoalInput;
};

export type MutationAddUserTagArgs = {
  input: AddUserTagInput;
};

export type MutationSaveUserOnboardingArgs = {
  input: SaveUserOnboardingInput;
};

export type MutationConnectSlackChannelArgs = {
  input: ConnectSlackChannelInput;
};

export type MutationShareProjectArgs = {
  input: ShareProjectInput;
};

export type MutationEditProjectLinkArgs = {
  input: EditProjectLinkInput;
};

export type MutationCreateUserTagForCloudIdArgs = {
  input: CreateUserTagForCloudIdInput;
};

export type MutationAddProjectTeamLinkArgs = {
  input: AddProjectTeamLinkInput;
};

export type MutationSendAtlasUpgradeRequestArgs = {
  input: SendAtlasUpgradeRequestInput;
};

export type MutationCreateHelpPointerArgs = {
  input: CreateHelpPointerInput;
};

export type MutationInviteUserArgs = {
  input: InviteUserInput;
};

export type MutationUpdateHelpPointerArgs = {
  input: UpdateHelpPointerInput;
};

export type MutationEditCustomFieldArgs = {
  input: EditCustomFieldInput;
};

export type MutationDeleteLearningArgs = {
  input: DeleteLearningInput;
};

export type MutationEditWorkspaceArgs = {
  input: EditWorkspaceInput;
};

export type MutationCreateAnswerArgs = {
  input: CreateAnswerInput;
};

export type MutationAddGoalProjectLinkArgs = {
  input: AddGoalProjectLinkInput;
};

export type MutationDeleteMilestoneArgs = {
  input: DeleteMilestoneInput;
};

export type MutationEditProjectMetadataArgs = {
  input: EditProjectMetadataInput;
};

export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};

export type MutationSetIsProjectArchivedArgs = {
  input: SetIsProjectArchivedInput;
};

export type MutationInviteTeamMembersArgs = {
  input: InviteTeamMembersInput;
};

export type MutationEditProjectUpdateArgs = {
  input: EditProjectUpdateInput;
};

export type MutationUpdateLearningArgs = {
  input: UpdateLearningInput;
};

export type MutationCreateGoalUpdateArgs = {
  input: CreateGoalUpdateInput;
};

export type MutationCreateGoalArgs = {
  input: CreateGoalInput;
};

export type MutationAddProjectMemberArgs = {
  input: AddProjectMemberInput;
};

export type MutationCreateWorkspaceArgs = {
  input: CreateWorkspaceInput;
};

export type MutationShareGoalArgs = {
  input: ShareGoalInput;
};

export type MutationCreateTeamKudosArgs = {
  input: CreateTeamKudosInput;
};

export type MutationRemoveProjectMemberArgs = {
  input: RemoveProjectMemberInput;
};

export type MutationCloneGoalArgs = {
  input: CloneGoalInput;
};

export type MutationSetProjectOwnerArgs = {
  input: SetProjectOwnerInput;
};

export type MutationDeleteCommentArgs = {
  input: DeleteCommentInput;
};

export type MutationAddUpvoteArgs = {
  input: AddUpvoteInput;
};

export type MutationRemoveWatcherArgs = {
  input: RemoveWatcherInput;
};

export type MutationDeleteProjectLinkArgs = {
  input: DeleteProjectLinkInput;
};

export type MutationRemoveTagsArgs = {
  input: RemoveTagsInput;
};

export type MutationEditTagArgs = {
  input: EditTagInput;
};

export type MutationDeleteLatestGoalUpdateArgs = {
  input: DeleteLatestGoalUpdateInput;
};

export type MutationEditGoalArgs = {
  input: EditGoalInput;
};

export type MutationSetTagsArgs = {
  input: SetTagsInput;
};

export type MutationSetProjectDependencyArgs = {
  input: SetProjectDependencyInput;
};

export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};

export type MutationCreateProjectFusionArgs = {
  input: CreateProjectFusionInput;
};

export type MutationRenameTeamArgs = {
  input: RenameTeamInput;
};

export type MutationUpdateUserWorkspacePreferenceArgs = {
  input: UpdateUserWorkspacePreferenceInput;
};

export type MutationAddTagsArgs = {
  input: AddTagsInput;
};

export type MutationCreateTagArgs = {
  input: CreateTagInput;
};

export type MutationDeleteProjectRoleArgs = {
  input: DeleteProjectRoleInput;
};

export type MutationUpdateUserTagArgs = {
  input: UpdateUserTagInput;
};

export type MutationDeleteProjectDependencyArgs = {
  input: DeleteProjectDependencyInput;
};

export type MutationRemoveUserTagArgs = {
  input: RemoveUserTagInput;
};

export type MutationSetProjectRoleArgs = {
  input: SetProjectRoleInput;
};

export type MutationRemoveProjectTeamLinkArgs = {
  input: RemoveProjectTeamLinkInput;
};

export type MutationCreateProjectUpdateArgs = {
  input: CreateProjectUpdateInput;
};

export type MutationCreateIndividualKudosArgs = {
  input: CreateIndividualKudosInput;
};

export type MutationDeleteLatestProjectUpdateArgs = {
  input: DeleteLatestProjectUpdateInput;
};

export type MutationMarkUserAsOnboardedArgs = {
  input: MarkUserAsOnboardedInput;
};

export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};

export type MutationDeleteHelpPointerArgs = {
  input: DeleteHelpPointerInput;
};

export type MutationSendSampleDigestArgs = {
  input: SendSampleDigestInput;
};

export type MutationEditGoalUpdateArgs = {
  input: EditGoalUpdateInput;
};

export type MutationCreateLearningArgs = {
  input: CreateLearningInput;
};

export type MutationDeleteAnswerArgs = {
  input: DeleteAnswerInput;
};

export type MutationAddProjectMembersArgs = {
  input: AddProjectMembersInput;
};

export type MutationSetIsGoalArchivedArgs = {
  input: SetIsGoalArchivedInput;
};

export type MutationCloneProjectArgs = {
  input: CloneProjectInput;
};

export type MutationDeleteProjectFusionArgs = {
  input: DeleteProjectFusionInput;
};

export type MutationUpdateUserPreferencesArgs = {
  input: UpdateUserPreferencesInput;
};

export type MutationAddWatcherByAccountIdsArgs = {
  input: AddWatcherByAccountIdsInput;
};

export type MutationUpdateMilestoneArgs = {
  input: UpdateMilestoneInput;
};

export type MutationAddTeamWatcherArgs = {
  input: AddTeamWatcherInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationDeleteUserOnboardingArgs = {
  input: DeleteUserOnboardingInput;
};

export type MutationCreateMilestoneArgs = {
  input: CreateMilestoneInput;
};

export type MutationAddProjectViewersArgs = {
  input: AddProjectViewersInput;
};

export type MutationRemoveUpvoteArgs = {
  input: RemoveUpvoteInput;
};

export type MutationCreateQuestionArgs = {
  input: CreateQuestionInput;
};

export type MutationDisconnectSlackChannelArgs = {
  input: DisconnectSlackChannelInput;
};

export type MutationDeleteGoalProjectLinkArgs = {
  input: DeleteGoalProjectLinkInput;
};

export type Node = {
  id: Scalars['ID'];
};

export type Onboarding = Node & {
  __typename?: 'Onboarding';
  completionDate: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  key: Maybe<OnboardingItemKey>;
};

export type OnboardingConnection = {
  __typename?: 'OnboardingConnection';
  edges: Maybe<Array<Maybe<OnboardingEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type OnboardingEdge = {
  __typename?: 'OnboardingEdge';
  node: Maybe<Onboarding>;
  cursor: Scalars['String'];
};

export type OnboardingFilterInput = {
  keys: Maybe<Array<Maybe<OnboardingItemKey>>>;
};

export enum OnboardingItemKey {
  ExploreDirectoryBanner = 'EXPLORE_DIRECTORY_BANNER',
  GoalsAboutTabBanner = 'GOALS_ABOUT_TAB_BANNER',
  GoalsDirectoryBanner = 'GOALS_DIRECTORY_BANNER',
  GoalsUpdatesBanner = 'GOALS_UPDATES_BANNER',
  GoalsUpdatesTabBanner = 'GOALS_UPDATES_TAB_BANNER',
  PasteWorkTrackingJiraLinkBanner = 'PASTE_WORK_TRACKING_JIRA_LINK_BANNER',
  ProductOverview = 'PRODUCT_OVERVIEW',
  ProjectAboutTabBanner = 'PROJECT_ABOUT_TAB_BANNER',
  ProjectDirectoryBanner = 'PROJECT_DIRECTORY_BANNER',
  ProjectLearningsBanner = 'PROJECT_LEARNINGS_BANNER',
  ProjectUpdatesTabBanner = 'PROJECT_UPDATES_TAB_BANNER',
  ProjectUpdateBanner = 'PROJECT_UPDATE_BANNER',
  StaffDirectoryUploadHighResPic = 'STAFF_DIRECTORY_UPLOAD_HIGH_RES_PIC',
  SyncWithJiraBanner = 'SYNC_WITH_JIRA_BANNER',
}

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor: Maybe<Scalars['String']>;
  endCursor: Maybe<Scalars['String']>;
};

export type PendingInviteStatus = {
  __typename?: 'PendingInviteStatus';
  inviteDate: Maybe<Scalars['DateTime']>;
  workspaceName: Maybe<Scalars['String']>;
  workspaceUuid: Maybe<Scalars['String']>;
};

export type PeopleFieldAggregate = {
  __typename?: 'PeopleFieldAggregate';
  count: Scalars['Int'];
  imageDirectoryUrl: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type PeopleFieldAggregateResponse = {
  __typename?: 'PeopleFieldAggregateResponse';
  cursor: Maybe<Scalars['String']>;
  results: Maybe<Array<Maybe<PeopleFieldAggregate>>>;
};

export enum PeopleFieldAggregateSort {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export enum PeopleFieldType {
  Department = 'DEPARTMENT',
  JobTitle = 'JOB_TITLE',
  Location = 'LOCATION',
}

export type PermissionGrantSerialized = {
  __typename?: 'PermissionGrantSerialized';
  permission: Maybe<PermissionSerialized>;
  permitted: Scalars['Boolean'];
};

export type PermissionSerialized = {
  __typename?: 'PermissionSerialized';
  description: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
};

export type PermissionsInspectorResult = {
  __typename?: 'PermissionsInspectorResult';
  compiledPermissions: Maybe<Array<Maybe<PermissionGrantSerialized>>>;
  roles: Maybe<Array<Maybe<RoleSerialized>>>;
};

export enum PhaseSortEnum {
  OrderAsc = 'ORDER_ASC',
  OrderDesc = 'ORDER_DESC',
}

export type PostSlackConnection = {
  __typename?: 'PostSlackConnection';
  creationDate: Maybe<Scalars['String']>;
  error: Maybe<Scalars['String']>;
  private: Maybe<Scalars['Boolean']>;
  resourceId: Maybe<Scalars['String']>;
  resourceType: Maybe<Scalars['String']>;
  slackChannelId: Maybe<Scalars['String']>;
  slackChannelName: Maybe<Scalars['String']>;
  slackTeamId: Maybe<Scalars['String']>;
  subscriberAaid: Maybe<Scalars['String']>;
  uuid: Maybe<Scalars['String']>;
};

export enum ProfilePictureSize {
  Large = 'LARGE',
  Maximum = 'MAXIMUM',
  Medium = 'MEDIUM',
}

export type Project = Node & {
  __typename?: 'Project';
  archived: Scalars['Boolean'];
  ari: Scalars['String'];
  changelog: Maybe<ProjectChangelogItemConnection>;
  comments: Maybe<CommentConnection>;
  /** @deprecated Please use slackChannels instead. */
  connectedSlackChannels: Maybe<Array<Maybe<SlackConnection>>>;
  creationDate: Scalars['Date'];
  customFields: Maybe<CustomFieldConnection>;
  dependencies: Maybe<ProjectDependencyConnection>;
  dueDate: Maybe<TargetDate>;
  goals: Maybe<GoalConnection>;
  icon: Maybe<Icon>;
  iconData: Maybe<Scalars['String']>;
  iconUrl: Maybe<IconUrIs>;
  id: Scalars['ID'];
  integration: Maybe<ProjectFusion>;
  key: Scalars['String'];
  latestUpdateDate: Maybe<Scalars['DateTime']>;
  learnings: Maybe<LearningConnection>;
  links: Maybe<LinkConnection>;
  memberWorkspaceMemberships: Maybe<WorkspaceMemberConnection>;
  members: Maybe<UserConnection>;
  milestones: Maybe<MilestoneConnection>;
  name: Scalars['String'];
  newPhase: Maybe<ProjectPhaseDetails>;
  owner: Maybe<User>;
  phase: Maybe<ProjectPhaseDetails>;
  private: Scalars['Boolean'];
  projectDepthData: Maybe<Array<Maybe<ProjectDepthData>>>;
  projectMemberships: Maybe<ProjectMemberConnection>;
  projectNumber: Scalars['Int'];
  projectTeamLinks: Maybe<ProjectTeamLinkConnection>;
  relatedLearnings: Maybe<LearningConnection>;
  shipDate: Maybe<Scalars['DateTime']>;
  slackChannels: Maybe<SlackConnectionConnection>;
  startDate: Maybe<Scalars['Date']>;
  state: Maybe<ProjectState>;
  status: Maybe<Status>;
  tags: Maybe<TagConnection>;
  tagsWatchedByUser: Maybe<TagConnection>;
  targetDate: Scalars['Date'];
  targetDateConfidence: Scalars['Int'];
  teams: Maybe<TeamConnection>;
  teamsWatchedByUser: Maybe<TeamConnection>;
  theMeasurement: Maybe<Scalars['String']>;
  theWhat: Maybe<Scalars['String']>;
  theWhy: Maybe<Scalars['String']>;
  updates: Maybe<ProjectUpdateConnection>;
  url: Maybe<Scalars['String']>;
  userUpdateCount: Scalars['Int'];
  uuid: Scalars['UUID'];
  viewers: Maybe<UserConnection>;
  watcherCount: Scalars['Int'];
  watcherWorkspaceMemberships: Maybe<WorkspaceMemberConnection>;
  watchers: Maybe<UserConnection>;
  watching: Scalars['Boolean'];
  workspace: Workspace;
  workspaceId: Scalars['Int'];
};

export type ProjectChangelogArgs = {
  hasUpdateId: Maybe<Scalars['Boolean']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectCommentsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectCustomFieldsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectDependenciesArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectGoalsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectLearningsArgs = {
  sort: Maybe<Array<Maybe<LearningSortEnum>>>;
  createdBefore: Maybe<Scalars['DateTime']>;
  after: Maybe<Scalars['String']>;
  createdAfter: Maybe<Scalars['DateTime']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectLinksArgs = {
  after: Maybe<Scalars['String']>;
  type: Maybe<LinkType>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectMemberWorkspaceMembershipsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectMembersArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectMilestonesArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectProjectDepthDataArgs = {
  managerAaids: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProjectProjectMembershipsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectProjectTeamLinksArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectRelatedLearningsArgs = {
  sort: Maybe<Array<Maybe<LearningSortEnum>>>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectSlackChannelsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectTagsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectTagsWatchedByUserArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectTeamsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectTeamsWatchedByUserArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectUpdatesArgs = {
  createdAtOrAfter: Maybe<Scalars['DateTime']>;
  createdAtOrBefore: Maybe<Scalars['DateTime']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectViewersArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectWatcherWorkspaceMembershipsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectWatchersArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectChangelogItem = Node & {
  __typename?: 'ProjectChangelogItem';
  accountId: Maybe<Scalars['String']>;
  creationDate: Maybe<Scalars['DateTime']>;
  field: Maybe<ProjectFusionField>;
  id: Scalars['ID'];
  newValue: Maybe<Scalars['String']>;
  oldValue: Maybe<Scalars['String']>;
  projectFusion: Maybe<ProjectFusion>;
};

export type ProjectChangelogItemConnection = {
  __typename?: 'ProjectChangelogItemConnection';
  edges: Maybe<Array<Maybe<ProjectChangelogItemEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type ProjectChangelogItemEdge = {
  __typename?: 'ProjectChangelogItemEdge';
  node: Maybe<ProjectChangelogItem>;
  cursor: Scalars['String'];
};

export type ProjectConnection = {
  __typename?: 'ProjectConnection';
  edges: Maybe<Array<Maybe<ProjectEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type ProjectDependency = Node & {
  __typename?: 'ProjectDependency';
  created: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  incomingProject: Maybe<Project>;
  linkType: Maybe<ProjectDependencyRelationship>;
  outgoingProject: Maybe<Project>;
};

export type ProjectDependencyConnection = {
  __typename?: 'ProjectDependencyConnection';
  edges: Maybe<Array<Maybe<ProjectDependencyEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type ProjectDependencyEdge = {
  __typename?: 'ProjectDependencyEdge';
  node: Maybe<ProjectDependency>;
  cursor: Scalars['String'];
};

export enum ProjectDependencyRelationship {
  DependedBy = 'DEPENDED_BY',
  DependsOn = 'DEPENDS_ON',
  Related = 'RELATED',
}

export type ProjectDepthData = {
  __typename?: 'ProjectDepthData';
  depth: Scalars['Int'];
  managerProfile: Maybe<UserPii>;
};

export type ProjectEdge = {
  __typename?: 'ProjectEdge';
  node: Maybe<Project>;
  cursor: Scalars['String'];
};

export type ProjectFusion = Node & {
  __typename?: 'ProjectFusion';
  creationDate: Maybe<Scalars['DateTime']>;
  creator: Maybe<User>;
  fields: Maybe<Array<Maybe<ProjectFusionField>>>;
  id: Scalars['ID'];
  issue: Maybe<GqlJiraIssue>;
  issueId: Scalars['Int'];
  project: Maybe<Project>;
};

export type ProjectFusionContext = {
  __typename?: 'ProjectFusionContext';
  orgId: Maybe<Scalars['String']>;
  startDateFieldId: Maybe<Scalars['String']>;
};

export enum ProjectFusionField {
  DueDate = 'DUE_DATE',
  Followers = 'FOLLOWERS',
  Labels = 'LABELS',
  StartDate = 'START_DATE',
  Status = 'STATUS',
  Summary = 'SUMMARY',
}

export enum ProjectFusionObjectType {
  Issue = 'ISSUE',
}

export type ProjectFusionOngoingSyncOptionsInput = {
  dueDate: Maybe<Scalars['String']>;
  labels: Scalars['Boolean'];
  startDate: Maybe<Scalars['String']>;
  summary: Maybe<Scalars['String']>;
};

export type ProjectFusionOptionsInput = {
  ongoing: Maybe<ProjectFusionOngoingSyncOptionsInput>;
};

export enum ProjectFusionProviders {
  Jira = 'JIRA',
}

export type ProjectFusionTargetInput = {
  baseUrl: Maybe<Scalars['String']>;
  objectId: Maybe<Scalars['String']>;
  objectType: Maybe<ProjectFusionObjectType>;
  provider: Maybe<ProjectFusionProviders>;
};

export type ProjectMember = {
  __typename?: 'ProjectMember';
  id: Maybe<ProjectMemberKey>;
  joined: Maybe<Scalars['DateTime']>;
  project: Maybe<Project>;
  user: Maybe<User>;
};

export type ProjectMemberConnection = {
  __typename?: 'ProjectMemberConnection';
  edges: Maybe<Array<Maybe<ProjectMemberEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type ProjectMemberEdge = {
  __typename?: 'ProjectMemberEdge';
  node: Maybe<ProjectMember>;
  cursor: Scalars['String'];
};

export type ProjectMemberKey = {
  __typename?: 'ProjectMemberKey';
  projectId: Maybe<Scalars['Int']>;
  userId: Maybe<Scalars['Int']>;
};

export enum ProjectPhase {
  Done = 'done',
  InProgress = 'in_progress',
  Paused = 'paused',
  Pending = 'pending',
  Cancelled = 'cancelled',
}

export type ProjectPhaseDetails = {
  __typename?: 'ProjectPhaseDetails';
  displayName: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Maybe<ProjectPhase>;
};

export type ProjectPhaseDetailsConnection = {
  __typename?: 'ProjectPhaseDetailsConnection';
  edges: Maybe<Array<Maybe<ProjectPhaseDetailsEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type ProjectPhaseDetailsEdge = {
  __typename?: 'ProjectPhaseDetailsEdge';
  node: Maybe<ProjectPhaseDetails>;
  cursor: Scalars['String'];
};

export enum ProjectRole {
  ProjectContributor = 'PROJECT_CONTRIBUTOR',
  ProjectViewer = 'PROJECT_VIEWER',
}

export enum ProjectSortEnum {
  CreationDateAsc = 'CREATION_DATE_ASC',
  CreationDateDesc = 'CREATION_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LatestUpdateDateAsc = 'LATEST_UPDATE_DATE_ASC',
  LatestUpdateDateDesc = 'LATEST_UPDATE_DATE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TargetDateAsc = 'TARGET_DATE_ASC',
  TargetDateDesc = 'TARGET_DATE_DESC',
  WatcherCountAsc = 'WATCHER_COUNT_ASC',
  WatcherCountDesc = 'WATCHER_COUNT_DESC',
  WatchingAsc = 'WATCHING_ASC',
  WatchingDesc = 'WATCHING_DESC',
}

export type ProjectState = {
  __typename?: 'ProjectState';
  atCompletionState: Maybe<CompletionProjectState>;
  label: Maybe<Scalars['String']>;
  localizedLabel: Maybe<LocalizationField>;
  value: Maybe<ProjectStateValue>;
};

export type ProjectStateConnection = {
  __typename?: 'ProjectStateConnection';
  count: Scalars['Int'];
  edges: Maybe<Array<Maybe<ProjectStateEdge>>>;
  pageInfo: PageInfo;
};

export type ProjectStateEdge = {
  __typename?: 'ProjectStateEdge';
  cursor: Scalars['String'];
  node: Maybe<ProjectState>;
};

export enum ProjectStateValue {
  Archived = 'archived',
  AtRisk = 'at_risk',
  Done = 'done',
  OffTrack = 'off_track',
  OnTrack = 'on_track',
  Paused = 'paused',
  Pending = 'pending',
  Cancelled = 'cancelled',
  NO_UPDATE = 'missed',
}

export type ProjectTeamLink = {
  __typename?: 'ProjectTeamLink';
  creationDate: Maybe<Scalars['DateTime']>;
  id: Maybe<ProjectTeamLinkId>;
  project: Maybe<Project>;
  team: Maybe<Team>;
};

export type ProjectTeamLinkConnection = {
  __typename?: 'ProjectTeamLinkConnection';
  edges: Maybe<Array<Maybe<ProjectTeamLinkEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type ProjectTeamLinkEdge = {
  __typename?: 'ProjectTeamLinkEdge';
  node: Maybe<ProjectTeamLink>;
  cursor: Scalars['String'];
};

export type ProjectTeamLinkId = {
  __typename?: 'ProjectTeamLinkId';
  projectId: Maybe<Scalars['Int']>;
  teamId: Maybe<Scalars['Int']>;
};

export type ProjectUpdate = Node & {
  __typename?: 'ProjectUpdate';
  ari: Scalars['String'];
  changelog: Maybe<Array<Maybe<ProjectChangelogItem>>>;
  comments: Maybe<CommentConnection>;
  creationDate: Maybe<Scalars['DateTime']>;
  creator: Maybe<User>;
  editDate: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lastEditedBy: Maybe<User>;
  learnings: Maybe<LearningConnection>;
  missedUpdate: Scalars['Boolean'];
  newPhase: Maybe<ProjectPhaseDetails>;
  newPhaseNew: Maybe<ProjectPhaseDetails>;
  newStatus: Maybe<Status>;
  newTargetDate: Maybe<Scalars['Date']>;
  newTargetDateConfidence: Scalars['Int'];
  notes: Maybe<Array<Maybe<UpdateNote>>>;
  newState: Maybe<ProjectState>;
  oldState: Maybe<ProjectState>;
  oldPhase: Maybe<ProjectPhaseDetails>;
  oldPhaseNew: Maybe<ProjectPhaseDetails>;
  oldStatus: Maybe<Status>;
  oldTargetDate: Maybe<Scalars['Date']>;
  oldTargetDateConfidence: Maybe<Scalars['Int']>;
  project: Maybe<Project>;
  summary: Maybe<Scalars['String']>;
  updateType: Maybe<UpdateType>;
  uuid: Maybe<Scalars['UUID']>;
};

export type ProjectUpdateCommentsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectUpdateLearningsArgs = {
  sort: Maybe<Array<Maybe<LearningSortEnum>>>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectUpdateNotesArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type ProjectUpdateConnection = {
  __typename?: 'ProjectUpdateConnection';
  edges: Maybe<Array<Maybe<ProjectUpdateEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type ProjectUpdateEdge = {
  __typename?: 'ProjectUpdateEdge';
  node: Maybe<ProjectUpdate>;
  cursor: Scalars['String'];
};

export enum ProjectVisibilityFilter {
  All = 'ALL',
  OnlyPrivate = 'ONLY_PRIVATE',
  OnlyPublic = 'ONLY_PUBLIC',
}

export type Query = {
  __typename?: 'Query';
  projects: Maybe<Array<Maybe<Project>>>;
  reportingLines: Maybe<ReportingLines>;
  helpPointersByTeam: Maybe<HelpPointerConnection>;
  projectFusionContext: Maybe<ProjectFusionContext>;
  townsquare: Maybe<Townsquare>;
  allStatuses: Maybe<StatusConnection>;
  goals: Maybe<Array<Maybe<Goal>>>;
  allPhases: Maybe<ProjectPhaseDetailsConnection>;
  projectTql: Maybe<ProjectConnection>;
  goalStatusSummary: Maybe<StatusSummary>;
  goal: Maybe<Goal>;
  explainTql: Maybe<TqlExplanation>;
  goalStates: Maybe<GoalStateConnection>;
  userAccessStatus: Maybe<UserAccessStatus>;
  managersTql: Maybe<CpusSearchUserConnection>;
  currentUser: Maybe<User>;
  allWorkspaces: Maybe<WorkspaceConnection>;
  slackChannels: Maybe<Array<Maybe<SlackChannel>>>;
  allGoalPhases: Maybe<GoalPhaseDetailsConnection>;
  userProfilesByAaids: Maybe<UserPiiConnection>;
  permissionsInspector: Maybe<PermissionsInspectorResult>;
  recommendedQuestionsForUser: Maybe<QuestionConnection>;
  userWorkspaceByContext: Maybe<UserWorkspaceByContextResponse>;
  allQuestions: Maybe<QuestionConnection>;
  hasGoals: Maybe<HasRecords>;
  workspaceCount: Maybe<RecordCount>;
  userSearch: Maybe<Array<Maybe<User>>>;
  helpPointerTql: Maybe<HelpPointerConnection>;
  peopleTql: Maybe<CpusSearchUserConnection>;
  kudosTql: Maybe<KudosConnection>;
  teamsTql: Maybe<TeamSearchResponseConnection>;
  statusSummary: Maybe<StatusSummary>;
  allWatchedUpdatesV2: Maybe<UpdateConnection>;
  allProjectPhases: Maybe<ProjectPhaseDetailsConnection>;
  questionById: Maybe<Question>;
  projectStates: Maybe<ProjectStateConnection>;
  projectStatusSummaryForTag: Maybe<StatusSummaryForTag>;
  trendingTags: Maybe<TagConnection>;
  goalTqlFullHierarchy: Maybe<GoalConnection>;
  peopleFieldAggregates: Maybe<PeopleFieldAggregateResponse>;
  workspace: Maybe<Workspace>;
  projectByKey: Maybe<Project>;
  tagsByUuid: Maybe<Array<Maybe<Tag>>>;
  allWatchedGoalUpdates: Maybe<UpdateConnection>;
  project: Maybe<Project>;
  tagByUuid: Maybe<Tag>;
  goalStatusSummaryForTag: Maybe<StatusSummaryForTag>;
  projectUpdate: Maybe<ProjectUpdate>;
  goalSearch: Maybe<GoalConnection>;
  kudosByUuid: Maybe<Kudos>;
  tag: Maybe<Tag>;
  allWatchedProjectUpdates: Maybe<UpdateConnection>;
  teamByTeamId: Maybe<Team>;
  suggestedTagsForQuestion: Maybe<Array<Maybe<Tag>>>;
  goalUpdate: Maybe<GoalUpdate>;
  questionTql: Maybe<QuestionConnection>;
  findParentGoalSuggestions: Maybe<GoalConnection>;
  tagSearch: Maybe<TagConnection>;
  goalByKey: Maybe<Goal>;
  projectStatusSummary: Maybe<StatusSummary>;
  suggestedQuestionsForQuestion: Maybe<Array<Maybe<Question>>>;
  helpPointerSearch: Maybe<HelpPointerSearchResponse>;
  allHelpPointers: Maybe<HelpPointerConnection>;
  updatesWithTeam: Maybe<UpdateConnection>;
  projectSearchByOrgId: Maybe<ProjectConnection>;
  questionByUuid: Maybe<Question>;
  preferredWorkspace: Maybe<Workspace>;
  userByAaid: Maybe<User>;
  tagSearchByCloudId: Maybe<TagConnection>;
  learningsByTeam: Maybe<LearningConnection>;
  isFeatureEnabled: Maybe<IsFeatureEnabledResponse>;
  updatesWithTag: Maybe<UpdateConnection>;
  projectSearch: Maybe<ProjectConnection>;
  goalTql: Maybe<GoalConnection>;
  canCreateProjectFusion: Maybe<CanCreateProjectFusionResponse>;
  findSubGoalSuggestions: Maybe<GoalConnection>;
  tagTql: Maybe<TagConnection>;
  teamsByTeamIds: Maybe<TeamConnection>;
  hasProjects: Maybe<HasRecords>;
  user: Maybe<User>;
  node: Maybe<Node>;
};

export type QueryProjectsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryReportingLinesArgs = {
  aaidOrHash: Maybe<Scalars['String']>;
};

export type QueryHelpPointersByTeamArgs = {
  teamId: Scalars['String'];
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type QueryProjectFusionContextArgs = {
  baseUrl: Scalars['String'];
  issueKey: Scalars['String'];
};

export type QueryAllStatusesArgs = {
  after: Maybe<Scalars['String']>;
  sort: Maybe<Array<Maybe<StatusSortEnum>>>;
  first: Maybe<Scalars['Int']>;
};

export type QueryGoalsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryAllPhasesArgs = {
  after: Maybe<Scalars['String']>;
  sort: Maybe<Array<Maybe<PhaseSortEnum>>>;
  first: Maybe<Scalars['Int']>;
};

export type QueryProjectTqlArgs = {
  q: Maybe<Scalars['String']>;
  managerAaids: Maybe<Array<Maybe<Scalars['String']>>>;
  after: Maybe<Scalars['String']>;
  sort: Maybe<Array<Maybe<ProjectSortEnum>>>;
  first: Maybe<Scalars['Int']>;
  shouldRefetch: Maybe<Scalars['Boolean']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type QueryGoalStatusSummaryArgs = {
  endDate: Maybe<Scalars['DateTime']>;
  startDate: Maybe<Scalars['DateTime']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type QueryGoalArgs = {
  id: Scalars['ID'];
};

export type QueryExplainTqlArgs = {
  q: Scalars['String'];
  entity: Maybe<TqlEntity>;
};

export type QueryGoalStatesArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type QueryManagersTqlArgs = {
  q: Scalars['String'];
  organisationId: Scalars['String'];
  cloudId: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  sort: Maybe<Array<Maybe<DirectorySortEnum>>>;
  first: Maybe<Scalars['Int']>;
};

export type QueryCurrentUserArgs = {
  workspaceId: Maybe<Scalars['ID']>;
};

export type QueryAllWorkspacesArgs = {
  includePending: Maybe<Scalars['Boolean']>;
  organisationId: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type QuerySlackChannelsArgs = {
  resourceId: Maybe<Scalars['String']>;
  teamId: Scalars['String'];
  name: Maybe<Scalars['String']>;
  channelType: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  resourceType: Maybe<Scalars['String']>;
};

export type QueryAllGoalPhasesArgs = {
  after: Maybe<Scalars['String']>;
  sort: Maybe<Array<Maybe<PhaseSortEnum>>>;
  first: Maybe<Scalars['Int']>;
};

export type QueryUserProfilesByAaidsArgs = {
  after: Maybe<Scalars['String']>;
  aaids: Array<Maybe<Scalars['String']>>;
  first: Maybe<Scalars['Int']>;
};

export type QueryPermissionsInspectorArgs = {
  entityId: Scalars['String'];
  user: Scalars['String'];
};

export type QueryRecommendedQuestionsForUserArgs = {
  cloudId: Scalars['String'];
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type QueryUserWorkspaceByContextArgs = {
  workspaceContext: Maybe<WorkspaceContextInput>;
};

export type QueryAllQuestionsArgs = {
  cloudId: Scalars['String'];
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type QueryHasGoalsArgs = {
  includeArchived: Maybe<Scalars['Boolean']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type QueryWorkspaceCountArgs = {
  includePending: Maybe<Scalars['Boolean']>;
};

export type QueryUserSearchArgs = {
  workspaceUuids: Maybe<Array<Maybe<Scalars['UUID']>>>;
  q: Maybe<Scalars['String']>;
};

export type QueryHelpPointerTqlArgs = {
  q: Scalars['String'];
  after: Maybe<Scalars['String']>;
  sort: Maybe<Array<Maybe<HelpPointerSortEnum>>>;
  first: Maybe<Scalars['Int']>;
  workspaceUuid: Scalars['UUID'];
};

export type QueryPeopleTqlArgs = {
  q: Scalars['String'];
  organisationId: Scalars['String'];
  cloudId: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  sort: Maybe<Array<Maybe<DirectorySortEnum>>>;
  first: Maybe<Scalars['Int']>;
};

export type QueryKudosTqlArgs = {
  q: Scalars['String'];
  countOnly: Maybe<Scalars['Boolean']>;
  after: Maybe<Scalars['String']>;
  sort: Maybe<Array<Maybe<KudosSortEnum>>>;
  first: Maybe<Scalars['Int']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type QueryTeamsTqlArgs = {
  q: Scalars['String'];
  organisationId: Scalars['String'];
  after: Maybe<Scalars['String']>;
  sort: Maybe<Array<Maybe<DirectoryTeamSortEnum>>>;
  first: Maybe<Scalars['Int']>;
};

export type QueryStatusSummaryArgs = {
  endDate: Maybe<Scalars['DateTime']>;
  startDate: Maybe<Scalars['DateTime']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type QueryAllWatchedUpdatesV2Args = {
  createdBefore: Maybe<Scalars['DateTime']>;
  after: Maybe<Scalars['String']>;
  createdAfter: Maybe<Scalars['DateTime']>;
  first: Maybe<Scalars['Int']>;
};

export type QueryAllProjectPhasesArgs = {
  after: Maybe<Scalars['String']>;
  sort: Maybe<Array<Maybe<PhaseSortEnum>>>;
  first: Maybe<Scalars['Int']>;
};

export type QueryQuestionByIdArgs = {
  id: Scalars['ID'];
};

export type QueryProjectStatesArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type QueryProjectStatusSummaryForTagArgs = {
  tagUuid: Maybe<Scalars['UUID']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type QueryTrendingTagsArgs = {
  workspaceUUID: Maybe<Scalars['UUID']>;
  first: Maybe<Scalars['Int']>;
};

export type QueryGoalTqlFullHierarchyArgs = {
  q: Scalars['String'];
  managerAaids: Maybe<Array<Maybe<Scalars['String']>>>;
  after: Maybe<Scalars['String']>;
  childrenOf: Maybe<Scalars['String']>;
  sorts: Maybe<Array<Maybe<GoalSortEnum>>>;
  first: Maybe<Scalars['Int']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type QueryPeopleFieldAggregatesArgs = {
  searchString: Maybe<Scalars['String']>;
  peopleFieldType: Maybe<PeopleFieldType>;
  sort: Maybe<PeopleFieldAggregateSort>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type QueryWorkspaceArgs = {
  id: Scalars['ID'];
};

export type QueryProjectByKeyArgs = {
  trackViewEvent: Maybe<TrackViewEvent>;
  key: Scalars['String'];
};

export type QueryTagsByUuidArgs = {
  uuids: Maybe<Array<Maybe<Scalars['UUID']>>>;
};

export type QueryAllWatchedGoalUpdatesArgs = {
  stateFilter: Maybe<UpdateStateFilter>;
  createdBefore: Maybe<Scalars['DateTime']>;
  after: Maybe<Scalars['String']>;
  createdAfter: Maybe<Scalars['DateTime']>;
  workspaceUUID: Maybe<Scalars['UUID']>;
  first: Maybe<Scalars['Int']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type QueryProjectArgs = {
  id: Scalars['ID'];
};

export type QueryTagByUuidArgs = {
  uuid: Maybe<Scalars['UUID']>;
};

export type QueryGoalStatusSummaryForTagArgs = {
  tagUuid: Maybe<Scalars['UUID']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type QueryProjectUpdateArgs = {
  id: Scalars['ID'];
};

export type QueryGoalSearchArgs = {
  watcher: Maybe<Array<Maybe<Scalars['String']>>>;
  phase: Maybe<Array<Maybe<Scalars['String']>>>;
  owner: Maybe<Array<Maybe<Scalars['String']>>>;
  sort: Maybe<Array<Maybe<GoalSortEnum>>>;
  createdAfter: Maybe<Scalars['DateTime']>;
  rootLevelGoal: Maybe<Scalars['Boolean']>;
  archived: Maybe<Scalars['Boolean']>;
  q: Maybe<Scalars['String']>;
  teamId: Maybe<Array<Maybe<Scalars['String']>>>;
  after: Maybe<Scalars['String']>;
  createdBefore: Maybe<Scalars['DateTime']>;
  tag: Maybe<Array<Maybe<Scalars['UUID']>>>;
  first: Maybe<Scalars['Int']>;
  expandGoals: Maybe<Array<Maybe<Scalars['ID']>>>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type QueryKudosByUuidArgs = {
  uuid: Scalars['UUID'];
};

export type QueryTagArgs = {
  id: Scalars['ID'];
};

export type QueryAllWatchedProjectUpdatesArgs = {
  stateFilter: Maybe<UpdateStateFilter>;
  createdBefore: Maybe<Scalars['DateTime']>;
  after: Maybe<Scalars['String']>;
  createdAfter: Maybe<Scalars['DateTime']>;
  workspaceUUID: Maybe<Scalars['UUID']>;
  first: Maybe<Scalars['Int']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type QueryTeamByTeamIdArgs = {
  teamId: Scalars['String'];
};

export type QuerySuggestedTagsForQuestionArgs = {
  cloudId: Scalars['String'];
  description: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type QueryGoalUpdateArgs = {
  id: Scalars['ID'];
};

export type QueryQuestionTqlArgs = {
  q: Scalars['String'];
  countOnly: Maybe<Scalars['Boolean']>;
  cloudId: Scalars['String'];
  after: Maybe<Scalars['String']>;
  sort: Maybe<Array<Maybe<QuestionSortEnum>>>;
  first: Maybe<Scalars['Int']>;
};

export type QueryFindParentGoalSuggestionsArgs = {
  q: Maybe<Scalars['String']>;
  goalId: Scalars['ID'];
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type QueryTagSearchArgs = {
  filter: Maybe<TagEntityFiltersInput>;
  q: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  sort: Maybe<Array<Maybe<TagSortEnum>>>;
  first: Maybe<Scalars['Int']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type QueryGoalByKeyArgs = {
  trackViewEvent: Maybe<TrackViewEvent>;
  key: Scalars['String'];
};

export type QueryProjectStatusSummaryArgs = {
  endDate: Maybe<Scalars['DateTime']>;
  startDate: Maybe<Scalars['DateTime']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type QuerySuggestedQuestionsForQuestionArgs = {
  cutoffDistance: Maybe<Scalars['Float']>;
  cloudId: Scalars['String'];
  description: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type QueryHelpPointerSearchArgs = {
  q: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type QueryAllHelpPointersArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type QueryUpdatesWithTeamArgs = {
  teamId: Scalars['String'];
  after: Maybe<Scalars['String']>;
  workspaceUUID: Maybe<Scalars['UUID']>;
  first: Maybe<Scalars['Int']>;
};

export type QueryProjectSearchByOrgIdArgs = {
  archived: Maybe<Scalars['Boolean']>;
  q: Maybe<Scalars['String']>;
  organisationId: Scalars['String'];
  after: Maybe<Scalars['String']>;
  sort: Maybe<Array<Maybe<ProjectSortEnum>>>;
  first: Maybe<Scalars['Int']>;
};

export type QueryQuestionByUuidArgs = {
  uuid: Scalars['UUID'];
};

export type QueryUserByAaidArgs = {
  aaid: Scalars['String'];
  workspaceId: Maybe<Scalars['ID']>;
};

export type QueryTagSearchByCloudIdArgs = {
  q: Maybe<Scalars['String']>;
  cloudId: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  sort: Maybe<Array<Maybe<TagSortEnum>>>;
  first: Maybe<Scalars['Int']>;
};

export type QueryLearningsByTeamArgs = {
  teamId: Scalars['String'];
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type QueryIsFeatureEnabledArgs = {
  context: Maybe<Array<Maybe<IsFeatureEnabledContextInput>>>;
  featureKey: Scalars['String'];
};

export type QueryUpdatesWithTagArgs = {
  tagUuid: Maybe<Scalars['UUID']>;
  after: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type QueryProjectSearchArgs = {
  phase: Maybe<Array<Maybe<Scalars['String']>>>;
  watcher: Maybe<Array<Maybe<Scalars['String']>>>;
  owner: Maybe<Array<Maybe<Scalars['String']>>>;
  targetDateAfter: Maybe<Scalars['String']>;
  visibility: Maybe<ProjectVisibilityFilter>;
  targetDateBefore: Maybe<Scalars['String']>;
  sort: Maybe<Array<Maybe<ProjectSortEnum>>>;
  createdAfter: Maybe<Scalars['String']>;
  archived: Maybe<Scalars['Boolean']>;
  q: Maybe<Scalars['String']>;
  contributor: Maybe<Array<Maybe<Scalars['String']>>>;
  commHealthRating: Maybe<Array<Maybe<CommunicationHealth>>>;
  teamId: Maybe<Array<Maybe<Scalars['String']>>>;
  after: Maybe<Scalars['String']>;
  createdBefore: Maybe<Scalars['String']>;
  tag: Maybe<Array<Maybe<Scalars['UUID']>>>;
  first: Maybe<Scalars['Int']>;
  workspaceId: Maybe<Scalars['ID']>;
  status: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type QueryGoalTqlArgs = {
  q: Scalars['String'];
  after: Maybe<Scalars['String']>;
  sorts: Maybe<Array<Maybe<GoalSortEnum>>>;
  first: Maybe<Scalars['Int']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type QueryCanCreateProjectFusionArgs = {
  baseUrl: Scalars['String'];
  issueKey: Scalars['String'];
  projectId: Scalars['ID'];
};

export type QueryFindSubGoalSuggestionsArgs = {
  q: Maybe<Scalars['String']>;
  goalId: Scalars['ID'];
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type QueryTagTqlArgs = {
  q: Scalars['String'];
  after: Maybe<Scalars['String']>;
  sort: Maybe<Array<Maybe<TagSortEnum>>>;
  workspaceUUID: Maybe<Scalars['UUID']>;
  first: Maybe<Scalars['Int']>;
};

export type QueryTeamsByTeamIdsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  teamIds: Array<Maybe<Scalars['String']>>;
};

export type QueryHasProjectsArgs = {
  includeArchived: Maybe<Scalars['Boolean']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryNodeArgs = {
  id: Scalars['ID'];
};

export type Question = Node & {
  __typename?: 'Question';
  answerCount: Scalars['Int'];
  answers: Maybe<AnswerConnection>;
  ari: Scalars['String'];
  creationDate: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  hasUpvoted: Scalars['Boolean'];
  id: Scalars['ID'];
  lastEditedDate: Maybe<Scalars['DateTime']>;
  owner: Maybe<User>;
  tags: Maybe<TagConnection>;
  title: Maybe<Scalars['String']>;
  upvoteCount: Scalars['Int'];
  upvoteUsers: Maybe<UserConnection>;
  uuid: Maybe<Scalars['UUID']>;
};

export type QuestionAnswersArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type QuestionTagsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type QuestionUpvoteUsersArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type QuestionConnection = {
  __typename?: 'QuestionConnection';
  edges: Maybe<Array<Maybe<QuestionEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type QuestionEdge = {
  __typename?: 'QuestionEdge';
  node: Maybe<Question>;
  cursor: Scalars['String'];
};

export enum QuestionSortEnum {
  AnswerCountAsc = 'ANSWER_COUNT_ASC',
  AnswerCountDesc = 'ANSWER_COUNT_DESC',
  CreationDateAsc = 'CREATION_DATE_ASC',
  CreationDateDesc = 'CREATION_DATE_DESC',
  LatestUpdateDateAsc = 'LATEST_UPDATE_DATE_ASC',
  LatestUpdateDateDesc = 'LATEST_UPDATE_DATE_DESC',
  UpvoteCountAsc = 'UPVOTE_COUNT_ASC',
  UpvoteCountDesc = 'UPVOTE_COUNT_DESC',
}

export type RecordCount = {
  __typename?: 'RecordCount';
  recordCount: Scalars['Int'];
};

export type ReportCounts = {
  __typename?: 'ReportCounts';
  directReports: Scalars['Int'];
  totalReports: Scalars['Int'];
};

export type ReportingLines = {
  __typename?: 'ReportingLines';
  managers: Maybe<Array<Maybe<ReportingLinesUser>>>;
  peers: Maybe<Array<Maybe<ReportingLinesUser>>>;
  reports: Maybe<Array<Maybe<ReportingLinesUser>>>;
  user: Maybe<ReportingLinesUser>;
};

export type ReportingLinesUser = {
  __typename?: 'ReportingLinesUser';
  accountIdentifier: Maybe<Scalars['String']>;
  identifierType: Maybe<AccountIdentifierType>;
  numberOfDirectReports: Scalars['Int'];
  numberOfTotalReports: Scalars['Int'];
  pii: Maybe<UserPii>;
};

export type RoleSerialized = {
  __typename?: 'RoleSerialized';
  description: Maybe<Scalars['String']>;
  extends: Maybe<Array<Maybe<RoleSerialized>>>;
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  permissions: Maybe<Array<Maybe<PermissionGrantSerialized>>>;
};

export enum SiteInviteState {
  Failed = 'FAILED',
  Invited = 'INVITED',
  Pending = 'PENDING',
}

export type SlackChannel = {
  __typename?: 'SlackChannel';
  creator: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  loggedIn: Maybe<Scalars['Boolean']>;
  name: Maybe<Scalars['String']>;
  numMembers: Maybe<Scalars['Int']>;
  private: Maybe<Scalars['Boolean']>;
  slackTeamName: Maybe<Scalars['String']>;
};

export type SlackConnection = {
  __typename?: 'SlackConnection';
  channel: Maybe<SlackChannel>;
  creationDate: Maybe<Scalars['String']>;
  subscriberAaid: Maybe<Scalars['String']>;
  uuid: Maybe<Scalars['String']>;
};

export type SlackConnectionConnection = {
  __typename?: 'SlackConnectionConnection';
  edges: Maybe<Array<Maybe<SlackConnectionEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type SlackConnectionEdge = {
  __typename?: 'SlackConnectionEdge';
  node: Maybe<SlackConnection>;
  cursor: Scalars['String'];
};

export type Status = Node & {
  __typename?: 'Status';
  displayName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  order: Scalars['Int'];
};

export type StatusConnection = {
  __typename?: 'StatusConnection';
  edges: Maybe<Array<Maybe<StatusEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type StatusEdge = {
  __typename?: 'StatusEdge';
  node: Maybe<Status>;
  cursor: Scalars['String'];
};

export enum StatusSortEnum {
  OrderAsc = 'ORDER_ASC',
  OrderDesc = 'ORDER_DESC',
}

export type StatusSummary = {
  __typename?: 'StatusSummary';
  active: Scalars['Int'];
  atRisk: Scalars['Int'];
  done: Scalars['Int'];
  missed: Scalars['Int'];
  offTrack: Scalars['Int'];
  onTrack: Scalars['Int'];
};

export type StatusSummaryForTag = {
  __typename?: 'StatusSummaryForTag';
  atRisk: Scalars['Int'];
  done: Scalars['Int'];
  offTrack: Scalars['Int'];
  onTrack: Scalars['Int'];
  paused: Scalars['Int'];
  pending: Scalars['Int'];
};

export type TqlExplanation = {
  __typename?: 'TQLExplanation';
  availableFields: Maybe<Array<Maybe<TqlExplanationField>>>;
  costThreshold: Scalars['Int'];
  entity: Maybe<TqlEntity>;
  explanation: Maybe<Scalars['String']>;
  logSafeQuery: Maybe<Scalars['String']>;
  overBudget: Scalars['Boolean'];
  queryCost: Scalars['Int'];
};

export type TqlExplanationField = {
  __typename?: 'TQLExplanationField';
  aliases: Maybe<Array<Maybe<Scalars['String']>>>;
  cost: Scalars['Int'];
  description: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  operators: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Tag = Node & {
  __typename?: 'Tag';
  creationDate: Maybe<Scalars['DateTime']>;
  description: Maybe<Scalars['String']>;
  goalUsageCount: Scalars['Int'];
  goals: Maybe<GoalConnection>;
  helpLinks: Maybe<HelpPointerConnection>;
  helpPointerUsageCount: Scalars['Int'];
  iconData: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  projectUsageCount: Scalars['Int'];
  projects: Maybe<ProjectConnection>;
  questionUsageCount: Scalars['Int'];
  questions: Maybe<QuestionConnection>;
  topContributors: Maybe<UserConnection>;
  topTeams: Maybe<TeamConnection>;
  usageCount: Scalars['Int'];
  uuid: Maybe<Scalars['UUID']>;
  watcherCount: Scalars['Int'];
  watchers: Maybe<UserConnection>;
  watching: Maybe<Scalars['Boolean']>;
  workspaceId: Scalars['Int'];
};

export type TagGoalsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type TagHelpLinksArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type TagProjectsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type TagQuestionsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type TagTopContributorsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type TagTopTeamsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type TagWatchersArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type TagConnection = {
  __typename?: 'TagConnection';
  edges: Maybe<Array<Maybe<TagEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type TagEdge = {
  __typename?: 'TagEdge';
  node: Maybe<Tag>;
  cursor: Scalars['String'];
};

export type TagEntityFiltersInput = {
  questions: Maybe<ComparatorsInput>;
};

export enum TagSortEnum {
  EntityCountDesc = 'ENTITY_COUNT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  UsageAsc = 'USAGE_ASC',
  UsageDesc = 'USAGE_DESC',
  WatcherAsc = 'WATCHER_ASC',
  WatcherDesc = 'WATCHER_DESC',
}

export type TargetDate = {
  __typename?: 'TargetDate';
  confidence: Maybe<TargetDateType>;
  dateRange: Maybe<TargetDateRange>;
  label: Maybe<Scalars['String']>;
  overdue: Maybe<Scalars['Boolean']>;
};

export type TargetDateLabelArgs = {
  longFormat: Maybe<Scalars['Boolean']>;
};

export type TargetDateRange = {
  __typename?: 'TargetDateRange';
  end: Maybe<Scalars['Instant']>;
  start: Maybe<Scalars['Instant']>;
};

export enum TargetDateType {
  Day = 'DAY',
  Month = 'MONTH',
  Quarter = 'QUARTER',
}

export type Team = Node & {
  __typename?: 'Team';
  avatarUrl: Maybe<Scalars['String']>;
  goals: Maybe<GoalConnection>;
  helpPointers: Maybe<HelpPointerConnection>;
  id: Scalars['ID'];
  members: Maybe<TeamMemberConnection>;
  name: Maybe<Scalars['String']>;
  permission: Maybe<TeamPermission>;
  projects: Maybe<ProjectConnection>;
  teamId: Maybe<Scalars['String']>;
  watchers: Maybe<UserConnection>;
  watching: Maybe<Scalars['Boolean']>;
};

export type TeamGoalsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type TeamHelpPointersArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type TeamMembersArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type TeamProjectsArgs = {
  after: Maybe<Scalars['String']>;
  tql: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type TeamWatchersArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type TeamConnection = {
  __typename?: 'TeamConnection';
  edges: Maybe<Array<Maybe<TeamEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export enum TeamDiscoverability {
  Discoverable = 'DISCOVERABLE',
}

export type TeamEdge = {
  __typename?: 'TeamEdge';
  node: Maybe<Team>;
  cursor: Scalars['String'];
};

export type TeamMember = {
  __typename?: 'TeamMember';
  aaid: Maybe<Scalars['String']>;
  pii: Maybe<UserPii>;
  state: Maybe<MembershipState>;
};

export type TeamMemberConnection = {
  __typename?: 'TeamMemberConnection';
  edges: Maybe<Array<Maybe<TeamMemberEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type TeamMemberEdge = {
  __typename?: 'TeamMemberEdge';
  node: Maybe<TeamMember>;
  cursor: Scalars['String'];
};

export enum TeamMembershipSettings {
  AdminInvite = 'ADMIN_INVITE',
  MemberInvite = 'MEMBER_INVITE',
  Open = 'OPEN',
}

export enum TeamPermission {
  FullRead = 'FULL_READ',
  FullWrite = 'FULL_WRITE',
  None = 'NONE',
}

export type TeamResponseBody = {
  __typename?: 'TeamResponseBody';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

export enum TeamRestriction {
  NoRestriction = 'NO_RESTRICTION',
  OrgMembers = 'ORG_MEMBERS',
}

export type TeamSearchResponse = {
  __typename?: 'TeamSearchResponse';
  description: Maybe<Scalars['String']>;
  discoverable: Maybe<TeamDiscoverability>;
  displayName: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  largeAvatarImageUrl: Maybe<Scalars['String']>;
  members: Maybe<TeamMemberConnection>;
  membershipSettings: Maybe<TeamMembershipSettings>;
  organizationId: Maybe<Scalars['String']>;
  restriction: Maybe<TeamRestriction>;
  smallAvatarImageUrl: Maybe<Scalars['String']>;
  state: Maybe<TeamState>;
};

export type TeamSearchResponseMembersArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type TeamSearchResponseConnection = {
  __typename?: 'TeamSearchResponseConnection';
  edges: Maybe<Array<Maybe<TeamSearchResponseEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type TeamSearchResponseEdge = {
  __typename?: 'TeamSearchResponseEdge';
  node: Maybe<TeamSearchResponse>;
  cursor: Scalars['String'];
};

export enum TeamState {
  Active = 'ACTIVE',
  Disbanded = 'DISBANDED',
  Purged = 'PURGED',
}

export type TenantResponseBody = {
  __typename?: 'TenantResponseBody';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

export type ThemeUrIs = {
  __typename?: 'ThemeURIs';
  dark: Maybe<Scalars['URI']>;
  light: Maybe<Scalars['URI']>;
};

export type Townsquare = {
  __typename?: 'Townsquare';
  commentsByAri: Maybe<Array<Maybe<Comment>>>;
  goalByKey: Maybe<Goal>;
  goalSearch: Maybe<GoalConnection>;
  goalsByAri: Maybe<Array<Maybe<Goal>>>;
  helpPointerSearch: Maybe<HelpPointerSearchResponse>;
  helpPointersByCloudIds: Maybe<HelpPointerConnection>;
  helpPointersByTeam: Maybe<HelpPointerConnection>;
  projectByKey: Maybe<Project>;
  projectSearch: Maybe<ProjectConnection>;
  projectsByAri: Maybe<Array<Maybe<Project>>>;
};

export type TownsquareCommentsByAriArgs = {
  aris: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TownsquareGoalByKeyArgs = {
  key: Scalars['String'];
};

export type TownsquareGoalSearchArgs = {
  q: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  after: Maybe<Scalars['String']>;
  sort: Maybe<Array<Maybe<GoalSortEnum>>>;
  first: Maybe<Scalars['Int']>;
};

export type TownsquareGoalsByAriArgs = {
  aris: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TownsquareHelpPointerSearchArgs = {
  q: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  cloudIds: Maybe<Array<Maybe<Scalars['String']>>>;
  first: Maybe<Scalars['Int']>;
};

export type TownsquareHelpPointersByCloudIdsArgs = {
  after: Maybe<Scalars['String']>;
  cloudIds: Maybe<Array<Maybe<Scalars['String']>>>;
  first: Maybe<Scalars['Int']>;
};

export type TownsquareHelpPointersByTeamArgs = {
  teamId: Scalars['String'];
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type TownsquareProjectByKeyArgs = {
  key: Scalars['String'];
};

export type TownsquareProjectSearchArgs = {
  phase: Maybe<Array<Maybe<Scalars['String']>>>;
  q: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  after: Maybe<Scalars['String']>;
  sort: Maybe<Array<Maybe<ProjectSortEnum>>>;
  first: Maybe<Scalars['Int']>;
};

export type TownsquareProjectsByAriArgs = {
  aris: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum TqlEntity {
  Goal = 'GOAL',
  HelpPointer = 'HELP_POINTER',
  Kudos = 'KUDOS',
  Person = 'PERSON',
  Project = 'PROJECT',
  Question = 'QUESTION',
  Tag = 'TAG',
  Team = 'TEAM',
  Unknown = 'UNKNOWN',
}

export enum TrackViewEvent {
  Direct = 'DIRECT',
  Indirect = 'INDIRECT',
  Skip = 'SKIP',
}

export type Update = GoalUpdate | ProjectUpdate;

export type UpdateConnection = {
  __typename?: 'UpdateConnection';
  edges: Maybe<Array<Maybe<UpdateEdge>>>;
  pageInfo: PageInfo;
};

export type UpdateEdge = {
  __typename?: 'UpdateEdge';
  node: Maybe<Update>;
  cursor: Scalars['String'];
};

export type UpdateNote = Node & {
  __typename?: 'UpdateNote';
  archived: Scalars['Boolean'];
  creationDate: Maybe<Scalars['DateTime']>;
  creator: Maybe<User>;
  goalUpdate: Maybe<GoalUpdate>;
  id: Scalars['ID'];
  index: Maybe<Scalars['Int']>;
  projectUpdate: Maybe<ProjectUpdate>;
  summary: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  uuid: Maybe<Scalars['UUID']>;
};

export type UpdateNoteInput = {
  archived: Maybe<Scalars['Boolean']>;
  summary: Scalars['String'];
  title: Maybe<Scalars['String']>;
  uuid: Maybe<Scalars['String']>;
};

export enum UpdateStateFilter {
  Active = 'ACTIVE',
  AtRisk = 'AT_RISK',
  Done = 'DONE',
  NoUpdate = 'NO_UPDATE',
  OffTrack = 'OFF_TRACK',
  OnTrack = 'ON_TRACK',
  Paused = 'PAUSED',
  Pending = 'PENDING',
}

export enum UpdateType {
  System = 'SYSTEM',
  User = 'USER',
}

export enum UpgradeRequestVariation {
  AtlasPremium = 'ATLAS_PREMIUM',
  Generic = 'GENERIC',
  GoalScoringMode = 'GOAL_SCORING_MODE',
  PrivateProjects = 'PRIVATE_PROJECTS',
}

export type UpvoteEntities = Answer | Question;

export type User = Node & {
  __typename?: 'User';
  aaid: Maybe<Scalars['String']>;
  emailReminderEnabled: Scalars['Boolean'];
  emailSummaryEnabled: Scalars['Boolean'];
  goals: Maybe<GoalConnection>;
  goalsWatching: Maybe<GoalConnection>;
  id: Scalars['ID'];
  memberOfProjects: Maybe<ProjectConnection>;
  msteamsReminderEnabled: Scalars['Boolean'];
  msteamsSummaryEnabled: Scalars['Boolean'];
  msteamsTenants: Maybe<Array<Maybe<TenantResponseBody>>>;
  onboarded: Scalars['Boolean'];
  onboarding: Maybe<OnboardingConnection>;
  pii: Maybe<UserPii>;
  projects: Maybe<ProjectConnection>;
  slackReminderEnabled: Scalars['Boolean'];
  slackSummaryEnabled: Scalars['Boolean'];
  slackTeams: Maybe<Array<Maybe<TeamResponseBody>>>;
  tags: Maybe<UserTagConnection>;
  tagsWatching: Maybe<TagConnection>;
  teams: Maybe<TeamConnection>;
  teamsWatching: Maybe<TeamConnection>;
  watching: Maybe<ProjectConnection>;
};

export type UserGoalsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type UserGoalsWatchingArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type UserMemberOfProjectsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type UserOnboardingArgs = {
  filter: Maybe<OnboardingFilterInput>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type UserProjectsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type UserTagsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type UserTagsWatchingArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type UserTeamsArgs = {
  organisationId: Maybe<Scalars['String']>;
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type UserTeamsWatchingArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type UserWatchingArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  workspaceId: Maybe<Scalars['ID']>;
};

export type UserAccessStatus = {
  __typename?: 'UserAccessStatus';
  pendingInvites: Maybe<Array<Maybe<PendingInviteStatus>>>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Maybe<Array<Maybe<UserEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type UserEdge = {
  __typename?: 'UserEdge';
  node: Maybe<User>;
  cursor: Scalars['String'];
};

export type UserIdentifierInput = {
  accountId: Maybe<Scalars['String']>;
  addAsWatcher: Maybe<Scalars['Boolean']>;
  createPendingWorkspaceMembership: Scalars['Boolean'];
};

export type UserInvite = Node & {
  __typename?: 'UserInvite';
  applied: Scalars['Boolean'];
  creationDate: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  invitedAs: Maybe<InviteTypeEnum>;
  invitedBy: Maybe<User>;
  invitedTo: Maybe<Scalars['ID']>;
  siteInviteState: Maybe<SiteInviteState>;
  updatedDate: Maybe<Scalars['DateTime']>;
};

export type UserPii = {
  __typename?: 'UserPII';
  accountId: Maybe<Scalars['String']>;
  accountStatus: Maybe<AccountStatus>;
  accountType: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  extendedProfile: Maybe<ExtendedProfile>;
  locale: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  nickname: Maybe<Scalars['String']>;
  orgId: Maybe<Scalars['String']>;
  picture: Maybe<Scalars['String']>;
  zoneinfo: Maybe<Scalars['String']>;
};

export type UserPiiConnection = {
  __typename?: 'UserPIIConnection';
  edges: Maybe<Array<Maybe<UserPiiEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type UserPiiEdge = {
  __typename?: 'UserPIIEdge';
  node: Maybe<UserPii>;
  cursor: Scalars['String'];
};

export type UserTag = Node & {
  __typename?: 'UserTag';
  created: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  matchable: Scalars['Boolean'];
  tag: Maybe<Tag>;
  user: Maybe<User>;
};

export type UserTagConnection = {
  __typename?: 'UserTagConnection';
  edges: Maybe<Array<Maybe<UserTagEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type UserTagEdge = {
  __typename?: 'UserTagEdge';
  node: Maybe<UserTag>;
  cursor: Scalars['String'];
};

export type UserWorkspaceByContextResponse = {
  __typename?: 'UserWorkspaceByContextResponse';
  requestedContextMatchedType: Maybe<WorkspaceContextType>;
  workspace: Maybe<Workspace>;
};

export type UserWorkspacePermissions = {
  __typename?: 'UserWorkspacePermissions';
  admin: Scalars['Boolean'];
  billingAdmin: Scalars['Boolean'];
};

export type Workspace = Node & {
  __typename?: 'Workspace';
  cloudId: Scalars['String'];
  cloudName: Maybe<Scalars['String']>;
  cloudUrl: Maybe<Scalars['String']>;
  creationDate: Scalars['DateTime'];
  defaultCustomFields: Array<Maybe<DefaultCustomField>>;
  featureContext: Maybe<FeatureContext>;
  goalScoringMode: GoalScoringMode;
  id: Scalars['ID'];
  keyNumber: Scalars['Int'];
  keyPrefix: Scalars['String'];
  mediaClientId: Scalars['String'];
  members: Array<Maybe<WorkspaceMember>>;
  membersCount: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  organisationId: Scalars['String'];
  tags: Maybe<TagConnection>;
  timezone: Scalars['String'];
  userWorkspacePermissions: Maybe<UserWorkspacePermissions>;
  uuid: Scalars['UUID'];
};

export type WorkspaceTagsArgs = {
  after: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
};

export type WorkspaceConnection = {
  __typename?: 'WorkspaceConnection';
  edges: Maybe<Array<Maybe<WorkspaceEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type WorkspaceContextInput = {
  contextType: Maybe<WorkspaceContextType>;
  identifier: Maybe<Scalars['String']>;
};

export enum WorkspaceContextType {
  Default = 'Default',
  GoalKey = 'GoalKey',
  ProjectKey = 'ProjectKey',
  QuestionId = 'QuestionId',
  QuestionUuid = 'QuestionUuid',
  TagId = 'TagId',
  WorkspaceCloudId = 'WorkspaceCloudId',
  WorkspaceInOrgByCloudId = 'WorkspaceInOrgByCloudId',
}

export type WorkspaceEdge = {
  __typename?: 'WorkspaceEdge';
  node: Maybe<Workspace>;
  cursor: Scalars['String'];
};

export type WorkspaceFeatures = {
  __typename?: 'WorkspaceFeatures';
  goalScoringMode: Scalars['Boolean'];
  privateProjects: Scalars['Boolean'];
};

export type WorkspaceMember = Node & {
  __typename?: 'WorkspaceMember';
  id: Scalars['ID'];
  status: Maybe<WorkspaceMembershipStatusType>;
  type: Maybe<WorkspaceMemberType>;
  user: Maybe<User>;
  workspace: Maybe<Workspace>;
};

export type WorkspaceMemberConnection = {
  __typename?: 'WorkspaceMemberConnection';
  edges: Maybe<Array<Maybe<WorkspaceMemberEdge>>>;
  pageInfo: PageInfo;
  count: Scalars['Int'];
};

export type WorkspaceMemberEdge = {
  __typename?: 'WorkspaceMemberEdge';
  node: Maybe<WorkspaceMember>;
  cursor: Scalars['String'];
};

export enum WorkspaceMemberType {
  User = 'user',
}

export enum WorkspaceMembershipStatusType {
  Active = 'ACTIVE',
  Pending = 'PENDING',
}

export type AddGoalProjectLinkInput = {
  clientMutationId: Maybe<Scalars['String']>;
  goalId: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type AddGoalProjectLinkPayload = {
  __typename?: 'addGoalProjectLinkPayload';
  clientMutationId: Maybe<Scalars['String']>;
  goal: Maybe<Goal>;
  project: Maybe<Project>;
};

export type AddProjectMemberInput = {
  clientMutationId: Maybe<Scalars['String']>;
  accountId: Maybe<Scalars['String']>;
  projectKey: Scalars['String'];
  member: Maybe<UserIdentifierInput>;
};

export type AddProjectMemberPayload = {
  __typename?: 'addProjectMemberPayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
};

export type AddProjectMembersInput = {
  clientMutationId: Maybe<Scalars['String']>;
  projectKey: Scalars['String'];
  members: Maybe<Array<Maybe<UserIdentifierInput>>>;
};

export type AddProjectMembersPayload = {
  __typename?: 'addProjectMembersPayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
  results: Maybe<Array<Maybe<AddMembersResult>>>;
};

export type AddProjectTeamLinkInput = {
  clientMutationId: Maybe<Scalars['String']>;
  projectKey: Scalars['String'];
  members: Maybe<Array<Maybe<Scalars['String']>>>;
  teamId: Scalars['String'];
};

export type AddProjectTeamLinkPayload = {
  __typename?: 'addProjectTeamLinkPayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
  team: Maybe<Team>;
};

export type AddProjectViewersInput = {
  clientMutationId: Maybe<Scalars['String']>;
  viewers: Array<Maybe<UserIdentifierInput>>;
  projectKey: Scalars['String'];
};

export type AddProjectViewersPayload = {
  __typename?: 'addProjectViewersPayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
};

export type AddTagsInput = {
  clientMutationId: Maybe<Scalars['String']>;
  tagUuids: Array<Maybe<Scalars['String']>>;
  id: Scalars['ID'];
};

export type AddTagsPayload = {
  __typename?: 'addTagsPayload';
  clientMutationId: Maybe<Scalars['String']>;
  tags: Maybe<Array<Maybe<Tag>>>;
};

export type AddTeamWatcherInput = {
  clientMutationId: Maybe<Scalars['String']>;
  teamId: Scalars['String'];
};

export type AddTeamWatcherPayload = {
  __typename?: 'addTeamWatcherPayload';
  clientMutationId: Maybe<Scalars['String']>;
  isWatching: Scalars['Boolean'];
};

export type AddUpvoteInput = {
  clientMutationId: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type AddUpvotePayload = {
  __typename?: 'addUpvotePayload';
  clientMutationId: Maybe<Scalars['String']>;
  isUpvoted: Scalars['Boolean'];
  upvoteEntity: Maybe<UpvoteEntities>;
};

export type AddUserTagInput = {
  clientMutationId: Maybe<Scalars['String']>;
  matchable: Scalars['Boolean'];
  tagUuid: Scalars['UUID'];
};

export type AddUserTagPayload = {
  __typename?: 'addUserTagPayload';
  clientMutationId: Maybe<Scalars['String']>;
  result: Maybe<User>;
};

export type AddWatcherByAccountIdInput = {
  clientMutationId: Maybe<Scalars['String']>;
  accountId: Scalars['String'];
  id: Scalars['ID'];
};

export type AddWatcherByAccountIdPayload = {
  __typename?: 'addWatcherByAccountIdPayload';
  clientMutationId: Maybe<Scalars['String']>;
  isPendingMember: Scalars['Boolean'];
  isWatching: Scalars['Boolean'];
};

export type AddWatcherByAccountIdsInput = {
  clientMutationId: Maybe<Scalars['String']>;
  accountIds: Array<Maybe<Scalars['String']>>;
  id: Scalars['ID'];
};

export type AddWatcherByAccountIdsPayload = {
  __typename?: 'addWatcherByAccountIdsPayload';
  clientMutationId: Maybe<Scalars['String']>;
  result: Maybe<Array<Maybe<AddWatcherResult>>>;
};

export type AddWatcherInput = {
  clientMutationId: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type AddWatcherPayload = {
  __typename?: 'addWatcherPayload';
  clientMutationId: Maybe<Scalars['String']>;
  isWatching: Scalars['Boolean'];
};

export type CloneGoalInput = {
  clientMutationId: Maybe<Scalars['String']>;
  goalId: Scalars['ID'];
  addWatchers: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  ownerId: Scalars['String'];
  addProjects: Maybe<Scalars['Boolean']>;
};

export type CloneGoalPayload = {
  __typename?: 'cloneGoalPayload';
  clientMutationId: Maybe<Scalars['String']>;
  goal: Maybe<Goal>;
};

export type CloneProjectInput = {
  clientMutationId: Maybe<Scalars['String']>;
  addWatchers: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  ownerId: Scalars['String'];
  projectId: Scalars['ID'];
  addLinks: Maybe<Scalars['Boolean']>;
};

export type CloneProjectPayload = {
  __typename?: 'cloneProjectPayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
};

export type ConnectSlackChannelInput = {
  clientMutationId: Maybe<Scalars['String']>;
  slackTeamId: Scalars['String'];
  resourceId: Scalars['ID'];
  slackChannelIds: Array<Maybe<Scalars['String']>>;
};

export type ConnectSlackChannelPayload = {
  __typename?: 'connectSlackChannelPayload';
  clientMutationId: Maybe<Scalars['String']>;
  slackConnections: Maybe<Array<Maybe<PostSlackConnection>>>;
};

export type CreateAnswerInput = {
  clientMutationId: Maybe<Scalars['String']>;
  questionId: Scalars['ID'];
  description: Scalars['String'];
};

export type CreateAnswerPayload = {
  __typename?: 'createAnswerPayload';
  clientMutationId: Maybe<Scalars['String']>;
  answer: Maybe<Answer>;
};

export type CreateCommentInput = {
  clientMutationId: Maybe<Scalars['String']>;
  entityId: Scalars['ID'];
  commentText: Scalars['JSONString'];
};

export type CreateCommentPayload = {
  __typename?: 'createCommentPayload';
  clientMutationId: Maybe<Scalars['String']>;
  comment: Maybe<Comment>;
};

export type CreateDefaultCustomFieldInput = {
  clientMutationId: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: Scalars['String'];
  required: Maybe<Scalars['Boolean']>;
  workspaceId: Scalars['ID'];
};

export type CreateDefaultCustomFieldPayload = {
  __typename?: 'createDefaultCustomFieldPayload';
  clientMutationId: Maybe<Scalars['String']>;
  defaultCustomField: Maybe<DefaultCustomField>;
};

export type CreateGoalInput = {
  clientMutationId: Maybe<Scalars['String']>;
  parentGoalId: Maybe<Scalars['ID']>;
  targetDate: Maybe<Scalars['Date']>;
  name: Scalars['String'];
  targetDateConfidence: Maybe<Scalars['Int']>;
  ownerId: Scalars['String'];
  workspaceId: Scalars['ID'];
};

export type CreateGoalPayload = {
  __typename?: 'createGoalPayload';
  clientMutationId: Maybe<Scalars['String']>;
  goal: Maybe<Goal>;
};

export type CreateGoalUpdateInput = {
  clientMutationId: Maybe<Scalars['String']>;
  phase: Maybe<GoalPhase>;
  summary: Maybe<Scalars['String']>;
  score: Maybe<Scalars['Int']>;
  goalId: Scalars['ID'];
  targetDate: Maybe<Scalars['Date']>;
  updateNotes: Maybe<Array<Maybe<UpdateNoteInput>>>;
  targetDateConfidence: Maybe<Scalars['Int']>;
};

export type CreateGoalUpdatePayload = {
  __typename?: 'createGoalUpdatePayload';
  clientMutationId: Maybe<Scalars['String']>;
  update: Maybe<GoalUpdate>;
};

export type CreateHelpPointerInput = {
  clientMutationId: Maybe<Scalars['String']>;
  tagUuids: Maybe<Array<Maybe<Scalars['UUID']>>>;
  teamId: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  name: Scalars['String'];
  link: Scalars['String'];
  icon: Maybe<IconInput>;
  newTeamName: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  type: HelpPointerType;
};

export type CreateHelpPointerPayload = {
  __typename?: 'createHelpPointerPayload';
  clientMutationId: Maybe<Scalars['String']>;
  helpPointer: Maybe<HelpPointer>;
};

export type CreateIndividualKudosInput = {
  clientMutationId: Maybe<Scalars['String']>;
  bgTheme: Maybe<KudosBgThemeType>;
  imageUrl: Maybe<Scalars['String']>;
  description: Scalars['String'];
  recipientId: Scalars['String'];
  stillImageUrl: Maybe<Scalars['String']>;
  workspaceId: Scalars['ID'];
};

export type CreateIndividualKudosPayload = {
  __typename?: 'createIndividualKudosPayload';
  clientMutationId: Maybe<Scalars['String']>;
  kudos: Maybe<Kudos>;
};

export type CreateLearningInput = {
  clientMutationId: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  description: Scalars['String'];
  entityId: Scalars['ID'];
};

export type CreateLearningPayload = {
  __typename?: 'createLearningPayload';
  clientMutationId: Maybe<Scalars['String']>;
  learning: Maybe<Learning>;
};

export type CreateMilestoneInput = {
  clientMutationId: Maybe<Scalars['String']>;
  targetDate: Scalars['Date'];
  title: Scalars['String'];
  projectId: Scalars['ID'];
  targetDateType: Maybe<TargetDateType>;
};

export type CreateMilestonePayload = {
  __typename?: 'createMilestonePayload';
  clientMutationId: Maybe<Scalars['String']>;
  milestone: Maybe<Milestone>;
};

export type CreateProjectFusionInput = {
  clientMutationId: Maybe<Scalars['String']>;
  projectKey: Scalars['String'];
  options: Maybe<ProjectFusionOptionsInput>;
  target: ProjectFusionTargetInput;
};

export type CreateProjectFusionPayload = {
  __typename?: 'createProjectFusionPayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
};

export type CreateProjectInput = {
  clientMutationId: Maybe<Scalars['String']>;
  private: Maybe<Scalars['Boolean']>;
  workspaceGlobalId: Maybe<Scalars['String']>;
  theWhat: Maybe<Scalars['String']>;
  targetDate: Maybe<Scalars['String']>;
  theMeasurement: Maybe<Scalars['String']>;
  icon: Maybe<IconInput>;
  name: Scalars['String'];
  targetDateConfidence: Maybe<Scalars['Int']>;
  ownerId: Scalars['String'];
  theWhy: Maybe<Scalars['String']>;
  workspaceUuid: Maybe<Scalars['UUID']>;
};

export type CreateProjectLinkInput = {
  clientMutationId: Maybe<Scalars['String']>;
  provider: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  iconUrl: Maybe<Scalars['String']>;
  type: Maybe<LinkType>;
  projectId: Scalars['ID'];
  url: Scalars['String'];
};

export type CreateProjectLinkPayload = {
  __typename?: 'createProjectLinkPayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
};

export type CreateProjectPayload = {
  __typename?: 'createProjectPayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
};

export type CreateProjectUpdateInput = {
  clientMutationId: Maybe<Scalars['String']>;
  phase: Maybe<Scalars['String']>;
  summary: Maybe<Scalars['String']>;
  newPhase: Maybe<ProjectPhase>;
  targetDate: Maybe<Scalars['Date']>;
  updateNotes: Maybe<Array<Maybe<UpdateNoteInput>>>;
  targetDateConfidence: Maybe<Scalars['Int']>;
  projectId: Scalars['ID'];
  status: Maybe<Scalars['String']>;
};

export type CreateProjectUpdatePayload = {
  __typename?: 'createProjectUpdatePayload';
  clientMutationId: Maybe<Scalars['String']>;
  update: Maybe<ProjectUpdate>;
};

export type CreateQuestionInput = {
  clientMutationId: Maybe<Scalars['String']>;
  tagUuids: Maybe<Array<Maybe<Scalars['UUID']>>>;
  cloudId: Scalars['String'];
  description: Scalars['String'];
  title: Scalars['String'];
  uuid: Maybe<Scalars['UUID']>;
};

export type CreateQuestionPayload = {
  __typename?: 'createQuestionPayload';
  clientMutationId: Maybe<Scalars['String']>;
  question: Maybe<Question>;
};

export type CreateTagForCloudIdInput = {
  clientMutationId: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  name: Scalars['String'];
};

export type CreateTagForCloudIdPayload = {
  __typename?: 'createTagForCloudIdPayload';
  clientMutationId: Maybe<Scalars['String']>;
  tag: Maybe<Tag>;
};

export type CreateTagInput = {
  clientMutationId: Maybe<Scalars['String']>;
  name: Scalars['String'];
  workspaceId: Scalars['ID'];
};

export type CreateTagPayload = {
  __typename?: 'createTagPayload';
  clientMutationId: Maybe<Scalars['String']>;
  tag: Maybe<Tag>;
};

export type CreateTeamInput = {
  clientMutationId: Maybe<Scalars['String']>;
  projectKey: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  name: Scalars['String'];
};

export type CreateTeamKudosInput = {
  clientMutationId: Maybe<Scalars['String']>;
  bgTheme: Maybe<KudosBgThemeType>;
  imageUrl: Maybe<Scalars['String']>;
  teamId: Scalars['String'];
  description: Scalars['String'];
  stillImageUrl: Maybe<Scalars['String']>;
  workspaceId: Scalars['ID'];
};

export type CreateTeamKudosPayload = {
  __typename?: 'createTeamKudosPayload';
  clientMutationId: Maybe<Scalars['String']>;
  kudos: Maybe<Kudos>;
};

export type CreateTeamPayload = {
  __typename?: 'createTeamPayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
  team: Maybe<Team>;
};

export type CreateUserInput = {
  clientMutationId: Maybe<Scalars['String']>;
};

export type CreateUserPayload = {
  __typename?: 'createUserPayload';
  clientMutationId: Maybe<Scalars['String']>;
  user: Maybe<CreatedUser>;
};

export type CreateUserTagForCloudIdInput = {
  clientMutationId: Maybe<Scalars['String']>;
  matchable: Scalars['Boolean'];
  cloudId: Scalars['ID'];
  name: Scalars['String'];
};

export type CreateUserTagForCloudIdPayload = {
  __typename?: 'createUserTagForCloudIdPayload';
  clientMutationId: Maybe<Scalars['String']>;
  result: Maybe<User>;
};

export type CreateUserTagInput = {
  clientMutationId: Maybe<Scalars['String']>;
  matchable: Scalars['Boolean'];
  name: Scalars['String'];
  workspaceId: Scalars['ID'];
};

export type CreateUserTagPayload = {
  __typename?: 'createUserTagPayload';
  clientMutationId: Maybe<Scalars['String']>;
  result: Maybe<User>;
};

export type CreateWorkspaceInput = {
  clientMutationId: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateWorkspacePayload = {
  __typename?: 'createWorkspacePayload';
  clientMutationId: Maybe<Scalars['String']>;
  workspace: Maybe<Workspace>;
};

export type DeleteAnswerInput = {
  clientMutationId: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteAnswerPayload = {
  __typename?: 'deleteAnswerPayload';
  clientMutationId: Maybe<Scalars['String']>;
  answer: Maybe<Answer>;
  success: Scalars['Boolean'];
};

export type DeleteCommentInput = {
  clientMutationId: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
};

export type DeleteCommentPayload = {
  __typename?: 'deleteCommentPayload';
  clientMutationId: Maybe<Scalars['String']>;
  comment: Maybe<Comment>;
  deleted: Scalars['Boolean'];
};

export type DeleteGoalProjectLinkInput = {
  clientMutationId: Maybe<Scalars['String']>;
  goalId: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type DeleteGoalProjectLinkPayload = {
  __typename?: 'deleteGoalProjectLinkPayload';
  clientMutationId: Maybe<Scalars['String']>;
  isRemoved: Scalars['Boolean'];
  project: Maybe<Project>;
};

export type DeleteHelpPointerInput = {
  clientMutationId: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteHelpPointerPayload = {
  __typename?: 'deleteHelpPointerPayload';
  clientMutationId: Maybe<Scalars['String']>;
  helpPointer: Maybe<HelpPointer>;
  success: Scalars['Boolean'];
};

export type DeleteLatestGoalUpdateInput = {
  clientMutationId: Maybe<Scalars['String']>;
  updateId: Scalars['ID'];
  goalId: Scalars['ID'];
};

export type DeleteLatestGoalUpdatePayload = {
  __typename?: 'deleteLatestGoalUpdatePayload';
  clientMutationId: Maybe<Scalars['String']>;
  goal: Maybe<Goal>;
};

export type DeleteLatestProjectUpdateInput = {
  clientMutationId: Maybe<Scalars['String']>;
  updateId: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type DeleteLatestProjectUpdatePayload = {
  __typename?: 'deleteLatestProjectUpdatePayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
};

export type DeleteLearningInput = {
  clientMutationId: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteLearningPayload = {
  __typename?: 'deleteLearningPayload';
  clientMutationId: Maybe<Scalars['String']>;
  learning: Maybe<Learning>;
};

export type DeleteMilestoneInput = {
  clientMutationId: Maybe<Scalars['String']>;
  milestoneId: Scalars['ID'];
};

export type DeleteMilestonePayload = {
  __typename?: 'deleteMilestonePayload';
  clientMutationId: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type DeleteProjectDependencyInput = {
  clientMutationId: Maybe<Scalars['String']>;
  outgoingProjectId: Scalars['ID'];
  incomingProjectId: Scalars['ID'];
};

export type DeleteProjectDependencyPayload = {
  __typename?: 'deleteProjectDependencyPayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
};

export type DeleteProjectFusionInput = {
  clientMutationId: Maybe<Scalars['String']>;
  projectKey: Scalars['String'];
  target: ProjectFusionTargetInput;
};

export type DeleteProjectFusionPayload = {
  __typename?: 'deleteProjectFusionPayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
};

export type DeleteProjectLinkInput = {
  clientMutationId: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type DeleteProjectLinkPayload = {
  __typename?: 'deleteProjectLinkPayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
};

export type DeleteProjectRoleInput = {
  clientMutationId: Maybe<Scalars['String']>;
  accountId: Scalars['String'];
  projectKey: Scalars['String'];
};

export type DeleteProjectRolePayload = {
  __typename?: 'deleteProjectRolePayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
};

export type DeleteQuestionInput = {
  clientMutationId: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type DeleteQuestionPayload = {
  __typename?: 'deleteQuestionPayload';
  clientMutationId: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type DeleteUserOnboardingInput = {
  clientMutationId: Maybe<Scalars['String']>;
  onboardingKey: Maybe<OnboardingItemKey>;
};

export type DeleteUserOnboardingPayload = {
  __typename?: 'deleteUserOnboardingPayload';
  clientMutationId: Maybe<Scalars['String']>;
  result: Maybe<Onboarding>;
};

export type DisconnectSlackChannelInput = {
  clientMutationId: Maybe<Scalars['String']>;
  connectionUuid: Scalars['String'];
};

export type DisconnectSlackChannelPayload = {
  __typename?: 'disconnectSlackChannelPayload';
  clientMutationId: Maybe<Scalars['String']>;
  uuid: Maybe<Scalars['String']>;
};

export type EditCustomFieldInput = {
  clientMutationId: Maybe<Scalars['String']>;
  customFieldId: Scalars['String'];
  value: Scalars['String'];
};

export type EditCustomFieldPayload = {
  __typename?: 'editCustomFieldPayload';
  clientMutationId: Maybe<Scalars['String']>;
  customField: Maybe<CustomField>;
};

export type EditGoalInput = {
  clientMutationId: Maybe<Scalars['String']>;
  goalId: Scalars['ID'];
  targetDate: Maybe<Scalars['Date']>;
  name: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  targetDateConfidence: Maybe<Scalars['Int']>;
  ownerId: Maybe<Scalars['String']>;
};

export type EditGoalPayload = {
  __typename?: 'editGoalPayload';
  clientMutationId: Maybe<Scalars['String']>;
  goal: Maybe<Goal>;
};

export type EditGoalUpdateInput = {
  clientMutationId: Maybe<Scalars['String']>;
  phase: Maybe<GoalPhase>;
  summary: Maybe<Scalars['String']>;
  score: Maybe<Scalars['Int']>;
  updateId: Scalars['ID'];
  goalId: Scalars['ID'];
  targetDate: Maybe<Scalars['Date']>;
  updateNotes: Maybe<Array<Maybe<UpdateNoteInput>>>;
  targetDateConfidence: Maybe<Scalars['Int']>;
};

export type EditGoalUpdatePayload = {
  __typename?: 'editGoalUpdatePayload';
  clientMutationId: Maybe<Scalars['String']>;
  update: Maybe<GoalUpdate>;
};

export type EditProjectIconInput = {
  clientMutationId: Maybe<Scalars['String']>;
  icon: Maybe<IconInput>;
  projectId: Scalars['ID'];
};

export type EditProjectIconPayload = {
  __typename?: 'editProjectIconPayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
};

export type EditProjectLinkInput = {
  clientMutationId: Maybe<Scalars['String']>;
  linkId: Scalars['ID'];
  provider: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  iconUrl: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
  url: Scalars['String'];
};

export type EditProjectLinkPayload = {
  __typename?: 'editProjectLinkPayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
};

export type EditProjectMetadataInput = {
  clientMutationId: Maybe<Scalars['String']>;
  theWhat: Maybe<Scalars['String']>;
  theMeasurement: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  theWhy: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
  startDate: Maybe<Scalars['Date']>;
};

export type EditProjectMetadataPayload = {
  __typename?: 'editProjectMetadataPayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
};

export type EditProjectUpdateInput = {
  clientMutationId: Maybe<Scalars['String']>;
  phase: Maybe<Scalars['String']>;
  summary: Maybe<Scalars['String']>;
  updateId: Scalars['ID'];
  newPhase: Maybe<ProjectPhase>;
  targetDate: Maybe<Scalars['Date']>;
  updateNotes: Maybe<Array<Maybe<UpdateNoteInput>>>;
  targetDateConfidence: Maybe<Scalars['Int']>;
  projectId: Scalars['ID'];
  status: Maybe<Scalars['String']>;
};

export type EditProjectUpdatePayload = {
  __typename?: 'editProjectUpdatePayload';
  clientMutationId: Maybe<Scalars['String']>;
  update: Maybe<ProjectUpdate>;
};

export type EditTagInput = {
  clientMutationId: Maybe<Scalars['String']>;
  description: Scalars['String'];
  tagUuid: Scalars['UUID'];
  workspaceUuid: Scalars['UUID'];
};

export type EditTagPayload = {
  __typename?: 'editTagPayload';
  clientMutationId: Maybe<Scalars['String']>;
  tag: Maybe<Tag>;
};

export type EditWorkspaceInput = {
  clientMutationId: Maybe<Scalars['String']>;
  goalScoringMode: Maybe<GoalScoringMode>;
  timezone: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  keyPrefix: Maybe<Scalars['String']>;
  workspaceUuid: Scalars['UUID'];
};

export type EditWorkspacePayload = {
  __typename?: 'editWorkspacePayload';
  clientMutationId: Maybe<Scalars['String']>;
  workspace: Maybe<Workspace>;
};

export type InviteTeamMembersInput = {
  clientMutationId: Maybe<Scalars['String']>;
  accountIds: Array<Maybe<Scalars['String']>>;
  teamId: Scalars['String'];
};

export type InviteTeamMembersPayload = {
  __typename?: 'inviteTeamMembersPayload';
  clientMutationId: Maybe<Scalars['String']>;
  team: Maybe<Team>;
};

export type InviteUserInput = {
  clientMutationId: Maybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  inviteTo: Scalars['ID'];
  inviteAs: Maybe<Scalars['String']>;
};

export type InviteUserPayload = {
  __typename?: 'inviteUserPayload';
  clientMutationId: Maybe<Scalars['String']>;
  invite: Maybe<UserInvite>;
};

export type JoinWorkspaceBySiteInput = {
  clientMutationId: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
};

export type JoinWorkspaceBySitePayload = {
  __typename?: 'joinWorkspaceBySitePayload';
  clientMutationId: Maybe<Scalars['String']>;
  result: Maybe<Array<Maybe<Workspace>>>;
};

export type MarkUserAsOnboardedInput = {
  clientMutationId: Maybe<Scalars['String']>;
};

export type MarkUserAsOnboardedPayload = {
  __typename?: 'markUserAsOnboardedPayload';
  clientMutationId: Maybe<Scalars['String']>;
  result: Maybe<User>;
};

export type RemoveProjectMemberInput = {
  clientMutationId: Maybe<Scalars['String']>;
  accountId: Scalars['String'];
  projectKey: Scalars['String'];
};

export type RemoveProjectMemberPayload = {
  __typename?: 'removeProjectMemberPayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
};

export type RemoveProjectTeamLinkInput = {
  clientMutationId: Maybe<Scalars['String']>;
  projectKey: Scalars['String'];
  removeMembers: Maybe<Scalars['Boolean']>;
  teamId: Scalars['String'];
};

export type RemoveProjectTeamLinkPayload = {
  __typename?: 'removeProjectTeamLinkPayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
  team: Maybe<Team>;
};

export type RemoveTagsInput = {
  clientMutationId: Maybe<Scalars['String']>;
  tagUuids: Array<Maybe<Scalars['String']>>;
  id: Scalars['ID'];
};

export type RemoveTagsPayload = {
  __typename?: 'removeTagsPayload';
  clientMutationId: Maybe<Scalars['String']>;
  tags: Maybe<Array<Maybe<Tag>>>;
};

export type RemoveTeamMemberInput = {
  clientMutationId: Maybe<Scalars['String']>;
  accountId: Scalars['String'];
  teamId: Scalars['String'];
};

export type RemoveTeamMemberPayload = {
  __typename?: 'removeTeamMemberPayload';
  clientMutationId: Maybe<Scalars['String']>;
  team: Maybe<Team>;
};

export type RemoveTeamWatcherInput = {
  clientMutationId: Maybe<Scalars['String']>;
  teamId: Scalars['String'];
};

export type RemoveTeamWatcherPayload = {
  __typename?: 'removeTeamWatcherPayload';
  clientMutationId: Maybe<Scalars['String']>;
  isWatching: Scalars['Boolean'];
};

export type RemoveUpvoteInput = {
  clientMutationId: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type RemoveUpvotePayload = {
  __typename?: 'removeUpvotePayload';
  clientMutationId: Maybe<Scalars['String']>;
  isUpvoted: Scalars['Boolean'];
  upvoteEntity: Maybe<UpvoteEntities>;
};

export type RemoveUserTagInput = {
  clientMutationId: Maybe<Scalars['String']>;
  tagUuid: Scalars['UUID'];
};

export type RemoveUserTagPayload = {
  __typename?: 'removeUserTagPayload';
  clientMutationId: Maybe<Scalars['String']>;
  result: Maybe<User>;
};

export type RemoveWatcherInput = {
  clientMutationId: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type RemoveWatcherPayload = {
  __typename?: 'removeWatcherPayload';
  clientMutationId: Maybe<Scalars['String']>;
  isWatching: Scalars['Boolean'];
};

export type RenameTeamInput = {
  clientMutationId: Maybe<Scalars['String']>;
  teamId: Scalars['String'];
  name: Scalars['String'];
};

export type RenameTeamPayload = {
  __typename?: 'renameTeamPayload';
  clientMutationId: Maybe<Scalars['String']>;
  team: Maybe<Team>;
};

export type SaveUserOnboardingInput = {
  clientMutationId: Maybe<Scalars['String']>;
  onboardingKey: Maybe<OnboardingItemKey>;
};

export type SaveUserOnboardingPayload = {
  __typename?: 'saveUserOnboardingPayload';
  clientMutationId: Maybe<Scalars['String']>;
  result: Maybe<Onboarding>;
};

export type SendAtlasUpgradeRequestInput = {
  clientMutationId: Maybe<Scalars['String']>;
  feature: Maybe<UpgradeRequestVariation>;
  workspaceUuid: Scalars['UUID'];
};

export type SendAtlasUpgradeRequestPayload = {
  __typename?: 'sendAtlasUpgradeRequestPayload';
  clientMutationId: Maybe<Scalars['String']>;
  isSend: Scalars['Boolean'];
};

export type SendSampleDigestInput = {
  clientMutationId: Maybe<Scalars['String']>;
};

export type SendSampleDigestPayload = {
  __typename?: 'sendSampleDigestPayload';
  clientMutationId: Maybe<Scalars['String']>;
  isSend: Scalars['Boolean'];
};

export type SetIsGoalArchivedInput = {
  clientMutationId: Maybe<Scalars['String']>;
  updateDescendantGoals: Maybe<Scalars['Boolean']>;
  goalId: Scalars['ID'];
  isArchived: Scalars['Boolean'];
};

export type SetIsGoalArchivedPayload = {
  __typename?: 'setIsGoalArchivedPayload';
  clientMutationId: Maybe<Scalars['String']>;
  goal: Maybe<Goal>;
};

export type SetIsProjectArchivedInput = {
  clientMutationId: Maybe<Scalars['String']>;
  isArchived: Scalars['Boolean'];
  projectId: Scalars['ID'];
};

export type SetIsProjectArchivedPayload = {
  __typename?: 'setIsProjectArchivedPayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
};

export type SetParentGoalInput = {
  clientMutationId: Maybe<Scalars['String']>;
  parentGoalId: Maybe<Scalars['ID']>;
  goalId: Scalars['ID'];
};

export type SetParentGoalPayload = {
  __typename?: 'setParentGoalPayload';
  clientMutationId: Maybe<Scalars['String']>;
  goal: Maybe<Goal>;
  parentGoal: Maybe<Goal>;
};

export type SetProjectDependencyInput = {
  clientMutationId: Maybe<Scalars['String']>;
  outgoingProjectId: Scalars['ID'];
  type: ProjectDependencyRelationship;
  incomingProjectId: Scalars['ID'];
};

export type SetProjectDependencyPayload = {
  __typename?: 'setProjectDependencyPayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
};

export type SetProjectOwnerInput = {
  clientMutationId: Maybe<Scalars['String']>;
  accountId: Scalars['String'];
  projectKey: Scalars['String'];
};

export type SetProjectOwnerPayload = {
  __typename?: 'setProjectOwnerPayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
};

export type SetProjectRoleInput = {
  clientMutationId: Maybe<Scalars['String']>;
  accountId: Scalars['String'];
  projectKey: Scalars['String'];
  role: ProjectRole;
};

export type SetProjectRolePayload = {
  __typename?: 'setProjectRolePayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
};

export type SetTagsInput = {
  clientMutationId: Maybe<Scalars['String']>;
  tagUuids: Array<Maybe<Scalars['String']>>;
  id: Scalars['ID'];
};

export type SetTagsPayload = {
  __typename?: 'setTagsPayload';
  clientMutationId: Maybe<Scalars['String']>;
  tags: Maybe<Array<Maybe<Tag>>>;
};

export type ShareGoalInput = {
  clientMutationId: Maybe<Scalars['String']>;
  goalKey: Scalars['String'];
  users: Array<Maybe<UserIdentifierInput>>;
};

export type ShareGoalPayload = {
  __typename?: 'shareGoalPayload';
  clientMutationId: Maybe<Scalars['String']>;
  goal: Maybe<Goal>;
};

export type ShareProjectInput = {
  clientMutationId: Maybe<Scalars['String']>;
  projectKey: Scalars['String'];
  users: Array<Maybe<UserIdentifierInput>>;
};

export type ShareProjectPayload = {
  __typename?: 'shareProjectPayload';
  clientMutationId: Maybe<Scalars['String']>;
  project: Maybe<Project>;
};

export type ShareUpdateInput = {
  clientMutationId: Maybe<Scalars['String']>;
  updateId: Scalars['ID'];
  recipientAccountId: Maybe<Scalars['String']>;
  recipientEmailAddress: Scalars['String'];
};

export type ShareUpdatePayload = {
  __typename?: 'shareUpdatePayload';
  clientMutationId: Maybe<Scalars['String']>;
  isShared: Scalars['Boolean'];
};

export type UpdateAnswerInput = {
  clientMutationId: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type UpdateAnswerPayload = {
  __typename?: 'updateAnswerPayload';
  clientMutationId: Maybe<Scalars['String']>;
  answer: Maybe<Answer>;
};

export type UpdateCommentInput = {
  clientMutationId: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
  commentText: Scalars['JSONString'];
};

export type UpdateCommentPayload = {
  __typename?: 'updateCommentPayload';
  clientMutationId: Maybe<Scalars['String']>;
  comment: Maybe<Comment>;
};

export type UpdateHelpPointerInput = {
  clientMutationId: Maybe<Scalars['String']>;
  tagUuids: Maybe<Array<Maybe<Scalars['UUID']>>>;
  teamId: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  link: Maybe<Scalars['String']>;
  icon: Maybe<IconInput>;
  newTeamName: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: Maybe<HelpPointerType>;
};

export type UpdateHelpPointerPayload = {
  __typename?: 'updateHelpPointerPayload';
  clientMutationId: Maybe<Scalars['String']>;
  helpPointer: Maybe<HelpPointer>;
};

export type UpdateLearningInput = {
  clientMutationId: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type UpdateLearningPayload = {
  __typename?: 'updateLearningPayload';
  clientMutationId: Maybe<Scalars['String']>;
  learning: Maybe<Learning>;
};

export type UpdateMilestoneInput = {
  clientMutationId: Maybe<Scalars['String']>;
  targetDate: Maybe<Scalars['Date']>;
  milestoneId: Scalars['ID'];
  completionDate: Maybe<Scalars['Date']>;
  title: Maybe<Scalars['String']>;
  isCompleted: Maybe<Scalars['Boolean']>;
  targetDateType: Maybe<TargetDateType>;
};

export type UpdateMilestonePayload = {
  __typename?: 'updateMilestonePayload';
  clientMutationId: Maybe<Scalars['String']>;
  milestone: Maybe<Milestone>;
};

export type UpdateQuestionInput = {
  clientMutationId: Maybe<Scalars['String']>;
  tagUuids: Maybe<Array<Maybe<Scalars['UUID']>>>;
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  title: Maybe<Scalars['String']>;
};

export type UpdateQuestionPayload = {
  __typename?: 'updateQuestionPayload';
  clientMutationId: Maybe<Scalars['String']>;
  question: Maybe<Question>;
};

export type UpdateUserPreferencesInput = {
  clientMutationId: Maybe<Scalars['String']>;
  emailReminderEnabled: Maybe<Scalars['Boolean']>;
  msteamsReminderEnabled: Maybe<Scalars['Boolean']>;
  slackSummaryEnabled: Maybe<Scalars['Boolean']>;
  msteamsSummaryEnabled: Maybe<Scalars['Boolean']>;
  slackReminderEnabled: Maybe<Scalars['Boolean']>;
  emailSummaryEnabled: Maybe<Scalars['Boolean']>;
};

export type UpdateUserPreferencesPayload = {
  __typename?: 'updateUserPreferencesPayload';
  clientMutationId: Maybe<Scalars['String']>;
  result: Maybe<User>;
};

export type UpdateUserTagInput = {
  clientMutationId: Maybe<Scalars['String']>;
  matchable: Scalars['Boolean'];
  tagUuid: Scalars['UUID'];
};

export type UpdateUserTagPayload = {
  __typename?: 'updateUserTagPayload';
  clientMutationId: Maybe<Scalars['String']>;
  result: Maybe<User>;
};

export type UpdateUserWorkspacePreferenceInput = {
  clientMutationId: Maybe<Scalars['String']>;
  workspaceId: Scalars['ID'];
};

export type UpdateUserWorkspacePreferencePayload = {
  __typename?: 'updateUserWorkspacePreferencePayload';
  clientMutationId: Maybe<Scalars['String']>;
  result: Maybe<Workspace>;
};
