import { SummaryItemType, summaryItemTypeToStatusMap } from '@tc/WeeklySummary/WeeklySummary';
import { SummaryItemTypeToCountMap } from '@shared/StatusSummaryBadges';
import { Project } from '@queries/GetProjectPageItems';
import { isActive } from '@tc/Filters/MetaFilter/util';

const getProjectStatusKey = ({ state: { value: status } }: Project) => {
  const isInProgress = isActive(status);

  if (!isInProgress) {
    return;
  }

  if (isInProgress && status === summaryItemTypeToStatusMap[SummaryItemType.ON_TRACK]) {
    return SummaryItemType.ON_TRACK;
  }

  if (isInProgress && status === summaryItemTypeToStatusMap[SummaryItemType.AT_RISK]) {
    return SummaryItemType.AT_RISK;
  }

  if (isInProgress && status === summaryItemTypeToStatusMap[SummaryItemType.OFF_TRACK]) {
    return SummaryItemType.OFF_TRACK;
  }
};

export const getSummaryItemTypeToCountMap = (projects: Project[]) =>
  projects.reduce<SummaryItemTypeToCountMap | undefined>((acc, project) => {
    const statusKey = getProjectStatusKey(project);

    if (!statusKey) {
      return acc;
    }

    return {
      ...(acc || {}),
      [statusKey]: (acc?.[statusKey] ?? 0) + 1,
    };
  }, undefined);
