import { useCallback, MouseEvent } from 'react';
import { ProductHome } from '@atlaskit/atlassian-navigation';
import { useRouter } from 'react-resource-router';
import styled from 'styled-components';

import { Logo, Icon } from '@tc/Logo';

const LogoWrapper = styled.div`
  margin-left: 48px;
`;

export const Home = () => {
  const [, routerActions] = useRouter();
  const clickCallback = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      if (e.metaKey) {
        window.open('/', '_blank', 'noreferrer');
      } else {
        routerActions.push('/');
      }
    },
    [routerActions]
  );

  return (
    <LogoWrapper>
      <ProductHome testId="navbar" href="/" icon={Icon} logo={Logo} onClick={clickCallback} />
    </LogoWrapper>
  );
};
