import { parseISO, addWeeks, isBefore, isAfter } from 'date-fns';
import sortBy from 'lodash.sortby';
import { cloneDeep } from 'lodash';

import { ProjectStateValue } from '@generated/graphql';
import { isValid } from '@tc/UpdateCard/UpdateDiff';
import { Project, ProjectUpdate, State } from '@queries/GetProjectPageItems';
import { Initiative } from 'src/pages/program/children/utils/program';
import { endOfWeek, startOfWeek, isSameWeek, tzDate } from '@tc/util/date';
import { Status } from 'src/graphql/types';
import { StatusToStatusChangeCounterMapType } from '@department/children/utils/weeklySummary';
import { DepartmentProject } from '@queries/GetDepartmentProjects';
import { isActive } from '@tc/Filters/MetaFilter/util';

export type ProjectWithUpdates = (Project | Initiative | DepartmentProject) & {
  targetWeekUpdate?: ProjectUpdate;
  weekBeforeTargetWeekUpdate?: ProjectUpdate;
  isVerified?: boolean;
};

export type StatusChangeSummaryType = {
  in: ProjectWithUpdates[];
  out: ProjectWithUpdates[];
  noChange: ProjectWithUpdates[];
};

export type StatusToStatusChangeSummaryMapType = { [status in Status | ProjectStateValue]: StatusChangeSummaryType };

const getInOutStatusOrPhase = (oldState: State, newState: State) => {
  const outStatusOrPhase = oldState;
  const inStatusOrPhase = newState;

  return { outStatusOrPhase, inStatusOrPhase };
};

const getNoChangeStatus = (update: ProjectUpdate) => {
  const { newState } = update;

  return newState;
};

export const isCreatedOnTargetWeek = (startDate: Date, endDate: Date, creationDate: Date): boolean =>
  isAfter(creationDate, startDate) && isBefore(creationDate, endDate);

export const isCreatedWeekBeforeTargetWeek = (startDate: Date, creationDate: Date): boolean => {
  const startDateBeforeTargetWeek = startOfWeek(addWeeks(startDate, -1));
  const endDateBeforeTargetWeek = endOfWeek(addWeeks(startDate, -1));

  return isAfter(creationDate, startDateBeforeTargetWeek) && isBefore(creationDate, endDateBeforeTargetWeek);
};

export const mapStatusChangeToCounter = (statusToInOutNoChangeProjectsMap: StatusToStatusChangeSummaryMapType) => {
  const statusToStatusChangeCounterMap = {} as StatusToStatusChangeCounterMapType;

  Object.entries(statusToInOutNoChangeProjectsMap).forEach(([status, { in: inProjects, out, noChange }]) => {
    statusToStatusChangeCounterMap[status as Status] = {
      in: inProjects.length,
      out: out.length,
      noChange: noChange.length,
    };
  });

  return statusToStatusChangeCounterMap;
};

export const getStatusToInOutNoChangeProjectsMap = (
  projects: (Initiative | Project | DepartmentProject)[],
  startDate: Date,
  endDate: Date
) => {
  const statusToInOutNoChangeProjectsMap = {
    on_track: { in: [], out: [], noChange: [] },
    off_track: { in: [], out: [], noChange: [] },
    at_risk: { in: [], out: [], noChange: [] },
    done: { in: [], out: [], noChange: [] },
    pending: { in: [], out: [], noChange: [] },
    paused: { in: [], out: [], noChange: [] },
    missed: { in: [], out: [], noChange: [] },
    cancelled: { in: [], out: [], noChange: [] },
    archived: { in: [], out: [], noChange: [] },
  } as StatusToStatusChangeSummaryMapType;

  projects.forEach((project) => {
    const weekBeforeTargetWeekUpdate = project.updates.edges.find(({ node: { creationDate } }) =>
      isCreatedWeekBeforeTargetWeek(startDate, parseISO(creationDate))
    );
    const targetWeekUpdate = project.updates.edges.find(({ node: { creationDate } }) =>
      isCreatedOnTargetWeek(startDate, endDate, parseISO(creationDate))
    );

    const isUpdateCreatedBefore = project.updates.edges.find(({ node: { creationDate } }) =>
      isBefore(parseISO(creationDate), endDate)
    );

    const isThisWeek = isSameWeek(startDate, tzDate());

    const wasCreatedAfterTargetWeek = isAfter(parseISO(project.creationDate), endDate);

    const isCreatedBeforeTargetWeek = isBefore(parseISO(project.creationDate), endDate);

    const isPendingProject =
      isCreatedBeforeTargetWeek && (project.updates.edges.length === 0 || !isUpdateCreatedBefore);

    const isPendingProjectOlderThanWeekBeforeTargetWeek =
      !targetWeekUpdate && project.state.value === ProjectStateValue.Pending && !wasCreatedAfterTargetWeek;

    if (isPendingProjectOlderThanWeekBeforeTargetWeek || isPendingProject) {
      const firstUpdateBeforeTargetWeek = project.updates.edges.find(({ node: { creationDate } }) =>
        isBefore(parseISO(creationDate), startDate)
      );
      const lastUpdate = isThisWeek ? project?.updates?.edges[0] : firstUpdateBeforeTargetWeek!;

      const projectWithUpdates: ProjectWithUpdates = {
        ...project,
        targetWeekUpdate: lastUpdate?.node,
        weekBeforeTargetWeekUpdate: lastUpdate?.node,
      };

      if (lastUpdate) {
        const isNoPendingOrAfterSelectedDate =
          lastUpdate?.node?.newState?.value !== ProjectStateValue.Pending ||
          isAfter(parseISO(lastUpdate?.node?.creationDate), endDate);

        if (isNoPendingOrAfterSelectedDate) {
          const { newState, oldState } = lastUpdate?.node;

          if (isAfter(parseISO(lastUpdate.node.creationDate), endDate)) {
            statusToInOutNoChangeProjectsMap[oldState.value].noChange.push(projectWithUpdates);

            return;
          }

          statusToInOutNoChangeProjectsMap[newState.value].noChange.push(projectWithUpdates);

          return;
        }
      }

      statusToInOutNoChangeProjectsMap.pending.noChange.push(projectWithUpdates);

      return;
    }

    const isCompletedProjectOlderThanWeekBeforeTargetWeek =
      !targetWeekUpdate &&
      project.updates.edges.length &&
      project.state.value === ProjectStateValue.Done &&
      !wasCreatedAfterTargetWeek;

    if (isCompletedProjectOlderThanWeekBeforeTargetWeek) {
      const lastUpdate = project.updates.edges[0];
      const isNoDoneOrAfterSelectedDate =
        lastUpdate.node.newState.value !== ProjectStateValue.Done ||
        isAfter(parseISO(lastUpdate.node.creationDate), endDate);

      if (isNoDoneOrAfterSelectedDate) {
        return;
      }

      const projectWithUpdates: ProjectWithUpdates = {
        ...project,
        targetWeekUpdate: lastUpdate?.node,
        weekBeforeTargetWeekUpdate: lastUpdate?.node,
      };

      statusToInOutNoChangeProjectsMap.done.noChange.push(projectWithUpdates);

      return;
    }

    const isCancelledProjectOlderThanWeekBeforeTargetWeek =
      !targetWeekUpdate &&
      project.updates.edges.length &&
      project.state.value === ProjectStateValue.Cancelled &&
      !wasCreatedAfterTargetWeek;

    if (isCancelledProjectOlderThanWeekBeforeTargetWeek) {
      const firstUpdateBeforeTargetWeek = project.updates.edges.find(({ node: { creationDate } }) =>
        isBefore(parseISO(creationDate), startDate)
      );

      const lastUpdate = isThisWeek ? project.updates.edges[0] : firstUpdateBeforeTargetWeek!;

      if (!lastUpdate) {
        return;
      }

      const projectWithUpdates: ProjectWithUpdates = {
        ...project,
        targetWeekUpdate: lastUpdate?.node,
        weekBeforeTargetWeekUpdate: lastUpdate?.node,
      };

      const isNoCancelledOrAfterSelectedDate =
        lastUpdate?.node?.newState?.value !== ProjectStateValue.Cancelled ||
        isAfter(parseISO(lastUpdate?.node?.creationDate), endDate);

      if (isNoCancelledOrAfterSelectedDate) {
        const { newState, oldState } = lastUpdate?.node;

        if (isAfter(parseISO(lastUpdate.node.creationDate), endDate)) {
          statusToInOutNoChangeProjectsMap[oldState.value].noChange.push(projectWithUpdates);

          return;
        }

        statusToInOutNoChangeProjectsMap[newState.value].noChange.push(projectWithUpdates);

        return;
      }

      statusToInOutNoChangeProjectsMap.cancelled.noChange.push(projectWithUpdates);

      return;
    }

    const isPausedProjectOlderThanWeekBeforeTargetWeek =
      !targetWeekUpdate &&
      project.updates.edges.length &&
      project.state.value === ProjectStateValue.Paused &&
      !wasCreatedAfterTargetWeek;

    if (isPausedProjectOlderThanWeekBeforeTargetWeek) {
      const firstUpdateBeforeTargetWeek = project.updates.edges.find(({ node: { creationDate } }) =>
        isBefore(parseISO(creationDate), startDate)
      );
      const lastUpdate = isThisWeek ? project.updates.edges[0] : firstUpdateBeforeTargetWeek!;

      if (!lastUpdate) {
        return;
      }

      const projectWithUpdates: ProjectWithUpdates = {
        ...project,
        targetWeekUpdate: lastUpdate?.node,
        weekBeforeTargetWeekUpdate: lastUpdate?.node,
      };

      const isNoPausedOrAfterSelectedDate =
        lastUpdate?.node?.newState?.value !== ProjectStateValue.Paused ||
        isAfter(parseISO(lastUpdate?.node?.creationDate), endDate);

      if (isNoPausedOrAfterSelectedDate) {
        const { newState, oldState } = lastUpdate?.node;

        if (isAfter(parseISO(lastUpdate.node.creationDate), endDate)) {
          statusToInOutNoChangeProjectsMap[oldState.value].noChange.push(projectWithUpdates);

          return;
        }

        statusToInOutNoChangeProjectsMap[newState.value].noChange.push(projectWithUpdates);

        return;
      }

      statusToInOutNoChangeProjectsMap.paused.noChange.push(projectWithUpdates);

      return;
    }

    if (!weekBeforeTargetWeekUpdate && !targetWeekUpdate) {
      return;
    }

    const projectWithUpdates: ProjectWithUpdates = {
      ...project,
      targetWeekUpdate: targetWeekUpdate?.node,
      weekBeforeTargetWeekUpdate: weekBeforeTargetWeekUpdate?.node,
    };

    const isUpdatedOnThisWeek = isSameWeek(new Date(project.updates.edges[0].node.creationDate), tzDate());

    if (weekBeforeTargetWeekUpdate && isThisWeek && !isUpdatedOnThisWeek) {
      const { missedUpdate, editDate, newState } = weekBeforeTargetWeekUpdate.node;
      const isInProgress = isActive(newState.value);
      const isDonePhase = newState.value === ProjectStateValue.Done;
      const isCancelledPhase = newState.value === ProjectStateValue.Cancelled;
      const isPausedPhase = newState.value === ProjectStateValue.Paused;
      const isPendingPhase = newState.value === ProjectStateValue.Pending;
      const wasUpdatedTwoWeeksAgo = !missedUpdate || editDate;

      if (
        isInProgress &&
        !isDonePhase &&
        !isCancelledPhase &&
        !isPausedPhase &&
        !isPendingPhase &&
        !wasUpdatedTwoWeeksAgo
      ) {
        const statusOrPhase = getNoChangeStatus(weekBeforeTargetWeekUpdate.node);

        statusToInOutNoChangeProjectsMap.missed.in.push(projectWithUpdates);
        statusToInOutNoChangeProjectsMap[statusOrPhase.value].noChange.push(projectWithUpdates);

        return;
      }
    }

    if (isThisWeek && !isUpdatedOnThisWeek) {
      const { state } = project;
      const isInProgress = isActive(state.value);
      const isDonePhase = state.value === ProjectStateValue.Done;
      const isCancelledPhase = state.value === ProjectStateValue.Cancelled;
      const isPausedPhase = state.value === ProjectStateValue.Paused;
      const isPendingPhase = state.value === ProjectStateValue.Pending;

      if (isInProgress && !isDonePhase && !isCancelledPhase && !isPausedPhase && !isPendingPhase) {
        statusToInOutNoChangeProjectsMap.missed.in.push(projectWithUpdates);
        statusToInOutNoChangeProjectsMap[state.value as keyof StatusToStatusChangeSummaryMapType].noChange.push(
          projectWithUpdates
        );

        return;
      }
    }

    if (!weekBeforeTargetWeekUpdate && targetWeekUpdate) {
      const { oldState, newState } = targetWeekUpdate.node;

      const { inStatusOrPhase } = getInOutStatusOrPhase(oldState, newState);

      statusToInOutNoChangeProjectsMap[inStatusOrPhase.value].in.push(projectWithUpdates);

      return;
    }

    if (weekBeforeTargetWeekUpdate && !targetWeekUpdate) {
      const statusOrPhase = getNoChangeStatus(weekBeforeTargetWeekUpdate.node);

      statusToInOutNoChangeProjectsMap[statusOrPhase.value].noChange.push(projectWithUpdates);

      return;
    }

    if (!weekBeforeTargetWeekUpdate || !targetWeekUpdate) {
      return;
    }

    const {
      missedUpdate: missedUpdateTwoWeeksAgo,
      editDate: editDateTwoWeeksAgo,
      newState: newStateTwoWeeksAgo,
    } = weekBeforeTargetWeekUpdate.node;
    const {
      missedUpdate: missedUpdateWeekAgo,
      editDate: editDateWeekAgo,
      newState: newStateWeekAgo,
    } = targetWeekUpdate.node;

    const wasUpdated = !missedUpdateWeekAgo || editDateWeekAgo;
    const wasUpdatedTwoWeeksAgo = !missedUpdateTwoWeeksAgo || editDateTwoWeeksAgo;
    const hasStatusOrPhaseChanged =
      isValid(newStateTwoWeeksAgo) && newStateTwoWeeksAgo?.value !== newStateWeekAgo.value;

    if (wasUpdated && hasStatusOrPhaseChanged) {
      const { outStatusOrPhase, inStatusOrPhase } = getInOutStatusOrPhase(newStateTwoWeeksAgo, newStateWeekAgo);

      statusToInOutNoChangeProjectsMap[outStatusOrPhase.value].out.push(projectWithUpdates);
      statusToInOutNoChangeProjectsMap[inStatusOrPhase.value].in.push(projectWithUpdates);

      return;
    }

    if (wasUpdated && !hasStatusOrPhaseChanged) {
      const statusOrPhase = getNoChangeStatus(targetWeekUpdate.node);

      statusToInOutNoChangeProjectsMap[statusOrPhase.value].noChange.push(projectWithUpdates);

      return;
    }

    if (!wasUpdated && !wasUpdatedTwoWeeksAgo) {
      const firstUpdateBeforeTargetWeek = project.updates.edges.find(({ node: { creationDate } }) => {
        const wasCreatedBeforeTargetWeek = isBefore(parseISO(creationDate), startDate);

        return wasCreatedBeforeTargetWeek;
      });

      if (firstUpdateBeforeTargetWeek) {
        const statusOrPhase = getNoChangeStatus(firstUpdateBeforeTargetWeek.node);

        statusToInOutNoChangeProjectsMap[statusOrPhase.value].noChange.push(projectWithUpdates);
      }

      statusToInOutNoChangeProjectsMap.missed.noChange.push(projectWithUpdates);

      return;
    }
    if (!wasUpdated && wasUpdatedTwoWeeksAgo) {
      const { inStatusOrPhase } = getInOutStatusOrPhase(newStateTwoWeeksAgo, newStateWeekAgo);

      statusToInOutNoChangeProjectsMap.missed.in.push(projectWithUpdates);
      statusToInOutNoChangeProjectsMap[inStatusOrPhase.value].noChange.push(projectWithUpdates);
    }
  });

  return sortProjects(statusToInOutNoChangeProjectsMap);
};

export const getProjectScore = (projectOrInitiative: Initiative | Project | DepartmentProject) => {
  if ('projects' in projectOrInitiative) {
    return 1;
  }

  return 2;
};

export const sortProjects = (statusToInOutNoChangeProjectsMap: StatusToStatusChangeSummaryMapType) => {
  const statusToInOutNoChangeProjectsMapCopy = cloneDeep(statusToInOutNoChangeProjectsMap);

  Object.keys(statusToInOutNoChangeProjectsMapCopy).forEach((key) => {
    const status = key as keyof StatusToStatusChangeSummaryMapType;
    const inProjects = statusToInOutNoChangeProjectsMapCopy[status].in;
    const outProjects = statusToInOutNoChangeProjectsMapCopy[status].out;
    const noChangeProjects = statusToInOutNoChangeProjectsMapCopy[status].noChange;

    statusToInOutNoChangeProjectsMapCopy[status].in = sortBy(inProjects, [getProjectScore]);
    statusToInOutNoChangeProjectsMapCopy[status].out = sortBy(outProjects, [getProjectScore]);
    statusToInOutNoChangeProjectsMapCopy[status].noChange = sortBy(noChangeProjects, [getProjectScore]);
  });

  return statusToInOutNoChangeProjectsMapCopy;
};
