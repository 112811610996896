import Loadable from 'react-loadable';
import { RouteContext } from 'react-resource-router';

import { LoadableErrorHandler } from '@shared/LoadableErrorHandler';

export type ProgramDashboardPageProps = RouteContext & {
  isAssetsEnable?: boolean;
};

export const Dashboard = Loadable<ProgramDashboardPageProps, never>({
  loader: () =>
    import(/* webpackChunkName: "ProgramPage" */ './program/ProgramDashboardPage').then(
      ({ DashboardView }) => DashboardView
    ),
  loading: LoadableErrorHandler,
});

export const InitiativeDashboardPage = Loadable<RouteContext, never>({
  loader: () =>
    import(/* webpackChunkName: "InitiativeDashboardPage" */ './InitiativeDashboardPage').then(
      ({ InitiativeDashboardPage }) => InitiativeDashboardPage
    ),
  loading: LoadableErrorHandler,
});
