import Loadable from 'react-loadable';

import { LoadableErrorHandler } from '@shared/LoadableErrorHandler';

import { HelpPanelProps } from './HelpPanel';

export const HelpPanel = Loadable<HelpPanelProps, never>({
  loader: () => import(/* webpackChunkName: "help-panel" */ './HelpPanel').then(({ HelpPanel }) => HelpPanel),
  loading: LoadableErrorHandler,
});
