import { startOfMonth, subMonths } from 'date-fns';

import { Project } from '@queries/GetProjectPageItems';
import { Goal, SubGoal } from '@queries/SearchProjectByKey';
import { goalScoreToStatusEntity } from '@tc/util/goalScoreToStatus';
import { tzDate } from '@tc/util/date';
import { formatIsoLocalDate } from '@tc/util/dateFormat';
import { isActive } from '@tc/Filters/MetaFilter/util';

const statusToScoresMap: { [status: string]: number } = {
  pending: 1,
  off_track: 2,
  at_risk: 3,
  on_track: 4,
  paused: 5,
  done: 6,
  cancelled: 7,
};

export const getProjectScore = ({ state }: Pick<Project, 'state'>): number => statusToScoresMap[state.value];

export const getGoalScore = ({ state }: Goal | SubGoal) =>
  statusToScoresMap[isActive(state.value) ? goalScoreToStatusEntity(state.score).name : state.value];

export const threeMonthsAgo = formatIsoLocalDate(startOfMonth(subMonths(tzDate(), 3)));
