import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100vh;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  text-align: center;
`;

export const GlobalError = () => (
  <Container>
    <div>
      <img src="/error.png" alt="error" width="115" />
      <p>Something went wrong</p>
    </div>
  </Container>
);
