import { Wrapper, defaultProps, Props } from '@tc/Logo/Wrapper';

const svg = `
<canvas height="32" width="32" aria-hidden="true"></canvas>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="32" height="32" rx="3" fill="#FFBDAD"/>
  <path d="M12.1094 10.7266V22H14.125V18.2734H16.5703C18.8516 18.2734 20.4453 16.75 20.4453 14.5234C20.4453 12.2578 18.9062 10.7266 16.6562 10.7266H12.1094ZM14.125 12.375H16.125C17.5781 12.375 18.3984 13.125 18.3984 14.5234C18.3984 15.8828 17.5547 16.6484 16.1172 16.6484H14.125V12.375Z" fill="#BF2600"/>
</svg>
`;

export const CreateProgramIcon = (props: Props) => <Wrapper {...defaultProps} {...props} svg={svg} />;
