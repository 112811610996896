import { ReactNode } from 'react';
import Loadable from 'react-loadable';
import { ProfilecardTriggerPosition } from '@atlaskit/profilecard';

import { Initiative, Program } from 'src/pages/program/children/utils/program';
import { User } from 'src/graphql/generated/graphql';
import { GqlUser } from 'src/graphql/types';
import { ProjectUpdate, Project, State } from 'src/graphql/queries/GetProjectPageItems';
import { Context } from '@tc/providers';
import { LoadableErrorHandler } from '@shared/LoadableErrorHandler';

export type RenderPropCallback = () => ReactNode;
type RenderCollapsibleExtrasCallbackProps = { scheduleUpdate?: () => void };
export type RenderCollapsibleExtrasCallback = ({ scheduleUpdate }: RenderCollapsibleExtrasCallbackProps) => ReactNode;

export type UpdateCardProps = {
  id: string;
  renderCardTopLeftAfter?: RenderPropCallback;
  renderCardTopExtras?: RenderPropCallback;
  renderContentExtras?: RenderCollapsibleExtrasCallback;
  renderFooterExtras?: RenderCollapsibleExtrasCallback | RenderPropCallback;
  renderHeader?: RenderPropCallback;
  renderTooltipContent?: (date: Date) => string;
  summary?: string;
  context: Context;
  uuid: string;
  creator?: ProjectUpdate['creator'] | GqlUser | User | null;
  creationDate?: string;
  editDate?: string | null;
  updateType: 'project-update' | 'goal-update';
  updateShareLink?: string;
  isCollapsible?: boolean;
  isExpanded?: boolean;
  ari: string;
  containerAri: string;
  profileCardPosition?: ProfilecardTriggerPosition;
  isTransparent?: boolean;
  project?: Program | Initiative;
  shouldAddTabs?: boolean;
  targetDate?: string;
  targetDateConfidence?: number;
  state?: State;
  isProjectsLoading?: boolean;
  projects?: Project[];
  activeProjects?: Project[];
  isTargetWeekUpdate?: boolean;
  isUserAvatarFixedWidth?: boolean;
  onClickCollapseButton?: () => void;
};

export const UpdateCard = Loadable<UpdateCardProps, never>({
  loader: () => import(/* webpackChunkName: "project-update" */ './UpdateCard').then(({ UpdateCard }) => UpdateCard),
  loading: LoadableErrorHandler,
});
