import { getConfig } from 'src/config';

export type DocumentWithStorageAccess = Document & {
  hasStorageAccess?: () => Promise<boolean>;
  requestStorageAccess?: () => Promise<undefined>;
};

export const isEmbed = () => /(\/embeds\/)/gi.test(window.location.pathname);

export const getLogInUrl = (redirectUrl?: string) => {
  const href = redirectUrl || window.location.href;

  return `${getConfig().atlassianAccountUrl}/login?continue=${encodeURIComponent(href)}&application=global-state`;
};

export const returnAfterLogin = () => {
  if (isEmbed()) {
    // Embeds needs to handle this differently
    return;
  }
  window.location.href = getLogInUrl();
};

export const documentHasStorageAccess = () => (document as DocumentWithStorageAccess).hasStorageAccess !== undefined;

export enum StorageAccessState {
  Granted,
  UserMustGrant,
}

const checkCanSetCookie = () => {
  ///TODO check cookie value
  const cookieName = 'com.atlassian.atlas.jira-integration-cookie-check';

  document.cookie = `${cookieName}=any; SameSite=None; Secure`;

  const canSetCookie = document.cookie.indexOf(cookieName) !== -1;

  document.cookie = `${cookieName}=; SameSite=None; Secure; expires=Thu, 01 Jan 1970 00:00:00 GMT`;

  return canSetCookie;
};

export async function requestStorageAccess(): Promise<StorageAccessState> {
  if (checkCanSetCookie()) {
    // Do not need storage access if we can set cookies, because that's what the Storage Access API is an alternative for
    return StorageAccessState.Granted;
  }

  if (document.hasStorageAccess) {
    const hasAccess = await document.hasStorageAccess();

    if (!hasAccess) {
      // Check whether unpartitioned cookie access has been granted
      // to another same-site embed
      try {
        const permission = await navigator.permissions.query({
          // 'storage-access' only exists in some browsers,  but we cannot assume which do: https://developer.mozilla.org/en-US/docs/Web/API/Permissions
          name: 'storage-access' as PermissionName & 'storage-access',
        });

        if (permission.state === 'granted') {
          // If so, you can just call requestStorageAccess() without a user interaction,
          // and it will resolve automatically.
          await document.requestStorageAccess();

          return StorageAccessState.Granted;
        } else if (permission.state === 'prompt') {
          // Need to call requestStorageAccess() after a user interaction
          return StorageAccessState.UserMustGrant;
        } else if (permission.state === 'denied') {
          // User has denied unpartitioned cookie access
          return StorageAccessState.UserMustGrant;
        }
      } catch (error) {
        // Some browsers don't support this API, so we can't assume it will work
        return StorageAccessState.Granted;
      }
    }
  }

  return StorageAccessState.Granted;
}

export const ensureCorrectAuth = async (shouldRedirect = true) => {
  const authCheckPromise = await fetch(getConfig().authCheckUrl);
  const { code, errors } = await authCheckPromise.json();
  const isUnauthenticated = code && code === 401;
  const isUnauthorized = errors && errors[0].message === 401;
  const isError = isUnauthenticated || isUnauthorized || !!errors;

  if (isError) {
    if (!shouldRedirect) {
      return false;
    }

    if (isUnauthenticated) {
      window.location.href = getLogInUrl();
    }

    return false;
  }

  return true;
};
