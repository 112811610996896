import { N300 } from '@atlaskit/theme/colors';
import { h600 } from '@atlaskit/theme/typography';
import styled from 'styled-components';
import { useMemo } from 'react';

import { StatusOrPhaseLozenge } from '@tc/StatusOrPhaseLozenge';
import { Initiative, Program } from '@program/children/utils/program';
import { DueDate } from '@program/children/DueDate';
import { Tag } from '@shared/TawBreakdown/TawTag';
import { getProjectTawPhase } from '@shared/TawBreakdown/utils';
import { useFetchTawPhases, useTawPhaseStore } from 'src/store/tawPhaseStore';
import { HeaderTitle } from '@shared/Header/HeaderTitle';
import { ellipsis } from '@tc/UserAvatar/styles';
import { Sources } from '@hooks/useAnalytics';

export const Container = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`;

export const IconWrapper = styled.div`
  margin-right: 12px;
`;

export const Side = styled.div`
  width: 70%;
  display: flex;
  flex: 1 1 0;
  min-width: 5px;
  align-items: center;
`;

export const Status = styled(Side)`
  width: 30%;
  margin-top: 24px;
  justify-content: flex-end;
  transform: translate(0, -17px);
  flex-wrap: wrap;

  @media (max-width: 400px) {
    justify-content: flex-start;
    flex: 1 1 100%;
  }
`;

export const Name = styled.a`
  width: 85%;
  ${h600};
  display: flex;
  margin-top: 0;
  transform: translate(0, -9px);

  &:hover,
  &:active {
    ${h600};
    margin-top: 0;
    text-decoration: none;
  }
`;

export const Home = styled.div<{ isInitiativePage?: boolean }>`
  font-size: smaller;
  vertical-align: top;
  color: ${N300};
  width: 80%;
  padding: ${({ isInitiativePage }) => (isInitiativePage ? '5px 5px 8px 5px' : '5px 0 8px 5px')};
  ${ellipsis};
  transform: translateX(-4px);

  a,
  a:hover,
  a:active {
    color: ${N300};
  }
`;

export const TargetDate = styled.div`
  margin-left: 8px;
  margin-top: 8px;
`;

export const BreadcrumbWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
`;

export const WarningIconWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-left: 8px;
`;

export const StatusWrapper = styled.div`
  margin-top: 8px;
`;

const TawPhase = styled(Tag)`
  margin: 8px 8px 0 0;
  padding: 2px 8px;
  cursor: default;
`;

export type ParentPrograms = {
  name: string;
  link: string;
  creationDate: string;
};

export type AssetsParentProgram = Omit<ParentPrograms, 'creationDate'>;

type HeaderProps = {
  project: Program | Initiative;
  breadcrumbText?: string;
  breadcrumbLink?: string;
  className?: string;
  parentPrograms?: (ParentPrograms | AssetsParentProgram)[];
  isIndependentInit?: boolean;
  isInitiativePage?: boolean;
  isVerified?: boolean;
};

export const HeaderComponent = ({
  project,
  breadcrumbText,
  breadcrumbLink = '/',
  className,
  parentPrograms,
  isInitiativePage,
  isIndependentInit,
  isVerified,
}: HeaderProps) => {
  const { state } = project;

  useFetchTawPhases();

  const [{ tawPhases, isLoading }] = useTawPhaseStore();

  const tawPhase = useMemo(() => (isLoading ? [] : getProjectTawPhase(project.tags.edges, tawPhases)), [
    isLoading,
    project.tags.edges,
    tawPhases,
  ]);

  return (
    <Container className={className}>
      <Side>
        <HeaderTitle
          project={project}
          breadcrumbLink={breadcrumbLink}
          breadcrumbText={breadcrumbText}
          parentPrograms={parentPrograms}
          isInitiativePage={isInitiativePage}
          isIndependentInit={isIndependentInit}
          isVerified={!!isVerified}
          source={isInitiativePage ? Sources.INITIATIVE_PAGE : Sources.PROGRAM_PAGE}
        />
      </Side>
      <Status>
        <TawPhase isEmpty={false}>{tawPhase}</TawPhase>
        <StatusWrapper>
          <StatusOrPhaseLozenge state={state} />
        </StatusWrapper>
        <TargetDate>
          <DueDate project={project} />
        </TargetDate>
      </Status>
    </Container>
  );
};
