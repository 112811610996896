import { useCallback, useState } from 'react';
import Button from '@atlaskit/button';
import { N10 } from '@atlaskit/theme/colors';
import styled from 'styled-components';

import { ensureCorrectAuth, getLogInUrl } from '@shared/utils/auth';

const Container = styled.div`
  display: flex;
  align-content: center;
  text-align: center;

  width: 100vw;
  height: 100vh;
  background: ${N10};

  img {
    width: 120px;
    margin-top: 16px;
  }

  div {
    flex: 1;
    align-self: center;
  }

  p {
    margin: 10px;
  }

  button {
    margin-bottom: 16px;
  }
`;

const NoAccess = styled.p`
  font-weight: bold;
`;

type Props = {
  requiresAuthCheck?: boolean;
};

export const RequestStorageAccess = ({ requiresAuthCheck = true }: Props) => {
  const [noAccess, setNoAccess] = useState(false);
  const [needAuth, setNeedAuth] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const onClick = useCallback(async () => {
    setNoAccess(false);
    setButtonDisabled(true);

    try {
      await document.requestStorageAccess();

      if (!requiresAuthCheck) {
        window.location.reload();

        return;
      }
      const hasCorrectAuth = await ensureCorrectAuth(false);

      if (hasCorrectAuth) {
        window.location.reload();
      } else {
        setNeedAuth(true);
      }
    } catch (e) {
      setButtonDisabled(false);
      setNoAccess(true);
    }
  }, [requiresAuthCheck]);

  const onSignInClick = useCallback(() => {
    const pop = window.open(getLogInUrl(), '_blank');

    pop?.addEventListener('unload', () => {
      window.location.reload();
    });
  }, []);

  return (
    <Container>
      <div>
        <img src="/FirewallKey@2x.png" alt="firewall" />
        {needAuth ? (
          <>
            <p>Looks like you haven't signed in to Global State.</p>
            <Button appearance="primary" onClick={onSignInClick}>
              Sign in
            </Button>
          </>
        ) : (
          <>
            <p>This integration requires storage access.</p>
            {noAccess && <NoAccess>Authorization failed. Please try again.</NoAccess>}
            <Button appearance="primary" onClick={onClick} isDisabled={buttonDisabled}>
              Authorize
            </Button>
          </>
        )}
      </div>
    </Container>
  );
};
