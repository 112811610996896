import { AbstractMentionResource, MentionContextIdentifier, MentionNameDetails } from '@atlaskit/mention';
import { gql } from '@apollo/client';
import { Search } from 'js-search';

import { apolloClient } from 'src/clients/apolloClient';
import { GET_USER_PROFILE } from 'src/graphql/queries/GetUserProfile';

export const USERS_QUERY = gql`
  query MentionPicker($displayName: String, $workspaceUuids: [UUID]) {
    userSearch(q: $displayName, workspaceUuids: $workspaceUuids) {
      id
      aaid
      pii {
        name
        nickname
        picture
      }
    }
  }
`;

const toMentionDetail = (searchResult: {
  aaid: string;
  pii: {
    name: string;
    nickname: string;
    picture: string;
  };
}): MentionNameDetails & { avatarUrl: string } => ({
  id: searchResult.aaid,
  name: searchResult.pii.name,
  avatarUrl: searchResult.pii.picture,
  status: 2,
});

export class MentionProvider extends AbstractMentionResource {
  private mentionNames: Map<string, MentionNameDetails> = new Map();

  async filter(query?: string | undefined, contextIdentifier?: MentionContextIdentifier | undefined) {
    const mentionResult = await this.getAllMentionsForWorkspace(contextIdentifier?.containerId);

    const search = new Search('id');

    search.addIndex('name');
    search.addIndex('mentionName');
    search.addIndex('nickname');
    search.addDocuments(mentionResult);

    if (!query) {
      this._notifyListeners({ mentions: mentionResult, query: '' });
    } else {
      this._notifyListeners({ mentions: search.search(query) as MentionNameDetails[], query });
    }
  }

  async resolveMentionName(id: string): Promise<MentionNameDetails> {
    if (!this.mentionNames.has(id)) {
      await this.fetchUser(id);
    }

    const mention = this.mentionNames.get(id);

    if (mention) {
      return mention;
    }

    return {
      id,
      name: 'unknown',
      status: 0,
    };
  }

  supportsMentionNameResolving() {
    return true;
  }

  private cacheMentionName(mention: MentionNameDetails) {
    this.mentionNames.set(mention.id, mention);
  }

  private async fetchUser(aaid: string) {
    try {
      const result = await apolloClient.query({
        query: GET_USER_PROFILE,
        variables: {
          aaid,
        },
      });

      if (result && result.data) {
        const { userByAaid } = result.data;

        this.cacheMentionName(toMentionDetail(userByAaid));
      }
    } catch (err) {
      //
    }
  }

  private async getAllMentionsForWorkspace(workspaceUuid?: string) {
    try {
      const result = await apolloClient.query({
        query: USERS_QUERY,
        variables: {
          workspaceUuids: [workspaceUuid],
        },
      });

      if (result && result.data) {
        const { userSearch } = result.data;
        const mentions = userSearch.map(toMentionDetail).filter((m: MentionNameDetails) => m.name);

        mentions.forEach((mention: MentionNameDetails) => this.cacheMentionName(mention));

        return mentions;
      }
    } catch (err) {
      return [];
    }
  }
}
