import { StatusLozenge } from '@tc/StatusLozenge';
import { State } from '@queries/GetProjectPageItems';
import { GoalState } from '@generated/graphql';

interface Props {
  state: State | GoalState;
  score?: number | null;
  isDashboard?: boolean;
  isSidebarGoal?: boolean;
  isGroup?: boolean;
  isGoal?: boolean;
}

export const StatusOrPhaseLozenge = ({ state, score, isDashboard = false, isGroup, isGoal }: Props) => (
  <StatusLozenge state={state} score={score} isDashboard={isDashboard} isGroup={isGroup} isGoal={isGoal} />
);
