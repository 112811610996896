import { useCallback, useState } from 'react';
import Tooltip from '@atlaskit/tooltip';
import { LinkItem } from '@atlaskit/menu';
import OfficeBuildingIcon from '@atlaskit/icon/glyph/office-building';

import { WorkspaceDetails } from '@shared/ProfileAndWorkspaceSwitcher';

const MAX_WIDTH_OF_TEXT_BEFORE_TRUNCATING = 424;

type Props = {
  onItemClick: (workspace: WorkspaceDetails) => void;
  workspace: WorkspaceDetails;
};

type WorkspaceLinkItemProps = {
  workspace: WorkspaceDetails;
  handleClick: () => void;
  setIncludeTooltip: (value: boolean) => void;
};

const WorkspaceLinkItem = ({ workspace, handleClick, setIncludeTooltip }: WorkspaceLinkItemProps) => (
  <div>
    <LinkItem
      iconBefore={<OfficeBuildingIcon label="Workspace option" />}
      key={`workspace-switcher-option-${workspace.globalId}`}
      testId={`workspace-switcher-option-${workspace.globalId}`}
      onClick={handleClick}
    >
      <span
        ref={(el) => {
          if ((el?.offsetWidth ?? 0) >= MAX_WIDTH_OF_TEXT_BEFORE_TRUNCATING) {
            setIncludeTooltip(true);
          }
        }}
      >
        {workspace.name}
      </span>
    </LinkItem>
  </div>
);

export const SwitchWorkspaceItem = ({ onItemClick, workspace }: Props) => {
  const [includeTooltip, setIncludeTooltip] = useState(false);
  const handleOnClick = useCallback(() => onItemClick(workspace), [onItemClick, workspace]);

  return includeTooltip ? (
    <Tooltip key={workspace.globalId} content={workspace.name}>
      <WorkspaceLinkItem workspace={workspace} setIncludeTooltip={setIncludeTooltip} handleClick={handleOnClick} />
    </Tooltip>
  ) : (
    <WorkspaceLinkItem workspace={workspace} setIncludeTooltip={setIncludeTooltip} handleClick={handleOnClick} />
  );
};
