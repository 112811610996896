import styled from 'styled-components';

import { SkeletonLoader } from '@shared/SkeletonLoader';

export const ValidationLoaderBar = styled(SkeletonLoader)`
  width: 280px;
  height: 16px;
`;

export const ValidationLoader = () => <ValidationLoaderBar />;
