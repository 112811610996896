import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useQueryParam } from 'react-resource-router';

import { endOfWeek, isLastWeek, startOfWeek, tzDate } from '@tc/util/date';
import { getStartDate, getEndDate } from '@tc/UpdatesPage/utils';
import { formatIsoLocalDateTime } from '@tc/util/dateFormat';

type TargetDate = {
  startDate: Date;
  endDate: Date;
};

type TargetWeekContextType = TargetDate & {
  setStartDate: (date: Date) => void;
};

type Props = { children: ReactNode };

export const TargetWeekContext = createContext<TargetWeekContextType>({
  startDate: new Date(),
  endDate: new Date(),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setStartDate: (date) => {},
});

export const useTargetWeek = () => useContext(TargetWeekContext);

export const TargetWeekProvider = ({ children }: Props) => {
  const [start, setStart] = useQueryParam('start');
  const [end, setEnd] = useQueryParam('end');

  const setDates = (date: Date) => {
    if (isLastWeek(date)) {
      setStart(undefined);
      setEnd(undefined);

      return;
    }

    const newStartDate = startOfWeek(date);
    const newEndDate = endOfWeek(date);

    setStart(formatIsoLocalDateTime(newStartDate));
    setEnd(formatIsoLocalDateTime(newEndDate));
  };

  const startDate = useMemo(() => tzDate(getStartDate({ start }).toISOString()), [start]);
  const endDate = useMemo(() => tzDate(getEndDate({ end }).toISOString()), [end]);

  return (
    <TargetWeekContext.Provider value={{ startDate, endDate, setStartDate: setDates }}>
      {children}
    </TargetWeekContext.Provider>
  );
};
